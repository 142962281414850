import React from "react";
import { LabelIcons, Logout } from "../SVGs/OtherSvgs";

const TextInputReadOnl = ({ placeholder, type = "text" }) => (
    <div className="read-only-bg flex flex-row m-2">
        <input type={type} readOnly value={placeholder} className="font-bold flex-grow focus:outline-none menu-phone" />
        {LabelIcons.PhoneNumber}
    </div>
);

export default TextInputReadOnl;
// <TextInput value={firstName} setValue={setFirstName} required placeholder="Name" />
