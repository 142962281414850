import React from "react";
import { TicketSvgs } from "../../SVGs/Tickets";
import Language from "../../Utils/Languages";
const toggleCollaps = {
    Close: "Open",
    Open: "Close",
};

const TicketIconCard = ({ icon, children, onClick, collaps }) => {
    return (
        <div className="rounded-3 carbonic-card shadow-1 p-4 m-4 ">
            <div
                onClick={() => {
                    onClick(collaps ? toggleCollaps[icon] : icon);
                }}
                className="flex flex-row items-center icon-card">
                {TicketSvgs[icon]}
                <p className="pr-4 carbonic font-bold">{Language[icon]}</p>
            </div>
            <div className="p-2">{!collaps && children}</div>
        </div>
    );
};

export default TicketIconCard;
