import React, { useEffect, useState } from "react";
import Scroller from "../ComponentsV2/Scroller";
import ListButton from "../ComponentsV2/ListButton";
import TicketCard from "./Components/TicketCard";
import Ticket from "../API/Ticket";
import Language from "../Utils/Languages";
import TicketIconCard from "./Components/TicketIconCard";
import DialogCombo from "../ComponentsV2/DialogCombo";
import { Link } from "react-router-dom";
import EmptyList from "../ComponentsV2/EmptyList";
import ChangeTitle from "../Utils/ChangeTitle";
import TicketCardClosed from "./Components/TicketCardClosed";
import IconCard from "./Components/IconCard";
import { getBusinesses } from "../Utils/Utils";

let canLoadMoreOpen = false;
let canLoadMoreClose = true;
let businesses = [
    {
        title: Language.AllCompanies,
        id: "",
    },
];

const BusinessTicketsView = () => {
    const [dialogInfoHolder, setDialogInfoHolder] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(businesses.find((b) => b.id === localStorage.getItem("selected-business")) || businesses[0]);

    const [collaps, setCollaps] = useState("Close");

    const [openedTickets, setOpenedTickets] = useState([]);
    const [closedTickets, setClosedTickets] = useState([]);

    const fetchTickets = async (clear = false, _collaps = collaps) => {
        if (_collaps === "Open") {
            let newTickets = await Ticket.getTickets(clear ? 0 : closedTickets.length, selectedBusiness?.value, "&state=2");
            setClosedTickets(clear ? newTickets : [...closedTickets, ...newTickets]);
            canLoadMoreClose = newTickets.length > 24;
        } else {
            let newTickets = await Ticket.getTickets(clear ? 0 : openedTickets.length, selectedBusiness?.value, "&state=0");
            setOpenedTickets(clear ? newTickets : [...openedTickets, ...newTickets]);
            canLoadMoreOpen = newTickets.length > 24;
        }
    };

    const search = (trem) => {
        canLoadMoreOpen = true;
        canLoadMoreClose = true;
        setClosedTickets([]);
        setOpenedTickets([]);
        fetchTickets(true, collaps, trem);
    };

    useEffect(() => {
        setTimeout(async () => {
            businesses = await getBusinesses();
            businesses.map((b) => (b.value = b.id));
            setSelectedBusiness(businesses[0]);
        }, 0);
    }, []);

    useEffect(() => {
        search();
    }, [selectedBusiness]);

    const onTicketsConatinerClicked = (_collaps) => {
        fetchTickets(false, _collaps);
        setCollaps(_collaps);
    };
    const changeCombo = async () => {
        setDialogInfoHolder({ items: businesses, onClick: (item) => setSelectedBusiness(item) });
    };

    return (
        <>
            <Scroller fetchData={fetchTickets} canLoadMore={collaps === "Open" ? canLoadMoreClose : canLoadMoreOpen}>
                <div className="col-span-full">
                    <IconCard icon="Company">
                        <ListButton canSelect={businesses?.length > 1} onClick={changeCombo} title={selectedBusiness.title} />
                    </IconCard>
                </div>

                <TicketIconCard icon="Open" collaps={collaps === "Open"} onClick={onTicketsConatinerClicked}>
                    {openedTickets.length > 0 || canLoadMoreOpen ? openedTickets.map((item, index) => <TicketCard key={index} item={item} />) : <EmptyList />}
                </TicketIconCard>
                <TicketIconCard icon="Close" collaps={collaps === "Close"} onClick={onTicketsConatinerClicked}>
                    {closedTickets.length > 0 || canLoadMoreClose ? (
                        closedTickets.map((item, index) => <TicketCardClosed key={index} item={item} />)
                    ) : (
                        <EmptyList />
                    )}
                </TicketIconCard>
            </Scroller>

            {dialogInfoHolder && <DialogCombo dialogInfoHolder={dialogInfoHolder} close={() => setDialogInfoHolder(false)} />}
            <Link onClick={() => ChangeTitle(Language.NewTicket)} to="/tickets/new/" style={{ top: 0 }} className="absolute left-2 z-40 row items-center">
                <p className="font-bold carbonic">{Language.Create}</p>
                <svg className="h-10 p-2 carbonic-card-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.77 329.7">
                    <path
                        d="M495.72,665.06q-52.75,0-105.53,0c-32.08,0-54.24-22.24-54.26-54.43q0-105.76,0-211.52c0-32.12,22.22-54.22,54.43-54.24q76,0,152,0c14.24,0,23.52,12.75,18.61,25.48-2.44,6.35-7.08,10.43-13.85,11.67a46.74,46.74,0,0,1-8.45.62q-72.53,0-145,0c-1.67,0-3.34,0-5,0-9.59.23-14.9,5.31-14.91,14.92q-.09,107.26,0,214.53c0,9.87,5.3,15.06,15.25,15.07q107,.07,214.07,0c10.49,0,15.27-5.31,15.27-16.58q0-74.5.07-149a34.32,34.32,0,0,1,1.26-10.33c2.79-8.74,11.78-13.78,21-12.27,8.93,1.45,15.44,9.15,15.46,18.77q.12,51.26.05,102.51c0,17.5.1,35,0,52.51-.22,29.52-22.86,52.13-52.42,52.23C567.74,665.14,531.73,665.05,495.72,665.06Z"
                        transform="translate(-335.91 -335.38)"
                    />
                    <path
                        d="M467.74,533.35c2.3-12.16,4.33-23.27,6.58-34.34.3-1.48,1.59-2.9,2.72-4q76.33-76.43,152.74-152.77c8.9-8.88,21.63-9,29.92-.71s8,20.91-1,29.91q-76.14,76.25-152.45,152.34a13.48,13.48,0,0,1-6.41,3.26C489.47,529.23,479,531.14,467.74,533.35Z"
                        transform="translate(-335.91 -335.38)"
                    />
                </svg>
            </Link>
            {/* <Link
                to={{ pathname: "tickets/new", title: Language.NewTicket }}
                className="bg-green-6 rounded-2xl self-start px-4 py-2 mb-4 font-bold text-white text-xl">
                {Language.AddTicket}
            </Link> */}
        </>
    );
};

export default BusinessTicketsView;
