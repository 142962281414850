import React from "react";
import useLocalStorage from "@rehooks/local-storage";
import BackButton from "./BackButton";

const TopBar = ({}) => {
    const [tobBar] = useLocalStorage("topBar");
    return (
        <>
            <BackButton />
            <div className="sticky left-0  right-0 top-0 flex flex-row items-center justify-center z-10 py-2">
                {tobBar === "logo" ? (
                    <>
                        <svg height={25} className="topbar-logo fade-in-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.1 417">
                            <path
                                d="M292,631V366a18.84,18.84,0,0,0,.91-2.75c5.43-32,23.27-54.24,53-66.85,5.75-2.44,12-3.63,18.08-5.4H636a22.32,22.32,0,0,0,2.79.94c40,7.65,69,41.87,69.17,82.66.26,66.31.09,132.63.06,198.95a77.2,77.2,0,0,1-8.77,36.56c-16.53,31.25-42.7,47.21-78,47.38-54.49.27-109,.07-163.47.07H451V708H375c-1.6-.61-3.17-1.63-4.81-1.77-34.28-2.94-63.21-25.51-73.86-58C294.49,642.63,293.42,636.75,292,631Zm111-20.44h7q104.22,0,208.45,0c25.82,0,42.57-16.73,42.58-42.51q.06-94,0-187.95c0-25.83-16.71-42.62-42.46-42.64q-118.47-.06-237,0c-25.82,0-42.62,16.79-42.64,42.48q0,43,0,86,0,76.23,0,152.46c0,17.7,9.67,32.38,25.3,38.36,12.53,4.8,25.55,2.24,38.7,2.82Z"
                                transform="translate(-292 -291)"
                            />
                        </svg>
                        <svg height={25} className="carbonic-tint px-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 712 271.03">
                            <path
                                d="M516,364c0,29.13.44,58.27,0,87.4-.24,15.8-16.68,30.11-33.58,30.16-23.64.07-47.28-.21-70.92-.26-7,0-14,.29-21,.29a34.8,34.8,0,0,1-34.66-29.18c-2.78-17.73,7-34,23.84-39.61a34.74,34.74,0,0,1,42.12,16.86c2.45,4.76,3.77,10.11,5.82,15.79h45.21a73,73,0,0,0-8.35-35.59c-13.11-25.33-33.88-40.42-62.15-45A33.74,33.74,0,0,1,399,364H382c-3.72.82-7.45,1.57-11.15,2.47-29,7.11-56.47,31.48-61.9,69.91-2.05,14.55,1,28,5.73,41.39.42,1.21.93,2.39,1.64,4.22h-58.1v41.33c-2.13.12-3.87.3-5.62.32-8.65.09-17.31.13-26,.22-18.8.2-35.1-15.37-35.51-34.61-.4-18.47-.16-37-.2-55.44V427H144v67c.76,3.86,1.42,7.75,2.29,11.58,8.68,38,40.47,64.11,79.6,64.72,26.15.41,52.32.13,78.48.13,1.1,0,2.2-.22,3.66-.38V528.92h5.24q80.48-.15,160.94-.32c5.16,0,10.33-.18,15.47-.59,39.2-3.2,72.61-38.25,73.25-77.54.47-28.81.08-57.64.07-86.47Z"
                                transform="translate(-144 -364)"
                            />
                            <path
                                d="M851.05,417.92c-11.8-30.11-33.86-47.67-65.72-53A32.84,32.84,0,0,1,782,364H766c-6.91,1.77-14,2.93-20.69,5.4a82,82,0,0,0-47.48,107.66c.48,1.22.91,2.47,1.56,4.24H647V364H600q0,90,.07,180a35.31,35.31,0,0,1-29.19,34.81c-2.23.38-4.51.52-6.58.76v46.37c19.77-.3,37.12-6.12,52.14-18.38,24.91-20.33,32.71-47.5,30.23-78.93h7.15c39.5,0,79,.25,118.49-.08,33.49-.28,59-15,74.66-44.8,4.32-8.22,6.09-17.79,9-26.74V435C854.37,429.3,853.2,423.42,851.05,417.92Zm-41.39,29c-.8,20.13-16.81,35.56-36.2,34.87-20.12-.71-35.93-17.19-35.13-36.62a35.82,35.82,0,0,1,37.37-34.38C794.7,411.43,810.4,428.14,809.66,446.94Z"
                                transform="translate(-144 -364)"
                            />
                            <path
                                d="M523.75,544a23.74,23.74,0,0,0-23.67,23.44c-.12,13.3,10,23.65,23.12,23.7a23.63,23.63,0,0,0,23.88-23.64A23.11,23.11,0,0,0,523.75,544Z"
                                transform="translate(-144 -364)"
                            />
                            <path
                                d="M225.51,587.89a23.62,23.62,0,0,0-23.59,23.93A23.11,23.11,0,0,0,225.54,635a23.75,23.75,0,0,0,23.38-23.72A23,23,0,0,0,225.51,587.89Z"
                                transform="translate(-144 -364)"
                            />
                            <path
                                d="M286.8,587.89a23.63,23.63,0,0,0-23.88,23.57A23.11,23.11,0,0,0,286.11,635c13.57,0,22.81-9.63,22.81-23.87C308.92,597.22,300.11,587.94,286.8,587.89Z"
                                transform="translate(-144 -364)"
                            />
                        </svg>
                    </>
                ) : (
                    <p className="font-bold fade-in-top carbonic text-center text-xl">{tobBar}</p>
                )}
            </div>
        </>
    );
};

export default TopBar;
