import React from "react";
import Language from "../Utils/Languages";

const DetailsLabel = ({ details, title = "LastUpdate" }) =>
    details ? (
        <p className="font-bold carbonic-2 pb-2">
            <span>{Language[title]}</span> : <span className="orange-text">{details}</span>
        </p>
    ) : (
        <></>
    );
export default DetailsLabel;
