import { Options, ShortCurrences } from "../Settings";

const FormateCurrency = (val, _currencyId = undefined) => {
    let currencyId = _currencyId || Options.currency.value;
    let newVal = `${val}`?.replace("-", "").split("."),
        beforPoint = newVal[0],
        length = beforPoint?.length,
        owl = newVal[1] && !newVal[1]?.startsWith("00") ? `.${newVal[1].length > 2 ? newVal[1].substring(0, 2) : newVal[1]}` : "";
    for (let o = length; o > 0; o -= 3) o - 3 > 0 ? (owl = `,${beforPoint.substring(o, o - 3)}${owl}`) : (owl = beforPoint.substring(0, o) + owl);

    let formated = `${owl}  ${ShortCurrences[currencyId]}`;
    return val >= 0 ? formated : `${formated} -`;
};

export default FormateCurrency;