import Language from "../Utils/Languages";
import BusinessAPI from "../API/Business";
import { BackUp, Edit, Wornning } from "../SVGs/OtherSvgs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import LoaderComponent from "../Components/LoaderComponent";
import TimeSetting from "../OnlineDbBackup/Views/TimeSetting";
import BottomConfirmPopup from "./Components/BottomConfirmPopup";
import { Category, Company, LocationMark } from "../SVGs/BusinessesSvgs";
import AddOwnerPopup from "./Components/AddOwnerPopup";
import OwnerCard from "./Components/OwnerCard";
import ChangeTitlePopup from "./Components/ChangeTitlePopup";
import DialogConatiner from "../ComponentsV2/DialogConatiner";
import Info from "../NodeBuilder/Info";
import Confirmer from "../Containers/Confirmer";

const BusinessView = () => {
    const [owners, setOwners] = useState([]);
    const [business, setBusiness] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showSetBackupTime, setShowSetBackupTime] = useState(false);
    const [isSubedToOnlineBackup, setIsSubedToOnlineBackup] = useState(false);
    const [popupInput, setPopupInput] = useState("");

    let params = useParams();
    let navigate = useNavigate();

    const fetchBusiness = () => {
        BusinessAPI.getBusiness(params.id)
            .then((result) => {
                setBusiness(result.data.business);
                setOwners(Object.values(result.data.owners));
            })
            .finally(() => setIsLoading(false));
    };

    const removeOwnership = (password) => {
        setPopupInput(false);
        BusinessAPI.removeOwnership(business.id, password)
            .then(() => {
                sessionStorage.clear();
                navigate("/businesses/");
            })
            .catch(() => Info({ title: "RongPassword", type: "error" }));
    };

    useEffect(() => {
        fetchBusiness();
    }, []);

    useEffect(() => {
        if (!business) return;
        BusinessAPI.isSubedTo(business.id, "com.morabaa.onlinedbbackup")
            .then((res) => {
                setIsSubedToOnlineBackup(res.response?.status === 404 ? false : true);
            })
            .catch(() => setIsSubedToOnlineBackup(false));
    }, [business]);

    const addOwner = async (ownerId) => {
        Confirmer({
            title: Language.AreYouSuer,
            confirm: async () => {
                await BusinessAPI.addOwner(business.id, ownerId);
                window.location.reload();
            },
        });
    };

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <div className="scrller-container scroller p-4">
            <div onClick={() => setPopupInput(business.title)} style={{ top: 0 }} className="absolute top-0 left-3 cursor-pointer font-bold z-20">
                {Edit}
            </div>
            {business && (
                <div className="rounded-2xl p-2 col w-80 mx-auto">
                    {Company}
                    <div className="flex flex-row cursor-pointer items-center justify-center py-3">
                        <p className="font-bold carbonic text-base pt-1">{business.title}</p>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex-1 col items-center">
                            {LocationMark}
                            <p className="font-medium carbonic-3 text-center pt-2">{business.address}</p>
                        </div>
                        <p style={{ width: 3, height: 15, backgroundColor: "#f48114", borderRadius: 25, marginTop: 10 }} />
                        <div className="flex-1 col items-center">
                            {Category}
                            <p className="font-medium carbonic-3 text-center pt-2">{business.businessCategory.title}</p>
                        </div>
                    </div>
                </div>
            )}
            {isSubedToOnlineBackup && (
                <>
                    <div
                        onClick={() => setShowSetBackupTime(!showSetBackupTime)}
                        className="flex flex-row items-center red-bg rounded-xl max-w-fit my-4 py-2 px-4 shadow-1 cursor-pointer">
                        {BackUp}
                        <p className="font-bold text-xl text-center text-white">{Language.BackupTime}</p>
                    </div>
                    {showSetBackupTime && (
                        <DialogConatiner close={() => setShowSetBackupTime(!showSetBackupTime)}>
                            <div className="self-center" onClick={(e) => e.stopPropagation()} style={{ margin: "0 auto" }}>
                                <TimeSetting
                                    className="bg-gray-50 rounded-3xl px-12 py-4"
                                    businessId={business?.id}
                                    onSave={() => setShowSetBackupTime(false)}
                                />
                            </div>
                        </DialogConatiner>
                    )}
                </>
            )}
            <div className="col carbonic-card rounded-xl p-3 shadow-1 my-2">
                <p className="font-bold text-3xl carbonic p-3 col-span-full">{Language.Owners}</p>
                {owners.map((owner) => (
                    <OwnerCard key={owner.id} owner={owner} />
                ))}

                <p onClick={() => setPopupInput("addOwner")} className="font-bold cursor-pointer text-center green-text my-3 text-base">
                    <span className="text-2xl"> + </span>
                    <span>{Language.AddOwner}</span>
                </p>
            </div>

            <div className="flex flex-row items-center justify-center cursor-pointer my-6" onClick={() => setPopupInput("cancelOwnership")}>
                {Wornning}
                <p className="font-bold red-text px-1">{Language.CancelOwnerShip}</p>
            </div>
            {popupInput === "cancelOwnership" ? (
                <BottomConfirmPopup onSubmit={removeOwnership} close={() => setPopupInput(false)} />
            ) : popupInput === "addOwner" ? (
                <AddOwnerPopup
                    owners={owners}
                    addOwner={(ownerId) => {
                        addOwner(ownerId);
                    }}
                    close={() => setPopupInput(false)}
                />
            ) : (
                popupInput === business.title && <ChangeTitlePopup business={business} setBusiness={setBusiness} close={() => setPopupInput(false)} />
            )}
        </div>
    );
};

export default BusinessView;
