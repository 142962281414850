import React from "react";

const CurrencyLabelIcon = ({ price }) => (
    <>
        <svg height={36} className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
            <path
                className="border"
                d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
            />
            <path
                d="M688.36,358.05V462.81a22.17,22.17,0,0,0-.73,3.36c-.9,14.81-7.26,27-17.61,37.39L503.31,670.31c-7,7-15.11,12.51-24.81,15.06-5.1,1.34-10.37,2-15.57,2.93h-1a11.6,11.6,0,0,0-2.39-.64c-16.16-.7-29-7.93-40.26-19.25-29.71-30-59.65-59.77-89.5-89.63-23.87-23.87-24.34-58.44-.57-82.35q83.36-83.79,167.08-167.21c8.68-8.69,18.91-14.52,31.08-16.65a45.89,45.89,0,0,1,7.93-.75c36.39,0,72.79-.4,109.17.26,20,.36,37.32,15.74,42,35.26C687.27,350.86,687.73,354.48,688.36,358.05Zm-94.8,19.84a27.94,27.94,0,1,0,28.08,27.55A28,28,0,0,0,593.56,377.89Z"
                transform="translate(-108 -111)"
            />
        </svg>
        <p className="font-bold p-4 text-center">
            <span className="carbonic">{price.amount}</span>
            <br />
            <span className="font-bold carbonic-3">{price.currency}</span>
        </p>
    </>
);

export default CurrencyLabelIcon;
