import FormateCurrency from "../Utils/CurrencyFormater";
import { GetFormatedDate } from "../Utils/DateFromater";
import Formate from "../Utils/Formate";

export const DailyFormat = (data) => {
    let formated = [];
    data.forEach((element, i) => {
        let haveCustomLabels = ["00000007", "00000008", "00000009"].indexOf(element.business.category.split("-")[0]) > -1 || i === 0;
        let business = element.business;
        let amounts = FormateAmounts(element);
        let date = FormateDate(element);

        formated.push({ id: business.id, haveCustomLabels, date, businessTitle: business.title, category: business.category, ...amounts });
    });

    return formated;
};

export const TransactionsFormater = (data) => {
    let arr = [];
    data.forEach((element) => {
        element.amount = FormateCurrency(element.amount, element.currencyId);
        element.description = element.description.split("~/");
        element.date = GetFormatedDate(element);
        arr.push(element);
    });
};

export const DailyFormatOfBusiness = (data) => {
    let formated = [];
    let currencyId = localStorage.getItem("mainCurrencyId");

    if (currencyId === 1) {
        data.forEach((element) => {
            let { profitInIQD, saleInIQD, expenseInIQD, purchasesInIQD, id, date, businessId } = element;
            let businessTitle = element.businessTitle;
            let amounts = [
                (profitInIQD = Formate(profitInIQD || 0, 1)),
                (saleInIQD = Formate(saleInIQD || 0, 1)),
                (expenseInIQD = Formate(expenseInIQD || 0, 1)),
                (purchasesInIQD = Formate(purchasesInIQD || 0, 1)),
            ];
            date = GetFormatedDate(date);
            formated.push({ id, amounts, date, businessTitle, businessId });
        });
    } else {
        data.forEach((element) => {
            let { profitInUSD, saleInUSD, expenseInUSD, purchasesInUSD, id, date, businessId } = element;
            let businessTitle = element.businessTitle;
            let amounts = [
                (profitInUSD = Formate(profitInUSD || 0, 2)),
                (saleInUSD = Formate(saleInUSD || 0, 2)),
                (expenseInUSD = Formate(expenseInUSD || 0, 2)),
                (purchasesInUSD = Formate(purchasesInUSD || 0, 2)),
            ];
            date = GetFormatedDate(date);

            formated.push({ id, amounts, date, businessTitle, businessId });
        });
    }
    return formated;
};

function FormateDate(element) {
    let dateObj = new Date(element.date);
    if (dateObj > new Date()) {
        dateObj.setHours(dateObj.getHours() - 3);
        element.date = dateObj.toISOString();
    }
    let date = GetFormatedDate(element);
    return date;
}

function FormateAmounts(element) {
    let amounts = {
        profits: [],
        sales: [],
        expenses: [],
        purchases: [],
    };
    Object.keys(amounts).forEach((newAmountKey) => {
        Object.keys(element[newAmountKey]).forEach((amountKey) => {
            let amountObj = element[newAmountKey][amountKey];
            if (amountObj) amounts[newAmountKey].push(FormateCurrency(amountObj, amountKey));
        });
        if (amounts[newAmountKey].length === 0) amounts[newAmountKey] = [FormateCurrency(0)];
    });
    return amounts;
}
