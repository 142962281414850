import React from "react";

const ToggleButton = ({ onClick, value, icon = 0, className, title }) => {
    return (
        <div onClick={onClick} className="w-full">
            <div className={`tint-settings shadow carbonic-card rounded-5 p-4 min-w-max ${className}`}>
                {Icons[icon]}
                <p className="font-bold pt-3">
                    <span className="carbonic"> {value} </span>
                    <br />
                    <span className="carbonic-3"> {title} </span>
                </p>
            </div>
        </div>
    );
};

const Icons = [
    //
    <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.59 376.62">
        <path
            d="M499.6,688.3C395.17,687.69,310.92,603,311.71,499.35c.8-104.32,85.21-188.26,188.69-187.66,104.46.62,188.74,85.38,187.89,189C687.42,605,603.08,688.91,499.6,688.3ZM613,611.9l1.51.4c41-45.33,55.73-97.8,38.82-157.21-14.33-50.32-47.68-84.42-97.14-105,0,8.31.47,15.31-.1,22.23A36.43,36.43,0,0,1,525.91,405c-5.71.93-11.6.71-17.41.94-13.31.53-24.29,9.71-26.57,22.81-.81,4.71-.64,9.6-.77,14.42-.31,11.13-7.7,18.7-18.85,19.1-2.94.1-5.9,0-8.9,0v47h5.9c16.16,0,32.32-.12,48.48,0,22.41.21,37.89,14.84,39.26,36.85.42,6.67,3.65,10,10.41,10.24,7,.24,14-.17,21,.36,19.7,1.5,34.34,17.41,34.59,37.22C613.09,599.94,613,605.92,613,611.9ZM480.8,613.25l-2.64-.3c-19.65-2.2-33.14-16.07-34.54-35.81-.39-5.47.1-11-.36-16.47a11.79,11.79,0,0,0-2.8-6.92q-45.57-45.94-91.43-91.6c-1-1-2.16-1.86-3.4-2.93-26.34,97.06,44,190.54,135.17,199Z"
            transform="translate(-311.71 -311.69)"
        />
    </svg>,

    <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.026 182.026">
        <g>
            <path
                d="M31.859,91.005c0-29.508,21.753-53.977,50.061-58.382v12.881l36.396-22.755L81.913,0v14.222
			c-38.388,4.545-68.266,37.218-68.266,76.784c0,27.809,14.789,52.153,36.849,65.778l17.853-11.141
			C46.939,136.717,31.859,115.602,31.859,91.005z"
            />
            <path
                d="M131.525,25.237l-17.851,11.157c21.418,8.892,36.499,30.012,36.499,54.611c0,29.532-21.749,53.985-50.058,58.396v-12.887
			l-36.402,22.764l36.402,22.747v-14.244c38.386-4.53,68.263-37.199,68.263-76.776C168.379,63.207,153.59,38.865,131.525,25.237z"
            />
            <path
                d="M95.401,130.802v-9.942c11.204-1.953,17.362-9.362,17.362-18.041c0-8.777-4.679-14.134-16.275-18.241
			c-8.298-3.121-11.716-5.166-11.716-8.383c0-2.733,2.045-5.464,8.395-5.464c7.015,0,11.496,2.235,14.041,3.319l2.819-11.023
			c-3.203-1.557-7.602-2.919-14.131-3.208v-8.586h-9.562v9.261c-10.42,2.041-16.473,8.777-16.473,17.355
			c0,9.458,7.123,14.344,17.568,17.846c7.204,2.441,10.323,4.782,10.323,8.493c0,3.896-3.796,6.041-9.354,6.041
			c-6.336,0-12.093-2.048-16.183-4.29l-2.925,11.411c3.693,2.148,10.041,3.899,16.569,4.189v9.264H95.401z"
            />
        </g>
    </svg>,

    <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 378">
        <path
            d="M502,311c10.13,4.05,12.22,7.18,12.23,18.38,0,8.14.08,16.29,0,24.43-.13,8.71-6,14.72-14.2,14.71s-14.07-6-14.19-14.72c-.11-8.14,0-16.29,0-24.43,0-11.23,2.12-14.39,12.23-18.37Z"
            transform="translate(-311 -311)"
        />
        <path
            d="M311,498c4.23-10.28,7.15-12.23,18.35-12.24,8.14,0,16.29-.08,24.44,0,8.74.13,14.72,6,14.72,14.19s-6,14.07-14.7,14.21c-8.15.13-16.3,0-24.44,0-11.21,0-14.18-2-18.37-12.24Z"
            transform="translate(-311 -311)"
        />
        <path
            d="M689,502c-4.17,10.22-7.18,12.24-18.38,12.24-8.14,0-16.29.08-24.43,0-8.73-.14-14.72-6-14.73-14.21s6-14.06,14.73-14.19c8.15-.12,16.29-.05,24.44,0,11.22,0,14.21,2,18.37,12.24Z"
            transform="translate(-311 -311)"
        />
        <path
            d="M498,689c-10.13-4-12.23-7.12-12.23-18.35,0-8.14-.09-16.29,0-24.43.15-8.8,5.92-14.67,14.18-14.69s14,5.91,14.2,14.69c.13,8.14,0,16.28,0,24.43,0,11.22-2.11,14.37-12.23,18.35Z"
            transform="translate(-311 -311)"
        />
        <path d="M396.16,500a103.84,103.84,0,1,1,104,103.85A103.94,103.94,0,0,1,396.16,500Z" transform="translate(-311 -311)" />
        <path
            d="M411.28,395.49c-.51,6.74-2.67,11.16-7.73,13.84-5.22,2.75-10.7,2.82-15-1.05A325.94,325.94,0,0,1,365.24,385c-4.82-5.34-3.68-13.58,1.44-18.58,4.94-4.83,13-5.92,18.21-1.27,8.16,7.32,15.8,15.25,23.3,23.27C410.07,390.47,410.57,393.78,411.28,395.49Z"
            transform="translate(-311 -311)"
        />
        <path
            d="M637.81,378.45c-1,1.9-2.26,5.84-4.78,8.63-6.12,6.8-12.72,13.2-19.35,19.51-6.38,6.07-14.84,6-20.58.29s-5.77-14.2.31-20.56c6.56-6.86,13.29-13.58,20.2-20.09,4.53-4.27,10-5,15.76-2.46C634.74,366.2,637.26,370.66,637.81,378.45Z"
            transform="translate(-311 -311)"
        />
        <path
            d="M396.45,588.18c6.2.61,10.62,3.27,13.22,8.45s2.54,10.68-1.41,15c-7.34,7.93-14.94,15.66-23,22.89-5.29,4.76-13.71,3.75-18.63-1.2s-5.88-13.37-1.1-18.64c7.25-8,15-15.54,22.94-22.92C390.51,589.85,393.77,589.32,396.45,588.18Z"
            transform="translate(-311 -311)"
        />
        <path
            d="M638,622.89c-.64,5.9-3.07,10.28-8.08,12.86s-10.74,2.78-15-1.11c-8.12-7.38-15.94-15.14-23.3-23.26-4.67-5.16-3.67-13.21,1.14-18.21,5-5.17,13.16-6.39,18.55-1.59,8.18,7.3,15.81,15.24,23.3,23.26C636.46,616.83,636.9,620.17,638,622.89Z"
            transform="translate(-311 -311)"
        />
    </svg>,
    <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 369.35 370">
        <path
            d="M438,315c12,5.43,14.36,12.35,8.43,24.34-40.9,82.73-5.92,181.11,78.35,218.13,44.8,19.67,89.88,18.33,134.27-2.49,3.15-1.48,6.31-3.26,9.65-3.91,5.46-1.06,10.2.95,13.61,5.48,3.55,4.71,3.78,9.88,1.36,15.08a190.59,190.59,0,0,1-26,41.28c-30.46,36.71-68.91,60.12-116,68.72-10.1,1.84-20.45,2.28-30.69,3.37h-3c-2.47-.26-4.93-.66-7.4-.75a183.12,183.12,0,0,1-67.7-15.37c-85-37.25-132-125.92-112.66-216.56C334,387.86,373.24,342.57,433,315Z"
            transform="translate(-315.91 -315)"
        />
    </svg>,

    <svg className="svg-size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 376.93">
        <path
            d="M349,650V463a32.85,32.85,0,0,0,.94-3.32,41.94,41.94,0,0,1,40-34.84c10-.36,20-.28,30-.08,3.7.07,4.88-1,4.83-4.77-.15-12.83-.36-25.68.21-38.49,1.93-42.81,44.31-76.49,86.48-69C549.53,319.26,575,349.3,575.3,388c.1,10.83.13,21.66,0,32.49,0,3.33,1.09,4.29,4.32,4.25,10.33-.13,20.67-.26,31,.13A42,42,0,0,1,650,459.2c.25,1.28.66,2.54,1,3.81V650c-.79,3-1.44,6.13-2.4,9.11-5.94,18.29-21.92,29.19-43,29.2q-93.15.06-186.29,0c-10,0-20,.2-30-.19A42.08,42.08,0,0,1,350,653.84C349.75,652.55,349.34,651.29,349,650ZM546.91,424.4c0-13.85.46-27.33-.1-40.77-1-22.88-20.28-42-43.16-43.53-23.72-1.55-45.92,14.06-49.25,36.84-2.26,15.48-1.37,31.42-1.9,47.46ZM566,575.17a18.66,18.66,0,1,0-18.89-18.58A18.79,18.79,0,0,0,566,575.17ZM434.8,537.87a18.66,18.66,0,1,0,18.08,19.36A18.79,18.79,0,0,0,434.8,537.87Zm65.39,37.3a18.66,18.66,0,1,0-19-18.46A18.75,18.75,0,0,0,500.19,575.17Z"
            transform="translate(-349 -311.46)"
        />
    </svg>,
];
export default ToggleButton;
