import { SalesReportAPI } from "./remoteAPI";
import { TransactionsFormater } from "../DataFormater/Home";
import Formate from "../Utils/Formate";
import { CurrencesTitles } from "../Settings";
import { GetFormatedDate, TimeToString } from "../Utils/DateFromater";

const Statuses = {
    //
    "دائن / علينا": 1,
    "مدين / لنا": 2,
    "": 0,
    null: 0,
};

const SalesReport = {
    getSalesPerDay: () => SalesReportAPI.get("/v3/nets"),
    getSalePerDay: (businessId) => SalesReportAPI.get(`/v3/nets/${businessId}`),
    getAccounts: async (offset, businessId = "", searchQuery = "") => {
        let url = `/v1/accounts/?offset=${offset}`;
        if (businessId) url += `&businessId=${businessId}`;
        if (searchQuery) url += `&q=${searchQuery}`;

        let arr = [];
        let res = await SalesReportAPI.get(url);
        res?.data?.forEach((account) => {
            let balances = account.balances;
            balances
                ?.sort((a) => a.currencyId)
                ?.forEach((_balance) => {
                    _balance.amount = Formate(Math.abs(_balance.amount));
                    _balance.state = Statuses[_balance.state];
                    _balance.currency = CurrencesTitles[_balance.currencyId];
                });
            account.balances = balances;
            arr.push(account);
        });
        return arr;
    },
    getThisMonth: (businessId) => SalesReportAPI.get(`/v2/nets/${businessId}/this-month`),
    getTransactions: async (accountId, currencyId, onComplate) => {
        let { data } = await SalesReportAPI.get(`/v1/transactions/${accountId}/${currencyId}`);
        data.forEach((element) => {
            element.date = GetFormatedDate(element);
            element.balance = Formate(element.balance);
            element.amount = Formate(element.amount);
        });
        onComplate(data);
    },
    getTotals: (businessId, url = `/v2/accounts/totals/${businessId ? `?businessId=${businessId}` : ""}`) => SalesReportAPI.get(url),
    getBusinessTransactions: ({ Done, Error }, offset, { businessId, opType }) => {
        SalesReportAPI.get(`/v2/businesstransactions/ofbusiness/${businessId}?offset=${offset}&type=${opType || -1}`)
            .then(({ data }) => {
                TransactionsFormater(data);
                Done(data);
            })
            .catch(Error);
    },
    getLastUpdate: (businessId, setLastUpdate) =>
        businessId
            ? SalesReportAPI.get(`/v1/lastupdates/ofbusiness/${businessId}`)
                  .then(({ data }) => {
                      data.updatedAt ? setLastUpdate(TimeToString(data.updatedAt)) : setLastUpdate(null);
                  })
                  .catch(() => setLastUpdate(null))
            : setLastUpdate(null),
    getCustomers: async (offset, phoneNumber) => {
        let url = `/v1/businesses/ofclient/${phoneNumber}?offset=${offset}`;
        let arr = [];
        let { data } = await SalesReportAPI.get(url);
        data.forEach((account) => {
            let balances = account.balances?.filter((balance) => balance.amount !== 0) || [];
            balances
                ?.sort((a) => a.currencyId)
                ?.forEach((_balance) => {
                    _balance.amount = Formate(Math.abs(_balance.amount));
                    _balance.state = Statuses[_balance.state];
                    _balance.currency = CurrencesTitles[_balance.currencyId];
                });
            account.balances = balances?.length > 0 ? balances : null;
            arr.push(account);
        });
        return arr;
    },
    toggleFavorite: (id) => SalesReportAPI.post(`/v1/accounts/${id}/toggle-favorite`),
    getTotalAccounts: (businessId) => SalesReportAPI.get(`/v1/accounts/count?businessId=${businessId}`),
};

export default SalesReport;
