import React from "react";
import { TicketSvgs } from "../../SVGs/Tickets";
import Language from "../../Utils/Languages";

const IconCard = ({ icon, children }) => {
    return (
        <div className="rounded-3 carbonic-card shadow-1 p-4 m-4">
            <div className="flex flex-row items-center icon-card">
                {TicketSvgs[icon]}
                <p className="pr-4 carbonic font-bold">{Language[icon]}</p>
            </div>
            {children}
        </div>
    );
};

export default IconCard;
