import React from "react";
import Avatar from "../../SVGs/Avatar";
import { Logout } from "../../SVGs/OtherSvgs";

const ProfileHolder = ({ user, onClick, onLogout }) => (
    <div className="menu-profile-border  flex flex-row items-center my-2" style={{ padding: 12, height: 100, borderRadius: 25 }}>
        {user?.image ? (
            <img onClick={() => onClick("/owner")} className="object-cover" src={user.image} style={{ width: 73, height: 73, borderRadius: 15 }} />
        ) : (
            <Avatar />
        )}
        <div style={{ alignItems: "flex-start", paddingRight: 20, flexGrow: 1 }}>
            <p className="carbonic font-bold">
                {user?.firstName} {user?.lastName}
            </p>
            <p className="menu-phone font-bold">{user?.phone_number}</p>
        </div>
        <div className="bg-logout p-3 rounded-md" onClick={onLogout}>
            {/* <TestIcon className="p-2" fill="#e3353c" height={40} /> */}
            {Logout}
        </div>
    </div>
);

export default ProfileHolder;
