import React from "react";
import ChangePasswordView from "../Owner/Views/ChangePasswordView";
import Language from "../Utils/Languages";
import ThirdPartyAppsComponent from "./Components/ThirdPartyAppsComponent";

const AccountSettingsView = () => {
    return (
        <div>
            <p className="font-bold text-base text-gray-700 mb-4">{Language.AccountSitting}</p>
            <ChangePasswordView />
            <p className="font-bold text-base text-gray-700 mt-8 mb-4">{Language.ThirdPartyApps}</p>
            <ThirdPartyAppsComponent />
        </div>
    );
};

export default AccountSettingsView;
