import { HubAPI } from "../API/remoteAPI";
import { getApi } from "../scripts/Fetcher";
import Language from "./Languages";
export default class Utils {
    static Round = (num) => Math.round(num * 100) / 100;
    static getStorage = (key) => JSON.parse(localStorage.getItem(key));
    static setStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));
    static removeAllChildNodes(parent) {
        while (parent.firstChild) parent.removeChild(parent.firstChild);
    }
    static uuid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
    // static hasValue = (value) => [undefined, null, -1, "", "-1"].includes(value) === false;
    static hasValue = (value) => [undefined, null, ""].includes(value) === false;
    static Formate = (amount) => {
        let newVal = `${amount}`?.replace("-", "").split("."),
            beforPoint = newVal[0],
            length = beforPoint?.length,
            owl = newVal[1] && !newVal[1]?.startsWith("00") ? `.${newVal[1].length > 2 ? newVal[1].substring(0, 2) : newVal[1]}` : "";
        for (let o = length; o > 0; o -= 3) o - 3 > 0 ? (owl = `,${beforPoint.substring(o, o - 3)}${owl}`) : (owl = beforPoint.substring(0, o) + owl);
        return amount >= 0 ? owl : `- ${owl}`;
    };

    static FormatWithCurrency = (amount, currencyId) => {
        let newVal = `${amount}`?.replace("-", "").split("."),
            beforPoint = newVal[0],
            length = beforPoint?.length,
            owl = newVal[1] && !newVal[1]?.startsWith("00") ? `.${newVal[1].length > 2 ? newVal[1].substring(0, 2) : newVal[1]}` : "";
        for (let o = length; o > 0; o -= 3) o - 3 > 0 ? (owl = `,${beforPoint.substring(o, o - 3)}${owl}`) : (owl = beforPoint.substring(0, o) + owl);

        let formated = `${owl}  ${getShortCurrency(currencyId)}`;
        return amount >= 0 ? formated : `${formated} -`;
    };
}

export const getShortCurrency = (currencyId) =>
    ({ undefined: "", 0: "د.ع", 1: "د.ع", 2: "$", 3: "€", 4: "£", 5: "₪", 6: "₹", 7: "₩", 8: "¥", 9: "₺", 10: "₴", 11: "₫" }[currencyId]);

const testOptions = (count) => {
    let _options = [];
    for (let index = 0; index < count; index++) {
        _options.push({ title: index + Math.round(Math.random() * 10), id: index });
    }
    return _options;
};

export const getBusiness = async (businessId) => await (await getBusinesses(false)).find((b) => b.id === businessId);

export const getBusinesses = async (hasDefault = true) => {
    let stored = JSON.parse(sessionStorage.getItem("businesses-list"));
    if (!stored) {
        let businessesOptions = [];
        let { data } = await HubAPI.get("/businesses");
        data.forEach(({ business }) => {
            //.sort((a, b) => a.name.length - b.name.length)
            businessesOptions.push({ title: business.title, id: business.id, categoryId: business.businessCategory.id });
        });
        sessionStorage.setItem("businesses-list", JSON.stringify(businessesOptions));
        stored = businessesOptions;
    }

    return hasDefault && stored.length > 1 ? [{ key: "stoteId", title: "كل الشركات", id: "" }, ...stored] : stored;
};

const RepresentativesTiers = [
    "0eb492d5-51e5-4830-9c22-6849b090c397",
    "18405edf-e58b-4221-b369-c50a102e94bc",
    "2a9852a6-1281-47e1-a9a0-2c9f0277acb8",
    "e90fae80-13f2-426e-98df-31884d2cd24c",
    "6fd39a10-9715-423d-9c9d-2b10768df073",
    "4db955e5-865a-41da-9baa-8dc4ccc1b489",
];

export const getRestBusinesses = async () => (await getBusinesses(false)).filter((b) => b.categoryId === "00000004-0000-0000-0000-000000000000");
export const getRepsBusinesses = async () => (await getBusinesses(false)).filter((b) => RepresentativesTiers.includes(b));

export const AccountsStatues = {
    //
    "دائن / علينا": 1,
    "مدين / لنا": 2,
    "دائن علينا": 1,
    "مدين لنا": 2,
    "": 0,
    null: 0,
    undefined: 0,
};
