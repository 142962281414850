import React, { useEffect, useState } from "react";
import OnlineDbBackup from "../../API/OnlineDbBackup";
import Form from "../../Components/Form";
import LoaderComponent from "../../Components/LoaderComponent";
import Info from "../../NodeBuilder/Info";
import Language from "../../Utils/Languages";
import TimePicker from "../Components/TimePicker";

const TimeSetting = ({ businessId, ...props }) => {
    const [firstUploadTime, setFirstUploadTime] = useState(null);
    const [secondUploadTime, setSecondUploadTime] = useState("");

    const getDateTimeISOString = (timeString) => {
        let now = new Date();
        let split = timeString.split(":");
        let dt = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(split[0]), parseInt(split[1]), 0);
        return dt.toISOString();
    };

    const saveUploadTime = () => {
        let firstISOString = getDateTimeISOString(firstUploadTime);
        let secondISOString = getDateTimeISOString(secondUploadTime);
        OnlineDbBackup.setTimeSettings(firstISOString, secondISOString, businessId)
            .then(() => {
                Info({ title: "Saved" });
            })
            .catch((e) => {
                Info({ title: "InSaved" });
            })
            .finally(() => {
                if (props.onSave) props.onSave();
            });
    };

    useEffect(() => {
        if (!businessId) return;
        OnlineDbBackup.getTimeSettings(businessId)
            .then((response) => {
                setFirstUploadTime(new Date(response.data.firstBackupAt).toTimeString().split(" ")[0]);
                setSecondUploadTime(new Date(response.data.secondBackupAt).toTimeString().split(" ")[0]);
            })
            .catch(() => {
                setFirstUploadTime("09:00:00");
                setSecondUploadTime("20:00:00");
            });
    }, [businessId]);

    return firstUploadTime ? (
        <Form className="font-bold" onSubmit={saveUploadTime}>
            <dev className="flex flex-col ">
                {firstUploadTime && (
                    <>
                        <label>{Language.FirstTimeBackup}</label>
                        <TimePicker className="mb-2" value={firstUploadTime} onChange={setFirstUploadTime} />
                    </>
                )}
                {secondUploadTime && (
                    <>
                        <label>{Language.SecondTimeBackup}</label>
                        <TimePicker value={secondUploadTime} onChange={setSecondUploadTime} />
                    </>
                )}
            </dev>
        </Form>
    ) : (
        <LoaderComponent />
    );
};

export default TimeSetting;
