import { AppUpdate, AppUpdateAvailability } from "@capawesome/capacitor-app-update";
import { SafeArea } from "capacitor-plugin-safe-area";
import OneSignal from "onesignal-cordova-plugin";
import { App as Cap } from "@capacitor/app";
import Notifications from "../API/Notifications";
import React from "react";
import Language from "./Languages";
import PopupNotificatinoCard from "../Notifications/PopupNotificatinoCard";

const CapacitorInit = async () => {
    setTimeout(() => {
        performImmediateUpdate();
    }, 100);

    global.onBack = () => {
        window.history.back();
    };

    if (global.platform === "ios") {
        preventZooming();
        addSafeArea();
        addBackOnSwaip();
    } else if (global.platform === "android") {
        Cap.addListener("backButton", () => {
            if (window.location.pathname === "/") Cap.exitApp();
            else global.onBack();
        });
    }
    setOneSignal();
};

export default CapacitorInit;

async function addSafeArea() {
    const { insets } = await SafeArea.getSafeAreaInsets();
    const root = document.querySelector(":root");
    root.style.setProperty("--safe-area-top", `${insets.top + 4}px`);
    root.style.setProperty("--safe-area-bottom", `${insets.bottom - 10}px`);
}

function preventZooming() {
    window.addEventListener(
        "touchmove",
        (e) => {
            if (e.scale !== 1) e.preventDefault();
        },
        { passive: false }
    );
}

function addBackOnSwaip() {
    let startX = 0;
    const threshold = 50;
    const backArea = 25;

    const backTrigger = () => {
        global.onBack();
    };

    document.addEventListener("touchstart", ({ touches }) => {
        startX = touches[0].clientX;
    });
    document.addEventListener("touchend", ({ changedTouches }) => {
        if ((startX < backArea || startX > window.innerWidth - backArea) && Math.abs(changedTouches[0].clientX - startX) > threshold) backTrigger();
    });
}

const setOneSignal = () => {
    try {
        OneSignal.setAppId("410398b1-baa8-4bb9-aeba-16ed0dc4322d");
        OneSignal.setNotificationOpenedHandler(function (data) {
            const notificationData = data.notification;
            let url = notificationData.additionalData?.url;
            if (url) {
                if (url.startsWith("http")) window.open(url, "_blank");
                else {
                    // let _url = window.location.pathname + url;
                    // _url = _url.replace("//", "/");
                    setTimeout(() => {
                        global.setPopup({
                            page: (
                                <PopupNotificatinoCard
                                    title={notificationData.title}
                                    content={notificationData.body}
                                    close={() => {
                                        global.setPopup();
                                    }}
                                    url={url}
                                    contfrimText={Language.Details}
                                    cancelText={Language.CloseIt}
                                />
                            ),
                        });
                    }, 300);
                }
            }
        });
        OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
            if (!localStorage.getItem("token")) return;
            if (accepted) {
                OneSignal.getDeviceState((s) => {
                    try {
                        Notifications.sendToken(s.userId);
                    } catch {}
                });
            } else {
                OneSignal.getDeviceState((s) => {
                    try {
                        Notifications.deleteToken(s.userId);
                    } catch {}
                });
            }
        });
    } catch {}
};
const performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE && result.immediateUpdateAllowed) await AppUpdate.performImmediateUpdate();
};
