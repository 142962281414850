import React from "react";

const Grid = ({ provider, ItemBuilder, className = "grid", onClick }) => {
    [provider.items, provider.setItems] = React.useState(provider.items);
    provider.setItem = React.useMemo(() => {
        return (item) =>
            provider.setItems((items) =>
                items.map((i) => {
                    if (i.id === item.id) {
                        i = item;
                    }
                    return i;
                })
            );
    }, []);
    React.useEffect(() => {
        provider.itemsChanged();
    }, [provider.items]);

    return (
        <div id="grid-container" className={className} onClick={onClick}>
            {provider.items.map((item, i) => (
                <ItemBuilder key={i} item={item} provider={provider} />
            ))}
        </div>
    );
};

export default React.memo(Grid);
