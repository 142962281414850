export const RightEdge = (
    <svg className="invoice-seprater-svg" style={{ zIndex: 1000 }} xmlns="http://www.w3.org/2000/svg" height="46" viewBox="20 0 20 46">
        <path
            d="M-988.01-1471.078q0-.05,0-.1v-7.293H-988v7.394Zm-45.99,0v-46h45.988v.13q0-.063,0-.13h.01v7.394h-.012v-7.264c-.112,4.453-2.9,7.984-4.091,9.3l-10.039,10.04a4.967,4.967,0,0,0-1.465,3.536,4.964,4.964,0,0,0,1.465,3.535l9.651,9.651-.008,0a.026.026,0,0,1,.009.008,16.727,16.727,0,0,1,2.182,2.767,13.893,13.893,0,0,1,2.3,6.936v.1Z"
            transform="translate(1029 1517.078)"
        />
    </svg>
);

export const LeftEdge = (
    <svg className="invoice-seprater-svg" style={{ zIndex: 1000 }} xmlns="http://www.w3.org/2000/svg" height="46" viewBox="20 0 20 46">
        <path
            d="M-1170-1471.078v-.4a13.307,13.307,0,0,1,1.78-5.793,17.667,17.667,0,0,1,2.357-3.266l10-10a4.968,4.968,0,0,0,1.465-3.535,4.971,4.971,0,0,0-1.465-3.536l-10.255-10.255a15.413,15.413,0,0,1-3.886-8.779v-.43h46v46Z"
            transform="translate(1190 1517.078)"
        />
    </svg>
);

export const TopStar = (
    <svg className="invoice-shape-svg absolute left-2 m-3" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 34.43 34.431">
        <path
            d="M17.215,34.43v0a24.439,24.439,0,0,0-6.313-10.9A24.433,24.433,0,0,0,0,17.214,24.425,24.425,0,0,0,10.9,10.9,24.43,24.43,0,0,0,17.215,0,24.429,24.429,0,0,0,23.53,10.9a24.426,24.426,0,0,0,10.9,6.314A24.467,24.467,0,0,0,17.216,34.427Z"
            transform="translate(0 0.001)"
        />
    </svg>
);

export const BillNumber = (
    <svg className="invoice-number-svg" height={36} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="invoice-border-svg"
            d="M673.21,156A174.81,174.81,0,0,1,848,330.79V676.21A174.81,174.81,0,0,1,673.21,851H327.79A174.81,174.81,0,0,1,153,676.21V330.79A174.81,174.81,0,0,1,327.79,156H673.21m0-45H327.79C206.4,111,108,209.4,108,330.79V676.21C108,797.6,206.4,896,327.79,896H673.21C794.6,896,893,797.6,893,676.21V330.79C893,209.4,794.6,111,673.21,111Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M549,330c6,2.48,7.86,7.12,7.85,13.42q-.15,156-.07,311.94c0,10.33-3.09,13.45-13.33,13.46q-25,0-50,0c-9.34,0-12.6-3.33-12.6-12.7q0-156.48-.09-312.94c0-6.45,2.11-10.86,8.21-13.17Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M455,330c5.92,2.64,7.61,7.28,7.6,13.57q-.18,156-.09,312c0,10.23-3.08,13.21-13.51,13.23-4.34,0-8.67.07-13,0-7.89-.16-11.25-3.49-11.5-11.22,0-.66,0-1.33,0-2q0-156-.09-312c0-6.29,1.68-10.93,7.6-13.57Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M360,330c6.26,2.08,8.36,6.36,8.35,12.92q-.23,156.51-.11,313c0,9.81-3,12.81-12.77,12.86-4,0-8-.19-12,.06-6.34.39-11-1.86-13.48-7.82V338l8-8Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M670,661.08c-2.54,5.94-7.14,8.21-13.47,7.82-4.15-.26-8.33,0-12.5-.06-9.09-.08-12.27-3.25-12.27-12.35q0-96.71,0-193.4c0-40,.11-80.09-.1-120.13,0-6.56,2.09-10.84,8.35-12.92h22l8,8Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M605,330c6.14,2.23,8.23,6.48,8.22,13Q613,499.54,613.1,656c0,9.76-3,12.79-12.81,12.84-4.16,0-8.32,0-12.49,0-9.37-.07-12.7-3.34-12.71-12.58q0-57,0-114.1,0-99.4-.08-198.82c0-6.39,1.93-10.9,8-13.31Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M399,330c5.5,2.83,7.22,7.34,7.21,13.44Q406,499.73,406.09,656a20.87,20.87,0,0,1-.89,6.83,9.19,9.19,0,0,1-10.35,5.93,9.39,9.39,0,0,1-8-8.92c-.08-1.16,0-2.33,0-3.49q0-156.75-.11-313.51c0-6,1.79-10.22,7.23-12.79Z"
            transform="translate(-108 -111)"
        />
    </svg>
);

export const TotalAmout = (
    <svg height={36} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="invoice-border-svg"
            d="M673.21,156A174.81,174.81,0,0,1,848,330.79V676.21A174.81,174.81,0,0,1,673.21,851H327.79A174.81,174.81,0,0,1,153,676.21V330.79A174.81,174.81,0,0,1,327.79,156H673.21m0-45H327.79C206.4,111,108,209.4,108,330.79V676.21C108,797.6,206.4,896,327.79,896H673.21C794.6,896,893,797.6,893,676.21V330.79C893,209.4,794.6,111,673.21,111Z"
            transform="translate(-108 -111)"
        />
        <path
            fill="#f48114"
            d="M349,633V332c6.25-11.66,12-13.56,24.31-8.09,9.26,4.1,18.59,8,27.72,12.4a11.44,11.44,0,0,0,10.35.25c11.46-4.84,23.11-9.23,34.61-14a17.27,17.27,0,0,1,14,.05c11.5,4.75,23.17,9.1,34.63,13.94a12.81,12.81,0,0,0,10.86.05c11.46-4.84,23.12-9.22,34.61-14a17.27,17.27,0,0,1,14-.05c11.66,4.81,23.44,9.32,35.09,14.14a10.69,10.69,0,0,0,9.44-.13c9.3-4.41,18.75-8.49,28.16-12.67,12.34-5.49,18.08-3.57,24.3,8.09V633a11.15,11.15,0,0,0-.89,2.2A51.89,51.89,0,0,1,599,679.07q-98.94.1-197.89,0a52.07,52.07,0,0,1-49-35.15C350.81,640.36,350,636.66,349,633ZM499.28,462.1h85.45c1.66,0,3.33,0,5-.06,7.59-.4,13-5.5,13.48-12.75s-3.92-13.23-11.33-14.73a33,33,0,0,0-6.45-.42q-85.45,0-170.9,0a31.49,31.49,0,0,0-6.94.56c-7.29,1.67-11.49,7.75-10.72,15.14.8,7.65,6.44,12.22,15.47,12.23Q455.82,462.16,499.28,462.1ZM462,519v0c-16.64,0-33.29-.07-49.93,0-9.42,0-15.37,5.63-15.3,14s6.21,13.84,15.55,13.84q49.94,0,99.88,0c9.6,0,15.82-5.61,15.77-14.1,0-8.26-6.11-13.76-15.53-13.8C495.59,519,478.78,519,462,519Zm.57,84.4q23.7,0,47.42,0a46.3,46.3,0,0,0,6.46-.37,13.46,13.46,0,0,0,11.52-12c.59-6.48-3-12.65-9-14.56a24.59,24.59,0,0,0-7.37-1q-49.17-.09-98.34,0a30.09,30.09,0,0,0-5.45.47,13.42,13.42,0,0,0-11,12.43c-.59,8.79,5.44,14.89,15.33,15C428.91,603.53,445.72,603.42,462.53,603.43Zm117.29-56.49v0c3.49,0,7,.2,10.47,0a13.76,13.76,0,0,0,13-14.31A13.53,13.53,0,0,0,590,519.13c-6.47-.21-13-.18-19.43,0-7.9.2-13.58,5.91-13.73,13.51a13.8,13.8,0,0,0,13.53,14.29C573.5,547.07,576.67,546.94,579.82,546.94Zm.27,28.36c-4,.2-8.05.1-12,.67-6.82,1-11.38,6.78-11.29,13.73s4.85,12.83,11.72,13.3a172.17,172.17,0,0,0,22.94,0c6.84-.45,11.68-6.4,11.79-13.26S598.81,577,592,576C588.09,575.4,584.06,575.5,580.09,575.3Z"
            transform="translate(-108 -111)"
        />
    </svg>
);
export const ThePrice = (
    <svg height={36} className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="border"
            d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
        />
        <path
            d="M688.36,358.05V462.81a22.17,22.17,0,0,0-.73,3.36c-.9,14.81-7.26,27-17.61,37.39L503.31,670.31c-7,7-15.11,12.51-24.81,15.06-5.1,1.34-10.37,2-15.57,2.93h-1a11.6,11.6,0,0,0-2.39-.64c-16.16-.7-29-7.93-40.26-19.25-29.71-30-59.65-59.77-89.5-89.63-23.87-23.87-24.34-58.44-.57-82.35q83.36-83.79,167.08-167.21c8.68-8.69,18.91-14.52,31.08-16.65a45.89,45.89,0,0,1,7.93-.75c36.39,0,72.79-.4,109.17.26,20,.36,37.32,15.74,42,35.26C687.27,350.86,687.73,354.48,688.36,358.05Zm-94.8,19.84a27.94,27.94,0,1,0,28.08,27.55A28,28,0,0,0,593.56,377.89Z"
            transform="translate(-108 -111)"
        />
    </svg>
);

export const CloseIcon = (
    <svg fill="#9c9da4" className="absolute m-3" height={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.38 377.38">
        <g>
            <circle cx="188.69" cy="188.69" r="188.69" />
        </g>
        <rect fill="#fff" x="96.66" y="173.68" width="185.03" height="30.19" rx="15.1" transform="translate(188.89 -78.48) rotate(45)" />
        <rect fill="#fff" x="96.46" y="173.31" width="185.03" height="30.19" rx="15.1" transform="translate(455.82 188) rotate(135)" />
    </svg>
);

export const TotalsSvg = (
    <svg fill="#f48114" height={36} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <rect width="785" height="785" rx="219.79" />
        <path
            fill="#fff"
            d="M349,633V332c6.25-11.66,12-13.56,24.31-8.09,9.26,4.1,18.59,8,27.72,12.4a11.44,11.44,0,0,0,10.35.25c11.46-4.84,23.11-9.23,34.61-14a17.27,17.27,0,0,1,14,.05c11.5,4.75,23.17,9.1,34.63,13.94a12.81,12.81,0,0,0,10.86.05c11.46-4.84,23.12-9.22,34.61-14a17.27,17.27,0,0,1,14-.05c11.66,4.81,23.44,9.32,35.09,14.14a10.69,10.69,0,0,0,9.44-.13c9.3-4.41,18.75-8.49,28.16-12.67,12.34-5.49,18.08-3.57,24.3,8.09V633a11.15,11.15,0,0,0-.89,2.2A51.89,51.89,0,0,1,599,679.07q-98.94.1-197.89,0a52.07,52.07,0,0,1-49-35.15C350.81,640.36,350,636.66,349,633ZM499.28,462.1h85.45c1.66,0,3.33,0,5-.06,7.59-.4,13-5.5,13.48-12.75s-3.92-13.23-11.33-14.73a33,33,0,0,0-6.45-.42q-85.45,0-170.9,0a31.49,31.49,0,0,0-6.94.56c-7.29,1.67-11.49,7.75-10.72,15.14.8,7.65,6.44,12.22,15.47,12.23Q455.82,462.16,499.28,462.1ZM462,519v0c-16.64,0-33.29-.07-49.93,0-9.42,0-15.37,5.63-15.3,14s6.21,13.84,15.55,13.84q49.94,0,99.88,0c9.6,0,15.82-5.61,15.77-14.1,0-8.26-6.11-13.76-15.53-13.8C495.59,519,478.78,519,462,519Zm.57,84.4q23.7,0,47.42,0a46.3,46.3,0,0,0,6.46-.37,13.46,13.46,0,0,0,11.52-12c.59-6.48-3-12.65-9-14.56a24.59,24.59,0,0,0-7.37-1q-49.17-.09-98.34,0a30.09,30.09,0,0,0-5.45.47,13.42,13.42,0,0,0-11,12.43c-.59,8.79,5.44,14.89,15.33,15C428.91,603.53,445.72,603.42,462.53,603.43Zm117.29-56.49v0c3.49,0,7,.2,10.47,0a13.76,13.76,0,0,0,13-14.31A13.53,13.53,0,0,0,590,519.13c-6.47-.21-13-.18-19.43,0-7.9.2-13.58,5.91-13.73,13.51a13.8,13.8,0,0,0,13.53,14.29C573.5,547.07,576.67,546.94,579.82,546.94Zm.27,28.36c-4,.2-8.05.1-12,.67-6.82,1-11.38,6.78-11.29,13.73s4.85,12.83,11.72,13.3a172.17,172.17,0,0,0,22.94,0c6.84-.45,11.68-6.4,11.79-13.26S598.81,577,592,576C588.09,575.4,584.06,575.5,580.09,575.3Z"
            transform="translate(-108 -111)"
        />
    </svg>
);
