import React from "react";
import Fotter from "./Fotter";
import RepliessCard from "./RepliessCard";
import ListLoader from "../ComponentsV2/ListLoader";
import TimeLine from "../Tickets/Components/TimeLine";
import GetRating from "../Components/GetRating";

const Chat = ({ fetchRepliess, repliess, canLoadMore, sendReply, canReply, ticket, isLoading, children }) => {
    const onContainerScroll = ({ target }) => {
        if (canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 250) {
            canLoadMore = false;
            fetchRepliess(false);
        }
    };
    const containerRef = React.useRef(null);
    return (
        <>
            <div ref={containerRef} onScroll={onContainerScroll} className="scrller-container min-h-screen scroller flex flex-col-reverse">
                {!canReply && (
                    // <div className="fixed bottom-10 left-0 right-0">
                    <TimeLine time={`${ticket.updatedAt} تم الأغلاق `} color="#dd3643" />
                    // </div>
                )}
                {repliess.map((item, index) => (
                    <RepliessCard item={item} key={index} />
                ))}
                {isLoading ? (
                    <ListLoader />
                ) : (
                    <div className="flex flex-col items-center flex-grow">
                        {children}
                        {ticket && (
                            <>
                                <p className="font-bold carbonic py-4">{ticket.title}</p>
                                {ticket.rating > 0 && (
                                    <>
                                        <GetRating rating={ticket.rating} />
                                        <p>{ticket.ratingDescription}</p>
                                    </>
                                )}
                                <p>{ticket.body}</p>
                            </>
                        )}
                        {ticket?.displayDay && <p className="carbonic-card rounded-5 self-center px-6 py-2 shadow-1 font-bold">{ticket?.displayDay}</p>}
                    </div>
                )}
                {canLoadMore ? <ListLoader /> : <TimeLine time={`${ticket.openedAt} تم الفتح `} />}
            </div>
            {canReply && <Fotter chatRef={containerRef} sendReply={sendReply} placeholder="اكتب شيئا" />}
        </>
    );
};

export default Chat;
