import React from "react";
import Language from "../Utils/Languages";
const amoutyTypes = {
    profits: { icon: <img src="/Home/profit_g.png" style={{ height: 32, width: 57 }} />, title: Language.Profit },
    sales: { icon: <img src="/Home/sales_g.png" style={{ height: 34, width: 30 }} />, title: Language.Sale },
};

const amoutCutomTypes = {
    profits: { icon: <img src="/Home/profit_g.png" style={{ height: 32, width: 57 }} />, title: Language.Profit },
    sales: { icon: <img src="/Home/sales_g.png" style={{ height: 34, width: 30 }} />, title: Language.Income },
};

const GlowLabel = ({ total, prop, color }) => {
    const amountType = total.haveCustomLabels ? amoutCutomTypes[prop] : amoutyTypes[prop];
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: 114, justifyContent: "space-around" }}>
            {amountType.icon}
            <p className="text-center">
                <span className="carbonic font-medium">{amountType.title}</span>
                {total[prop]?.map((amount, i) => (
                    <span key={i} className={`font-bold ${color}`}>
                        <br />
                        {amount}
                    </span>
                ))}
            </p>
        </div>
    );
};

export default GlowLabel;
