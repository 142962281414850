import { OnlineDbBackupAPI } from "./remoteAPI";

const OnlineDbBackup = {
    setTimeSettings: (firstUploadTime, secondUploadTime, businessId) => {
        let data = {
            firstBackupAt: firstUploadTime,
            secondBackupAt: secondUploadTime,
            businessId,
        };
        return OnlineDbBackupAPI.post("/settings", data);
    },
    getTimeSettings: (businessId) => OnlineDbBackupAPI.get(`/settings/${businessId}`),
};

export default OnlineDbBackup;
