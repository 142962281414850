import React from "react";
import Language from "../Utils/Languages";

const FormTextArea = ({ value, setValue, required, className, placeholder, rows = 6, onFocus }) => {
    return (
        <textarea
            type="text"
            value={value}
            rows={rows}
            onFocus={onFocus}
            required={required}
            placeholder={Language[placeholder]}
            onChange={({ target }) => setValue(target.value)}
            className={`font-bold carbonic-card-1 flex-grow outline-none carbonic ${className}`}
        />
    );
};

export default FormTextArea;
