import React from "react";
import Language from "../Utils/Languages";
import Line from "./Line";
//<img src="/accounts/accoutsNet.png" className="tint-2 mx-2" style={{ height: 22, width: 19 }} />

const amoutyTypes = {
    expenses: { icon: <img src="/Home/exp.png" className="tint-1 mx-2" style={{ height: 17, width: 23 }} />, title: Language.Expense },
    purchases: { icon: <img src="/Home/pur.png" className="tint-1 mx-2" style={{ height: 22, width: 25 }} />, title: Language.Purchases },
};
const amoutCutomTypes = {
    expenses: { icon: <img src="/Home/exp.png" className="tint-1 mx-2" style={{ height: 17, width: 23 }} />, title: Language.Dicounts },
    purchases: { icon: <img src="/Home/pur.png" className="tint-1 mx-2" style={{ height: 22, width: 25 }} />, title: Language.Purchases },
};

const HeaderLabel = ({ total, prop, color, extra }) => {
    const amountType = total.haveCustomLabels ? amoutCutomTypes[prop] : amoutyTypes[prop];

    return (
        <div className={extra} style={{ padding: 10, flex: 1 }}>
            <div className="flex flex-row items-center">
                <Line />
                {amountType.icon}
                <p className="font-bold carbonic">{amountType.title}</p>
            </div>
            <p className="font-bold carbonic text-left">
                {total[prop]?.map((amount, i) => (
                    <span className={`font-bold ${color}`} key={i}>
                        {amount}
                        <br />
                    </span>
                ))}
            </p>
        </div>
    );
};

export default HeaderLabel;
