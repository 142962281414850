import React from "react";
const String = ({ prop }) => {
    return (
        <div key={prop.key} style={{ alignItems: "center" }} className={prop.containerClass + " relative row"}>
            <input
                type="text"
                tabIndex="-1"
                style={{ flexGrow: 1 }}
                id={prop.key}
                name={prop.key}
                placeholder={prop.title}
                defaultValue={prop.value}
                onChange={({ target }) => {
                    checkChange(target, prop);
                }}
                className="input"
            />
            <span
                className={`clear-icon ${prop.value ? "" : "hide"}`}
                onClick={({ target }) => {
                    prop.value = "";
                    target.previousSibling.value = "";
                    target.nextSibling.style.display = "block";
                    target.classList.add("hide");
                    if (prop.storageKey) localStorage.setItem(prop.storageKey, prop.value);
                    prop.onChange();
                }}>
                x
            </span>
            <svg
                style={{ position: "absolute", left: 10, top: 10, display: prop.value ? "none" : "block" }}
                height={20}
                className="tint  cursor-pointer pr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 340 340">
                <path
                    d="M330,474V460c.6-4.25,1.26-8.48,1.8-12.74,6.53-51.12,46.65-97.64,96.49-111.23,10.35-2.82,21.13-4.06,31.71-6h14c6.06.9,12.16,1.64,18.19,2.74,79.21,14.39,129,95.56,106,172.79-4.69,15.78-12.13,30.14-22.47,44.28a43.84,43.84,0,0,1,4.39,3.38C607,580,633.91,606.81,660.6,633.83c3.74,3.79,6.29,8.75,9.4,13.17v8a23.07,23.07,0,0,1-15,15h-8c-4.42-3.11-9.37-5.67-13.16-9.42-27.14-26.81-54.05-53.86-81-80.83-1.15-1.16-2.37-2.24-3.4-3.21a3.21,3.21,0,0,0-.82.14c-1,.65-1.92,1.31-2.88,2-28.34,19.69-59.69,28-94,24.26-57.37-6.26-105.23-49.14-117.84-105.44C332.13,489.76,331.27,481.83,330,474Zm38.31-7a98.88,98.88,0,0,0,98.77,98.83c54.11,0,98.5-44.31,98.71-98.47.21-54.46-44-98.83-98.56-99S368.35,412.47,368.31,467Z"
                    transform="translate(-330 -330)"
                />
            </svg>
        </div>
    );
};

export default React.memo(String);

const checkChange = (target, prop) => {
    let pastValue = target.value;
    setTimeout(() => {
        if (pastValue !== target.value) return;
        {
            prop.value = target.value;
            const clear = prop.showInClearBar
                ? () => {
                      prop.value = "";
                      target.value = "";
                      target.nextSibling.classList.add("hide");
                      prop.onChange();
                  }
                : null;
            if (prop.storageKey) localStorage.setItem(prop.storageKey, prop.value);
            prop.onChange(clear);
            if (target.value) {
                target.nextSibling.classList.remove("hide");
                target.parentElement.lastChild.style.display = "none";
            } else {
                target.nextSibling.classList.add("hide");
                target.parentElement.lastChild.style.display = "block";
            }
        }
    }, 700);
};
