import { Capacitor } from "@capacitor/core";
import { isAndroid, isMobile } from "react-device-detect";
import Info from "../NodeBuilder/Info";
import { Div, LinkButton, P, Span } from "../scripts/NodeBuilder";
import Language from "./Languages";

const InstallPWA = () => {
    if (localStorage.getItem("dont-show-install-toast")) return;
    if (isMobile && !Capacitor.isNativePlatform()) {
        const container = Div({
            className: "flex flex-row gap-2 fontBold",
        });
        const installBtn = LinkButton({
            id: "install-pwa-button",
            className: "cursor-pointer font-bold shadow-1 rounded-md text-red text-center px-2",
            href: isAndroid
                ? "https://play.google.com/store/apps/details?id=com.morabaa.mymorabaa&hl=en&gl=US"
                : "https://apps.apple.com/us/app/%D9%85%D8%B1%D8%A8%D8%B9%D9%8A-mymorbaa/id1581315442",
            innerText: Language.DownloadFromStore,
        });
        const neverShowAgain = P({
            className: "cursor-pointer font-bold shadow-1 rounded-md text-red text-center px-2",
            innerText: "عدم الاظهار مرة اخرى",
            onclick: () => {
                localStorage.setItem("dont-show-install-toast", true);
            },
        });
        container.append(installBtn);
        container.append(neverShowAgain);
        Info({ title: "InstallTheApp", content: "", children: container, timeout: 10000 });
    } else {
        const handler = (pwa) => {
            pwa.preventDefault();
            const container = Div({
                className: "flex flex-row gap-2 fontBold",
            });
            const installBtn = Span({
                id: "install-pwa-button",
                className: "cursor-pointer font-bold shadow-1 rounded-md text-red text-center px-2",
                innerText: Language.Install,
                onclick: () => {
                    pwa.prompt();
                },
            });
            const neverShowAgain = P({
                className: "cursor-pointer font-bold shadow-1 rounded-md text-red text-center px-2",
                innerText: "عدم الاظهار مرة اخرى",
                onclick: () => {
                    localStorage.setItem("dont-show-install-toast", true);
                },
            });
            container.append(installBtn);
            container.append(neverShowAgain);

            Info({ title: "InstallTheApp", content: "", children: container, timeout: 10000 });
            window.removeEventListener("transitionend", handler);
        };
        window.addEventListener("beforeinstallprompt", handler);
    }
};

// const InstallPWA = () => {
//     if (localStorage.getItem("dont-show-install-toast") || (isMobile && !Capacitor.isNativePlatform())) return;
//     const handler = (pwa) => {
//         pwa.preventDefault();
//         const removeToast = Info({
//             title: Language.InstallTheApp,
//             content: "",
//             children: InstallBtn,
//             childreProps: {
//                 install: () => {
//                     removeToast();
//                     pwa.prompt();
//                 },
//                 dontShowAgaine: () => {
//                     removeToast();
//                     localStorage.setItem("dont-show-install-toast", true);
//                 },
//             },
//             timeout: 10000,
//         });
//         window.removeEventListener("transitionend", handler);
//     };
//     window.addEventListener("beforeinstallprompt", handler);
// };

// const InstallBtn = ({ install, dontShowAgaine }) => {
//     return (
//         <div className="flex flex-row gap-2 fontBold pt-2">
//             <p className="shadow-lg rounded-md px-3 py-1" onClick={install}>
//                 {Language.Install}
//             </p>
//             <p className="shadow-lg rounded-md px-3 py-1" onClick={dontShowAgaine}>
//                 {"عدم الاظهار مرة اخرى"}
//             </p>
//         </div>
//     );
// };

export default InstallPWA;
