import React from "react";
import AccountCard from "./Components/AccountCard";
import AccountsUtils from "./AccountsUtils";
import AccountsHeader from "./Components/AccountsHeader";
import PageBuilder from "../PageBuilder/PageBuilder";

const AccountsView = () => {
    return (
        <PageBuilder
            itemBuilder={AccountCard}
            headerBuilder={AccountsHeader}
            id="accounts"
            apiId="sales"
            url="v1/accounts"
            onResult={AccountsUtils.onResult}
            queryChanged={AccountsUtils.queryChanged}
            queryParams={AccountsUtils.queryParams}
        />
    );
};

export default AccountsView;
