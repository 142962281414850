import moment from "moment";
import { TicketAPI } from "../API/remoteAPI";
import "moment/locale/ar";
import { TimeToString } from "../Utils/DateFromater";
import Info from "../NodeBuilder/Info";

const FormateTicket = async (data) => {
    let arr = [];
    data.forEach((item) => {
        let dsiscriptions = item.lastReply || item.ticket?.text;
        dsiscriptions = dsiscriptions.length > 75 ? `${dsiscriptions.substring(0, 75)}...` : dsiscriptions;
        let title = item?.ticket?.title;
        let date = TimeToString(item.lastUpdateAt || item.ticket.createdAt);
        arr.push({
            title,
            date,
            dsiscriptions,
            id: item.ticket.id,
            state: item.state.state,
            businessTitle: item.businessTitle,
        });
    });
    return arr;
};

const Ticket = {
    getTickets: async (offset, businessId, state, trem) => {
        let uri = businessId ? `ofbusiness/${businessId}` : `ofowner/me`;
        let { data } = await TicketAPI.get(`/tickets/${uri}/?offset=${offset}${state && state}`);
        let formatedTickets = await FormateTicket(data);

        return formatedTickets;
    },

    testerws: {
        createdAt: "2022-04-18T14:03:50.423075+03:00",
        deleted: false,
        id: "6645fea9-c69a-4342-b89a-a7048e310199",
        replierType: "owner",
        replyBy: "78df5c38-c462-4bc0-9a4b-be8676898f50",
        replyByFullName: "Owner Test",
        text: "تيست",
        ticket: null,
        ticketId: "f22fcbbb-b696-4779-ba73-3fc669ab08f2",
    },

    getTicket: async (ticketId, setTicket) => {
        let { data } = await TicketAPI.get(`/tickets/${ticketId}`);
        let ticket = data.ticket;
        let state = data.states.sort((a, b) => a.updatedAt > b.updatedAt)[0];
        ticket.updatedAt = TimeToString(state.updatedAt);
        ticket.openedAt = TimeToString(data.ticket.openedAt);
        ticket.state = state.state;
        ticket.attachments = data.attachments;

        let userId = JSON.parse(localStorage.getItem("owner")).id;
        ticket.attachments = [
            {
                replyBy: userId,
                text: ticket.title + "\n" + ticket.text,
            },
        ];

        data.attachments.forEach((element) => {
            element.replyBy = userId;
            ticket.attachments.push(element);
        });
        setTicket(ticket);
    },

    postTicket: (data) => {
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key === "photos")
                data[key].forEach(({ name, data }) => {
                    let type = `image/${name.substring(name.length - 3)}`;
                    formData.append("photos", { name, data, type });
                });
            else if (key === "audio") {
                formData.append("audioRecord", data[key]);
            } else formData.append(key, data[key]);
        });
        return TicketAPI.post(`/tickets/`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },

    getReplies: async (clear, ticketId, repliess, attachments) => {
        let offset = clear ? 0 : repliess.length;

        let _repliess = await TicketAPI.get(`/replies/ofticket/${ticketId}?offset=${offset}`);
        if (clear) {
            _repliess.data = [..._repliess.data, ...attachments];
        }
        return FormateReply(repliess, _repliess.data, clear);
    },
    close: async (ticketId, rating, description) => {
        await TicketAPI.patch(`/tickets/${ticketId}/`, { state: 2 });
        Ticket.rate(ticketId, rating, description).then(() => {
            Info({ title: "Closed" });
            window.history.back();
        });
    },
    rate: (ticketId, rating, reason) => TicketAPI.post(`/tickets/${ticketId}/rate`, { rating, reason }),

    reply: (dataToSend, repliess, onComplate, onError) => {
        TicketAPI.post(`/replies`, dataToSend)
            .then(({ data }) => {
                onComplate(FormateOneReply(repliess, data));
            })
            .catch(onError);
    },
    deleteReply: (replyId) => TicketAPI.delete(`/replies/${replyId}`),
};

export default Ticket;
const extraTypes = ["Image", "", "Audio"];

function FormateReply(repliess, _repliess, clear) {
    let userId = JSON.parse(localStorage.getItem("owner")).id;
    let length = repliess?.length;

    let arr = [];
    let oldDate = moment(_repliess[0]?.createdAt).locale("en").format("MMM DD");
    if (!clear) {
        arr = JSON.parse(JSON.stringify(repliess));
        repliess[length - 1].displayDay = null;
        oldDate = moment(repliess[length - 1].createdAt)
            .locale("en")
            .format("MMM DD");
    }

    _repliess.forEach((element, index) => {
        element.replierType = element.replyBy === userId ? "owner" : "replier";
        let extra = extraTypes[element.type];
        if (extra) element.replierType += extraTypes[element.type];
        let newReplies = _repliess[index + 1];
        let newDate = newReplies && moment(newReplies?.createdAt).locale("en").format("MMM DD");
        if (oldDate !== newDate) {
            element.displayDay = oldDate;
            oldDate = newDate;
        }
        element.createdAt = moment(element.createdAt).locale("en").format("hh:mm A");
        arr.push(element);
    });
    return arr;
}

function FormateOneReply(repliess, newReply) {
    let userId = JSON.parse(localStorage.getItem("owner")).id;
    newReply.replierType = newReply.replyBy === userId ? "owner" : "replier";
    let extra = extraTypes[newReply.type];
    if (extra) newReply.replierType += extraTypes[newReply.type];
    newReply.createdAt = moment(newReply.createdAt).locale("en").format("hh:mm A");
    return [newReply, ...repliess];
}
