import React from "react";
import { Link } from "react-router-dom";
import { SvgBorderWithPrices } from "../SVGs/BorderSvgs";
import ChangeTitle from "../Utils/ChangeTitle";
import Language from "../Utils/Languages";

const amoutTypes = {
    profits: "Profit",
    sales: "Sale",
    expenses: "Expense",
    purchases: "Purchases",
};
const amoutCutomTypes = {
    profits: "Profit",
    sales: "Income",
    expenses: "Expense",
    purchases: "Dicounts",
};

const OprationsButtons = ({ item }) => {
    return (
        <div className="row py-4 flex-grow">
            {Object.entries(item.haveCustomLabels ? amoutCutomTypes : amoutTypes).map(([key, value], index) => (
                <Link
                    key={key}
                    to={`/businesses/${item.id}/transactions/${index}`}
                    className="flex-1 col items-center tint"
                    onClick={() => {
                        ChangeTitle(`${Language[value]} / ${item.businessTitle}`);
                    }}>
                    <SvgBorderWithPrices icon={value} amounts={item[key]} amountClass="orange-text" />
                </Link>
            ))}
        </div>
    );
};

export default OprationsButtons;
