import IconCard from "./IconCard";
import React, { useState } from "react";
import Language from "../../Utils/Languages";
import GetRating from "../../Components/GetRating";
import RedButton from "../../ComponentsV2/RedButton";
import FormTextArea from "../../Components/FormTextArea";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";

const starIcon = "/tickets/star.png";

const CloseTicketHolder = ({ onComplate }) => {
    const [popupClosTicket, setPopupClosTicket] = useState(false);
    const [description, setDescription] = useState("");
    const [rating, setRating] = useState(0);

    const onRateChange = (index) => setRating(index === rating && index === 1 ? index - 1 : index);

    return (
        <>
            <div
                className="shdow-1 absolute left-0 top-1 z-10 rounded-2 items-center flex flex-row px-6 cursor-pointer"
                onClick={() => setPopupClosTicket(!popupClosTicket)}>
                <img style={{ height: 44, width: 44 }} src={starIcon} />
                <p className="font-bold carbonic">{Language.RatingAndClosing}</p>
            </div>
            {popupClosTicket && (
                <DialogConatiner childClassName="m-auto ticket-popup" close={() => setPopupClosTicket(false)}>
                    <IconCard icon="Evaluation">
                        <div className="flex flex-col p-2 m-2">
                            <GetRating rating={rating} onRateChange={onRateChange} />
                            {rating > 0 && (
                                <>
                                    <FormTextArea
                                        setValue={setDescription}
                                        value={description}
                                        placeholder="PleaseWriteTheReasonForTheEvaluationHere"
                                        className="shadow-1 m-2 p-5 rounded-2"
                                    />
                                    <RedButton title={Language.RatingAndClosing} color="#ffffff" onClick={() => onComplate(rating, description)} />
                                </>
                            )}
                        </div>
                    </IconCard>
                </DialogConatiner>
            )}
        </>
    );
};

export default CloseTicketHolder;
