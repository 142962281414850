import { Fragment } from "react";
import { CurrencesTitles, Options } from "../Settings";
import Language from "../Utils/Languages";
export const SvgBorderIcons = {
    1: (
        <>
            <path
                d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                transform="translate(-90 -80)"></path>
            <path
                d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                transform="translate(-90 -80)"></path>
            <path
                d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                transform="translate(-90 -80)"></path>
            <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-90 -80)"></path>
            <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-90 -80)"></path>
        </>
    ),
    2: (
        <>
            <path
                d="M352,679c-6.32-2.71-13.29-4.48-18.81-8.33-8.49-5.95-12.19-15.12-12.2-25.56q0-78.71,0-157.41c0-20.79,13.55-34.78,34.35-35.44.83,0,1.66,0,2.49,0q142.17,0,284.33,0c17.75,0,30.43,8.26,35.12,23.18a38,38,0,0,1,1.66,11.26q.16,78.2.07,156.41c0,19.13-10.45,31.72-29.17,35.17A11,11,0,0,0,648,679Zm26.21-29.2H621.8c5-19.45,11.19-25.61,28.54-28.24V509.38c-17.19-1.92-26.34-11.72-28.42-28.18H378.2c-4.9,19.39-11.21,25.65-28.54,28.24V621.57C366.91,624.12,373.24,630.4,378.21,649.8Z"
                transform="translate(-90 -80)"></path>
            <path
                d="M502,320c3.77,2.59,8,4.72,11.22,7.84,11.52,11.11,22.75,22.5,34,33.88,6.37,6.44,6.65,15,.89,20.95s-14.43,5.74-20.94-.46c-2.77-2.64-5.55-5.3-8.1-8.16-1.19-1.34-1.82-3.17-2.7-4.78l-2.13,1.47V377c0,14.17.07,28.34-.06,42.5-.08,8.3-6.12,14.46-13.93,14.62-8,.16-14.28-6.12-14.43-14.63-.14-8.16-.05-16.33-.06-24.5V370.15a36.9,36.9,0,0,0-3.35,2.54c-3.59,3.48-7,7.17-10.69,10.51a14.1,14.1,0,0,1-19.47-.18,14.31,14.31,0,0,1-1.16-19.47,41.88,41.88,0,0,1,3.43-3.63c11.52-11.57,23-23.23,34.68-34.6,2.4-2.33,5.84-3.58,8.8-5.32Z"
                transform="translate(-90 -80)"></path>
            <path
                d="M443.26,565.61c.29-17.62,4.43-34.06,16.19-47.65a53.39,53.39,0,0,1,87.72,9.68c12.74,23.45,13.11,47.92,1.92,72-9.61,20.7-26.47,32-49.48,31.84-23.46-.19-39.75-12.38-49.6-33.38C445.2,587.83,443.49,576.86,443.26,565.61Zm84.73-.07c-.11-10-2.23-19.49-8.26-27.69a24.54,24.54,0,0,0-39.72.33c-10.61,14.66-10.62,39.94,0,54.58a24.52,24.52,0,0,0,40,0C525.82,584.69,527.86,575.38,528,565.54Z"
                transform="translate(-90 -80)"></path>
            <path d="M386.75,546.54a19,19,0,1,1-18.85,19.22A19,19,0,0,1,386.75,546.54Z" transform="translate(-90 -80)"></path>
            <path d="M613.07,584.48a19,19,0,1,1,19-19A19,19,0,0,1,613.07,584.48Z" transform="translate(-90 -80)"></path>
        </>
    ),
    TheAmount: (
        <>
            <path d="M573.9,421.32c-2.66,5.61-4.56,11.78-8.17,16.69-6.84,9.33-16.92,12.61-28.31,12.48-12.64-.14-25.28,0-37.91,0q-127.06,0-254.11.06c-10.83,0-20.61-2.12-28.46-10.16A33.36,33.36,0,0,1,207,416.42q-.14-81.72,0-163.45c.05-19.33,16.08-34.16,35.31-34.05,62.84.36,125.69.14,188.53.14h106c19.89,0,31.28,8.86,36.39,28.21.08.3.4.53.61.8ZM515.66,248.08H265.18c-1.92,17.49-11.89,26.92-29.08,29V392.31c17.52,2.09,27.17,11.84,29.13,28.86a15.52,15.52,0,0,0,2,.31q122.45,0,244.91.1c3.19,0,4-1.26,4.34-4.11,1.59-11.82,8.58-19.35,19.52-23.36,2.8-1,5.86-1.34,8.85-2v-115C527.49,275,517.61,265.52,515.66,248.08Z" />
            <path d="M490.46,523.46c6-9.55,14.21-14.38,25.29-15.24V470.05h28.9c.12,1.51.34,3,.34,4.45,0,21.87.08,43.73,0,65.59-.08,20.62-16.74,31.27-35.9,23.38-31.51-13-62.69-26.9-94.81-38.14C368,509.12,320,500.79,270.86,499c-17.45-.63-30-10.21-33.73-25.36a30,30,0,0,1-.41-3.76C325.7,467.14,409.59,487,490.46,523.46Z" />
            <path d="M332.46,334.78c.48-21.23,7.05-40,23.43-54.18C378.51,261,409.35,263,429.68,285c24.55,26.62,24.93,71.44.83,98.49-26.1,29.29-69,23.51-88.62-11.94A76.05,76.05,0,0,1,332.46,334.78Z" />
            <path d="M274.49,353.93A19.24,19.24,0,1,1,294,334.54,19.26,19.26,0,0,1,274.49,353.93Z" />
            <path d="M506.18,353.93a19.24,19.24,0,1,1,19.46-19.43A19.26,19.26,0,0,1,506.18,353.93Z" />
        </>
    ),
    Company: (
        <path d="M508.68,569.85H271.82a12.8,12.8,0,0,0-2.78-1.27c-35.22-6.51-57.21-32.66-57.27-68.22,0-22,.07-44.08-.1-66.12,0-2.65-.47-6.24-2.18-7.79-14.7-13.37-23.35-30-27.68-49.13v-14.2c8.63-21.36,17.54-42.62,25.73-64.14a71.34,71.34,0,0,0,4.1-20.5c1.84-28.66,15.2-49.53,41.83-60.47,8.71-3.59,18.87-5,28.39-5.09,72.37-.41,144.74-.34,217.11-.18,37.37.09,66.54,24.47,69,59.82,1.06,15.2,5.7,28.57,11,42.19,5.42,13.94,11.53,27.66,16.22,41.84A53.26,53.26,0,0,1,597.5,378c-1.85,18.26-11.38,32.89-23.84,46a18.63,18.63,0,0,0-4.72,11.39c-.44,22.35-.08,44.71-.25,67.06a66.87,66.87,0,0,1-51.79,65.21C514.14,568.35,511.41,569.13,508.68,569.85ZM390.52,316.64c-45,0-90,.08-134.95-.12-5.5,0-8.49,1.36-10.33,6.92-4.45,13.44-8.93,27-14.71,39.85-4.52,10.07-2.18,18,4.71,25,4.9,4.94,10.6,9.62,16.81,12.65,20.15,9.84,42.21,3.79,60.21-15.6,11.86-12.77,25-12.89,36.61-.34,25.37,27.49,57.27,27.4,82.91-.22,10.5-11.32,22.61-11.52,34.77-1.74a195.9,195.9,0,0,0,26.61,18.08c21.5,12.12,48.94-2.52,57.34-20.87a18.86,18.86,0,0,0,.59-12.58c-4.81-14.71-10.75-29-15.67-43.71-1.92-5.72-4.76-7.5-10.74-7.46C480,316.75,435.24,316.64,390.52,316.64Z" />
    ),
    Phone: (
        <path
            d="M368,400.12c2-12.26,3.08-26.33,6.75-39.69,5.55-20.2,19.08-33.89,39.16-40.63,6.45-2.16,12.91-4.28,19.42-6.25,18.52-5.61,37.46,1.84,46,19.31a412.67,412.67,0,0,1,19.14,45.48c5.75,16.61,1.79,32-10.73,44.55-9.28,9.31-18.91,18.28-28.6,27.16-5.21,4.77-7,10.26-5.52,17.06,7.78,37,26.26,67.61,54.41,92.55,4.68,4.16,9.93,4.88,15.78,2.89Q540,557,556.35,551.68c17.87-5.88,34.42-.54,45.18,14.81q11.9,17,23.3,34.26c11.78,17.94,9.55,40.07-5.4,55.46-5.1,5.25-10.17,10.55-15.52,15.54-16.89,15.77-36.46,20.51-58.51,13.4-27.29-8.81-49.4-25.63-69-46C447.48,609.14,426,574,407.29,537c-15.3-30.23-28-61.48-34-95C370.9,428.78,369.87,415.31,368,400.12Z"
            transform="translate(-100 -100)"
        />
    ),
    Address: (
        <>
            <path
                d="M321,607c5.68-23,22.93-35,42.66-44.31,8.84-4.17,18.23-7.18,27.46-10.46a5.41,5.41,0,0,1,4.73.89c6.49,6.9,12.71,14.05,19.54,21.7-4.17,1.17-7.75,2.15-11.32,3.18-13.79,4-27.28,8.7-39.43,16.52a56.85,56.85,0,0,0-9.72,7.75c-7.36,7.46-7.45,14.42.21,21.63A73.16,73.16,0,0,0,370,634.7c18.43,10.12,38.54,15.47,59.06,19.13a397.53,397.53,0,0,0,146-.8c19-3.65,37.57-8.89,54.69-18.21a75.1,75.1,0,0,0,13.51-9.31c9.72-8.51,9.7-16.73-.23-25.12-4.78-4-10.09-7.92-15.81-10.29-12.56-5.19-25.53-9.38-38.34-14-1.08-.38-2.2-.67-4.16-1.26,6.9-7.75,13.4-15.15,20.08-22.39.63-.69,2.5-.74,3.59-.42,17.93,5.41,35.16,12.34,50.09,24,10.27,8,17.38,18.17,20.48,31v12c-4.84,19.9-18.78,31.94-35.88,41-30.23,16.07-63.18,22.32-96.74,26a437.78,437.78,0,0,1-92.76,0c-28.06-2.93-55.71-8-81.86-19C349,657.47,327.89,645.63,321,619Z"
                transform="translate(-100 -100)"
            />
            <path
                d="M499.69,311.17c68.19.28,125.48,52.41,131.52,120.23,3.18,35.7-6.22,68-29.28,95.27-23.85,28.24-48.74,55.63-73.85,82.77-16.67,18-39.5,18-56.14,0-25.76-27.91-52-55.51-75.71-85.14-61.63-77.1-17-193.07,80.19-210.4C484.1,312.52,491.93,312.05,499.69,311.17Zm-.3,179.3c25.37.5,47.1-20.27,47.57-45.48.5-26.55-19.65-47.72-46.1-48.42-25.33-.68-47.28,20.07-47.82,45.2C452.46,468.56,472.81,489.94,499.39,490.47Z"
                transform="translate(-100 -100)"
            />
        </>
    ),
    PurchasingPrice: (
        <>
            <path
                d="M304.92,330h31a12,12,0,0,0,2.32.8c11.13,1.34,20.63,6.21,27.12,15.14C370,352.35,372.94,360,377.08,368H690.52c12.31,0,18.45,7.48,16.18,19.31Q691.81,465.13,677.05,543c-5.27,28-29.18,50.76-55.66,50.95q-91.49.66-183,.07c-29.36-.2-53-20.72-58.6-49.69-11.12-57.41-22-114.87-33-172.31-1.68-8.8-6.48-12.93-15.48-13-8.16-.09-16.33.14-24.49-.07-10.08-.27-16.72-9.27-13.85-18.6C294.85,334.47,299.52,331.78,304.92,330ZM554.86,460.86l-.77,1.6H548.7q-34,0-68,0a41.54,41.54,0,0,0-7,.47,13.32,13.32,0,0,0-11,11.39c-1.34,9.29,5.05,16,15.69,16,23.5.11,47,0,70.49,0h5.29l.8,1.45c-4.07,3.84-8.26,7.55-12.18,11.53-6.27,6.37-6.6,14.75-1,20.45s14.11,5.65,20.45-.6q18.33-18.08,36.4-36.41c6.36-6.47,6.33-14.31-.07-20.82q-18.06-18.36-36.41-36.41c-6.26-6.15-14.9-6.19-20.47-.45-5.39,5.54-5.12,14,.87,20.09C546.54,453.26,550.77,457,554.86,460.86Z"
                transform="translate(-120 -100)"
            />
            <path
                d="M459,670c-5.28-2.59-11.27-4.32-15.7-8-10.21-8.39-12.28-23-5.94-34.5a28.49,28.49,0,1,1,31,41.56c-1.12.24-2.22.6-3.33.9Z"
                transform="translate(-120 -100)"
            />
            <path
                d="M591,670c-7.47-2-14.39-4.83-19.31-11.32a28.41,28.41,0,0,1,32.13-44.16,28.48,28.48,0,0,1-3,54.46c-1.28.31-2.54.68-3.8,1Z"
                transform="translate(-120 -100)"
            />
        </>
    ),
    SellingPrice: (
        <>
            <path
                d="M636,689c-8.25-1.19-16.35-2.81-23.35-7.78-12.78-9.08-18.78-21.58-18.78-37.12q0-72.42,0-144.85,0-71.67,0-143.35c0-22.11,13.57-39.28,34.29-43.62,2.58-.55,5.21-.86,7.82-1.28h1a28.16,28.16,0,0,0,3.37.74c22,2.12,38.66,19.7,38.69,41.61q.2,146.64,0,293.29c0,19.64-13.61,36.14-32.81,40.67-3,.72-6.17,1.13-9.26,1.69Z"
                transform="translate(-120 -100)"
            />
            <path
                d="M363,689c-8.75-1.3-17.27-3.07-24.58-8.62-11.22-8.51-17.44-19.73-17.47-33.73q-.24-104.43,0-208.86c0-22.31,18.29-40.52,41-41.57,22.44-1,41.78,16.11,44,39,.18,1.82.23,3.66.23,5.49q0,101.69,0,203.37c0,22.17-13.48,39.22-34.3,43.63-2.58.55-5.21.87-7.81,1.29Z"
                transform="translate(-120 -100)"
            />
            <path
                d="M499,689c-4.51-1-9.18-1.56-13.5-3.09-17.06-6-27.86-21.11-28-40.29-.3-40.62-.38-81.26,0-121.88.22-22.69,15.43-39.38,37.25-42.47,19.83-2.81,39.52,9.61,45.62,29.26a52.65,52.65,0,0,1,2.14,15.21q.26,58.93.07,117.89c-.06,22.65-13,39.35-33.82,44-2.57.57-5.18.94-7.78,1.4Z"
                transform="translate(-120 -100)"
            />
        </>
    ),
    InvoiceTotalAmount: (
        <path
            fill="#f48114"
            d="M349,633V332c6.25-11.66,12-13.56,24.31-8.09,9.26,4.1,18.59,8,27.72,12.4a11.44,11.44,0,0,0,10.35.25c11.46-4.84,23.11-9.23,34.61-14a17.27,17.27,0,0,1,14,.05c11.5,4.75,23.17,9.1,34.63,13.94a12.81,12.81,0,0,0,10.86.05c11.46-4.84,23.12-9.22,34.61-14a17.27,17.27,0,0,1,14-.05c11.66,4.81,23.44,9.32,35.09,14.14a10.69,10.69,0,0,0,9.44-.13c9.3-4.41,18.75-8.49,28.16-12.67,12.34-5.49,18.08-3.57,24.3,8.09V633a11.15,11.15,0,0,0-.89,2.2A51.89,51.89,0,0,1,599,679.07q-98.94.1-197.89,0a52.07,52.07,0,0,1-49-35.15C350.81,640.36,350,636.66,349,633ZM499.28,462.1h85.45c1.66,0,3.33,0,5-.06,7.59-.4,13-5.5,13.48-12.75s-3.92-13.23-11.33-14.73a33,33,0,0,0-6.45-.42q-85.45,0-170.9,0a31.49,31.49,0,0,0-6.94.56c-7.29,1.67-11.49,7.75-10.72,15.14.8,7.65,6.44,12.22,15.47,12.23Q455.82,462.16,499.28,462.1ZM462,519v0c-16.64,0-33.29-.07-49.93,0-9.42,0-15.37,5.63-15.3,14s6.21,13.84,15.55,13.84q49.94,0,99.88,0c9.6,0,15.82-5.61,15.77-14.1,0-8.26-6.11-13.76-15.53-13.8C495.59,519,478.78,519,462,519Zm.57,84.4q23.7,0,47.42,0a46.3,46.3,0,0,0,6.46-.37,13.46,13.46,0,0,0,11.52-12c.59-6.48-3-12.65-9-14.56a24.59,24.59,0,0,0-7.37-1q-49.17-.09-98.34,0a30.09,30.09,0,0,0-5.45.47,13.42,13.42,0,0,0-11,12.43c-.59,8.79,5.44,14.89,15.33,15C428.91,603.53,445.72,603.42,462.53,603.43Zm117.29-56.49v0c3.49,0,7,.2,10.47,0a13.76,13.76,0,0,0,13-14.31A13.53,13.53,0,0,0,590,519.13c-6.47-.21-13-.18-19.43,0-7.9.2-13.58,5.91-13.73,13.51a13.8,13.8,0,0,0,13.53,14.29C573.5,547.07,576.67,546.94,579.82,546.94Zm.27,28.36c-4,.2-8.05.1-12,.67-6.82,1-11.38,6.78-11.29,13.73s4.85,12.83,11.72,13.3a172.17,172.17,0,0,0,22.94,0c6.84-.45,11.68-6.4,11.79-13.26S598.81,577,592,576C588.09,575.4,584.06,575.5,580.09,575.3Z"
            transform="translate(-108 -111)"
        />
    ),
    ThePrice: (
        <path
            d="M688.36,358.05V462.81a22.17,22.17,0,0,0-.73,3.36c-.9,14.81-7.26,27-17.61,37.39L503.31,670.31c-7,7-15.11,12.51-24.81,15.06-5.1,1.34-10.37,2-15.57,2.93h-1a11.6,11.6,0,0,0-2.39-.64c-16.16-.7-29-7.93-40.26-19.25-29.71-30-59.65-59.77-89.5-89.63-23.87-23.87-24.34-58.44-.57-82.35q83.36-83.79,167.08-167.21c8.68-8.69,18.91-14.52,31.08-16.65a45.89,45.89,0,0,1,7.93-.75c36.39,0,72.79-.4,109.17.26,20,.36,37.32,15.74,42,35.26C687.27,350.86,687.73,354.48,688.36,358.05Zm-94.8,19.84a27.94,27.94,0,1,0,28.08,27.55A28,28,0,0,0,593.56,377.89Z"
            transform="translate(-108 -111)"
        />
    ),
    LocationMarkBg: (
        <>
            <path d="M397.31,201.19c54.89,3,96.83,27.24,120.51,77.29,23.39,49.44,17.59,97.71-16.4,140.5-23.54,29.63-49.77,57.18-75.52,85-17.29,18.69-40.28,18.73-57.55.07-25.91-28-52.19-55.77-76-85.49-31.86-39.78-38.47-85.32-19.44-132.57S328,211.31,378.46,203.13C384.68,202.12,391,201.82,397.31,201.19Zm47.38,134.3a47.63,47.63,0,1,0-47.61,48A47.64,47.64,0,0,0,444.69,335.49Z" />
            <path d="M310.53,468.55c-12.7,4.47-24.87,7.93-36.31,13a104.31,104.31,0,0,0-23.91,15c-8.78,7.33-8.9,15.95.21,22.89S270,532.81,280.71,537c41.51,16.1,85.09,19.67,129.15,18.5,36.05-1,71.65-5.41,105.34-19.12a119,119,0,0,0,27.33-15.84c10.47-8.07,10.05-16.6,0-25.4-13-11.46-29.11-16.93-45.32-22-4.27-1.33-8.61-2.4-14.09-3.92,7-7.92,13.47-15.29,20.14-22.45.79-.85,3-1.06,4.29-.67,19.51,6,38.44,13.35,53.94,27.22C585.07,494.39,585,522.1,561.1,543c-17.79,15.55-39.36,23.75-61.88,29.17-75.08,18.08-149.83,17.71-223.79-6-16.2-5.19-31.36-12.81-44-24.58-21.67-20.18-21.85-46.7-.25-66.94,15.73-14.73,35.29-22.34,55.49-28.48a6.19,6.19,0,0,1,5.15,1.58C298.14,454.43,304.18,461.42,310.53,468.55Z" />
        </>
    ),
    CategoryBg: (
        <path d="M457.08,495v45.89c10.54,0,21-.16,31.54.05,9.6.2,15.75,7.47,14.33,16.48-1.09,7-6.95,11.62-15.17,11.66-16.48.1-33,0-49.44,0H299.52c-1.58,0-3.17.07-4.74,0-8.43-.44-14.23-6.21-14.23-14.12s5.73-13.85,14.2-14c10.36-.22,20.72,0,31.57,0V495h-6.94c-22.8,0-45.6.07-68.4,0-26.74-.09-44.26-17.3-44.31-44q-.21-94.81,0-189.62c.06-26.94,17.8-44.23,44.85-44.23H531.87c27.46,0,45.07,17.25,45.12,44.64q.16,94.47,0,188.94c-.05,27.1-17.48,44.17-44.72,44.25-22.35.06-44.69,0-67,0ZM368.59,387.91c-12-12.1-23.4-23.68-34.93-35.13-7.06-7-14.94-7.42-20.84-1.38-5.68,5.8-5.22,13.57,1.46,20.32Q336,393.61,357.85,415.3c7.4,7.34,14.36,7.3,21.83-.05,9.18-9,18.22-18.18,27.31-27.29q31.59-31.62,63.15-63.27c6-6.05,6.1-13.81.84-19.36-5.51-5.81-13.39-5.81-19.88.12-2.5,2.28-4.82,4.76-7.21,7.16Zm59.76,107.54H355.64v45h72.71Z" />
    ),
    Profit: (
        <path
            transform="translate(-108 -111)"
            d="M292,574c1.27-6.46,5.16-11.18,9.72-15.72,40.25-40.1,80.6-80.09,120.42-120.62,11.06-11.26,23.47-10.12,33.26-.11,30.53,31.2,61.61,61.85,92.47,92.73,1.17,1.17,2.19,2.5,2.9,3.31l89.48-89.51c-.93-.06-2.48-.25-4-.26-10.83,0-21.66.14-32.49-.1-9-.2-15.8-5.2-18.42-12.82s-.53-15.95,5.9-20.68c3-2.24,7.17-4.19,10.83-4.23,29.15-.35,58.31-.34,87.46-.12a17.76,17.76,0,0,1,17.67,18q.26,43.23,0,86.47c-.06,10.15-8,17.84-18.09,18.36a18.87,18.87,0,0,1-19.38-15.9,43.76,43.76,0,0,1-.45-7c0-11.15,0-22.29,0-34.81-2.07,1.91-3.34,3-4.52,4.19l-96.84,96.83c-11.13,11.13-21.11,11.09-32.3-.1q-46.3-46.29-92.58-92.62a50.5,50.5,0,0,1-3.58-4.48c-1.8,1.64-2.91,2.57-3.92,3.59Q382.1,531.81,328.79,585.25c-4.57,4.59-9.36,8.38-15.79,9.75h-4q-13.47-3.53-17-17Z"
        />
    ),

    Sale: (
        <>
            <path
                d="M636,689c-8.25-1.19-16.35-2.81-23.35-7.78-12.78-9.08-18.78-21.58-18.78-37.12q0-72.42,0-144.85,0-71.67,0-143.35c0-22.11,13.57-39.28,34.29-43.62,2.58-.55,5.21-.86,7.82-1.28h1a28.16,28.16,0,0,0,3.37.74c22,2.12,38.66,19.7,38.69,41.61q.2,146.64,0,293.29c0,19.64-13.61,36.14-32.81,40.67-3,.72-6.17,1.13-9.26,1.69Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M363,689c-8.75-1.3-17.27-3.07-24.58-8.62-11.22-8.51-17.44-19.73-17.47-33.73q-.24-104.43,0-208.86c0-22.31,18.29-40.52,41-41.57,22.44-1,41.78,16.11,44,39,.18,1.82.23,3.66.23,5.49q0,101.69,0,203.37c0,22.17-13.48,39.22-34.3,43.63-2.58.55-5.21.87-7.81,1.29Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M499,689c-4.51-1-9.18-1.56-13.5-3.09-17.06-6-27.86-21.11-28-40.29-.3-40.62-.38-81.26,0-121.88.22-22.69,15.43-39.38,37.25-42.47,19.83-2.81,39.52,9.61,45.62,29.26a52.65,52.65,0,0,1,2.14,15.21q.26,58.93.07,117.89c-.06,22.65-13,39.35-33.82,44-2.57.57-5.18.94-7.78,1.4Z"
                transform="translate(-108 -111)"
            />
        </>
    ),
    Income: (
        <>
            <path
                d="M636,689c-8.25-1.19-16.35-2.81-23.35-7.78-12.78-9.08-18.78-21.58-18.78-37.12q0-72.42,0-144.85,0-71.67,0-143.35c0-22.11,13.57-39.28,34.29-43.62,2.58-.55,5.21-.86,7.82-1.28h1a28.16,28.16,0,0,0,3.37.74c22,2.12,38.66,19.7,38.69,41.61q.2,146.64,0,293.29c0,19.64-13.61,36.14-32.81,40.67-3,.72-6.17,1.13-9.26,1.69Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M363,689c-8.75-1.3-17.27-3.07-24.58-8.62-11.22-8.51-17.44-19.73-17.47-33.73q-.24-104.43,0-208.86c0-22.31,18.29-40.52,41-41.57,22.44-1,41.78,16.11,44,39,.18,1.82.23,3.66.23,5.49q0,101.69,0,203.37c0,22.17-13.48,39.22-34.3,43.63-2.58.55-5.21.87-7.81,1.29Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M499,689c-4.51-1-9.18-1.56-13.5-3.09-17.06-6-27.86-21.11-28-40.29-.3-40.62-.38-81.26,0-121.88.22-22.69,15.43-39.38,37.25-42.47,19.83-2.81,39.52,9.61,45.62,29.26a52.65,52.65,0,0,1,2.14,15.21q.26,58.93.07,117.89c-.06,22.65-13,39.35-33.82,44-2.57.57-5.18.94-7.78,1.4Z"
                transform="translate(-108 -111)"
            />
        </>
    ),
    Expense: (
        <>
            <path
                d="M304.92,330h31a12,12,0,0,0,2.32.8c11.13,1.34,20.63,6.21,27.12,15.14C370,352.35,372.94,360,377.08,368H690.52c12.31,0,18.45,7.48,16.18,19.31Q691.81,465.13,677.05,543c-5.27,28-29.18,50.76-55.66,50.95q-91.49.66-183,.07c-29.36-.2-53-20.72-58.6-49.69-11.12-57.41-22-114.87-33-172.31-1.68-8.8-6.48-12.93-15.48-13-8.16-.09-16.33.14-24.49-.07-10.08-.27-16.72-9.27-13.85-18.6C294.85,334.47,299.52,331.78,304.92,330ZM554.86,460.86l-.77,1.6H548.7q-34,0-68,0a41.54,41.54,0,0,0-7,.47,13.32,13.32,0,0,0-11,11.39c-1.34,9.29,5.05,16,15.69,16,23.5.11,47,0,70.49,0h5.29l.8,1.45c-4.07,3.84-8.26,7.55-12.18,11.53-6.27,6.37-6.6,14.75-1,20.45s14.11,5.65,20.45-.6q18.33-18.08,36.4-36.41c6.36-6.47,6.33-14.31-.07-20.82q-18.06-18.36-36.41-36.41c-6.26-6.15-14.9-6.19-20.47-.45-5.39,5.54-5.12,14,.87,20.09C546.54,453.26,550.77,457,554.86,460.86Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M459,670c-5.28-2.59-11.27-4.32-15.7-8-10.21-8.39-12.28-23-5.94-34.5a28.49,28.49,0,1,1,31,41.56c-1.12.24-2.22.6-3.33.9Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M591,670c-7.47-2-14.39-4.83-19.31-11.32a28.41,28.41,0,0,1,32.13-44.16,28.48,28.48,0,0,1-3,54.46c-1.28.31-2.54.68-3.8,1Z"
                transform="translate(-108 -111)"
            />
        </>
    ),
    Purchases: (
        <>
            <path
                d="M302,594V378c2.23-5.11,3.89-10.57,6.81-15.25,5-8,13.44-11.34,22.19-13.75H440a10.37,10.37,0,0,0,2.21.9C463.12,353,478.46,364,488.4,382.49a20.8,20.8,0,0,1,2.3,9.46q.19,111.2.1,222.4v5.85c-2.05-1.92-3.23-3-4.36-4.11-9.82-9.67-21.35-16.46-34.75-19.55a90.59,90.59,0,0,0-19.75-2.28C388.62,594,345.31,594.05,302,594Zm84.89-179c10.82,0,21.65.06,32.48,0,8.35-.06,14.56-6,14.62-13.8a14.13,14.13,0,0,0-14.39-14.15q-32.49-.24-65,0c-8.52.06-14.84,6.57-14.61,14.45S346.41,415,354.9,415C365.56,415.09,376.22,415,386.89,415Zm.14,66c10.67,0,21.33,0,32,0,8.62,0,15-6,15-14s-6.43-14-15-14q-32-.09-64,0c-8.59,0-15,6.13-15,14.05S346.42,481,355,481C365.7,481,376.37,481,387,481ZM367.9,547c4.65,0,9.3.08,14,0,8.39-.17,14.27-6,14.27-14s-5.87-13.88-14.27-13.94q-13.69-.1-27.41,0c-8.16.06-14.43,6.19-14.43,13.94s6.25,13.84,14.43,14C358.92,547.08,363.41,547,367.9,547Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M560,349H669c5.11,2.21,10.57,3.85,15.24,6.76,8.07,5,11.56,13.34,13.76,22.24V594c-2.16.07-4.32.21-6.49.21q-62,0-124,0c-18.29,0-34.65,5.65-48.81,17.33-3,2.5-5.83,5.25-9.54,8.61v-4.25q0-112.74.07-225.47a15.43,15.43,0,0,1,1.6-6.66c8.49-17.07,22.08-28,40.51-32.82C554.22,350.23,557.12,349.66,560,349Zm29.45,188.31c.16,2.49.21,4.48.42,6.46a13.44,13.44,0,0,0,14.25,12.4c7.83-.26,13-5.94,13.46-15,.13-2.68.26-4.41,3.58-5.37,18.21-5.26,30.05-21.36,29.32-39.26C649.73,478.12,637,463,618.27,459.1c-6.11-1.26-12.56-.88-18.85-1.28-7.23-.46-12.34-5.43-12.3-11.92,0-6.19,5.6-11.57,12.48-11.91,3-.15,6-.05,9,0a11.69,11.69,0,0,1,10.67,6.11c4.74,7.86,12.45,10.14,19.47,6.06s8.86-12.52,4.37-20.47a36.13,36.13,0,0,0-21.51-17.5c-2.85-.86-4.15-2-4-5.09a19.89,19.89,0,0,0-1.21-7.32A13.85,13.85,0,0,0,602,387.2c-6.68,1-12.05,5.91-12,12.62.08,5.91-2.13,8.3-7.5,10.58-14.78,6.26-24.43,22.59-23.33,37.52a39.65,39.65,0,0,0,29.41,35.75c7,1.8,14.47,1.68,21.74,2.15,7,.46,11.88,4.84,12.11,11.38s-4.28,11.74-11.24,12.16c-6,.35-12,.13-18,0-4.51-.11-7.73-2.44-10.18-6.21-4.9-7.54-12.9-9.76-19.67-5.64s-8.52,12.95-3.81,20.61C566.36,529.15,576.49,535.15,589.45,537.31Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M302,613c45,0,90-.27,134.94,0,22.13.12,43.59,16.88,50.06,38H331c-2.67-.84-5.41-1.52-8-2.54-11.68-4.6-17.76-13.87-21-25.46Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M513,651c6.5-21.13,28-37.88,50.06-38,45-.25,90,0,134.94,0v10c-2.15,4.73-3.79,9.78-6.55,14.13C686.3,645.23,678,648.77,669,651Z"
                transform="translate(-108 -111)"
            />
        </>
    ),
    Dicounts: (
        <>
            <path
                d="M302,594V378c2.23-5.11,3.89-10.57,6.81-15.25,5-8,13.44-11.34,22.19-13.75H440a10.37,10.37,0,0,0,2.21.9C463.12,353,478.46,364,488.4,382.49a20.8,20.8,0,0,1,2.3,9.46q.19,111.2.1,222.4v5.85c-2.05-1.92-3.23-3-4.36-4.11-9.82-9.67-21.35-16.46-34.75-19.55a90.59,90.59,0,0,0-19.75-2.28C388.62,594,345.31,594.05,302,594Zm84.89-179c10.82,0,21.65.06,32.48,0,8.35-.06,14.56-6,14.62-13.8a14.13,14.13,0,0,0-14.39-14.15q-32.49-.24-65,0c-8.52.06-14.84,6.57-14.61,14.45S346.41,415,354.9,415C365.56,415.09,376.22,415,386.89,415Zm.14,66c10.67,0,21.33,0,32,0,8.62,0,15-6,15-14s-6.43-14-15-14q-32-.09-64,0c-8.59,0-15,6.13-15,14.05S346.42,481,355,481C365.7,481,376.37,481,387,481ZM367.9,547c4.65,0,9.3.08,14,0,8.39-.17,14.27-6,14.27-14s-5.87-13.88-14.27-13.94q-13.69-.1-27.41,0c-8.16.06-14.43,6.19-14.43,13.94s6.25,13.84,14.43,14C358.92,547.08,363.41,547,367.9,547Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M560,349H669c5.11,2.21,10.57,3.85,15.24,6.76,8.07,5,11.56,13.34,13.76,22.24V594c-2.16.07-4.32.21-6.49.21q-62,0-124,0c-18.29,0-34.65,5.65-48.81,17.33-3,2.5-5.83,5.25-9.54,8.61v-4.25q0-112.74.07-225.47a15.43,15.43,0,0,1,1.6-6.66c8.49-17.07,22.08-28,40.51-32.82C554.22,350.23,557.12,349.66,560,349Zm29.45,188.31c.16,2.49.21,4.48.42,6.46a13.44,13.44,0,0,0,14.25,12.4c7.83-.26,13-5.94,13.46-15,.13-2.68.26-4.41,3.58-5.37,18.21-5.26,30.05-21.36,29.32-39.26C649.73,478.12,637,463,618.27,459.1c-6.11-1.26-12.56-.88-18.85-1.28-7.23-.46-12.34-5.43-12.3-11.92,0-6.19,5.6-11.57,12.48-11.91,3-.15,6-.05,9,0a11.69,11.69,0,0,1,10.67,6.11c4.74,7.86,12.45,10.14,19.47,6.06s8.86-12.52,4.37-20.47a36.13,36.13,0,0,0-21.51-17.5c-2.85-.86-4.15-2-4-5.09a19.89,19.89,0,0,0-1.21-7.32A13.85,13.85,0,0,0,602,387.2c-6.68,1-12.05,5.91-12,12.62.08,5.91-2.13,8.3-7.5,10.58-14.78,6.26-24.43,22.59-23.33,37.52a39.65,39.65,0,0,0,29.41,35.75c7,1.8,14.47,1.68,21.74,2.15,7,.46,11.88,4.84,12.11,11.38s-4.28,11.74-11.24,12.16c-6,.35-12,.13-18,0-4.51-.11-7.73-2.44-10.18-6.21-4.9-7.54-12.9-9.76-19.67-5.64s-8.52,12.95-3.81,20.61C566.36,529.15,576.49,535.15,589.45,537.31Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M302,613c45,0,90-.27,134.94,0,22.13.12,43.59,16.88,50.06,38H331c-2.67-.84-5.41-1.52-8-2.54-11.68-4.6-17.76-13.87-21-25.46Z"
                transform="translate(-108 -111)"
            />
            <path
                d="M513,651c6.5-21.13,28-37.88,50.06-38,45-.25,90,0,134.94,0v10c-2.15,4.73-3.79,9.78-6.55,14.13C686.3,645.23,678,648.77,669,651Z"
                transform="translate(-108 -111)"
            />
        </>
    ),
    Note: (
        <path
            d="M669,380.5v238c-.27,1.28-.66,2.55-.8,3.84-2.56,23.23-19.09,41.33-41.93,45.81-2.74.54-5.51.91-8.27,1.35H380a29.84,29.84,0,0,0-3.34-.76c-20.16-2.3-34.65-12.67-42.79-31.15-2.61-5.93-3.3-12.7-4.87-19.09v-238c.28-1.44.65-2.87.84-4.32,2.73-20.55,13.78-34.84,32.87-42.58,5.42-2.2,11.51-2.78,17.29-4.1H618a28.33,28.33,0,0,0,3.35.75c23.39,2.42,41.34,18.51,46.18,41.49C668.14,374.63,668.52,377.58,669,380.5ZM499,424.31H466c-18.66,0-37.31-.11-56,.06-10.09.1-16.49,8.61-13.89,17.92,1.8,6.45,7.51,10.13,16,10.13q87,0,173.91,0a24.94,24.94,0,0,0,5-.36,13.88,13.88,0,0,0,11.44-14.28c-.29-8.08-6.3-13.42-15.48-13.43Q542.94,424.25,499,424.31Zm0,84.45h59.47c10.32,0,20.66.15,31-.16,7.2-.22,12.45-5.75,13-12.78a13.67,13.67,0,0,0-10.89-14.64,26.13,26.13,0,0,0-5.45-.49q-87,0-173.9,0a25,25,0,0,0-5.93.6,13.63,13.63,0,0,0-10.47,14.92c.75,7.69,6.46,12.5,15.3,12.52Q455,508.83,499,508.76ZM470.5,537.33v0h-6c-18.16,0-36.32-.09-54.47.06-8.73.07-14.75,6.31-14.43,14.45s6.42,13.55,15.46,13.58c20.82.08,41.64,0,62.46,0q28.25,0,56.47,0c6.09,0,11.14-1.87,14.2-7.49,5.21-9.57-1.38-20.46-12.73-20.57C511.14,537.19,490.82,537.33,470.5,537.33Z"
            transform="translate(-108 -111)"
        />
    ),
};

const NotDataTitles = { Company: Language.ThereIsNoBusinessTitle, Phone: Language.ThereIsNoPhoneNumber, Address: Language.ThereIsNoLocation };

export const SvgBorderLabel = ({ title, icon }) =>
    title ? (
        <div className="flex flex-row items-center">
            <svg height={36} className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
                <path
                    className="border"
                    d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
                />
                {SvgBorderIcons[icon]}
            </svg>
            <p className="font-bold pr-3">
                <span className="carbonic-1">{title}</span>
            </p>
        </div>
    ) : (
        <div className="flex flex-row items-center not-fount">
            <svg height={36} className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
                {SvgBorderIcons[icon]}
            </svg>
            <p className="font-bold pr-3">
                <span className="carbonic-1">{NotDataTitles[icon]}</span>
            </p>
        </div>
    );

const SvgBorderWithPrice = ({ amount, icon, currency = Options.currency.title, amountClass = "carbonic" }) => (
    <div className="flex flex-col items-center">
        <SvgBorderPath children={SvgBorderIcons[icon]} />
        <p className="font-medium text-center py-2 text-sm">
            <span className="carbonic-1">{Language[icon]}</span>
            <br />
            <span className={`text-base item-amount ${amountClass}`}>{amount}</span>
            <br />
            <span className="carbonic-3">{currency}</span>
        </p>
    </div>
);

export const SvgBorderWithPrices = ({ amounts, icon, amountClass = "carbonic" }) => (
    <div className="flex flex-col items-center">
        <SvgBorderPath children={SvgBorderIcons[icon]} />
        <p className="font-bold text-center py-2 text-sm">
            <span className="carbonic-1">{Language[icon]}</span>
            {amounts.map((amount, index) => (
                <Fragment key={index}>
                    <br />
                    <span className={`${amountClass}`}>{amount}</span>
                </Fragment>
            ))}
            {/* <br />
            <span className="carbonic-3">{Options.currency.title}</span> */}
        </p>
    </div>
);

export const SvgBorderWithPriceTrasnactions = ({ amount, icon, currencyId, amountClass = "carbonic" }) => (
    <div className="flex flex-col items-center">
        <SvgBorderPath children={SvgBorderIcons[icon]} />
        <p className="font-medium text-center py-2 text-sm">
            <span className="carbonic-1">{Language[icon]}</span>
            <br />
            <span className={`text-base ${amountClass}`}>{amount}</span>
            <br />
            <span className="carbonic-3">{CurrencesTitles[currencyId]}</span>
        </p>
    </div>
);

export const SvgBorderPath = ({ children }) => (
    <svg height={36} className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="border"
            d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
        />
        {children}
    </svg>
);

export const SvgBorder = ({ titel, icon }) => (
    <div className="flex flex-col items-center">
        <SvgBorderPath children={SvgBorderIcons[icon]} />
        <p className="font-medium text-center py-1 text-sm">
            <span className="carbonic-1">{titel || Language[icon]}</span>
        </p>
    </div>
);
export default SvgBorderWithPrice;
