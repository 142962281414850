export const LocationMark = (
    <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358 377.31">
        <path
            d="M321,607c5.68-23,22.93-35,42.66-44.31,8.84-4.17,18.23-7.18,27.46-10.46a5.41,5.41,0,0,1,4.73.89c6.49,6.9,12.71,14.05,19.54,21.7-4.17,1.17-7.75,2.15-11.32,3.18-13.79,4-27.28,8.7-39.43,16.52a56.85,56.85,0,0,0-9.72,7.75c-7.36,7.46-7.45,14.42.21,21.63A73.16,73.16,0,0,0,370,634.7c18.43,10.12,38.54,15.47,59.06,19.13a397.53,397.53,0,0,0,146-.8c19-3.65,37.57-8.89,54.69-18.21a75.1,75.1,0,0,0,13.51-9.31c9.72-8.51,9.7-16.73-.23-25.12-4.78-4-10.09-7.92-15.81-10.29-12.56-5.19-25.53-9.38-38.34-14-1.08-.38-2.2-.67-4.16-1.26,6.9-7.75,13.4-15.15,20.08-22.39.63-.69,2.5-.74,3.59-.42,17.93,5.41,35.16,12.34,50.09,24,10.27,8,17.38,18.17,20.48,31v12c-4.84,19.9-18.78,31.94-35.88,41-30.23,16.07-63.18,22.32-96.74,26a437.78,437.78,0,0,1-92.76,0c-28.06-2.93-55.71-8-81.86-19C349,657.47,327.89,645.63,321,619Z"
            transform="translate(-321 -311.17)"
        />
        <path
            d="M499.69,311.17c68.19.28,125.48,52.41,131.52,120.23,3.18,35.7-6.22,68-29.28,95.27-23.85,28.24-48.74,55.63-73.85,82.77-16.67,18-39.5,18-56.14,0-25.76-27.91-52-55.51-75.71-85.14-61.63-77.1-17-193.07,80.19-210.4C484.1,312.52,491.93,312.05,499.69,311.17Zm-.3,179.3c25.37.5,47.1-20.27,47.57-45.48.5-26.55-19.65-47.72-46.1-48.42-25.33-.68-47.28,20.07-47.82,45.2C452.46,468.56,472.81,489.94,499.39,490.47Z"
            transform="translate(-321 -311.17)"
        />
    </svg>
);

export const Category = (
    <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370.51 351.98">
        <path
            d="M457.08,495v45.89c10.54,0,21-.16,31.54.05,9.6.2,15.75,7.47,14.33,16.48-1.09,7-6.95,11.62-15.17,11.66-16.48.1-33,0-49.44,0H299.52c-1.58,0-3.17.07-4.74,0-8.43-.44-14.23-6.21-14.23-14.12s5.73-13.85,14.2-14c10.36-.22,20.72,0,31.57,0V495h-6.94c-22.8,0-45.6.07-68.4,0-26.74-.09-44.26-17.3-44.31-44q-.21-94.81,0-189.62c.06-26.94,17.8-44.23,44.85-44.23H531.87c27.46,0,45.07,17.25,45.12,44.64q.16,94.47,0,188.94c-.05,27.1-17.48,44.17-44.72,44.25-22.35.06-44.69,0-67,0ZM368.59,387.91c-12-12.1-23.4-23.68-34.93-35.13-7.06-7-14.94-7.42-20.84-1.38-5.68,5.8-5.22,13.57,1.46,20.32Q336,393.61,357.85,415.3c7.4,7.34,14.36,7.3,21.83-.05,9.18-9,18.22-18.18,27.31-27.29q31.59-31.62,63.15-63.27c6-6.05,6.1-13.81.84-19.36-5.51-5.81-13.39-5.81-19.88.12-2.5,2.28-4.82,4.76-7.21,7.16Zm59.76,107.54H355.64v45h72.71Z"
            transform="translate(-206.56 -217.2)"
        />
    </svg>
);

export const BusinessesIcon = (
    <svg fill="#ffa520" height={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path d="M508.68,569.85H271.82a12.8,12.8,0,0,0-2.78-1.27c-35.22-6.51-57.21-32.66-57.27-68.22,0-22,.07-44.08-.1-66.12,0-2.65-.47-6.24-2.18-7.79-14.7-13.37-23.35-30-27.68-49.13v-14.2c8.63-21.36,17.54-42.62,25.73-64.14a71.34,71.34,0,0,0,4.1-20.5c1.84-28.66,15.2-49.53,41.83-60.47,8.71-3.59,18.87-5,28.39-5.09,72.37-.41,144.74-.34,217.11-.18,37.37.09,66.54,24.47,69,59.82,1.06,15.2,5.7,28.57,11,42.19,5.42,13.94,11.53,27.66,16.22,41.84A53.26,53.26,0,0,1,597.5,378c-1.85,18.26-11.38,32.89-23.84,46a18.63,18.63,0,0,0-4.72,11.39c-.44,22.35-.08,44.71-.25,67.06a66.87,66.87,0,0,1-51.79,65.21C514.14,568.35,511.41,569.13,508.68,569.85ZM390.52,316.64c-45,0-90,.08-134.95-.12-5.5,0-8.49,1.36-10.33,6.92-4.45,13.44-8.93,27-14.71,39.85-4.52,10.07-2.18,18,4.71,25,4.9,4.94,10.6,9.62,16.81,12.65,20.15,9.84,42.21,3.79,60.21-15.6,11.86-12.77,25-12.89,36.61-.34,25.37,27.49,57.27,27.4,82.91-.22,10.5-11.32,22.61-11.52,34.77-1.74a195.9,195.9,0,0,0,26.61,18.08c21.5,12.12,48.94-2.52,57.34-20.87a18.86,18.86,0,0,0,.59-12.58c-4.81-14.71-10.75-29-15.67-43.71-1.92-5.72-4.76-7.5-10.74-7.46C480,316.75,435.24,316.64,390.52,316.64Z" />
    </svg>
);
export const Company = (
    <svg fill="#c4c4c7" height={50} style={{ margin: "14px 0" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.25 387">
        <path
            fill="#ffa520"
            d="M571,306c3.26.59,6.55,1,9.76,1.79a42.36,42.36,0,0,1,32.45,41,34.08,34.08,0,0,0,2.07,11.08c4.44,12,8.87,24,14.07,35.71a28.11,28.11,0,0,1,0,24.14c-3.43,7.41-8.44,14.17-13.36,20.77-1.8,2.41-3,4.36-3,7.4.14,14,.07,28,.06,42,0,23.47-17.7,42.6-41.13,42.82q-72,.67-144,0c-23.41-.23-41.11-19.37-41.12-42.83,0-14-.07-28,.05-42a9,9,0,0,0-3-7.36c-8.47-8-14.18-17.73-15.43-29.43A32.24,32.24,0,0,1,370,398c3-9,6.76-17.68,10.21-26.5s6.5-17.35,7.11-27.17c1.25-20.22,17.87-35.63,38.31-37.61A28.71,28.71,0,0,0,429,306Zm-70.48,66.75c-28.49,0-57,.06-85.46-.08-3.72,0-5.68.92-6.89,4.65a220,220,0,0,1-9,24.28c-3.34,7.2-1.25,12.72,3.85,17.38a37.41,37.41,0,0,0,12,7.79c14.47,5.29,25.88-.71,35.7-11.06,7.45-7.86,16-7.89,23.27.05,15.67,17.2,36.34,17.2,52.07,0,6.75-7.39,14.64-7.58,22.42-1.25a93.13,93.13,0,0,0,16.4,11.21c13.07,6.59,29.56.84,36.09-12.36a13,13,0,0,0,.65-8.81c-2.91-9.17-6.69-18.06-9.74-27.19-1.22-3.68-3.11-4.72-6.85-4.7C556.83,372.82,528.68,372.75,500.52,372.75Z"
            transform="translate(-320.87 -306)"
        />
        <path
            d="M495,693c-7.77-2.21-14.94-5.37-20.3-11.79A33,33,0,0,1,482.16,632c2.74-1.75,3.71-3.52,3.68-6.72q-.21-25.49,0-51a13.51,13.51,0,0,1,11.67-13.48c7.5-1,13.95,2.73,16,9.66a26.85,26.85,0,0,1,.69,7.4c0,16-.05,32,.14,48a7.09,7.09,0,0,0,2.46,5.16c12.12,8.05,18.08,19.22,15.88,33.58-2.29,15-11.45,24.31-26.38,27.7a5.53,5.53,0,0,0-1.31.7Z"
            transform="translate(-320.87 -306)"
        />
        <path
            d="M320.87,632.65c.07-22.46,19.32-37.92,40.17-33.41,3.37.73,5.16-.14,7.31-2.31,10.41-10.56,21-21,31.42-31.49,4.27-4.3,9.39-5.79,15.08-4.19a13.32,13.32,0,0,1,9.73,10.42c1.26,5.5-.48,10.13-4.43,14.07-10.52,10.45-20.93,21-31.53,31.38-2.35,2.3-3.14,4.33-2.44,7.74,3.74,18.18-7.69,35.59-25.37,39.13a33.12,33.12,0,0,1-39.56-26.86A32.19,32.19,0,0,1,320.87,632.65Z"
            transform="translate(-320.87 -306)"
        />
        <path
            d="M679.12,634.9c-.48,10-6.22,20.21-18.15,26.15s-24,5-34.78-3.16-15.1-19.27-12.44-32.57c.76-3.82-.11-6-2.74-8.6-10.27-10-20.35-20.17-30.46-30.33-6.91-6.94-7.41-15.59-1.34-21.64s14.82-5.41,21.71,1.42q15.45,15.3,30.74,30.76c2.14,2.17,3.93,3.06,7.31,2.32C660,594.66,679.15,610.28,679.12,634.9Z"
            transform="translate(-320.87 -306)"
        />
    </svg>
);

export const KeysSvg = (
    <svg height={90} className="m-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 587.77 587.77">
        <g>
            <rect className="item-icon-bg" width="587.77" height="587.77" rx="163.27" />
        </g>
        <g fill="#ebb936">
            <path d="M620.49,763.73a109.51,109.51,0,0,1-216,0h65.18a46.5,46.5,0,0,0,85.62,0Z" transform="translate(-218.74 -386)" />
        </g>
        <g>
            <circle style={{ fill: "none", stroke: "#ebb936", strokeMiterlimit: 10, strokeWidth: 63 }} cx="291.52" cy="224.15" r="78" />
        </g>
    </svg>
);
