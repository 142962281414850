import ItemCard from "./Components/ItemCard";
import React from "react";
import ItemsUtils from "./ItemsUtils";
import ItemsHeader from "./Components/ItemsHeader";
import PageBuilder from "../PageBuilder/PageBuilder";

const ItemsView = () => {
    return (
        <PageBuilder
            limit={50}
            id="items"
            url="v1/items/ofowner"
            itemBuilder={ItemCard}
            headerBuilder={ItemsHeader}
            queryParams={ItemsUtils.queryParams}
            onResult={ItemsUtils.onResult}
            queryChanged={ItemsUtils.queryChanged}
            formBuilderClass="form-container"
            gridClass="small-card-grid"
        />
    );
};

export default ItemsView;
