import React from "react";
import InvoiceCard from "./Components/Card";
import InvoiceView from "./InvoiceView";
import PopupBuilder from "../Containers/PopupBuilder";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import Grid from "../Containers/Grid";
import CreateProvider from "../scripts/CreateApiProviderClass";
import InvoicesesUtils from "./InvoicesesUtils";
import FormContainer from "../Form/FormContainer";
import { GetFormatedDate } from "../Utils/DateFromater";
import Utils from "../Utils/Utils";
import Info from "../NodeBuilder/Info";

const id = "invoices";

const formatePrice = (items) => {
    items.forEach((item) => {
        item.price = Utils.Formate(item.soldAt, item.currencyId);
        item.totalPrice = Utils.Formate(item.total, item.currencyId);
    });
};

const InvoicesView = () => {
    const provider = React.useMemo(() => {
        console.debug(id + " RD");
        let _provider = new CreateProvider({
            url: "v1/history",
            scrollerId: `${id}-simple-wrapper`,
            sessionKey: `simple-stored-${id}`,
            providerId: id,
            apiId: "invoices",
            onResult: InvoicesesUtils.onResult,
        });

        _provider.search(true);
        setTimeout(() => {
            _provider.showInvoice = (value, isNew) => {
                provider.api
                    .get(`v1/invoices/${value}`)
                    .then((data) => {
                        formatePrice(data.items);
                        data.date = GetFormatedDate(data);
                        data.total = Object.values(data.totalByCurrency)[0];
                        data.currencyId = Object.keys(data.totalByCurrency)[0];
                        let invoice = data;
                        provider.setPopup({
                            page: (
                                <InvoiceView
                                    invoice={invoice}
                                    isNew={isNew}
                                    close={() => {
                                        provider.setPopup(null);
                                    }}
                                />
                            ),
                        });
                    })
                    .catch((err) => {
                        Info({ title: "الرجاء التأكد من رمز القائمة", content: " او الإنتظار حتى إنتهاء رفع البيانات", type: "error" });
                    });
            };
        }, 1);
        return _provider;
    }, []);

    return (
        <div id={`${id}-simple-wrapper`} className="wrapper scroller">
            <FormContainer
                onChange={async (child) => {
                    const value = child.value;
                    if (!value) return;
                    provider.showInvoice(value, true);
                }}
                children={InvoicesesUtils.queryParams}
            />
            <Grid ItemBuilder={InvoiceCard} provider={provider} className="small-card-grid" />
            <PageStateProvider provider={provider} />
            <PopupBuilder provider={provider} id="invoiceses" childClass="popup-child invoices-popup" />
        </div>
    );
};

export default InvoicesView;
