import React from "react";
import "./index.css";
import "./theme.css";
import "./global.css";
import "./extra.css";
import "./css/index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import CapacitorInit from "./Utils/CapacitorInit";
import { UpdateOptions, Options } from "./Settings";
import moment from "moment";
import UserInfo from "./Utils/UserInfo";
import { Capacitor } from "@capacitor/core";
import WebInit from "./Utils/WebInit";
import { BrowserRouter as Router } from "react-router-dom";
import { updateToken } from "./API/remoteAPI";

global.platform = Capacitor.getPlatform();
document.documentElement.className = global.platform;
export const main = async () => {
    let token = localStorage.getItem("token");
    updateToken(token);
    sessionStorage.clear();
    UpdateOptions();
    moment.locale(Options.language.value === "ar" ? "ar" : "en");
    document.body.className = localStorage.getItem("theme") || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    token && UserInfo.fetchUserInfo();
    if (Capacitor.isNativePlatform()) CapacitorInit();
    else WebInit();

    createRoot(document.getElementById("root")).render(
        <Router>
            <App token={token} />
        </Router>
    );
};
main();
