import React from "react";
import Button from "../ComponentsV2/Button";
import Language from "../Utils/Languages";
import { Share } from "@capacitor/share";
import RestaurantUtils from "./RestaurantUtils";
import { getBusiness } from "../Utils/Utils";

const RestaurantItemsHeader = ({ provider }) => {
    const copyLink = async ({ currentTarget }) => {
        const businessId = provider.queryParams.businessId?.value;
        if (!businessId) return;

        navigator.clipboard.writeText(`https://restaurant.morabaa.com/rests/${businessId}`);
        await Share.share({
            title: `${Language.ShareMenuUrlTitle} ${(await getBusiness(businessId)).title}`,
            url: `https://restaurant.morabaa.com/rests/${businessId}`,
        });
        // currentTarget.innerText = Language.Copied;
        // currentTarget.style.color = "#56e181";
        // setTimeout(() => {
        //     currentTarget.style.color = "unset";
        //     currentTarget.innerText = Language.CopyLink;
        // }, 1000);
    };
    provider.itemsChanged = React.useMemo(() => {
        return () => {
            setTimeout(() => {
                const toggleBtn = document.getElementById("toggle-select-btn");
                if (provider.items.length === 0) toggleBtn.style.display = "none";
                else toggleBtn.style.display = "block";
                toggleBtn.innerText = provider.items.some((item) => !item.checked) ? Language.ChoseAll : Language.Cancel + " " + Language.ChoseAll;
            }, 1);
        };
    }, []);

    const choseAllItems = ({ currentTarget }) => {
        const items = provider.items;
        provider.itemsChanged();
        if (items.some((item) => !item.checked)) {
            items.forEach((item) => {
                if (item.checked === undefined) item.checked = true;
                else if (item.checked === false) item.checked = 1;
            });
        } else {
            items.forEach((item) => {
                if (item.checked === 1) item.checked = false;
                else if (item.checked === true) item.checked = undefined;
            });
        }
        provider.setItems((items) => [...items]);
        provider.onItemClicked(null, provider);
    };

    return (
        <div style={{ marginBlock: -10, display: "flex", gap: 10 }}>
            <Button onClick={copyLink} title={Language.Share} Extra="flex-grow" />
            <Button onClick={choseAllItems} title={`${Language.ChoseAll}`} Extra="flex-grow" id="toggle-select-btn" />
        </div>
    );
};

export default RestaurantItemsHeader;
