import { getCurrencesTitle } from "../Settings";
import { TimeToString } from "../Utils/DateFromater";
import Language from "../Utils/Languages";
import Utils, { AccountsStatues, getBusinesses } from "../Utils/Utils";

const getLastUpdate = async (api, businessId) => {
    if (!Utils.hasValue(businessId)) return null;
    let data = await api.get(`/v1/lastupdates/ofbusiness/${businessId}`);
    if (data) return TimeToString(data.updatedAt);
};
const getTotalAcounts = async (api, businessId) => {
    let stored = JSON.parse(sessionStorage.getItem("totalAcounts"));
    if (!stored) {
        stored = await api.get(`/v1/accounts/count`);
        sessionStorage.setItem("totalAcounts", JSON.stringify(stored));
    }
    if (businessId) return stored[businessId];
    else return Object.values(stored).reduce((a, b) => a + b, 0);
};

const getTotals = async (api, businessId) => {
    let total = await api.get(`/v2/accounts/totals/?businessId=${businessId}`);
    const hasValue = Object.values(total).some((x) => {
        return Object.values(x).some((y) => y);
    });
    if (!hasValue) return null;
    let formatedTotals = {
        credits: [],
        debts: [],
        nets: [],
    };
    if (total) {
        Object.keys(total).forEach((key) => {
            Object.keys(total[key])
                .sort((o) => o)
                .forEach((currncyId) => {
                    let amount = total[key][currncyId] || 0;
                    formatedTotals[key].push(Utils.FormatWithCurrency(amount, currncyId));
                });
        });
        Object.keys(total).forEach((key) => {
            if (!formatedTotals[key]) formatedTotals[key] = [0];
        });
    }
    return formatedTotals;
};

const AccountsUtils = {
    onResult: async (data) => {
        let arr = [];
        data?.forEach((account) => {
            let balances = account.balances;
            balances
                ?.sort((a) => a.currencyId)
                ?.forEach((_balance) => {
                    _balance.amount = Utils.Formate(Math.abs(_balance.amount));
                    _balance.state = AccountsStatues[_balance.state];
                    _balance.currency = getCurrencesTitle(_balance.currencyId);
                });
            account.balances = balances;
            arr.push(account);
        });
        return arr;
    },

    queryChanged: async ({ api, header, queryParams, setHeader }) => {
        const businessId = queryParams.businessId?.value || "";
        let showTotals = !queryParams["q"]?.value;
        if (header.businessId === businessId) {
            if (header.showTotals && !showTotals) setHeader({ showTotals });
            return;
        }
        header.businessId = businessId;

        const values = await Promise.all([
            queryParams.q?.value ? async () => {} : await getTotals(api, businessId),
            getTotalAcounts(api, businessId),
            getLastUpdate(api, businessId),
        ]);
        showTotals = showTotals && !!values[0];
        setHeader({ lastUpdate: values[2], accountsCount: values[1], totals: values[0], showTotals });
    },

    queryParams: [
        {
            className: "form-section",
            children: [
                {
                    key: "q",
                    type: "text",
                    title: Language.AccountName,
                    value: "",
                    containerClass: "query-input",
                    storageKey: "accounts-search",
                    showInClearBar: false,
                },
                {
                    key: "businessId",
                    type: "popupSelector",
                    value: "",
                    options: [{ title: Language.AllCompanies, id: "" }],
                    id: "businesses-selector",
                    storageKey: "selected-business",
                    title: Language.AllCompanies,
                    getData: getBusinesses,
                    className: "selector",
                },
            ],
        },
    ],
};

export default AccountsUtils;
