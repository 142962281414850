import React, { useState } from "react";
import { Checked } from "../../SVGs/Reps";
import CurrencyLabelIcon from "../../ComponentsV2/CurrencyLabelIcon";
import DefaultImage from "../../ComponentsV2/DefaultImage";

const ItemsCheckButton = ({ item, provider }) => {
    const [checked, setChecked] = useState(item.checked);
    React.useMemo(() => {
        setChecked(item.checked);
    }, [item.checked]);

    const onCheck = () => {
        if (item.checked) item.checked = item.checked === 1 ? false : undefined;
        else item.checked = item.checked === undefined ? true : 1;
        setChecked(!checked);
        provider.onItemClicked(item, provider);
    };

    return (
        <div className={`container-${checked ? "checked" : "unchecked"} transition-1  cursor-pointer select-none`} onClick={onCheck}>
            <div className="rounded-4 flex flex-col account-check-bg shadow-1 transition-1" style={{ paddingTop: "10px" }}>
                <DefaultImage uri={item.smallImage} />
                <p className="font-bold carbonic text-center ">{item.name}</p>
                <CurrencyLabelIcon price={item.sellPrice} />
                <div className="icon-container overflow-hidden flex flex-col items-center check-bg" style={{ height: 40, borderRadius: "0 0 12px 12px" }}>
                    <div className="tint-squer resturnt-squer transition-1" />
                    {Checked}
                </div>
            </div>
        </div>
    );
};

export default ItemsCheckButton;
