import React from "react";
import Language from "../Utils/Languages";
import SubmitButton from "../ComponentsV2/SubmitButton";

const Form = ({ children, onSubmit, submitText = Language.Confirm, className, close }) => {
    const _onSubmit = (e) => {
        e.preventDefault();
        onSubmit(e);
        return false;
    };

    return (
        <form className={`flex flex-col ${className}`} onSubmit={_onSubmit}>
            {children}
            {close ? (
                <div className="flex flex-row">
                    <SubmitButton text={submitText} />
                    <input
                        onClick={close}
                        type="button"
                        value={Language.Cancel}
                        className="cursor-pointer shadow-1 carbonic-card-1 carbonic rounded-2 py-3 w-32 my-2 font-bold mx-auto"
                    />
                </div>
            ) : (
                <SubmitButton text={submitText} />
            )}
        </form>
    );
};

export default Form;
