import React from "react";
import { Link } from "react-router-dom";
import { MorabaaLog, MyMorabaaLogo } from "../SVGs/OtherSvgs";
import Language from "../Utils/Languages";
import packageJson from "../../package.json";
const AboutView = () => {
    return (
        <div className="p-10 pt-0 justify-evenly col h-screen">
            <div>
                <div className="flex flex-row justify-center">{MyMorabaaLogo}</div>
                <p className="font-bold text-xl p-1 carbonic-3 text-center">v {packageJson.version}</p>
                <p className="font-bold carbonic text-center text-xl pt-4">{Language.AboutText}</p>
                <a
                    href="https://morabaa.com/apps/mymorabaa/"
                    style={{ textDecorationLine: "underline", textDecorationColor: "#63cfc9" }}
                    target="_blank"
                    rel="noopener noreferrer">
                    <p className="font-bold text-2xl cyan-text text-center pb-8">المزيد من المعلومات</p>
                </a>
            </div>
            <div>
                <div className="flex flex-row justify-center">{MorabaaLog}</div>
                <p className="font-bold carbonic text-center text-xl pt-6">{Language.ForMoreInfo}</p>
                <a
                    href="https://morabaa.com"
                    style={{ textDecorationLine: "underline", textDecorationColor: "#63cfc9" }}
                    target="_blank"
                    rel="noopener noreferrer">
                    <p className="font-bold text-2xl cyan-text text-center">morabaa.com</p>
                </a>
            </div>
        </div>
    );
};

export default AboutView;
