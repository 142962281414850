import { TimeToString } from "../Utils/DateFromater";
import Language from "../Utils/Languages";
import { getBusinesses } from "../Utils/Utils";

const Categories = {
    "الأقساط المستحقة": 0,
    "مواد منتهية الصلاحية": 1,
    "المواد المحذوفة": 2,
    "الحسابات المحذوفة": 3,
    "قوائم الشراء المحذوفة": 4,
    "قوائم البيع المحذوفة": 5,
    "بكبات لم ترفع": 6,
};

const NotificationsUtils = {
    onResult: async (data) => {
        return data.map((notification) => {
            notification.category = Categories[notification.category];
            notification.createdAt = TimeToString(notification.createdAt);
            notification.title = notification.title.length < 70 ? notification.title : `${notification.title.substring(0, 70)}...`;
            return notification;
        });
    },
    queryParams: [
        {
            className: "form-section px-4",
            children: [
                // {
                //     key: "all",
                //     value: "",
                //     type: "popupSelector",
                //     id: "sort-selector",
                //     className: "selector",
                //     storageKey: "all-notification",
                //     title: Language.All,
                //     options: [
                //         { title: Language.All, id: true },
                //         { title: Language.Read, id: false },
                //         { title: Language.UnRead, id: "" },
                //     ],
                // },
                {
                    key: "all",
                    value: "true",
                    type: "none",
                },
                {
                    key: "businessId",
                    type: "popupSelector",
                    value: "",
                    options: [{ title: Language.AllCompanies, id: "" }],
                    id: "businesses-selector",
                    storageKey: "selected-business",
                    title: Language.AllCompanies,
                    getData: getBusinesses,
                    className: "selector",
                },
                {
                    key: "category",
                    type: "popupSelector",
                    value: -1,
                    id: "sort-selector",
                    className: "selector",
                    storageKey: "selected-notification-category",
                    title: Language.TheType,
                    options: [
                        { title: Language.All, value: "", id: -1, dispalyTitle: Language.TheType },
                        { title: Language.NotificationDue, value: "الأقساط المستحقة", id: 0 },
                        { title: Language.NotificationExpire, value: "مواد منتهية الصلاحية", id: 1 },
                        { title: Language.NotificationDeletedItems, value: "المواد المحذوفة", id: 2 },
                        { title: Language.NotificationDeletedAccounts, value: "الحسابات المحذوفة", id: 3 },
                        { title: Language.NotificationDeletedSaleBills, value: "قوائم الشراء المحذوفة", id: 4 },
                        { title: Language.NotificationDeletedBuyBill, value: "قوائم البيع المحذوفة", id: 5 },
                        { title: Language.NotificationFaildBucks, value: "بكبات لم ترفع", id: 6 },
                    ],
                },
            ],
        },
    ],
};

export default NotificationsUtils;
