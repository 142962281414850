import React, { useState } from "react";
import Language from "../../Utils/Languages";
import CategoriyHolder from "./CategoriyHolder";
import Notifications from "../../API/Notifications";
const Icons = {
    //
    // {icon: require("../../../assets/notifications/filled.png"), text: Language.Filled},
    // require("../../../assets/notifications/outline.png"),
    // 0: { icon: require("../../../assets/notifications/due.png"), text: Language.NotificationDue, color: "#FB5607" },
    0: { icon: "/notifications/due.png", text: Language.NotificationDue, color: "#00b4d8" },
    1: { icon: "/notifications/expire.png", text: Language.NotificationExpire, color: "#38b000" },
    2: { icon: "/notifications/deletedItems.png", text: Language.NotificationDeletedItems, color: "#D00000" },
    3: { icon: "/notifications/deletedAccounts.png", text: Language.NotificationDeletedAccounts, color: "#D00000" },
    4: { icon: "/notifications/deletedBills.png", text: Language.NotificationDeletedBuyBill, color: "#D00000" },
    5: { icon: "/notifications/deletedBills.png", text: Language.NotificationDeletedSaleBills, color: "#D00000" },
    6: { icon: "/notifications/backupFailed.png", text: Language.NotificationFaildBucks, color: "#D00000" },
};

const NotificationCard = ({ item, provider }) => {
    let category = Icons[item.category] || Icons[1];

    const showNotificationDetails = ({ currentTarget }) => {
        let _category = { text: category.text, color: category.color };
        provider.setPopup({
            page: (
                <div className="p-6 font-bold max-w-xl col items-start">
                    <CategoriyHolder category={_category} />
                    <p className="carbonic-1 py-2 text-base">{item.businessTitle}</p>
                    <p className="carbonic">{item.message}</p>
                </div>
            ),
        });
        Notifications.markRead(item.id);
        item.readAt = true;
        currentTarget.firstChild.style.backgroundColor = "#ee363600";
    };

    return (
        <div onClick={showNotificationDetails} className="fade-in-card flex flex-row  items-center carbonic-card m-2 shadow-1 p-3 rounded-4 ">
            <span className="p-1 -mt-10 -ml-5 rounded" style={{ backgroundColor: item.readAt ? "#ee363600" : "#ee3636" }} />
            <img src={category.icon} style={{ minWidth: 70 }} className="h-10 px-4" />
            <div className=" flex flex-col flex-grow items-start">
                <CategoriyHolder category={category} />
                <p className="font-bold carbonic-1 p-1 text-base">{item.businessTitle}</p>
                <p className="font-bold carbonic text-xl">{item.title}</p>
                <p className="text-gray-600 text-sm font-bold text-left pl-2">{item.createdAt}</p>
            </div>
        </div>
    );
};

export default NotificationCard;
