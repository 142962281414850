import Info from "../NodeBuilder/Info";
import { PermissionsTitles } from "../Representatives/Components/RepsPermissions";
import { HubAPI, RepresentativeAPI, SalesReportAPI, ItemsAPI } from "./remoteAPI";

const Representatives = {
    getStores: async ({ businessId, representativeId, Error }, setStores, stores, clear = false) => {
        let { data } = await ItemsAPI.get(`/stores/ofbusiness/${businessId}?offset=${clear ? 0 : stores.length || 0}&limit=${50}`);
        let repsStors = await RepresentativeAPI.get(`/stores/${representativeId}`);
        let _repsSotrs = repsStors.data || [];
        _repsSotrs.forEach((store) => {
            let founded = data.find((o) => o.id == store.id);
            founded && (founded.checked = true);
        });
        setStores([]);
        setTimeout(() => {
            const _stores = data.sort((o) => (o.checked ? -1 : 1));
            setStores(clear ? [..._stores] : stores.concat([...data]).sort((o) => (o.checked ? -1 : 1)));
        }, 100);
        return data;
    },

    setStores: async ({ representativeId, businessId, Done, Error }, stores) => {
        stores = stores.sort((o) => (o.checked ? -1 : 1));
        let dataRequest = {
            representativeId,
            businessId,
            storesIds: stores.filter((o) => o.checked)?.map((o) => o.id),
        };
        RepresentativeAPI.post("/stores/", dataRequest).then(Done).catch(Error);
    },

    getAccounts: async ({ businessId, representativeId }, clear, accounts, setAccounts, searchQuery) => {
        let url = `/v1/accounts/?offset=${clear ? 0 : accounts.length}`;
        if (businessId) url += `&businessId=${businessId}`;
        if (searchQuery) url += `&q=${searchQuery}`;
        let { data } = await SalesReportAPI.get(url);

        let newAccounts = [];
        let accountIds = data.map((o) => o.id);
        let repsAccounts = await RepresentativeAPI.post(`/accounts/ofrep/${representativeId}`, accountIds);

        data.forEach((newAccount) => {
            let { id, name, address, phoneNumber } = newAccount;
            let checked = repsAccounts.data.findIndex((rep) => rep.id === newAccount.id) > -1 ? 1 : undefined;
            newAccounts.push({ id, name, address, phoneNumber, checked });
        });

        setAccounts((clear ? newAccounts : accounts.concat(newAccounts)).sort((o) => (o.checked ? -1 : 1)));
        return data;
    },

    setAccounts: ({ businessId, representativeId, Done, Error }, accounts) => {
        accounts = accounts.sort((o) => (o.checked ? -1 : 1));
        let dataRequest = {
            representativeId,
            businessId,
            accountsIdsToAdd: accounts.filter((o) => o.checked === true).map((o) => o.id),
            accountsIdsToRemove: accounts.filter((o) => o.checked === false).map((o) => o.id),
        };
        RepresentativeAPI.post("/accounts/", dataRequest).then(Done).catch(Error);
    },
    setAccountsByQuery: async ({ representativeId, businessId, searchQuery, Done, Error }, selectAll) => {
        RepresentativeAPI.post("/accounts/byquery/", { representativeId, businessId, searchQuery })
            .then(() => {
                selectAll();
                Done();
                window.location.reload();
            })
            .catch(Error);
    },
    // Reps //
    getRepresentatives: ({ businessId }) => HubAPI.get(`/representatives/ofbusiness/${businessId}`),
    addRepresentative: (businessId, repsInfo, permissions, Done) => {
        HubAPI.post(`/representatives/ofbusiness/${businessId}/`, repsInfo).then(({ data }) => {
            RepresentativeAPI.post("/permissions", { representativeId: data.id, permissions }).then(() => Done());
        });
    },

    getRepresentative: (representativeId) => HubAPI.get(`/representatives/${representativeId}`),
    update: (representativeId, repsInfo, permissions, Done, Error) => {
        HubAPI.patch(`/representatives/${representativeId}`, repsInfo)
            .then(() => {
                RepresentativeAPI.post("/permissions", { representativeId, permissions }).then(Done).catch(Error);
            })
            .catch(Error);
    },
    // delete: ({ representativeId, Done, Error }) => HubAPI.delete(`/representatives/${representativeId}`).then(Done).catch(Error),
    delete: (representativeId) => HubAPI.delete(`/representatives/${representativeId}`),
    getBusinessesSubed: () => HubAPI.get("/solutions/com.morabaa.reps/businesses/"),
    getPermissions: async (representativeId, setRepsPermissions) => {
        let { data } = await RepresentativeAPI.get(`/permissions/ofrepresentative/${representativeId}`);
        data.forEach((perms) => (perms.title = PermissionsTitles[perms.key]));
        data = data.sort((o) => (o.key ? -1 : 1));
        setRepsPermissions(data);
    },
    unselectAllAccounts: (representativeId) => RepresentativeAPI.delete(`accounts/ofrep/${representativeId}/all`),
    // Reps //
};

export default Representatives;
