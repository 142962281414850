import React, { useState } from "react";
import Language from "../../Utils/Languages";
import { isMobile } from "react-device-detect";
import { Fatle } from "../../SVGs/OtherSvgs";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";

const BottomConfirmPopup = ({ onSubmit, close }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const toggleShowPassword = () => setShowPassword(!showPassword);
    const _onSubmit = (e) => {
        e.preventDefault();
        onSubmit(passwordConfirm);
        return false;
    };
    return (
        <DialogConatiner close={close}>
            <form onSubmit={_onSubmit} className="col center rounded-2 red-bg py-2 px-6" style={{ margin: -20 }}>
                {Fatle}
                <p className="font-bold text-white text-xl my-1">{Language.Wrnning}</p>
                <p className="font-bold text-white mt-6 mb-2 text-center">
                    <span className="font-bold">{Language.AddOwnerWorning}</span>
                </p>

                <div className="flex flex-row m-2 ">
                    <input
                        required
                        value={passwordConfirm}
                        placeholder={Language.Password}
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        style={{ backgroundColor: "#b82e38", color: "#dc979c" }}
                        onChange={({ target }) => setPasswordConfirm(target.value)}
                        className="font-bold flex-grow px-3 py-2 rounded-1 focus:outline-none"
                    />
                </div>
                <input
                    type="submit"
                    value={Language.Confirm}
                    className="cursor-pointer shadow-1 bg-white red-text rounded-2 py-2 my-2 w-32 font-bold mx-auto"
                />
            </form>
        </DialogConatiner>
    );
};

export default BottomConfirmPopup;
