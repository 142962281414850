import React from "react";
import Grid from "../Containers/Grid";
import GridHeader from "../Containers/GridHeader";
import FormContainer from "../Form/FormContainer";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import CreateProvider from "../scripts/CreateApiProviderClass";
import PopupBuilder from "../Containers/PopupBuilder";
const PageBuilder = ({
    url,
    id,
    apiId,
    onResult,
    queryChanged,
    formBuilderClass,
    gridClass,
    queryParams,
    itemBuilder: ItemBuilder,
    headerBuilder: HeaderBuilder,
    onItemClicked,
    hasPopup,
    limit,
}) => {
    const provider = React.useMemo(() => {
        console.debug(id + " RD");
        let _provider = new CreateProvider({
            url,
            scrollerId: `${id}-wrapper`,
            sessionKey: `stored-${id}`,
            onResult: onResult,
            onQueryChanged: queryChanged,
            providerId: id,
            apiId: apiId || id,
            limit: limit ?? 25,
        });
        _provider.onItemClicked = onItemClicked;
        return _provider;
    }, []);

    return (
        <div id={`${id}-wrapper`} className="wrapper scroller">
            {queryParams && <FormContainer provider={provider} children={queryParams} className={formBuilderClass} />}
            {HeaderBuilder && <GridHeader provider={provider} builder={HeaderBuilder} />}
            <Grid provider={provider} ItemBuilder={ItemBuilder} className={gridClass} />
            <PageStateProvider provider={provider} />
            {hasPopup && <PopupBuilder provider={provider} id={id} />}
        </div>
    );
};

export default PageBuilder;
