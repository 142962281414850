import { main } from "..";
import Notifications from "../API/Notifications";
const ClearStorge = async (logoutFromAllDevices = false) => {
    try {
        await removeNotification(logoutFromAllDevices);
    } catch {}
    let language = localStorage.getItem("language") || "ar";
    let theme = localStorage.getItem("theme") || "dark";
    let currency = localStorage.getItem("currency") || "1";
    localStorage.clear();
    localStorage.setItem("language", language);
    localStorage.setItem("theme", theme);
    localStorage.setItem("currency", currency);

    main();
    setTimeout(() => {
        window.location.reload();
    }, 700);
};

const removeNotification = async (logoutFromAllDevices = false) => {
    if (logoutFromAllDevices) await Notifications.deleteAllTokens();
    else {
        const pushToken = localStorage.getItem("pushToken");
        if (pushToken) await Notifications.deleteToken(pushToken);
    }
};

export default ClearStorge;
