import React from "react";

const DialogConatiner = ({ close, children, childClassName = "popup-child " }) => {
    return (
        <div id="13" onClick={({ target }) => target.id === "13" && close()} className={`popup-builder`}>
            <div className={`${childClassName}`}>{children}</div>
        </div>
    );
};

export default DialogConatiner;
