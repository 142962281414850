import SalesReport from "../API/SalesReport";
import React, { useState, useEffect } from "react";
import { DailyFormat } from "../DataFormater/Home";
import LoaderComponent from "../Components/LoaderComponent";
import BusinessDailyReportCard from "./Components/BusinessDailyReportCard";
import Scroller from "../ComponentsV2/Scroller";

const BusinessesDaileyReportView = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [salesPerDay, setSalesPerDay] = useState([]);

    const fetchSalesPerDay = () => {
        SalesReport.getSalesPerDay().then(({ data }) => {
            let fomrated = DailyFormat(data);
            if (fomrated.length > 1) {
                setSalesPerDay(fomrated.slice(1));
            } else setSalesPerDay(fomrated);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchSalesPerDay();
    }, []);

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <Scroller className="grid">
            {salesPerDay.map((item) => (
                <BusinessDailyReportCard key={item.id} sales={item.sales} title={item.businessTitle} businessCategoryId={item.businessCategory} item={item} />
            ))}
        </Scroller>
    );
};

export default BusinessesDaileyReportView;
