import React from "react";

const ButtonColors = {
    red: "red-bg text-white",
    redText: "red-text bg-whtie",
    black: "carbonic carbonic-card",
};

const Button = ({ onClick, title, Extra, color = "redText", id }) => {
    const buttonClass = `py-2 px-4 shadow-1 text-center font-bold rounded-1 ${Extra} ${ButtonColors[color]}`;
    return (
        <button id={id} style={{ maxWidth: 195, pointerEvents: "all" }} onClick={onClick} className={buttonClass}>
            {title}
        </button>
    );
};

export default Button;
