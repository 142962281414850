import React, { useState } from "react";
import { Checked } from "../../SVGs/Reps";
import Language from "../../Utils/Languages";

const CheckButton = ({ item, onClick }) => {
    const [checked, setChecked] = useState(!item.isActive);

    const onCheck = () => {
        setChecked(!checked);
        onClick();
    };

    return (
        <div className={`container-${checked ? "checked" : "unchecked"}  cursor-pointer transition-1 select-none mx-auto`} onClick={onCheck}>
            <div className="m-2 transition-1 rounded-2 flex flex-row items-center  account-check-bg shadow-1 ">
                <p className="carbonic flex-grow font-bold px-4 ">{Language.Freeze}</p>
                <div
                    className="icon-container transition-1 overflow-hidden transition-1 flex flex-row  items-center check-bg"
                    style={{ width: 40, height: 50, borderRadius: "12px 0 0 12px " }}>
                    <div className="tint-squer transition-1 squer" />
                    {Checked}
                </div>
            </div>
        </div>
    );
};

export default CheckButton;
