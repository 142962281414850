import { useParams } from "react-router";
import React from "react";
import TransactionCard from "./Components/TransactionCard";
import Utils from "../Utils/Utils";
import { GetFormatedDate } from "../Utils/DateFromater";
import PageBuilder from "../PageBuilder/PageBuilder";

const BusinessTransactionsView = () => {
    const params = useParams();
    return (
        <PageBuilder
            apiId="sales"
            id="business-transactions"
            url={`v2/businesstransactions/ofbusiness/${params.businessId}`}
            itemBuilder={TransactionCard}
            onResult={onResult}
            queryParams={[
                {
                    children: [
                        {
                            key: "type",
                            type: "none",
                            value: params.opType,
                        },
                    ],
                },
            ]}
        />
    );
};

export const onResult = (data) =>
    data.map((element) => {
        element.amount = Utils.FormatWithCurrency(element.amount, element.currencyId);
        element.description = element.description.split("~/");
        element.date = GetFormatedDate(element);
        return element;
    });

export default BusinessTransactionsView;
