import React from "react";

const OwnerCard = ({ owner }) => (
    <div className="row carbonic-card-2 p-2 m-2 rounded-xl shadow-1" key={owner.id}>
        <img
            src={
                owner.image ? `https://hubcore.morabaaapps.com${owner.image}` : "https://hubcore.morabaaapps.com/files/d576ef63-0d0e-4e1c-8375-2efed9cd1d70.svg"
            }
            alt="profile"
            className="h-12 w-12 rounded-full object-fill bg-gray-300 ml-4"
        />
        <p className="font-bold ">
            <span className="text-base carbonic">
                {owner.firstName} {owner.lastName}
            </span>
            <br />
            <span className="carbonic-3">{owner.phoneNumber}</span>
        </p>
    </div>
);

export default OwnerCard;
