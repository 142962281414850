import React, { useState, useEffect } from "react";
import { DevConsoleAPI } from "../../API/remoteAPI";
import Language from "../../Utils/Languages";

const ThirdPartyAppsComponent = () => {
    const [apps, setApps] = useState([]);

    const fetchApps = () => DevConsoleAPI.get("/apps/loggedin").then((response) => setApps(response.data));

    const logout = (appId) => {
        DevConsoleAPI.post(`/apps/${appId}/logout`).then(() => {
            setApps(apps.filter((x) => x.id !== appId));
        });
    };

    useEffect(() => {
        DevConsoleAPI.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
        fetchApps();
    }, []);

    return (
        <div className="flex flex-col">
            {apps &&
                apps.length > 0 &&
                apps.map((x) => (
                    <div key={x.id} className="flex flex-row p-4 rounded-lg shadow-lg">
                        <p className="flex-1">
                            <span className="font-bold text-gray-800">{x.appName}</span>
                            <br />
                            <span className="font-bold text-sm text-gray-800">{x.developerName}</span>
                        </p>
                        <button className="bg-red-700 rounded-full px-4 py-2 font-bold text-gray-50" onClick={() => logout(x.id)}>
                            {Language.Logout}
                        </button>
                    </div>
                ))}
        </div>
    );
};

export default ThirdPartyAppsComponent;
