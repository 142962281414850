import Lottie from "lottie-react";
import React from "react";
import Language from "../Utils/Languages";
import noData from "../lotties/noData.json";
const EmptyList = ({ title = Language.ThereIsNoData }) => {
    return (
        <div className="flex col-span-full flex-col items-center justify-center m-auto">
            <Lottie className="max-w-xl" animationData={noData} />
            <p className="font-bold text-2xl carbonic pt-6">{title}</p>
        </div>
    );

    return (
        <div className="flex col-span-full flex-col items-center justify-center m-auto">
            <svg height={200} width={200} fill="#dd3643" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.1 417">
                <path
                    d="M292,631V366a18.84,18.84,0,0,0,.91-2.75c5.43-32,23.27-54.24,53-66.85,5.75-2.44,12-3.63,18.08-5.4H636a22.32,22.32,0,0,0,2.79.94c40,7.65,69,41.87,69.17,82.66.26,66.31.09,132.63.06,198.95a77.2,77.2,0,0,1-8.77,36.56c-16.53,31.25-42.7,47.21-78,47.38-54.49.27-109,.07-163.47.07H451V708H375c-1.6-.61-3.17-1.63-4.81-1.77-34.28-2.94-63.21-25.51-73.86-58C294.49,642.63,293.42,636.75,292,631Zm111-20.44h7q104.22,0,208.45,0c25.82,0,42.57-16.73,42.58-42.51q.06-94,0-187.95c0-25.83-16.71-42.62-42.46-42.64q-118.47-.06-237,0c-25.82,0-42.62,16.79-42.64,42.48q0,43,0,86,0,76.23,0,152.46c0,17.7,9.67,32.38,25.3,38.36,12.53,4.8,25.55,2.24,38.7,2.82Z"
                    transform="translate(-292 -291)"
                />
            </svg>
        </div>
    );
};

export default EmptyList;
