import { GetFormatedDate } from "../Utils/DateFromater";
import { InvoicesAPI } from "./remoteAPI";

export const InvoicesApi = {
    getHistory: (offset, setHistories, onError) => {
        let url = `/history?offset=${offset}`;
        InvoicesAPI.get(url)
            .then(({ data }) => {
                data.forEach((element) => {
                    element.invoice.date = GetFormatedDate(element.invoice);
                    element.total = Object.values(element.totalByCurrency)[0];
                    element.currencyId = Object.keys(element.totalByCurrency)[0];
                });
                setHistories(data);
            })
            .catch(onError);
    },
    postHistory: (invoiceId) => InvoicesAPI.post("/history", { invoiceId }),
    getInvoice: (invoiceId) => InvoicesAPI.get(`/invoices/${invoiceId}`),
    canAdd: (invoiceId) => InvoicesAPI.get(`/history/canadd/${invoiceId}`),
};
