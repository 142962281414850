import { getCurrencesTitle } from "../Settings";
import { TimeToString } from "../Utils/DateFromater";
import Language from "../Utils/Languages";
import Utils, { getBusinesses } from "../Utils/Utils";

const getItemsCount = async (api, businessId) => {
    let query = businessId ? `?businessId=${businessId}` : "";
    return await api.get(`/v1/items/ofowner/count${query}`);
};

const getLastUpdate = async (api, businessId) => {
    if (!Utils.hasValue(businessId)) return null;
    let data = await api.get(`/v1/lastupdates/ofbusiness/${businessId}`);
    if (data) return TimeToString(data.updatedAt);
};

const ItemsUtils = {
    onResult: (data) => {
        let arr = [];
        data?.forEach((item) => {
            let { sellPriceObject, buyPriceObject, images } = item;
            item.sellPrice = { amount: Utils.Formate(sellPriceObject.price), currency: getCurrencesTitle(sellPriceObject.currencyId) };
            item.buyPrice = { amount: Utils.Formate(buyPriceObject.price), currency: getCurrencesTitle(buyPriceObject.currencyId) };

            item.smallImage = images?.small || undefined;
            item.mainImage = images?.main || undefined;

            if (item.packages) {
                item.packages.forEach((pack) => {
                    pack.price = Utils.Formate(pack.price, sellPriceObject.currencyId);
                    item.sellPrice.amount = pack.price;
                    item.quantityWithFill = pack.packageQuantity;
                });
                item.selectedPackageId = item.packages.find((p) => p.isDefault)?.packageId;
            }

            let { id, name, sellPrice, buyPrice, businessId, businessTitle, checked, groupId, smallImage, mainImage, quantityWithFill, packages } = item;
            arr.push({
                id,
                name,
                sellPrice,
                buyPrice,
                businessId,
                businessTitle,
                checked,
                groupId,
                smallImage,
                mainImage,
                quantityWithFill,
                packages,
                selectedPackageId: item.selectedPackageId,
            });
        });
        return arr;
    },
    queryChanged: async ({ api, header, setHeader, queryParams }) => {
        const businessId = queryParams.businessId?.value || "";
        if (header.businessId === businessId) return;
        header.businessId = businessId;

        const [lastUpdate, itemsCount] = await Promise.all([getLastUpdate(api, businessId), getItemsCount(api, businessId)]);
        setHeader({ lastUpdate, itemsCount });
    },

    queryParams: [
        {
            className: "form-section px-4",
            children: [
                {
                    key: "query",
                    type: "text",
                    title: Language.SearchingFor,
                    value: "",
                    storageKey: "items-query",
                    containerClass: "query-input",
                    showInClearBar: false,
                },
                {
                    key: "query-barcode",
                    type: "scanner",
                    value: "",
                    showInClearBar: false,
                },
                {
                    key: "businessId",
                    type: "popupSelector",
                    value: "",
                    options: [{ title: Language.AllCompanies, id: "" }],
                    id: "businesses-selector",
                    storageKey: "selected-business",
                    title: Language.AllCompanies,
                    getData: getBusinesses,
                    className: "selector",
                },
                {
                    key: "sort",
                    type: "popupSelector",
                    value: "",
                    id: "sort-selector",
                    className: "selector",
                    storageKey: "selected-sort",
                    options: [
                        { title: Language.Cancel, id: "", dispalyTitle: Language.OrderBy },
                        { title: Language.HighestSellingPriceFirst, id: "sellDesc" },
                        { title: Language.LowestSellingPriceFirst, id: "sellAsc" },
                        { title: Language.DateAddedMostRecentlyFirst, id: "addedDesc" },
                        { title: Language.DateAddedOldestFirst, id: "addedAsc" },
                        { title: Language.AlphabeticallyAscending, id: "alpaAsc" },
                        { title: Language.AlphabeticallyDescending, id: "alpaDesc" },
                    ],
                },
            ],
        },
    ],
};

export default ItemsUtils;
