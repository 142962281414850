import { SvgBorderPath } from "./BorderSvgs";

export const TicketSvgs = {
    Company: (
        <SvgBorderPath>
            <path
                className="svg-card"
                d="M508.68,569.85H271.82a12.8,12.8,0,0,0-2.78-1.27c-35.22-6.51-57.21-32.66-57.27-68.22,0-22,.07-44.08-.1-66.12,0-2.65-.47-6.24-2.18-7.79-14.7-13.37-23.35-30-27.68-49.13v-14.2c8.63-21.36,17.54-42.62,25.73-64.14a71.34,71.34,0,0,0,4.1-20.5c1.84-28.66,15.2-49.53,41.83-60.47,8.71-3.59,18.87-5,28.39-5.09,72.37-.41,144.74-.34,217.11-.18,37.37.09,66.54,24.47,69,59.82,1.06,15.2,5.7,28.57,11,42.19,5.42,13.94,11.53,27.66,16.22,41.84A53.26,53.26,0,0,1,597.5,378c-1.85,18.26-11.38,32.89-23.84,46a18.63,18.63,0,0,0-4.72,11.39c-.44,22.35-.08,44.71-.25,67.06a66.87,66.87,0,0,1-51.79,65.21C514.14,568.35,511.41,569.13,508.68,569.85ZM390.52,316.64c-45,0-90,.08-134.95-.12-5.5,0-8.49,1.36-10.33,6.92-4.45,13.44-8.93,27-14.71,39.85-4.52,10.07-2.18,18,4.71,25,4.9,4.94,10.6,9.62,16.81,12.65,20.15,9.84,42.21,3.79,60.21-15.6,11.86-12.77,25-12.89,36.61-.34,25.37,27.49,57.27,27.4,82.91-.22,10.5-11.32,22.61-11.52,34.77-1.74a195.9,195.9,0,0,0,26.61,18.08c21.5,12.12,48.94-2.52,57.34-20.87a18.86,18.86,0,0,0,.59-12.58c-4.81-14.71-10.75-29-15.67-43.71-1.92-5.72-4.76-7.5-10.74-7.46C480,316.75,435.24,316.64,390.52,316.64Z"
            />
        </SvgBorderPath>
    ),

    Open: (
        <SvgBorderPath>
            <path d="M391.89,222.67q63.08,0,126.16,0c35.83,0,58,22.09,58,57.9q0,117.68,0,235.36c0,26.62-15.54,47.8-39.8,54a73.9,73.9,0,0,1-18.13,1.91q-126.49.18-253,.06c-35.77,0-57.95-22.31-57.95-58q0-117.67,0-235.36c0-25.4,13.72-45.61,36.58-53,6.53-2.11,13.76-2.74,20.68-2.78C306.88,222.55,349.39,222.67,391.89,222.67Zm28.33,319.82V252c-2.59-.13-4.8-.33-7-.34q-74.24,0-148.48,0c-19.69,0-28.81,9.29-28.82,29.2q0,116.28,0,232.56c0,20.21,9.12,29.33,29.37,29.34q73.9,0,147.81,0C415.31,542.79,417.52,542.61,420.22,542.49Z" />
            <path d="M312,382.37c21.37,0,42.75,0,64.12,0,13.15,0,20.15,4.94,20.19,14.54s-6.92,14.79-20.06,14.79H310.19c6.87,7,12.73,12.68,18.33,18.63,6.2,6.57,6.18,15.46.22,21.18a14.14,14.14,0,0,1-20.15,0q-22.32-21.75-44.07-44.06c-6.08-6.23-6-14.39.07-20.66q21.53-22.06,43.58-43.59c6.27-6.11,14.93-5.86,20.66-.15s5.81,14.48-.11,20.69c-5.6,5.87-11.66,11.29-17.52,16.91C311.47,381.22,311.73,381.8,312,382.37Z" />
        </SvgBorderPath>
    ),
    Close: (
        <SvgBorderPath>
            <path d="M391.89,222.67q63.08,0,126.16,0c35.83,0,58,22.09,58,57.9q0,117.68,0,235.36c0,26.62-15.54,47.8-39.8,54a73.9,73.9,0,0,1-18.13,1.91q-126.49.18-253,.06c-35.77,0-57.95-22.31-57.95-58q0-117.67,0-235.36c0-25.4,13.72-45.61,36.58-53,6.53-2.11,13.76-2.74,20.68-2.78C306.88,222.55,349.39,222.67,391.89,222.67Zm28.33,319.82V252c-2.59-.13-4.8-.33-7-.34q-74.24,0-148.48,0c-19.69,0-28.81,9.29-28.82,29.2q0,116.28,0,232.56c0,20.21,9.12,29.33,29.37,29.34q73.9,0,147.81,0C415.31,542.79,417.52,542.61,420.22,542.49Z" />
            <path d="M344.29,412c-21.37,0-42.74,0-64.12,0-13.14,0-20.14-4.94-20.19-14.54s6.93-14.79,20.06-14.79c21.42,0,42.85,0,66.07,0-6.88-7-12.73-12.68-18.34-18.63-6.19-6.57-6.17-15.46-.21-21.18a14.13,14.13,0,0,1,20.15,0Q370,364.66,391.78,387c6.08,6.23,6,14.39-.08,20.66q-21.51,22-43.58,43.59c-6.26,6.11-14.93,5.86-20.66.15s-5.81-14.48.12-20.69c5.6-5.87,11.66-11.29,17.52-16.92Z" />
        </SvgBorderPath>
    ),
    Send: (
        <svg fill="#dd3643" width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398 397.74">
            <path
                d="M300.82,315.78c.13-10.91,9.29-17.35,19.06-14.29,12.2,3.82,24.27,8.06,36.39,12.1l330.22,110c7.85,2.62,12,7.32,12.31,14,.3,6.22-3.47,11.32-11,14.51q-80.47,34.11-161,68.08a11.33,11.33,0,0,0-6.66,6.68c-22.51,53.54-45.34,107-67.71,160.55-5.9,14.15-20,13.39-25.5,5.47a24.48,24.48,0,0,1-3-6.25Q363.08,504.2,302.31,321.76A60.46,60.46,0,0,1,300.82,315.78ZM439.58,643.87c1.11-2.3,1.73-3.45,2.24-4.65q25.71-60.69,51.47-121.36c1.4-3.28,0-4.83-2-6.81q-37-36.9-73.9-73.86c-4.36-4.36-8.85-8.6-13-13.13a13.86,13.86,0,0,1,1.39-20.39c6.19-5.23,13.63-4.47,20.37,2.27q42.47,42.39,84.78,84.9c2.8,2.82,4.85,3.27,8.55,1.68,39-16.74,78.12-33.25,117.21-49.83,2.1-.89,4.19-1.83,7.5-3.29L337.35,337.15Z"
                transform="translate(-300.82 -300.73)"
            />
        </svg>
    ),
    Evaluation: (
        <svg fill="#dd3643" width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.3 339.36">
            <path
                d="M500.13,330.33c38.5,0,77-.06,115.5,0,26.7.06,47.36,16.29,52.72,41.45a67.38,67.38,0,0,1,1.19,13.91q.11,114.27,0,228.55c0,27.85-16,48.71-41.65,54.15a66,66,0,0,1-13.4,1.2q-114.51.1-229,0c-27.78,0-48.72-16.25-54-41.8a66.16,66.16,0,0,1-1.11-13.42q-.11-114.53,0-229c0-27.58,16-48.35,41.39-53.82a67.21,67.21,0,0,1,13.9-1.16Q442.88,330.25,500.13,330.33Zm-61,248.26c.07,7.92,7.53,11.92,14.87,8.13,14-7.26,28.07-14.55,42-22.1,3.07-1.67,5.3-1.46,8.21.11,13.62,7.33,27.37,14.43,41.05,21.68,4,2.09,7.91,2.89,11.7,0,3.55-2.73,4.09-6.42,3.3-10.76-2.81-15.4-5.29-30.85-8-46.25a7.34,7.34,0,0,1,2.47-7.45c11.46-10.93,22.69-22.09,34.07-33.09,3-2.94,4.41-6.3,3.16-10.36s-4.34-6-8.51-6.62c-15.82-2.26-31.61-4.7-47.45-6.82a6.83,6.83,0,0,1-6-4.32C523.25,446.78,516.3,433,509.56,419c-2.06-4.24-4.76-7.17-9.82-7.09-4.84.08-7.4,3-9.37,7-6.68,13.77-13.58,27.43-20.21,41.23-1.44,3-3.11,4.47-6.61,4.92-15.35,2-30.63,4.51-46,6.62-4.5.62-8.1,2.1-9.59,6.66s.52,7.95,3.78,11.08c11.29,10.85,22.54,21.76,33.58,32.87a8.88,8.88,0,0,1,2.22,6.4c-.7,6.44-2.12,12.8-3.22,19.2C442.59,558.25,440.85,568.59,439.16,578.59Z"
                transform="translate(-330.3 -330.31)"
            />
        </svg>
    ),
    TicketInfo: (
        <svg fill="#dd3643" width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 395.91 264.02">
            <path
                d="M584.8,631.78c0-6.68.24-12.83-.07-19-.36-7.23-5.21-12.69-11.61-13.77s-13.11,2.32-15.37,8.44a28.12,28.12,0,0,0-1.26,8.31c-.23,4.82-.13,9.66-.2,14.49a7.2,7.2,0,0,1-.62,1.6h-5.06q-106.5,0-213,0c-21.35,0-35.43-14-35.54-35.25q-.06-14,0-28c0-11.11,4.45-16.07,15.52-17.5,23-3,39.64-18.22,44.69-40.88,6.43-28.83-14.55-58.16-43.92-61.41-11.91-1.32-16.35-6.22-16.27-18.31.07-11.32-.58-22.75.71-33.94,2-17.15,15.7-28.47,33-28.56,22.33-.12,44.66,0,67,0H556.74c0,7.26-.31,14,.1,20.78.36,6.18,4.31,10.05,10.07,11.67,5.59,1.58,11,.26,14.26-4.55,2.1-3.1,3.12-7.28,3.53-11.09.58-5.38.15-10.88.15-16.81h5.46q36,0,72,0c21.34,0,35.41,14,35.52,35.26,0,9.34,0,18.67,0,28,0,11.1-4.47,16-15.52,17.5-22.2,3-36.83,15.38-43.52,36.55-9.52,30.11,11.62,62.27,42.75,65.73,11.9,1.32,16.35,6.23,16.27,18.31-.07,11.32.52,22.74-.72,33.94-1.78,16.05-13.94,27.83-30,28.28C640,632.34,612.79,631.78,584.8,631.78ZM556.7,457.24c0,5.32-.34,10.66.08,15.94a13.76,13.76,0,0,0,13.13,12.66c6.54.39,13.57-4.1,14.05-10.84a248.92,248.92,0,0,0,.06-34.76c-.48-6.82-7.34-11.27-14-10.94a13.64,13.64,0,0,0-13.26,12.49C556.36,446.91,556.71,452.09,556.7,457.24Zm28.14,85.36c0-4.49.12-9,0-13.47-.28-8.81-6.11-15-14-15.11s-14,6-14.24,14.78c-.27,9-.26,18,0,26.94s6.28,14.92,14.18,14.85,13.82-6.22,14.12-15C585,551.25,584.84,546.92,584.84,542.6Z"
                transform="translate(-301.98 -367.96)"
            />
        </svg>
    ),
};
