import React from "react";

const GridHeader = ({ provider, builder: Builder = () => <></>, id = "grid-header", className = "items-header-container" }) => {
    [provider.header, provider.setHeaderState] = React.useState(provider.header);

    return provider.header ? (
        <div id={id} className={className}>
            <Builder provider={provider} />
        </div>
    ) : (
        <></>
    );
};

export default GridHeader;
