import axios from "axios";
import Info from "../NodeBuilder/Info";
// import JsonParser from "../scripts/NodeBuilder";
import Language, { ErrorTitles } from "../Utils/Languages";

const HubRoot = "https://hubcore.morabaaapps.com/api/v1";
const ItemsRoot = "https://items.morabaaapps.com/api/v1";
const OwnerTrackerRoot = "https://otracker.morabaaapps.com/api/v1";
const SalesReportRoot = "https://salereports.morabaaapps.com/api";
const RepresentativesRoot = "https://repsapi.morabaaapps.com/api/v1";
const RestaurantMenuRoot = "https://rest.morabaaapps.com/api/v1";
const TicketRoot = "https://tickets.morabaaapps.com/api/v1";
const NotificationsRoot = "https://notifications.morabaaapps.com/api/v1";
const OnlineDbBackupRoot = "https://onlinedbbackup.morabaaapps.com/api/v1";
const DevConsoleRoot = "https://devconsole.morabaaapps.com/api/v1";
const InvoicesRoot = "https://saturn.morabaa.cloud/api/v1";

const getAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: root,
        headers: {
            "Content-Type": "application/json",
            "App-Package": "com.morabaa.my",
        },
    });
    instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (err) => {
            // document.body.append(JsonParser(err, "fixed inset-0 z-50 h-screen overflow-y-auto"));
            console.log({ err });
            if (err.message === "Network Error") {
                Info({ title: "NoInternetConnection", type: "error", timeout: 10000 });
                throw err;
            }
            let response = err.response;
            if (response?.status !== 404) {
                if (err.config.url.includes("login")) {
                    Info({ title: "RongPasswordOrPhoneNumber", type: "error" });
                } else {
                    let detail = response?.data?.detail || response?.data || Language.AnErrorOccurred;
                    let errText = ErrorTitles[detail] || detail;
                    Info({ title: "Error", content: errText, type: "error" });
                }
            } else if (response.status === 401) {
                localStorage.removeItem("token");
            }
            throw err;
        }
    );
    return instance;
};

export const updateToken = (token) => {
    HubAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    ItemsAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    SalesReportAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    OwnerTrackerAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    RepresentativeAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    RestaurantMenuAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    TicketAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    NotificationsAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    OnlineDbBackupAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    InvoicesAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const HubAPI = getAxiosInstance(HubRoot);
export const ItemsAPI = getAxiosInstance(ItemsRoot);
export const SalesReportAPI = getAxiosInstance(SalesReportRoot);
export const OwnerTrackerAPI = getAxiosInstance(OwnerTrackerRoot);
export const RepresentativeAPI = getAxiosInstance(RepresentativesRoot);
export const RestaurantMenuAPI = getAxiosInstance(RestaurantMenuRoot);
export const TicketAPI = getAxiosInstance(TicketRoot);
export const NotificationsAPI = getAxiosInstance(NotificationsRoot);
export const OnlineDbBackupAPI = getAxiosInstance(OnlineDbBackupRoot);
export const DevConsoleAPI = getAxiosInstance(DevConsoleRoot);
export const InvoicesAPI = getAxiosInstance(InvoicesRoot);
