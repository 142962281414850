import React, { useState } from "react";
import { Checked } from "../../SVGs/Reps";

const CheckButton = ({ item, onClick }) => {
    const [checked, setChecked] = useState(item.checked);

    const onCheck = () => {
        item.checked = !item.checked;
        // if (item.checked) item.checked = item.checked === true ? 0 : true;
        // else item.checked = item.checked === undefined ? 1 : undefined;
        setChecked(!checked);
        onClick();
    };

    return (
        <div className={`container-${checked ? "checked" : "unchecked"} transition-1  cursor-pointer select-none`} onClick={onCheck}>
            <div className="m-2 rounded-2 flex flex-row search-bg shadow-1 transition-1">
                <p className="carbonic flex-grow font-bold p-4">{item.title}</p>
                <div className="icon-container overflow-hidden flex flex-row  items-center check-bg" style={{ width: 40, borderRadius: "12px 0 0 12px " }}>
                    <div className="tint-squer squer transition-1" />
                    {Checked}
                </div>
            </div>
        </div>
    );
};

export default CheckButton;
