import { GetFormatedDate } from "../Utils/DateFromater";
import Utils from "../Utils/Utils";

const HomeUtils = {
    onResult: (data, provider) => {
        let formated = [];
        data?.forEach((element, i) => {
            let haveCustomLabels = ["00000007", "00000008", "00000009"].indexOf(element.business.category.split("-")[0]) > -1 || i === 0;
            let business = element.business;
            let amounts = FormateAmounts(element);
            let date = GetFormatedDate(element);

            formated.push({ id: business.id, haveCustomLabels, date, businessTitle: business.title, category: business.category, ...amounts });
        });

        if (formated.length > 1) {
            setTimeout(() => {
                provider.setHeader(formated[0]);
            }, 100);
            return formated.slice(1);
        } else return formated;
    },
};

export default HomeUtils;

function FormateAmounts(element) {
    let amounts = {
        profits: [],
        sales: [],
        expenses: [],
        purchases: [],
    };
    Object.keys(amounts)?.forEach((newAmountKey) => {
        Object.keys(element[newAmountKey])?.forEach((amountKey) => {
            let amountObj = element[newAmountKey][amountKey];
            if (amountObj) amounts[newAmountKey].push(Utils.FormatWithCurrency(amountObj, amountKey));
        });
        if (amounts[newAmountKey].length === 0) amounts[newAmountKey] = [Utils.FormatWithCurrency(0, 0)];
    });
    return amounts;
}
