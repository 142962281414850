import React, { useState } from "react";
import Language from "../Utils/Languages";
import ProfileHolder from "./Components/ProfileHolder";
import ToggleButton from "./Components/ToggleButton";
import { Languages, Options, LanguagesTitles, ThemesTitles } from "../Settings";
import DialogCombo from "../ComponentsV2/DialogCombo";
import { useNavigate } from "react-router-dom";
import UpdateOwnerInfo from "./Components/UpdateOwnerInfo";
import { Edit } from "../SVGs/OtherSvgs";
import Scroller from "../ComponentsV2/Scroller";
import { HubAPI } from "../API/remoteAPI";
import ClearStorge from "../Utils/ClearStorge";
import Info from "../NodeBuilder/Info";
import Confirmer from "../Containers/Confirmer";

const SettingsView = () => {
    const owner = React.useMemo(() => JSON.parse(localStorage.getItem("owner")), []);

    const [dialogInfoHolder, setDialogInfoHolder] = useState();
    const [updateUserInfo, setUpdateUserInfo] = useState(false);

    const navigate = useNavigate();
    const options = {
        language: localStorage.getItem("language") || "ar",
        theme: localStorage.getItem("theme") || "dark",
        currency: localStorage.getItem("currency") || "1",
    };

    const handleShowOptions = (key, data, reload = false) => {
        setDialogInfoHolder({
            icon: 0,
            items: data,
            selected: Options[key],
            onClick: (item) => {
                if (options[key] !== item.value) {
                    Options[key] = item;
                    localStorage.setItem(key, item.value);
                    reload && window.location.reload(false);
                }
            },
        });
    };

    const deleteTheAccount = () => {
        HubAPI.delete("https://hubcore.morabaaapps.com/api/v1/owners/me")
            .then(() => {
                ClearStorge();
            })
            .catch((err) => {
                Info({ title: "Error", message: err?.message || Language.AnErrorOccurred, type: "error" });
            });
    };

    return (
        <>
            <Scroller className="small">
                <div className="m-auto">
                    <ProfileHolder owner={owner} />
                    <div className="small-card-grid p-4">
                        <ToggleButton
                            icon={0}
                            title={Language.TheLanguage}
                            value={LanguagesTitles[options.language]}
                            onClick={() => handleShowOptions("language", Languages, true)}
                        />
                        {/* <ToggleButton
                            icon={1}
                            title={Language.TheCurrency}
                            value={CurrencesTitles[options.currency]}
                            onClick={() => handleShowOptions("currency", Currences)}
                        /> */}
                        <ToggleButton
                            icon={options.theme === "light" ? 2 : 3}
                            title={Language.TheTheme}
                            value={ThemesTitles[options.theme]}
                            className="theme"
                            onClick={() => {
                                options.theme = options.theme === "light" ? "dark" : "light";
                                localStorage.setItem("theme", options.theme);
                                document.body.className = options.theme;
                            }}
                        />
                        <ToggleButton icon={4} value={"*******"} title={Language.ChangePassword} onClick={() => navigate("/password/change/")} />
                    </div>

                    {updateUserInfo && <UpdateOwnerInfo close={() => setUpdateUserInfo(false)} height={700} owner={owner} />}
                    {dialogInfoHolder && <DialogCombo dialogInfoHolder={dialogInfoHolder} close={() => setDialogInfoHolder(false)} />}
                    <div onClick={() => setUpdateUserInfo(owner)} className="absolute left-3 top-0 cursor-pointer font-bold z-20">
                        {Edit}
                    </div>
                </div>

                <button
                    onClick={() => {
                        Confirmer({
                            title: Language.AreYouSuer,
                            content: Language.DeleteTheAccountText,
                            confirm: deleteTheAccount,
                        });
                    }}
                    style={{ maxWidth: 150, margin: "auto" }}
                    className="m-2 py-3 px-6 shadow-1 text-center font-bold rounded-1 red-bg text-white fixed left-0 right-0 bottom-4">
                    {Language.DeleteTheAccount}
                </button>
            </Scroller>
        </>
    );
};

export default SettingsView;
