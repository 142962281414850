import React from "react";

const CustomerInfo = ({ customer }) => (
    <p className="font-bold text-center relative -top-3 px-2 carbonic-2">
        <span className="carbonic">{customer.name}</span>
        <br />
        <span>{customer.phone}</span>
        <br />
        <span>{customer.address}</span>
    </p>
);
export default CustomerInfo;
