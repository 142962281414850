import React from "react";
import CustomerCard from "./Components/CustomerCard";
import SimpaleBuilder from "../PageBuilder/SimpaleBuilder";
import Utils, { AccountsStatues } from "../Utils/Utils";
import { getCurrencesTitle } from "../Settings";

const CustomersView = () => {
    return (
        <SimpaleBuilder
            id="customers"
            apiId="sales"
            url={`v1/businesses/ofclient/${Utils.getStorage("owner").phone_number}`}
            itemBuilder={CustomerCard}
            onResult={(data) => {
                let arr = [];
                data.forEach((account) => {
                    let balances = account.balances?.filter((balance) => balance.amount !== 0) || [];
                    balances
                        ?.sort((a) => a.currencyId)
                        ?.forEach((_balance) => {
                            _balance.amount = Utils.Formate(Math.abs(_balance.amount));
                            _balance.state = AccountsStatues[_balance.state];
                            _balance.currency = getCurrencesTitle[_balance.currencyId];
                        });
                    account.balances = balances?.length > 0 ? balances : null;
                    arr.push(account);
                });
                return arr;
            }}
        />
    );
};
export default CustomersView;
