import { useParams } from "react-router";
import React, { useEffect } from "react";
import DateFormate from "../Components/DateFormate";
import { TheNote, TheOpration } from "../SVGs/AccountsSvgs";
import Language from "../Utils/Languages";
import { SvgBorderIcons, SvgBorderPath, SvgBorderWithPriceTrasnactions } from "../SVGs/BorderSvgs";
import ChangeTitle from "../Utils/ChangeTitle";
import SimpleBuilder from "../PageBuilder/SimpaleBuilder";
import Utils, { AccountsStatues, getBusinesses } from "../Utils/Utils";
import { GetFormatedDate } from "../Utils/DateFromater";
import { getApi } from "../scripts/Fetcher";
import { getCurrencesTitle } from "../Settings";

let account = { businessTitle: "", name: "" };

const TransStatusText = [
    ({ amount, currency }) => <></>,
    ({ amount, currency }) => (
        <>
            <SvgBorderPath children={SvgBorderIcons[2]} />
            <span className="carbonic-1 text-sm pt-2">{Language.Credit}</span>
            <span className="text-base orange-text">{amount}</span>
            <span className="carbonic-3 text-sm">{currency}</span>
        </>
    ),
    ({ amount, currency }) => (
        <>
            <SvgBorderPath children={SvgBorderIcons[1]} />
            <span className="carbonic-1 text-sm pt-2">{Language.Debt}</span>
            <span className="text-base green-text">{amount}</span>
            <span className="carbonic-3 text-sm">{currency}</span>
        </>
    ),
    ,
];

const TransactionsBalance = ({ amount, currency, state }) => {
    const Holder = TransStatusText[state];
    return (
        <div className="flex flex-col items-center">
            <Holder currency={currency} amount={amount} />
        </div>
    );
};

const TransactionsCard = ({ item }) => {
    return (
        <div className="fade-in-card col carbonic-card shadow-1 rounded-4 overflow-hidden" key={item.id}>
            <div className="inline-flex items-center p-4">
                {TheOpration}
                <p className="font-bold px-2">
                    <span className="carbonic-3">{Language.TheOperation}</span>
                    <span className="font-bold carbonic pr-2">{item.opType}</span>
                </p>
            </div>
            <div className="flex flex-row font-medium justify-around">
                <SvgBorderWithPriceTrasnactions currencyId={item.currencyId} icon="TheAmount" amount={item.amount} />

                <TransactionsBalance currency={item.currency} state={item.state} amount={item.balance} />
            </div>
            <div className="inline-flex items-center p-4">
                {TheNote}
                <p className="font-bold carbonic-3 pr-4 ">{item.description}&nbsp;</p>
            </div>
            <DateFormate date={item.date} />
        </div>
    );
};

const TransactionsView = () => {
    let params = useParams();

    const init = async () => {
        account = JSON.parse(sessionStorage.getItem(params.accountId));
        if (!account) {
            account = await getApi("sales").get(`v1/accounts/${params.accountId}`);
            account.businessTitle = (await getBusinesses()).find((b) => b.id === account.businessId).title;
            sessionStorage.setItem(params.accountId, JSON.stringify(account));
        }
        ChangeTitle(`${account.businessTitle} - ${account.name} `);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <SimpleBuilder
            baseURL="https://salereports.morabaaapps.com/api"
            id="accounts-transactions"
            url={`v1/transactions/${params.accountId}/${params.currencyId}`}
            apiId="sales"
            itemBuilder={TransactionsCard}
            limit={0}
            onResult={(_transactions, provider) => {
                _transactions.forEach((trans) => {
                    trans.date = GetFormatedDate(trans);
                    trans.balance = Utils.Formate(Math.abs(trans.balance));
                    trans.amount = Utils.Formate(Math.abs(trans.amount));
                    trans.state = AccountsStatues[trans.status];
                    trans.currency = getCurrencesTitle(trans.currencyId);
                });
            }}
        />
    );
};

export default React.memo(TransactionsView);
