export const BackUp = (
    <svg fill="#fff" className="ml-3" width="32px" height="32px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7" cy="7" r="1" />
        <circle cx="7" cy="15" r="1" />
        <circle cx="7" cy="23" r="1" />
        <path d="M12,26H4V20h8V18H4V12H22V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V26a2,2,0,0,0,2,2h8ZM4,4H20v6H4Z" transform="translate(0 0)" />
        <path d="M28,17v2.4131A6.996,6.996,0,1,0,22,30V28a5,5,0,1,1,4.5762-7H24v2h6V17Z" transform="translate(0 0)" />
        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" fill="none" width="32" height="32" />
    </svg>
);
export const Logout = (
    <svg height={25} fill="#e3353c" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 339.58">
        <path
            d="M330,382c1.72-7,2.36-14.55,5.36-21,9.4-20.09,25.81-30.38,48-30.45q83.75-.29,167.49,0c26.21.11,45.75,16,51.56,41.3a40,40,0,0,1,.53,13.81c-.8,6.2-6.73,10.69-13,11A14.32,14.32,0,0,1,576,386.58a28.5,28.5,0,0,1-.95-5.89c-.75-12.47-10.19-21.67-22.81-21.68q-85.24-.11-170.49,0A22.34,22.34,0,0,0,359,381.74q-.08,118.25,0,236.49A22.35,22.35,0,0,0,381.75,641c56.66.08,113.33-.1,170,.16,12,.05,23.38-10.44,23.42-22.33,0-8.23,4.32-13.35,11.27-15a13.92,13.92,0,0,1,15.29,6.69c3.16,5.67,1.93,11.74.74,17.54-4.9,23.72-25.41,40.2-47.1,41.15A17.57,17.57,0,0,0,552,670H382a32.26,32.26,0,0,0-3.85-.84c-20.22-2-34.76-12.3-43-30.71-2.82-6.32-3.51-13.6-5.17-20.45Z"
            transform="translate(-330 -330.42)"
        />
        <path
            d="M670,502c-2.67,3.91-4.83,8.33-8.11,11.66-20.45,20.77-41.16,41.29-61.75,61.93-4.28,4.3-9.37,5.79-15.07,4.18-5.37-1.52-8.71-5.43-9.86-10.91s.8-9.77,4.59-13.54c12-12,24-24.05,36.08-36a35.25,35.25,0,0,1,4.45-3.25l-.81-1.8H614q-95,0-189.92,0a42.3,42.3,0,0,1-7.48-.29c-6.68-1.21-11.46-7.64-11.13-14.58a13.93,13.93,0,0,1,12.89-13.5c2.16-.16,4.33-.1,6.49-.1h195.6c-1.83-2-2.92-3.32-4.11-4.51q-17.83-17.87-35.68-35.7c-7-7-7.57-15.51-1.55-21.63s14.82-5.64,21.69,1.21q31.86,31.75,63.54,63.69c2.46,2.49,3.79,6.1,5.64,9.19Z"
            transform="translate(-330 -330.42)"
        />
    </svg>
);
export const Select = (
    <svg height={16} className="select-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.55 377.7">
        <path
            d="M501,311c7,1.28,12.13,5.47,17,10.42q53.38,53.68,107,107.1c6.07,6.05,8.78,12.87,6.06,21.23a18.8,18.8,0,0,1-28.52,9.66,43.23,43.23,0,0,1-6-5.27q-46.15-46.08-92.23-92.23c-1.29-1.29-2.34-2.82-3.85-4.67-1.85,1.74-3.17,2.91-4.41,4.15q-47,47-94,94c-5.1,5.11-10.84,8.32-18.26,7.12a18.86,18.86,0,0,1-11.66-31c3.74-4.45,8.09-8.39,12.21-12.51,33.45-33.46,66.81-67,100.5-100.21,3.68-3.61,9.3-5.25,14-7.81Z"
            transform="translate(-367.68 -311)"
        />
        <path
            d="M499.36,643.31c9.21-9.26,17.17-17.31,25.18-25.32q37.1-37.14,74.23-74.21c11.36-11.3,29-7,32.8,7.89,2,7.65-.64,14-6.13,19.45Q603.65,592.8,582,614.59q-33,33-66.09,66.08c-10.72,10.7-21,10.71-31.68,0q-54.62-54.59-109.25-109.18c-6.05-6-8.86-12.85-6.19-21.21a18.78,18.78,0,0,1,28.43-9.93,41.48,41.48,0,0,1,6,5.28q46.32,46.27,92.58,92.62C497,639.55,497.89,641.24,499.36,643.31Z"
            transform="translate(-367.68 -311)"
        />
    </svg>
);

export const Edit = (
    <svg height={20} className="tint m-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.16 358.31">
        <path
            d="M531,382.35l86.68,86.71c-.82.87-2.11,2.29-3.46,3.64Q532.86,554,451.59,635.42a52,52,0,0,1-21.38,13.16q-44.08,14.28-88,29.1c-6.36,2.13-12.15,2.14-17.12-2.79s-4.89-10.76-2.77-17.11c10.07-30.09,19.74-60.32,30.12-90.3a45.55,45.55,0,0,1,10.24-16.75q82.59-83.24,165.7-166C529.31,383.82,530.31,383,531,382.35Z"
            transform="translate(-320.94 -320.73)"
        />
        <path
            d="M638.55,448.59c-29.22-29.25-57.79-57.85-86.6-86.67,0,.15-.12-.31,0-.48,10-9.74,19.32-20.35,30.21-29,18.94-15,46.42-15.4,67.55-2.6a61.67,61.67,0,0,1,11,96.33C653.31,433.6,646,441.07,638.55,448.59Z"
            transform="translate(-320.94 -320.73)"
        />
    </svg>
);

export const Phone = (
    <svg height={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.36 376.66">
        <path
            d="M368,400.12c2-12.26,3.08-26.33,6.75-39.69,5.55-20.2,19.08-33.89,39.16-40.63,6.45-2.16,12.91-4.28,19.42-6.25,18.52-5.61,37.46,1.84,46,19.31a412.67,412.67,0,0,1,19.14,45.48c5.75,16.61,1.79,32-10.73,44.55-9.28,9.31-18.91,18.28-28.6,27.16-5.21,4.77-7,10.26-5.52,17.06,7.78,37,26.26,67.61,54.41,92.55,4.68,4.16,9.93,4.88,15.78,2.89Q540,557,556.35,551.68c17.87-5.88,34.42-.54,45.18,14.81q11.9,17,23.3,34.26c11.78,17.94,9.55,40.07-5.4,55.46-5.1,5.25-10.17,10.55-15.52,15.54-16.89,15.77-36.46,20.51-58.51,13.4-27.29-8.81-49.4-25.63-69-46C447.48,609.14,426,574,407.29,537c-15.3-30.23-28-61.48-34-95C370.9,428.78,369.87,415.31,368,400.12Z"
            transform="translate(-368 -311.71)"
        />
    </svg>
);

export const Eye = (
    <svg className="label-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 302">
        <path
            d="M293,502v-4a32.45,32.45,0,0,0,1.95-3.3c15.54-34.18,36.61-64.43,64.33-89.87,29.59-27.16,63.78-45.39,103.62-52.22,9-1.54,18.06-2.42,27.1-3.61h20a45,45,0,0,0,4.84.84,195.66,195.66,0,0,1,93.84,30.43c38.63,24.53,67,58.36,88.45,98.33,3.43,6.39,6.59,12.93,9.87,19.4v4a11.77,11.77,0,0,0-1.37,1.91,277.21,277.21,0,0,1-51.07,77.54c-32.13,34.42-70.61,57.7-117.41,66-9.3,1.65-18.76,2.39-28.15,3.55H491c-1.79-.28-3.58-.7-5.39-.82a197.56,197.56,0,0,1-103.84-36.86c-33.92-24.09-59.4-55.47-78.9-91.93C299.45,515,296.28,508.47,293,502Zm206.7,92A94,94,0,1,0,406,499.69,94,94,0,0,0,499.7,594Z"
            transform="translate(-293 -349)"
        />
        <path
            d="M499.77,443.48c31.1-.24,56.69,25.18,56.8,56.42a56.7,56.7,0,0,1-56.1,56.64c-31.07.38-56.6-24.77-57-56.19C443.05,469.36,468.43,443.72,499.77,443.48Z"
            transform="translate(-293 -349)"
        />
    </svg>
);

export const MyMorabaaLogo = (
    <svg height={50} width={50} fill="#dd3643" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.1 417">
        <path
            d="M292,631V366a18.84,18.84,0,0,0,.91-2.75c5.43-32,23.27-54.24,53-66.85,5.75-2.44,12-3.63,18.08-5.4H636a22.32,22.32,0,0,0,2.79.94c40,7.65,69,41.87,69.17,82.66.26,66.31.09,132.63.06,198.95a77.2,77.2,0,0,1-8.77,36.56c-16.53,31.25-42.7,47.21-78,47.38-54.49.27-109,.07-163.47.07H451V708H375c-1.6-.61-3.17-1.63-4.81-1.77-34.28-2.94-63.21-25.51-73.86-58C294.49,642.63,293.42,636.75,292,631Zm111-20.44h7q104.22,0,208.45,0c25.82,0,42.57-16.73,42.58-42.51q.06-94,0-187.95c0-25.83-16.71-42.62-42.46-42.64q-118.47-.06-237,0c-25.82,0-42.62,16.79-42.64,42.48q0,43,0,86,0,76.23,0,152.46c0,17.7,9.67,32.38,25.3,38.36,12.53,4.8,25.55,2.24,38.7,2.82Z"
            transform="translate(-292 -291)"
        />
    </svg>
);

export const MorabaaLog = (
    <svg height={50} width={50} className="carbonic-tint" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 951.74 951.74">
        <g>
            <g>
                <path
                    d="M977.68,264.94V738.29a239,239,0,0,1-239.2,239.19H265.13A239,239,0,0,1,25.94,738.29V264.94A239,239,0,0,1,265.13,25.74H738.48a239,239,0,0,1,239.2,239.2Z"
                    transform="translate(-25.94 -25.74)"
                />
                <path fill="#dd3643" d="M864.55,770a109.51,109.51,0,0,1-216,0h65.18a46.5,46.5,0,0,0,85.62,0Z" transform="translate(-25.94 -25.74)" />
                <path
                    className="carbonic-tint-x"
                    d="M754.32,507A109.47,109.47,0,0,0,655.2,663H586.66V508h-63v244.2A47.61,47.61,0,0,1,476.1,799.8v63A110.67,110.67,0,0,0,586.65,752.62c0-.13,0-.25,0-.38V726H754.32a109.5,109.5,0,0,0,0-219Zm0,156a46.5,46.5,0,1,1,46.5-46.5A46.55,46.55,0,0,1,754.32,663Z"
                    transform="translate(-25.94 -25.74)"
                />
                <path
                    className="carbonic-tint-x"
                    d="M356.39,799.73v63H245.94v0A109.62,109.62,0,0,1,137.37,753.21h63a46.55,46.55,0,0,0,46.5,46.5v0Z"
                    transform="translate(-25.94 -25.74)"
                />
                <circle className="carbonic-tint-x" cx="418.62" cy="736.94" r="31.5" />
                <path
                    className="carbonic-tint-x"
                    d="M475,508.05V615.46A110.69,110.69,0,0,1,364.47,726H245.94v0A109.62,109.62,0,0,1,137.36,616.5h0a109.5,109.5,0,0,1,219,.26h-63a46.5,46.5,0,0,0-93,0h0A46.55,46.55,0,0,0,246.86,663v0H364.47A47.61,47.61,0,0,0,412,615.46V508.05Z"
                    transform="translate(-25.94 -25.74)"
                />
            </g>
        </g>
    </svg>
);

export const Note = (
    <svg className="tint-price-svg" height={36} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            d="M673.21,156A174.81,174.81,0,0,1,848,330.79V676.21A174.81,174.81,0,0,1,673.21,851H327.79A174.81,174.81,0,0,1,153,676.21V330.79A174.81,174.81,0,0,1,327.79,156H673.21m0-45H327.79C206.4,111,108,209.4,108,330.79V676.21C108,797.6,206.4,896,327.79,896H673.21C794.6,896,893,797.6,893,676.21V330.79C893,209.4,794.6,111,673.21,111Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M688.36,358.05V462.81a22.17,22.17,0,0,0-.73,3.36c-.9,14.81-7.26,27-17.61,37.39L503.31,670.31c-7,7-15.11,12.51-24.81,15.06-5.1,1.34-10.37,2-15.57,2.93h-1a11.6,11.6,0,0,0-2.39-.64c-16.16-.7-29-7.93-40.26-19.25-29.71-30-59.65-59.77-89.5-89.63-23.87-23.87-24.34-58.44-.57-82.35q83.36-83.79,167.08-167.21c8.68-8.69,18.91-14.52,31.08-16.65a45.89,45.89,0,0,1,7.93-.75c36.39,0,72.79-.4,109.17.26,20,.36,37.32,15.74,42,35.26C687.27,350.86,687.73,354.48,688.36,358.05Zm-94.8,19.84a27.94,27.94,0,1,0,28.08,27.55A28,28,0,0,0,593.56,377.89Z"
            transform="translate(-108 -111)"
        />
    </svg>
);
export const Amount = (
    <svg className="tint-price-svg" height={36} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            d="M673.21,156A174.81,174.81,0,0,1,848,330.79V676.21A174.81,174.81,0,0,1,673.21,851H327.79A174.81,174.81,0,0,1,153,676.21V330.79A174.81,174.81,0,0,1,327.79,156H673.21m0-45H327.79C206.4,111,108,209.4,108,330.79V676.21C108,797.6,206.4,896,327.79,896H673.21C794.6,896,893,797.6,893,676.21V330.79C893,209.4,794.6,111,673.21,111Z"
            transform="translate(-108 -111)"
        />
        <path
            d="M688.36,358.05V462.81a22.17,22.17,0,0,0-.73,3.36c-.9,14.81-7.26,27-17.61,37.39L503.31,670.31c-7,7-15.11,12.51-24.81,15.06-5.1,1.34-10.37,2-15.57,2.93h-1a11.6,11.6,0,0,0-2.39-.64c-16.16-.7-29-7.93-40.26-19.25-29.71-30-59.65-59.77-89.5-89.63-23.87-23.87-24.34-58.44-.57-82.35q83.36-83.79,167.08-167.21c8.68-8.69,18.91-14.52,31.08-16.65a45.89,45.89,0,0,1,7.93-.75c36.39,0,72.79-.4,109.17.26,20,.36,37.32,15.74,42,35.26C687.27,350.86,687.73,354.48,688.36,358.05Zm-94.8,19.84a27.94,27.94,0,1,0,28.08,27.55A28,28,0,0,0,593.56,377.89Z"
            transform="translate(-108 -111)"
        />
    </svg>
);

export const Fatle = (
    <svg height={38} fill="#fff" style={{ marginTop: 14 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.34 286.36">
        <path
            d="M499.94,356.82A143.18,143.18,0,1,1,356.83,500.18,143,143,0,0,1,499.94,356.82Zm0,21.25a121.93,121.93,0,1,0,122,122.08C622.05,432.82,567.28,378,499.91,378.07Z"
            transform="translate(-356.83 -356.82)"
        />
        <path
            d="M489.39,500q0-36.23,0-72.46c0-5.37.89-10.28,6.79-12.61a10.05,10.05,0,0,1,13.72,6.75,22.55,22.55,0,0,1,.67,5.43q0,72.95,0,145.9c0,5.24-1.12,9.9-6.78,12.1-5.9,2.3-12.17-.62-13.7-6.74a28.63,28.63,0,0,1-.69-6.92Q489.35,535.7,489.39,500Z"
            transform="translate(-356.83 -356.82)"
        />
    </svg>
);
export const Wornning = (
    <svg height={18} fill="#dd3643" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.34 286.36">
        <path
            d="M499.94,356.82A143.18,143.18,0,1,1,356.83,500.18,143,143,0,0,1,499.94,356.82Zm0,21.25a121.93,121.93,0,1,0,122,122.08C622.05,432.82,567.28,378,499.91,378.07Z"
            transform="translate(-356.83 -356.82)"
        />
        <path
            d="M489.39,500q0-36.23,0-72.46c0-5.37.89-10.28,6.79-12.61a10.05,10.05,0,0,1,13.72,6.75,22.55,22.55,0,0,1,.67,5.43q0,72.95,0,145.9c0,5.24-1.12,9.9-6.78,12.1-5.9,2.3-12.17-.62-13.7-6.74a28.63,28.63,0,0,1-.69-6.92Q489.35,535.7,489.39,500Z"
            transform="translate(-356.83 -356.82)"
        />
    </svg>
);
export const Price = (
    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="border"
            d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
        />
        <path d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z" />
        <path d="M482.33,198.57c16.71,0,33.41-.06,50.12,0,31.63.13,52.76,21.11,52.87,52.65.11,33.19,0,66.38,0,99.57,0,18.26-6.31,33.79-19.35,46.79Q480.39,483,395,568.51c-25.55,25.48-60.52,25.25-86.14-.33q-47-46.89-93.86-93.86c-24.17-24.2-25.07-59.41-1-83.67q86.73-87.58,174.23-174.4c12.12-12.1,27.09-17.55,44.06-17.66C448.92,198.49,465.63,198.57,482.33,198.57Zm5.82,68.71a29.31,29.31,0,0,0-29.27,28.5c-.23,15.6,12.87,29,28.52,29.27s28.9-12.73,29.24-28.54C517,281,503.79,267.42,488.15,267.28Z" />
    </svg>
);
export const Camera = (
    <svg className="svg-icon" style={{ height: 75, width: 75, margin: 25 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.73 358.29">
        <path
            d="M499.56,679q-62.51,0-125,0c-31.5-.06-56.14-20.53-62-51.49a58.67,58.67,0,0,1-.72-10.94q-.06-88.24,0-176.49c.05-31.37,20.33-56.28,51.15-61.47,10.72-1.81,22-1.63,32.89-.67,10.48.92,16.49-2.5,20.7-12,3.68-8.32,8.92-16,13.69-23.77,8.37-13.75,20.8-20.89,36.82-21.09q33-.4,66,0c16,.21,28.48,7.32,36.82,21.09q9.3,15.36,18.07,31.06c1.83,3.27,3.86,4.67,7.79,4.59,12.16-.23,24.35-.39,36.5.19,29.16,1.38,55.27,27.44,55.8,56.62.66,36,.26,72,.29,108,0,24.33-.54,48.68.14,73,.93,33.57-26.28,64-63.9,63.55C582.9,678.64,541.23,679,499.56,679Zm.27-28.23q62.76,0,125.53,0c21,0,34.75-13.93,34.76-34.8q0-88.76,0-177.52A32.2,32.2,0,0,0,628.3,406c-14.5-.23-29-.18-43.51-.05-7,.06-12-2.65-15.55-8.81-7.49-13.16-15.32-26.12-22.81-39.28-3.42-6-8.35-8.69-15.13-8.67q-31.26.09-62.52,0c-6.77,0-11.69,2.69-15.1,8.71C446.21,371,438.37,384,430.86,397.12c-3.51,6.15-8.56,8.85-15.57,8.78q-21-.22-42,0C353.77,406.12,340,420,340,439.46q0,88.76,0,177.52a34,34,0,0,0,.43,6.46c3.3,16.83,16.34,27.27,34.34,27.3Q437.31,650.84,499.83,650.76Z"
            transform="translate(-311.77 -320.81)"
        />
        <path
            d="M502.71,603.6c-45,2.41-84.74-34.33-87.45-80.78-2.66-45.58,34.06-86.36,79.9-88.75A85.2,85.2,0,0,1,584.87,515C587.4,561.53,550.77,601,502.71,603.6ZM443.7,519a56.36,56.36,0,1,0,56.45-56.63A56,56,0,0,0,443.7,519Z"
            transform="translate(-311.77 -320.81)"
        />
    </svg>
);

export const ModeSvgs = {
    "الوضع الليلي": (
        <svg className="mode-svg" height={30} width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 369.35 370">
            <path
                d="M438,315c12,5.43,14.36,12.35,8.43,24.34-40.9,82.73-5.92,181.11,78.35,218.13,44.8,19.67,89.88,18.33,134.27-2.49,3.15-1.48,6.31-3.26,9.65-3.91,5.46-1.06,10.2.95,13.61,5.48,3.55,4.71,3.78,9.88,1.36,15.08a190.59,190.59,0,0,1-26,41.28c-30.46,36.71-68.91,60.12-116,68.72-10.1,1.84-20.45,2.28-30.69,3.37h-3c-2.47-.26-4.93-.66-7.4-.75a183.12,183.12,0,0,1-67.7-15.37c-85-37.25-132-125.92-112.66-216.56C334,387.86,373.24,342.57,433,315Z"
                transform="translate(-315.91 -315)"
            />
        </svg>
    ),

    "الوضع النهاري": (
        <svg className="mode-svg" height={30} width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 378">
            <path
                d="M502,311c10.13,4.05,12.22,7.18,12.23,18.38,0,8.14.08,16.29,0,24.43-.13,8.71-6,14.72-14.2,14.71s-14.07-6-14.19-14.72c-.11-8.14,0-16.29,0-24.43,0-11.23,2.12-14.39,12.23-18.37Z"
                transform="translate(-311 -311)"
            />
            <path
                d="M311,498c4.23-10.28,7.15-12.23,18.35-12.24,8.14,0,16.29-.08,24.44,0,8.74.13,14.72,6,14.72,14.19s-6,14.07-14.7,14.21c-8.15.13-16.3,0-24.44,0-11.21,0-14.18-2-18.37-12.24Z"
                transform="translate(-311 -311)"
            />
            <path
                d="M689,502c-4.17,10.22-7.18,12.24-18.38,12.24-8.14,0-16.29.08-24.43,0-8.73-.14-14.72-6-14.73-14.21s6-14.06,14.73-14.19c8.15-.12,16.29-.05,24.44,0,11.22,0,14.21,2,18.37,12.24Z"
                transform="translate(-311 -311)"
            />
            <path
                d="M498,689c-10.13-4-12.23-7.12-12.23-18.35,0-8.14-.09-16.29,0-24.43.15-8.8,5.92-14.67,14.18-14.69s14,5.91,14.2,14.69c.13,8.14,0,16.28,0,24.43,0,11.22-2.11,14.37-12.23,18.35Z"
                transform="translate(-311 -311)"
            />
            <path d="M396.16,500a103.84,103.84,0,1,1,104,103.85A103.94,103.94,0,0,1,396.16,500Z" transform="translate(-311 -311)" />
            <path
                d="M411.28,395.49c-.51,6.74-2.67,11.16-7.73,13.84-5.22,2.75-10.7,2.82-15-1.05A325.94,325.94,0,0,1,365.24,385c-4.82-5.34-3.68-13.58,1.44-18.58,4.94-4.83,13-5.92,18.21-1.27,8.16,7.32,15.8,15.25,23.3,23.27C410.07,390.47,410.57,393.78,411.28,395.49Z"
                transform="translate(-311 -311)"
            />
            <path
                d="M637.81,378.45c-1,1.9-2.26,5.84-4.78,8.63-6.12,6.8-12.72,13.2-19.35,19.51-6.38,6.07-14.84,6-20.58.29s-5.77-14.2.31-20.56c6.56-6.86,13.29-13.58,20.2-20.09,4.53-4.27,10-5,15.76-2.46C634.74,366.2,637.26,370.66,637.81,378.45Z"
                transform="translate(-311 -311)"
            />
            <path
                d="M396.45,588.18c6.2.61,10.62,3.27,13.22,8.45s2.54,10.68-1.41,15c-7.34,7.93-14.94,15.66-23,22.89-5.29,4.76-13.71,3.75-18.63-1.2s-5.88-13.37-1.1-18.64c7.25-8,15-15.54,22.94-22.92C390.51,589.85,393.77,589.32,396.45,588.18Z"
                transform="translate(-311 -311)"
            />
            <path
                d="M638,622.89c-.64,5.9-3.07,10.28-8.08,12.86s-10.74,2.78-15-1.11c-8.12-7.38-15.94-15.14-23.3-23.26-4.67-5.16-3.67-13.21,1.14-18.21,5-5.17,13.16-6.39,18.55-1.59,8.18,7.3,15.81,15.24,23.3,23.26C636.46,616.83,636.9,620.17,638,622.89Z"
                transform="translate(-311 -311)"
            />
        </svg>
    ),
};

export const LabelIcons = {
    Name: (
        <svg className="label-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 440">
            <path
                transform="translate(-170 -170)"
                d="M221.82,472.7c.5-1.77,1-3.53,1.48-5.32,4.63-18.5,20.36-31.58,39.51-32.17,13.74-.42,27.5-.17,41.25-.18H516c26.09,0,40.4,11.35,46.54,36.79.08.33.44.59.66.88v27.13a47.73,47.73,0,0,0-1,4.83A85.07,85.07,0,0,1,542,551.05c-17.62,20.79-40.69,33.07-66.07,41.49-38,12.6-77,15.33-116.57,11-30.54-3.38-59.89-11.21-86.4-27.48-23.61-14.48-41.27-33.78-48.19-61.39-1.23-4.87-2-9.86-2.91-14.8Z"
            />
            <path transform="translate(-170 -170)" d="M392.48,179.56A106.46,106.46,0,1,1,286.1,285.71,106.1,106.1,0,0,1,392.48,179.56Z" />
        </svg>
    ),
    NameTitle: (
        <svg className="label-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <path
                d="M330,619V381c.28-1.44.65-2.87.84-4.32,2.73-20.55,13.78-34.84,32.87-42.58,5.42-2.2,11.51-2.78,17.29-4.1H619a28.33,28.33,0,0,0,3.35.75c23.39,2.42,41.34,18.51,46.18,41.49.61,2.89,1,5.84,1.47,8.76V619c-.27,1.28-.66,2.55-.8,3.84-2.56,23.23-19.09,41.33-41.93,45.81-2.74.54-5.51.91-8.27,1.35H381a29.84,29.84,0,0,0-3.34-.76c-20.16-2.3-34.65-12.67-42.79-31.15C332.26,632.16,331.57,625.39,330,619ZM500,518.93c-22.17,0-44.33-.07-66.5,0-10,0-17,6.18-18.08,15.76-1.25,11.57.67,22.73,9.12,31.08,6.53,6.45,13.94,12.63,22.06,16.74,23.52,11.87,48.82,13.54,74.56,10C537,590.3,552,585.39,565,575.83c12.33-9,20.17-20.53,19.79-36.61-.31-13.51-6.81-20.28-20.34-20.29Q532.24,518.89,500,518.93Zm-.64-28.48c25.32.52,47.07-20.19,47.6-45.32.56-26.71-19.79-48.05-46.33-48.58-25.31-.51-47.09,20.22-47.59,45.31C452.51,468.59,472.83,489.91,499.36,490.45Z"
                transform="translate(-330 -330)"
            />
        </svg>
    ),
    Address: (
        <svg className="label-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358 377.31">
            <path
                d="M321,607c5.68-23,22.93-35,42.66-44.31,8.84-4.17,18.23-7.18,27.46-10.46a5.41,5.41,0,0,1,4.73.89c6.49,6.9,12.71,14.05,19.54,21.7-4.17,1.17-7.75,2.15-11.32,3.18-13.79,4-27.28,8.7-39.43,16.52a56.85,56.85,0,0,0-9.72,7.75c-7.36,7.46-7.45,14.42.21,21.63A73.16,73.16,0,0,0,370,634.7c18.43,10.12,38.54,15.47,59.06,19.13a397.53,397.53,0,0,0,146-.8c19-3.65,37.57-8.89,54.69-18.21a75.1,75.1,0,0,0,13.51-9.31c9.72-8.51,9.7-16.73-.23-25.12-4.78-4-10.09-7.92-15.81-10.29-12.56-5.19-25.53-9.38-38.34-14-1.08-.38-2.2-.67-4.16-1.26,6.9-7.75,13.4-15.15,20.08-22.39.63-.69,2.5-.74,3.59-.42,17.93,5.41,35.16,12.34,50.09,24,10.27,8,17.38,18.17,20.48,31v12c-4.84,19.9-18.78,31.94-35.88,41-30.23,16.07-63.18,22.32-96.74,26a437.78,437.78,0,0,1-92.76,0c-28.06-2.93-55.71-8-81.86-19C349,657.47,327.89,645.63,321,619Z"
                transform="translate(-321 -311.17)"
            />
            <path
                d="M499.69,311.17c68.19.28,125.48,52.41,131.52,120.23,3.18,35.7-6.22,68-29.28,95.27-23.85,28.24-48.74,55.63-73.85,82.77-16.67,18-39.5,18-56.14,0-25.76-27.91-52-55.51-75.71-85.14-61.63-77.1-17-193.07,80.19-210.4C484.1,312.52,491.93,312.05,499.69,311.17Zm-.3,179.3c25.37.5,47.1-20.27,47.57-45.48.5-26.55-19.65-47.72-46.1-48.42-25.33-.68-47.28,20.07-47.82,45.2C452.46,468.56,472.81,489.94,499.39,490.47Z"
                transform="translate(-321 -311.17)"
            />
        </svg>
    ),
    PhoneNumber: (
        <svg className="label-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.36 376.66">
            <path
                d="M368,400.12c2-12.26,3.08-26.33,6.75-39.69,5.55-20.2,19.08-33.89,39.16-40.63,6.45-2.16,12.91-4.28,19.42-6.25,18.52-5.61,37.46,1.84,46,19.31a412.67,412.67,0,0,1,19.14,45.48c5.75,16.61,1.79,32-10.73,44.55-9.28,9.31-18.91,18.28-28.6,27.16-5.21,4.77-7,10.26-5.52,17.06,7.78,37,26.26,67.61,54.41,92.55,4.68,4.16,9.93,4.88,15.78,2.89Q540,557,556.35,551.68c17.87-5.88,34.42-.54,45.18,14.81q11.9,17,23.3,34.26c11.78,17.94,9.55,40.07-5.4,55.46-5.1,5.25-10.17,10.55-15.52,15.54-16.89,15.77-36.46,20.51-58.51,13.4-27.29-8.81-49.4-25.63-69-46C447.48,609.14,426,574,407.29,537c-15.3-30.23-28-61.48-34-95C370.9,428.78,369.87,415.31,368,400.12Z"
                transform="translate(-368 -311.71)"
            />
        </svg>
    ),
};
