import React from "react";
import Language from "../Utils/Languages";
import { LabelIcons } from "../SVGs/OtherSvgs";

const TextInput = ({ value, setValue, placeholder = "Name", required, type = "text", autoFocus, defaultValue }) => (
    <div className="text-input flex flex-row m-2">
        <input
            type={type}
            name={placeholder}
            defaultValue={defaultValue}
            value={value}
            required={required}
            autoFocus={autoFocus}
            placeholder={Language[placeholder]}
            onChange={({ target }) => setValue(target.value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632))}
            className="font-bold flex-grow focus:outline-none carbonic"
        />
        {LabelIcons[placeholder]}
    </div>
);

export default TextInput;
// <TextInput value={firstName} setValue={setFirstName}  placeholder="Name" />
