import React from "react";
import ListLabel from "./ListLabel";
import DialogConatiner from "./DialogConatiner";

const DialogCombo = ({ close, dialogInfoHolder }) => {
    return (
        <DialogConatiner close={close}>
            {dialogInfoHolder.items.map((item) => (
                <ListLabel
                    key={item.value}
                    title={item.title}
                    onClick={() => {
                        dialogInfoHolder.onClick(item);
                        close();
                    }}
                />
            ))}
        </DialogConatiner>
    );
};

export default DialogCombo;
// {dialogInfoHolder && <DialogCombo dialogInfoHolder={dialogInfoHolder} close={() => setDialogInfoHolder(false)} />}
