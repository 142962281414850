import React from "react";

const BackButton = ({ display = "none" }) => (
    <svg
        id="back-button"
        onClick={() => window.history.back()}
        className="absolute mr-2 z-30 cursor-pointer back-button"
        style={{ display, top: 6 }}
        height={26}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 377.39 377.39">
        <g>
            <circle cx="188.69" cy="188.69" r="188.69" />
        </g>
        <g id="Layer_2" data-name="Layer 2">
            <rect fill="#fff" x="500.48" y="399.43" width="27.48" height="133.96" rx="13.74" transform="translate(-490.8 189.38) rotate(-45)" />
            <rect fill="#fff" x="500.48" y="474.89" width="27.48" height="133.96" rx="13.74" transform="translate(222.16 -515.74) rotate(45)" />
        </g>
    </svg>
);

export default BackButton;
