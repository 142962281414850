import React from "react";
import PageBuilder from "../PageBuilder/PageBuilder";
import ItemsCheckButton from "./Components/ItemsCheckButton";
import RestaurantItemsHeader from "./RestaurantItemsHeader";
import RestaurantUtils from "./RestaurantUtils";

const RestaurantItemsView = () => {
    return (
        <PageBuilder
            id="restaurant"
            apiId="items"
            url="v1/items/ofowner"
            itemBuilder={ItemsCheckButton}
            headerBuilder={RestaurantItemsHeader}
            queryParams={RestaurantUtils.queryParams}
            onResult={RestaurantUtils.onResult}
            queryChanged={RestaurantUtils.queryChanged}
            //formBuilderClass="form-container"
            gridClass="small-card-grid"
            onItemClicked={RestaurantUtils.toggleSaveButton}
        />
    );
};

export default RestaurantItemsView;
