import React from "react";

const AudioPlayer = ({ url }) => {
    return (
        <audio preload="metadata" controls="controls">
            <source src={`https://tickets.morabaaapps.com${url}`} type="audio/mpeg" />
        </audio>
    );
};

export default AudioPlayer;
