import React from "react";
import Button from "../../ComponentsV2/Button";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";
import SvgBorderWithPrice from "../../SVGs/BorderSvgs";
import Language from "../../Utils/Languages";
import CustomerInfo from "./CustomerInfo";
import InvoicesItems from "./InvoicesItems";
import InvoiceTop from "./InvoiceTop";
import BillNumberLabel from "./NumberLabel";
import SperaterThenEdge from "./SperaterThenEdge";
import { CurrencesTitles } from "../../Settings";

const InvoiceCard = ({ invoice, close, postHistoy, isNew }) => {
    return (
        <>
            <div>
                <div className="carbonic-card overflow-hidden relative" style={{ borderTopRightRadius: 25, borderTopLeftRadius: 25 }}>
                    <InvoiceTop close={close} />
                    <div className="invoice-top-blur absolute top-10 w-full h-full" />
                    {invoice.customer && <CustomerInfo customer={invoice.customer} />}
                </div>
                <SperaterThenEdge />
                <div style={{ borderBottomRightRadius: 25, borderBottomLeftRadius: 25 }} className=" overflow-hidden">
                    <div className="flex flex-row justify-around carbonic-card pb-2">
                        <BillNumberLabel number={invoice.number} />
                        <p style={{ width: 3, height: 15, backgroundColor: "#f48114", borderRadius: 25, marginTop: 8 }} />
                        <SvgBorderWithPrice
                            amount={invoice.total}
                            currency={CurrencesTitles[invoice.currencyId]}
                            icon="InvoiceTotalAmount"
                            amountClass="orange-text"
                        />
                    </div>
                    <div className="carbonic-card col items-center">
                        <div className="row px-4 pb-1.5 pt-2.5 invoice-bg-date-2 font-bold carbonic-3 rounded-full">
                            <p className="pl-5">{invoice.date.short}</p>
                            <p className="pr-5">{invoice.date.long}</p>
                        </div>
                    </div>
                    <SperaterThenEdge />
                    <InvoicesItems items={invoice.items} />
                </div>
            </div>
            {isNew && (
                <div className="sticky flex flex-col left-0 right-0" style={{ bottom: -60 }}>
                    <Button onClick={postHistoy} title={Language.Save} color="redText" Extra="mx-auto px-10 " />
                </div>
            )}
        </>
    );
};

export default InvoiceCard;
