import React from "react";
import { Link } from "react-router-dom";
import { SvgBorderLabel } from "../../SVGs/BorderSvgs";
import { BusinessIcon, TransStatus } from "../../SVGs/AccountsSvgs";

const TransactionsLink = ({ accountId, onClick, balance }) => {
    const StateHolder = TransStatus[balance.state];
    return (
        <Link className="flex-1 items-center col py-1" onClick={() => onClick(accountId)} to={{ pathname: `/transactions/${accountId}/${balance.currencyId}` }}>
            <StateHolder amount={balance.amount} currency={balance.currency} />
        </Link>
    );
};

const CustomerCard = ({ item }) => {
    const account = !!item?.balances ? item.balances[0]?.account || {} : {};
    const NavigateToDetials = (accountId) => {
        sessionStorage.setItem(accountId, JSON.stringify({ businessTitle: item.businessTitle, name: account.name }));
    };
    return item ? (
        <div className="rounded-5 flex flex-col carbonic-card shadow-1 overflow-hidden">
            <div className="flex flex-row py-2">
                <div className="flex flex-col" style={{ width: "40%" }}>
                    {BusinessIcon}
                    <p className="font-bold px-3 carbonic text-center">
                        <span> {account.name} </span>
                    </p>
                </div>
                <div className="justify-around flex flex-col">
                    <SvgBorderLabel icon="Company" title={item.businessTitle} />
                    <SvgBorderLabel icon="Phone" title={account.phoneNumber} />
                    <SvgBorderLabel icon="Address" title={account.address} />
                </div>
            </div>

            <div className="row items-end border-top pt-3 flex-grow carbonic-card-1">
                {item.balances?.map((balance, index) => (
                    <TransactionsLink key={index} onClick={NavigateToDetials} accountId={balance.accountGuid} balance={balance} />
                ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default CustomerCard;
