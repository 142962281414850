import React from "react";

const DateFormate = ({ date, children }) => (
    <div className="font-bold row mt-auto carbonic-1 carbonic-card-1 justify-between items-center border-t py-2 px-3">
        {children}
        <p>{date.short}</p>
        <p>{date.long}</p>
    </div>
);

export default DateFormate;
