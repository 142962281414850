import { main } from "..";
import Info from "../NodeBuilder/Info";
import { ErrorTitles } from "../Utils/Languages";

const Apis = {
    hub: "https://hubcore.morabaaapps.com/api",
    items: "https://items.morabaaapps.com/api",
    ownerTracker: "https://otracker.morabaaapps.com/api",
    sales: "https://salereports.morabaaapps.com/api",
    reps: "https://repsapi.morabaaapps.com/api",
    restaurant: "https://rest.morabaaapps.com/api",
    tickets: "https://tickets.morabaaapps.com/api/v1",
    notifications: "https://notifications.morabaaapps.com/api",
    onlineDbBackup: "https://onlinedbbackup.morabaaapps.com/api",
    devConsole: "https://devconsole.morabaaapps.com/api",
    invoices: "https://saturn.morabaa.cloud/api",
};

export const getApi = (id) => {
    if (typeof Apis[id] === "string") {
        // console.log("First Time Create Api: ", id);
        Apis[id] = new Fetcher({
            baseURL: Apis[id],
            headers: {
                "App-Package": "com.morabaa.my",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        });
    }
    return Apis[id];
};
const onError = (err) => {
    console.log({ err });
    if (err.message === "Network Error") {
        Info({ title: "NoInternetConnection", type: "error", timeout: 10000 });
        return err;
    }
    let response = err.response;
    if (response?.status !== 404) {
        if (err?.config?.url?.includes("login")) {
            Info({ title: "RongPasswordOrPhoneNumber", type: "error" });
        } else {
            let details = response?.data?.details || ErrorTitles.Error;
            let errText = ErrorTitles[details] || details;
            Info({ title: "Error", content: errText, type: "error" });
        }
    } else if (response.status === 401 || response.status === 403) {
        localStorage.removeItem("token");
        main();
    }
    return err;
};

export default class Fetcher {
    constructor({ baseURL, headers, onResponse }) {
        const create = async (method) => {
            this[method] = (url, body) => {
                try {
                    const abortId = `${method}-${url.split("?")[0]}`;
                    if (this[abortId]) {
                        console.warn("A B O R T E D \nhttps: " + baseURL.slice(6) + url.split("?")[0] + "\n?" + url.split("?")[1]);
                        this[abortId].abort();
                    }
                    this[abortId] = new AbortController();
                    let _url = !url || url.startsWith("/") ? `${baseURL}${url}` : `${baseURL}/${url}`;
                    let props = { "Content-Type": "application/json", signal: this[abortId].signal, method, headers }; // mode: "no-cors",
                    if (body) props.body = JSON.stringify(body);
                    return new Promise(async (resolve, reject) => {
                        try {
                            const res = await fetch(_url, props);
                            this[abortId] = null;
                            if (res.ok && res.status !== 204) {
                                let jsonRes = null;
                                try {
                                    jsonRes = await res?.json();
                                } catch (err) {}
                                onResponse && onResponse(jsonRes);
                                resolve(jsonRes);
                            } else {
                                let { bodyUsed, redirected, status, statusText, type } = res;
                                reject({
                                    bodyUsed,
                                    ...props,
                                    redirected,
                                    status,
                                    statusText,
                                    type,
                                    url: _url,
                                    statusMessage: Fetcher.StatusCodeByMessage[status] || "Unknown Error",
                                });
                            }
                        } catch (err) {
                            if (err.name === "AbortError") return;
                            onError(err);
                            throw err;
                        }
                    });
                } catch (err) {
                    if (err.name === "AbortError") return;
                    onError(err);
                    throw err;
                }
            };
        };
        const createApiMethod = (method) => {
            this[method] = (url, body) => {
                console.warn("First Time Create Method: ", method, url);
                create(method);
                setTimeout(() => {
                    this[method](url, body);
                }, 100);
            };
        };

        create("get");
        create("post");
        create("delete");

        return this;
    }

    static StatusCodeByMessage = {
        0: "There Is No Response From Server Body Is Empty Connection May Be Very Slow",

        100: " Continue ",
        101: " Switching protocols ",
        102: " Processing ",
        103: " Early Hints ",

        //2xx Succesful
        200: " OK ",
        201: " Created ",
        202: " Accepted ",
        203: " Non-Authoritative Information ",
        204: " No Content ",
        205: " Reset Content ",
        206: " Partial Content ",
        207: " Multi-Status ",
        208: " Already Reported ",
        226: " IM Used ",

        //3xx Redirection
        300: " Multiple Choices ",
        301: " Moved Permanently ",
        302: " Found (Previously 'Moved Temporarily') ",
        303: " See Other ",
        304: " Not Modified ",
        305: " Use Proxy ",
        306: " Switch Proxy ",
        307: " Temporary Redirect ",
        308: " Permanent Redirect ",

        //4xx Client Error
        400: " Bad Request ",
        401: " Unauthorized ",
        402: " Payment Required ",
        403: " Forbidden ",
        404: " Not Found ",
        405: " Method Not Allowed ",
        406: " Not Acceptable ",
        407: " Proxy Authentication Required ",
        408: " Request Timeout ",
        409: " Conflict ",
        410: " Gone ",
        411: " Length Required ",
        412: " Precondition Failed ",
        413: " Payload Too Large ",
        414: " URI Too Long ",
        415: " Unsupported Media Type ",
        416: " Range Not Satisfiable ",
        417: " Expectation Failed ",
        418: " I'm a Teapot ",
        421: " Misdirected Request ",
        422: " Unprocessable Entity ",
        423: " Locked ",
        424: " Failed Dependency ",
        425: " Too Early ",
        426: " Upgrade Required ",
        428: " Precondition Required ",
        429: " Too Many Requests ",
        431: " Request Header Fields Too Large ",
        451: " Unavailable For Legal Reasons ",

        //5xx Server Error
        500: " Internal Server Error ",
        501: " Not Implemented ",
        502: " Bad Gateway ",
        503: " Service Unavailable ",
        504: " Gateway Timeout ",
        505: " HTTP Version Not Supported ",
        506: " Variant Also Negotiates ",
        507: " Insufficient Storage ",
        508: " Loop Detected ",
        510: " Not Extended ",
        511: " Network Authentication Required ",
    };
}
