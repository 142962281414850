import reportWebVitals from "../reportWebVitals";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import InstallPWA from "../Utils/InstallPWA";

const WebInit = () => {
    InstallPWA();
    serviceWorkerRegistration.register();
    reportWebVitals();
};
export default WebInit;
