import { HubAPI } from "./remoteAPI";

const BusinessAPI = {
    getBusinesses: () => HubAPI.get("/businesses/"),
    getBusiness: (businessId) => HubAPI.get(`/businesses/${businessId}`),
    removeOwnership: (businessId, password) => HubAPI.post(`/businesses/${businessId}/removeownership`, { password }),
    getOwnerByPhone: (phoneNumber) => HubAPI.get(`/owners/byphone/${phoneNumber}`),
    addOwner: (businessId, ownerId) => HubAPI.post(`/businesses/${businessId}/addowner/`, { id: ownerId }),
    updateTitle: (businessId, newTitle) => HubAPI.patch(`/businesses/${businessId}/`, { title: newTitle }),
    isSubedTo: (businessId, appPackage) => HubAPI.get(`/businesses/${businessId}/issubedto/${appPackage}`),
    getBusinessesCompo: async (setBusiness, setSelectedBusiness) => {
        let { data } = await HubAPI.get("/businesses/");
        let arr = data.map((element) => {
            return { title: element.business.title, value: element.business.id };
        });
        setSelectedBusiness && setSelectedBusiness(arr[0]);
        setBusiness(arr);
        return arr;
    },
};

export default BusinessAPI;
