import React, { useState } from "react";
import { Checked } from "../../SVGs/Reps";

const AccountCheckButton = ({ item, onClick }) => {
    const [checked, setChecked] = useState(item.checked);

    const onCheck = () => {
        if (item.checked) item.checked = item.checked === 1 ? false : undefined;
        else item.checked = item.checked === undefined ? true : 1;
        setChecked(!checked);
        onClick();
    };

    return (
        <div className={`container-${checked ? "checked" : "unchecked"} transition-1 cursor-pointer select-none`} onClick={onCheck}>
            <div className="m-2 rounded-2 flex flex-row account-check-bg transition-1 shadow-1 ">
                <p className="flex-grow font-bold p-4">
                    <span className="carbonic">{item.name}</span>
                    <br />
                    <span className="carbonic-2">{item.address}</span>
                    <br />
                    <span className="carbonic-2">{item.phoneNumber}</span>
                </p>
                <div
                    className="icon-container overflow-hidden flex flex-row  items-center check-bg account"
                    style={{ width: 40, borderRadius: "12px 0 0 12px " }}>
                    <div className="tint-squer squer transition-1" />
                    {Checked}
                </div>
            </div>
        </div>
    );
};

export default AccountCheckButton;
