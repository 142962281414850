import React from "react";

const Rotater = () => {
    return (
        <div style={{ zIndex: 0, marginBottom: "var(--safe-area-bottom)" }} className="absolute right-0 left-0 bottom-4 flex justify-center transition-2">
            <div id="rotater" aria-selected={"active"} className="home-tab transition-2" />
        </div>
    );
};

export default Rotater;
