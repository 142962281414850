import React from "react";
import { InvoicesApi } from "../API/Invoices";
import InvoiceCard from "./Components/InvoiceCard";

const InvoiceView = ({ invoice, close, isNew }) => {
    const postHistoy = ({ currentTarget }) => {
        InvoicesApi.postHistory(invoice.id).then(({ data }) => {
            currentTarget?.remove();
        });
    };

    return <InvoiceCard close={close} invoice={invoice} postHistoy={postHistoy} isNew={isNew} />;
};

export default InvoiceView;
