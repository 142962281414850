import Language from "../../Utils/Languages";
import ThisReps from "../Components/ThisReps";
import Button from "../../ComponentsV2/Button";
import React, { useEffect, useState } from "react";
import IconCard from "../../ComponentsV2/IconCard";
import Scroller from "../../ComponentsV2/Scroller";
import CheckButton from "../Components/CheckButton";
import Representatives from "../../API/Representative";
import SearchForma from "../../Components/SearchForma";
import ListButton from "../../ComponentsV2/ListButton";
import DialogCombo from "../../ComponentsV2/DialogCombo";
import AccountCheckButton from "../Components/AccountCheckButton";
import EmptyList from "../../ComponentsV2/EmptyList";
import Info from "../../NodeBuilder/Info";
import Confirmer from "../../Containers/Confirmer";

let canLoadMore = true;
let canLoadMoreStores = true;
const RepresentativesView = () => {
    const [stores, setStores] = useState([]);
    const [toSave, setToSave] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedReps, setselectedReps] = useState(false);
    const [representatives, setRepresentatives] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState(false);
    const [selectAllAccounts, setSelectAllAccounts] = useState(true);
    const [dialogInfoHolder, setDialogInfoHolder] = useState(false);

    const onComplated = {
        Done: () => {
            Info({ title: "Saved", type: "success" });
        },
        Error: () => {
            Info({ title: "Error", type: "error" });
        },
        // Loading: (loading) => setIsLoading(loading),
        businessId: selectedBusiness.value,
        representativeId: selectedReps.value,
        searchQuery,
    };

    const getBusinesses = () => {
        Representatives.getBusinessesSubed().then((businessesResponse) => {
            if (businessesResponse.data.length > 0) {
                let arr = businessesResponse.data.map((element) => {
                    return { value: element.id, title: element.title };
                });
                setBusinesses(arr);
                setSelectedBusiness(arr[0]);
            }
        });
    };

    const getBusinessReps = () => {
        Representatives.getRepresentatives(onComplated).then(({ data }) => {
            let businessReps = data.map((element) => {
                return {
                    title: `${element.firstName} ${element.lastName}${element.isActive ? "" : `(${Language.InActive})`}`,
                    value: element.id,
                    ...element,
                };
            });
            if (businessReps.length > 0) setselectedReps(businessReps[0]);
            setRepresentatives(businessReps);
        });
    };

    useEffect(() => {
        selectedBusiness && getBusinessReps();
    }, [selectedBusiness]);

    useEffect(() => {
        if (!selectedReps) return;
        search("");
    }, [selectedReps]);

    const getAccounts = async (clear = false, term = searchQuery) => {
        let newAccounts = await Representatives.getAccounts(onComplated, clear, accounts, setAccounts, term);
        canLoadMore = newAccounts.length >= 25;
        setSelectAllAccounts(newAccounts.findIndex((o) => !o.checked) === -1);
        const newStores = await Representatives.getStores(onComplated, setStores, stores, true);
        canLoadMoreStores = newStores.length >= 50;
    };

    const search = (term) => {
        canLoadMore = true;
        setAccounts([]);
        getAccounts(true, term);
    };

    const saveSelection = async () => {
        if (toSave.stores) Representatives.setStores(onComplated, stores);
        if (toSave.accounts) Representatives.setAccounts(onComplated, accounts);
        setToSave(false);
    };

    const selectAll = () => accounts.forEach((account) => (account.checked = true));

    useEffect(() => {
        getBusinesses();
    }, []);

    const changeCombo = (selected) => {
        let _dialogInfoHolder =
            selected === "reps"
                ? {
                      items: representatives,
                      onClick: (item) => {
                          setSearchQuery("");
                          setselectedReps(item);
                      },
                  }
                : { items: businesses, onClick: (item) => setSelectedBusiness(item) };
        setDialogInfoHolder(_dialogInfoHolder);
    };

    // const setAllByQuery = () => {
    //     setDialogQuestion({
    //         text: Language.GivePermission,
    //         codeToRun: () => {
    //             setIsLoading(true);
    //             Representatives.setAccountsByQuery(onComplated);
    //         },
    //     });
    // };
    const setAllByQuery = () => {
        Confirmer({
            title: Language.GivePermissionToAll,
            content: Language.GivePermission,
            confirm: () => {
                Representatives.setAccountsByQuery(onComplated, selectAll);
                getAccounts(true);
            },
        });
    };

    // const toggleSelectAll = () => {
    //     let accountToToggle = accounts;
    //     setAccounts([]);
    //     if (selectAllAccounts)
    //         accountToToggle.forEach((account) => {
    //             if (!account.checked) account.checked = account.checked === false ? 1 : true;
    //         });
    //     else
    //         accountToToggle.forEach((account) => {
    //             if (account.checked) account.checked = account.checked === true ? undefined : false;
    //         });
    //     setToSave({ accounts: true, stores: toSave.stores });
    //     setSelectAllAccounts(!selectAllAccounts);
    //     setTimeout(() => {
    //         setAccounts(accountToToggle);
    //     }, 1);
    // };

    function unselectAllAccounts() {
        Confirmer({ title: Language.AreYouSuer, confirm: () => Representatives.unselectAllAccounts(selectedReps.id).then(() => window.location.reload()) });
    }
    const loadMoreStores = async () => {
        const data = await Representatives.getStores(onComplated, setStores, stores, false);
        canLoadMoreStores = data.length >= 50;
    };

    return (
        <>
            <Scroller canLoadMore={canLoadMore} fetchData={getAccounts}>
                <IconCard icon="Company">
                    <ListButton canSelect={businesses?.length > 1} onClick={() => changeCombo("business")} title={selectedBusiness.title} />
                </IconCard>
                <ThisReps onListClick={() => changeCombo("reps")} reps={selectedReps} business={selectedBusiness} representatives={representatives} />
                <IconCard icon="HavePermisionForStor">
                    <div
                        className="scroller"
                        style={{ maxHeight: 500, overflow: "auto", paddingBottom: 10 }}
                        onScroll={({ target }) => {
                            if (canLoadMoreStores && target.scrollHeight - target.scrollTop < target.clientHeight + 250) {
                                canLoadMoreStores = false;
                                loadMoreStores();
                            }
                        }}>
                        {stores &&
                            stores.map((store) => (
                                <CheckButton
                                    key={store.id}
                                    item={store}
                                    onClick={() => {
                                        let { accounts, stores } = toSave;
                                        if (!stores) {
                                            setToSave({ accounts, stores: true });
                                        }
                                    }}
                                />
                            ))}
                    </div>
                </IconCard>

                <IconCard icon="WorkWith">
                    <SearchForma value={searchQuery} setValue={setSearchQuery} onSubmit={search} placeholder="SerchByPhoneNameOrAddres" className="m-2" />
                    <div className="flex flex-wrap gap-3 p-3">
                        {/* <Button title={`${selectAllAccounts ? "" : Language.Cancel} ${Language.ChoseAll}`} onClick={toggleSelectAll} /> */}
                        {accounts.length > 0 && (
                            <Button title={searchQuery.length > 0 ? Language.ChoseAsSearchResult : Language.ChoseAll} onClick={setAllByQuery} />
                        )}
                        <Button title={`${Language.Cancel} ${Language.ChoseAll}`} onClick={unselectAllAccounts} color="red" />
                    </div>

                    {canLoadMore || accounts.length > 0 ? (
                        accounts.map((account) => (
                            <AccountCheckButton
                                key={account.id}
                                item={account}
                                onClick={() => {
                                    if (!toSave.accounts) {
                                        setToSave({ accounts: true, stores: toSave.stores });
                                    }
                                }}
                            />
                        ))
                    ) : (
                        <EmptyList title={Language.ThereAreAccounts} />
                    )}
                </IconCard>
            </Scroller>

            {dialogInfoHolder && <DialogCombo dialogInfoHolder={dialogInfoHolder} close={() => setDialogInfoHolder(false)} />}
            {toSave && (
                <div style={{ pointerEvents: "none" }} className="fixed left-0 right-0 m-auto bottom-5 flex flex-row justify-center  z-50">
                    <Button onClick={saveSelection} title={Language.Save} Extra="mx-auto px-20" />
                </div>
            )}
        </>
    );
};

export default RepresentativesView;
