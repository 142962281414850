import React, { useState } from "react";
import Language from "../../Utils/Languages";
import BusinessAPI from "../../API/Business";
import TextInput from "../../Components/TextInput";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";
import ChangeTitle from "../../Utils/ChangeTitle";
import Info from "../../NodeBuilder/Info";

const ChangeTitlePopup = ({ close, business, setBusiness }) => {
    const [buinessTitle, setbuinessTitle] = useState(business.title);
    const _onSubmit = (e) => {
        e.preventDefault();
        updateTitle();
        close();
        return false;
    };
    const updateTitle = () => {
        if (!buinessTitle || buinessTitle === business.title) return;
        BusinessAPI.updateTitle(business.id, buinessTitle).then(() => {
            setBusiness({ ...business, title: buinessTitle });
            ChangeTitle(buinessTitle);
            Info({ title: "Saved", type: "success" });
        });
    };
    return (
        <DialogConatiner close={close}>
            <form onSubmit={_onSubmit} className="col-center rounded-2 carbonic-card">
                <p className="font-bold carbonic my-5">{Language.AddNewCompanyName}</p>
                <TextInput value={buinessTitle} setValue={setbuinessTitle} placeholder="Name" />
                <input
                    type="submit"
                    value={Language.Confirm}
                    className="cursor-pointer shadow-1 red-bg text-white rounded-1 py-2 my-2 w-32 font-bold mx-auto"
                />
            </form>
        </DialogConatiner>
    );
};

export default ChangeTitlePopup;
