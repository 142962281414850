import React, { memo } from "react";
import { BusinessIcon } from "../../SVGs/ItemsSvg";
import Language from "../../Utils/Languages";
import DefaultImage from "../../ComponentsV2/DefaultImage";
import SvgBorderWithPrice, { SvgBorderIcons, SvgBorderPath } from "../../SVGs/BorderSvgs";

const ItemCard = ({ item, provider }) => {
    return (
        <div className="fade-in-card carbonic-card shadow-1 flex flex-col py-3 px-1 rounded-2xl overflow-hidden">
            <div className="flex flex-row items-center">
                {BusinessIcon}
                <p className="font-bold carbonic-3 mr-2">{item.businessTitle}</p>
            </div>
            <DefaultImage
                onClick={() =>
                    provider.setHeader({
                        ...provider.header,
                        popupImage: item.mainImage,
                    })
                }
                uri={item.smallImage}
                quantity={item.quantityWithFill}
            />
            <p className="font-bold text-center carbonic py-2 mb-auto">{item.name}</p>
            <div className="py-1 flex flex-row justify-around">
                {item.packages ? (
                    hoverdPackage(item)
                ) : (
                    <SvgBorderWithPrice amount={item.buyPrice.amount} currency={item.buyPrice.currency} icon="PurchasingPrice" />
                )}
                <SvgBorderWithPrice amount={item.sellPrice.amount} currency={item.sellPrice.currency} icon="SellingPrice" />
            </div>
            {item.packages && (
                <div className="flex transition-1 pack-bg" style={{ marginInline: -6, marginBottom: -12, paddingTop: 1 }}>
                    {item.packages.map((fill) => {
                        let active = item.selectedPackageId === fill.packageId;
                        return (
                            <div
                                key={fill.packageId}
                                className={`${active ? "active-package" : ""} carbonic-1 font-bold`}
                                onClick={({ currentTarget }) => {
                                    currentTarget.parentElement.querySelector(".active-package").classList.remove("active-package");
                                    currentTarget.parentElement.parentElement.querySelector(".item-quantity").childNodes[1].innerText = fill.packageQuantity;
                                    currentTarget.parentElement.parentElement.querySelectorAll(".item-amount")[1].innerText = fill.price;
                                    currentTarget.classList.add("active-package");
                                    item.selectedPackageId = fill.packageId;
                                }}>
                                <p className="text-center px-2 py-1">{fill.packageName}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default memo(ItemCard);
function hoverdPackage(item) {
    return (
        <div
            className="flex flex-col items-center"
            onMouseOver={({ currentTarget }) => {
                if (!Object.values(item.packages).length > 0) return;
                let target = currentTarget.childNodes[1].childNodes[0];
                target.innerText = item.packages[0].packageName;
                target.style.color = "#f48114";
                target.style.fontWeight = "bold";
            }}
            onMouseOut={({ currentTarget }) => {
                if (!Object.values(item.packages).length > 0) return;
                let target = currentTarget.childNodes[1].childNodes[0];
                target.innerText = Language["PurchasingPrice"];
                target.style.color = "";
                target.style.fontWeight = "";
            }}
            onTouchStart={({ currentTarget }) => {
                if (!Object.values(item.packages).length > 0) return;
                let target = currentTarget.childNodes[1].childNodes[0];
                target.innerText = item.packages[0].packageName;
                target.style.color = "#f48114";
                target.style.fontWeight = "bold";
            }}
            onTouchEnd={({ currentTarget }) => {
                if (!Object.values(item.packages).length > 0) return;
                let target = currentTarget.childNodes[1].childNodes[0];
                target.innerText = Language["PurchasingPrice"];
                target.style.color = "";
                target.style.fontWeight = "";
            }}>
            <SvgBorderPath children={SvgBorderIcons["PurchasingPrice"]} />
            <p className="font-medium text-center py-2 text-sm">
                <span className="carbonic-1">{Language["PurchasingPrice"]}</span>
                <br />
                <span className={`text-base item-amount carbonic`}>{item.buyPrice.amount}</span>
                <br />
                <span className="carbonic-3">{item.buyPrice.currency}</span>
            </p>
        </div>
    );
}
