import React from "react";
import DateFormate from "../../Components/DateFormate";
import OprationsButtons from "../../ComponentsV2/OprationsButton";
import { BusinessIcon } from "../../SVGs/ItemsSvg";
import Language from "../../Utils/Languages";

const BusinessDailyReportCard = ({ item }) => {
    return (
        <div className="carbonic-card flex flex-col shadow-1 rounded-xl overflow-hidden">
            <div className="row items-center pr-4 pt-4">
                {BusinessIcon}
                <p className="carbonic font-bold pr-3">{item.businessTitle}</p>
            </div>
            <OprationsButtons item={item} />
            <DateFormate date={item.date}>
                <p>{Language.LastUpdate}</p>
            </DateFormate>
        </div>
    );
};

export default BusinessDailyReportCard;
