import React from "react";
import { Link } from "react-router-dom";

const IconLabel = ({ label }) => (
    <Link to={label.route} className="flex flex-row items-center menu-label rounded-md my-2 py-1 shadow-1 cursor-pointer">
        <img src={label.icon} style={{ height: 50, width: 50 }} />
        <p className="font-bold text-xl carbonic">{label.title}</p>
    </Link>
);

export default IconLabel;
