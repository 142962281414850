import React, { Fragment } from "react";
import { ConclusionSvg, TotalStatus } from "../../SVGs/AccountsSvgs";
import Language from "../../Utils/Languages";
const size = 75;
const TotalCardAccount = ({ totals }) => (
    <div className="fade-in-card col carbonic-card shadow-1 rounded-2xl overflow-hidden">
        <div className="row p-3">
            <div className="col flex-1 items-center">
                <img src="/accounts/oweUs_g.png" style={{ height: size, width: size }} />
                <p className="font-bold my-1 carbonic">{Language.Debt}</p>
                <p className="font-bold green-text text-center">
                    {totals.credits.map((d_amout, i) => (
                        <Fragment key={i}>
                            <span>{d_amout}</span>
                            <br />
                        </Fragment>
                    ))}
                </p>
            </div>
            <div className="col flex-1 items-center">
                <img src="/accounts/credit_g.png" style={{ height: size, width: size }} />
                <p className="font-bold my-1 carbonic">{Language.Credit}</p>
                <p className="font-bold orange-text text-center">
                    {totals.debts.map((c_amout, i) => (
                        <Fragment key={i}>
                            <span>{c_amout}</span>
                            <br />
                        </Fragment>
                    ))}
                </p>
            </div>
        </div>
        <div className="carbonic-card-1 p-3 col border-top">
            <div className="row items-center">
                <p style={{ backgroundColor: "#ffa44e" }} className=" h-4 w-1 mx-1 mb-1 rounded-2xl" />
                <ConclusionSvg />
                <p className="font-bold carbonic text-base">{Language.Conclusion}</p>
            </div>
            <p className="font-bold carbonic self-end text-left">
                {totals.nets.map((n_amout, i) => (
                    <Fragment key={i}>
                        <span>{n_amout}</span>
                        <br />
                    </Fragment>
                ))}
            </p>
        </div>
    </div>
);

export default TotalCardAccount;
