import React from "react";
import Avatar from "../../SVGs/Avatar";
const ProfileHolder = ({ owner }) => (
    <div className="flex flex-col items-center m-10">
        {owner.image ? <img src={owner.image} className="object-cover rounded-5" style={{ width: 120, height: 120 }} /> : <Avatar />}
        <p className="font-bold text-center py-2">
            <span className="carbonic">{owner.name}</span>
            <br />
            <span className="text-sm carbonic-2 MenuPhone">{owner.phone_number}</span>
        </p>
    </div>
);

export default ProfileHolder;
