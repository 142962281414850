import Language from "./Languages";

const Svgs = {
    businesses: (
        <svg height={50} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 451.25">
            <linearGradient id="New_Gradient_Swatch_1" x1="776.72" y1="159.34" x2="776.72" y2="248.23" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#ffd945" />
                <stop offset="1" stop-color="#ffa325" />
            </linearGradient>
            <linearGradient id="New_Gradient_Swatch_1_2" x1="589.11" y1="159.38" x2="589.11" y2="248.23" />
            <linearGradient id="New_Gradient_Swatch_1_3" x1="733.2" y1="295.77" x2="836.28" y2="295.77" />
            <linearGradient id="New_Gradient_Swatch_1_4" x1="529.16" y1="295.75" x2="632.23" y2="295.75" />
            <linearGradient id="New_Gradient_Swatch_1_5" x1="683.03" y1="548.75" x2="683.03" y2="609.25" />
            <linearGradient id="linear-gradient" x1="863.59" y1="158.97" x2="863.59" y2="248.23" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#ff7044" />
                <stop offset="1" stop-color="#f93019" />
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="502.07" y1="158" x2="502.07" y2="248.23" />
            <linearGradient id="linear-gradient-3" x1="682.72" y1="159.34" x2="682.72" y2="248.23" />
            <linearGradient id="linear-gradient-4" x1="832.42" y1="253.46" x2="935.71" y2="313.1" />
            <linearGradient id="linear-gradient-5" x1="423.85" y1="253.68" x2="526.09" y2="312.72" />
            <linearGradient id="linear-gradient-6" x1="628.74" y1="254.28" x2="730.79" y2="313.2" />
            <linearGradient id="linear-gradient-7" x1="594.09" y1="365.03" x2="594.09" y2="524.17" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#f1ecf5" />
                <stop offset="1" stop-color="#efdcfb" />
            </linearGradient>
            <linearGradient id="linear-gradient-8" x1="831.99" y1="369.22" x2="831.99" y2="548.95" />
            <path
                fill="#7e6a7d"
                d="M436.14,558.35c-.08-1.49-.21-3-.21-4.48q0-113.19,0-226.38c0-1.49.11-3,.18-4.48,11.55,14.36,26.43,21.09,44.94,20.2,25.33-1.24,44.86-17.9,48.24-45.43.26,1.48.54,2.94.78,4.42,2.52,15.5,11.3,27.15,24.22,34.95,9,5.42,19.64,6.52,30.38,6,21.2-1,39.74-15.51,45.27-36,.55-2.05,1.24-4.06,1.86-6.09.67,2.21,1.38,4.42,2,6.64,10.34,36.05,56.67,48.1,84.84,22.25,8.57-7.86,13-17.89,15.4-29,.27.4.7.78.77,1.22,3.91,23.93,25.21,42.59,53.68,41.1,25.06-1.31,45.08-19.35,47.7-44.47.6,2.23,1.28,4.45,1.79,6.71,5.4,23.83,28,37.41,46.27,37.86,18.86.46,34.06-5.72,45.79-20.47,0,12,.11,24,.11,35.94q0,96.63,0,193.24c0,2.16-.16,4.32-.24,6.47-7.48-7.12-16.35-10.22-26.67-9.68-5.1.26-10.24,0-15.36-.09q0-82.74-.09-165.49c0-10.44-3.65-14-14.18-14q-41.25,0-82.5,0c-12.07,0-15,3-15,15q-.06,82.25-.09,164.49c-2.83,0-5.66.15-8.49.15q-152.49,0-305-.13C452.33,548.75,443.55,551.29,436.14,558.35Zm157.22-34.18H711.85c9.47,0,12.4-2.81,12.4-12,0-45-.21-90,0-135,0-7.1-5-12.14-12.28-12.09-79.29.52-158.59.71-237.88,1-7.69,0-10.15,2.46-10.16,10.1q0,68.24,0,136.49c0,9,2.59,11.53,11.46,11.53Q534.37,524.17,593.36,524.17Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#New_Gradient_Swatch_1)"
                d="M833.81,243.91c-8.39-16.2-17.07-32.24-25.65-48.33q-9.61-18-19.24-36l-70.92-.2c3.67,20.41,6.59,41,11.21,61.19a186.19,186.19,0,0,1,4,27.7H835.44A20.5,20.5,0,0,0,833.81,243.91Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#New_Gradient_Swatch_1_2)"
                d="M648,159.41q-35.43,0-70.85,0c-15.16,28.32-30.37,56.6-45.37,85a21,21,0,0,0-1.54,3.85h102c1.23-12.8,3.78-25.44,6.2-38.1Q643.26,184.78,648,159.41Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#New_Gradient_Swatch_1_3)"
                d="M836.16,253.35a21.57,21.57,0,0,0-.72-5.12H733.2c1.21,16.18.85,32.43.89,48.69,0,1.33,0,2.67,0,4,.27.4.7.78.77,1.22,3.91,23.93,25.21,42.59,53.68,41.1,25.06-1.31,45.08-19.35,47.7-44.47C836.23,283.63,836.38,268.48,836.16,253.35Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#New_Gradient_Swatch_1_4)"
                d="M530.23,248.23a22.32,22.32,0,0,0-1,6.05c-.23,14.5,0,29,0,43.5.26,1.48.54,2.94.78,4.42,2.52,15.5,11.3,27.15,24.22,34.95,9,5.42,19.64,6.52,30.38,6,21.2-1,39.74-15.51,45.27-36,.55-2.05,1.24-4.06,1.86-6.09,0-9.81.64-19.66,0-29.42a142.81,142.81,0,0,1,.46-23.42Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#New_Gradient_Swatch_1_5)"
                d="M436.14,558.35c7.41-7.06,16.19-9.6,26.42-9.58q152.49.29,305,.13c2.83,0,5.66-.1,8.49-.15,6,.07,12,.21,18,.2q47-.06,93.93-.2c5.12,0,10.26.35,15.36.09,10.32-.54,19.19,2.56,26.67,9.68,6.14,7.79,8.33,16.71,7.89,26.5-.24,5.62,0,11.26,0,16.89,0,3.39-.91,6.24-4.59,7.34-2.5-.06-5-.17-7.49-.17H440.21c-2.5,0-5,.11-7.49.16-3.46-1-4.56-3.63-4.57-6.87,0-8.14-.06-16.29.15-24.44C428.48,570.49,431.84,564.21,436.14,558.35Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient)"
                d="M905.7,213.55q-20.3-21.68-40.56-43.37A35.9,35.9,0,0,0,842.94,159q-23.57.06-47.12.13c-2.31,0-4.61.29-6.91.44l19.25,36c8.58,16.09,17.26,32.13,25.65,48.33a20.5,20.5,0,0,1,1.63,4.32H938.27Q922,230.89,905.7,213.55Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-2)"
                d="M577.14,159.38c-4.92-.19-9.84-.52-14.76-.55-10.4,0-20.8.12-31.2.08A30.25,30.25,0,0,1,526,158h-3a49.22,49.22,0,0,1-4.88,1.9c-7.45,2.06-13.45,6.21-18.71,11.84q-35.27,37.83-70.76,75.44A5.42,5.42,0,0,1,427,248v.23H530.23a21,21,0,0,1,1.54-3.85C546.77,216,562,187.7,577.14,159.38Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-3)"
                d="M718,159.34l-70,.07q-4.77,25.36-9.56,50.72c-2.42,12.66-5,25.3-6.2,38.1h101a186.19,186.19,0,0,0-4-27.7C724.59,200.33,721.67,179.75,718,159.34Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-4)"
                d="M938.27,248.23H835.44a21.57,21.57,0,0,1,.72,5.12c.22,15.13.07,30.28.06,45.42.6,2.23,1.28,4.45,1.79,6.71,5.4,23.83,28,37.41,46.27,37.86,18.86.46,34.06-5.72,45.79-20.47q4.45-9.93,8.93-19.87V249C938.76,248.74,938.52,248.48,938.27,248.23Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-5)"
                d="M530.23,248.23H427V303l9.12,20c11.55,14.36,26.43,21.09,44.94,20.2,25.33-1.24,44.86-17.9,48.24-45.43,0-14.5-.26-29,0-43.5A22.32,22.32,0,0,1,530.23,248.23Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-6)"
                d="M733.2,248.23h-101a142.81,142.81,0,0,0-.46,23.42c.68,9.76.07,19.61,0,29.42.67,2.21,1.38,4.42,2,6.64,10.34,36.05,56.67,48.1,84.84,22.25,8.57-7.86,13-17.89,15.4-29,0-1.33,0-2.67,0-4C734.05,280.66,734.41,264.41,733.2,248.23Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-7)"
                d="M712,365c-79.29.52-158.59.71-237.88,1-7.69,0-10.15,2.46-10.16,10.1q0,68.24,0,136.49c0,9,2.59,11.53,11.46,11.53H711.85c9.47,0,12.4-2.81,12.4-12,0-45-.21-90,0-135C724.3,370,719.28,365,712,365Z"
                transform="translate(-427 -158)"
            />
            <path
                fill="url(#linear-gradient-8)"
                d="M887.86,383.26c0-10.44-3.65-14-14.18-14q-41.25,0-82.5,0c-12.07,0-15,3-15,15q-.06,82.25-.09,164.49c6,.07,12,.21,18,.2q47-.06,93.93-.2Q887.91,466,887.86,383.26Z"
                transform="translate(-427 -158)"
            />
        </svg>
    ),
};
export const MenuLabels = {
    Items: [
        { title: Language.Settings, icon: "/menu/settings.png", route: "/settings/" },
        { title: Language.AboutMyMorabaa, icon: "/menu/about.png", route: "/about/" },

        { title: Language.Companise, icon: "/menu/businesses.png", route: "/businesses/" },
        { title: Language.Customers, icon: "/menu/cutsomers.png", route: "/customers/" },
        { title: Language.Tickets, icon: "/menu/tickets.png", route: "/tickets/" },
        { title: Language.Invoices, icon: "/menu/invoices.png", route: "/invoices/" },
    ],
};

export const RouteTitles = {
    "/items/": Language.Items,
    "/invoices/": Language.Invoices,
    "/businesses/": Language.Companise,
    "/tickets/": Language.MenuItemTickets,
    "/representatives/": Language.Reps,
    "/restaurant/menu/": Language.Restaurant,
    "/settings/": Language.Settings,
    "/about/": Language.AboutMyMorabaa,
    "/menu/": "logo",

    "/accounts/": Language.AccountsBalances,
    "/": "logo",
    "/customers/": Language.Customers,
    "/notifications/": Language.TheNotifications,
};
