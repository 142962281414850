import ItemsUtils from "../Items/ItemsUtils";
import Info from "../NodeBuilder/Info";
import { getApi } from "../scripts/Fetcher";
import { Button } from "../scripts/NodeBuilder";
import Language from "../Utils/Languages";
import { getRestBusinesses } from "../Utils/Utils";

const updateItems = async (provider, callback) => {
    let deletedItems = provider.items.filter((item) => item.checked === false).map((item) => item.id);
    let addedItems = {};
    provider.items
        .filter((item) => item.checked === true)
        .forEach(({ groupId, id }) => {
            if (!addedItems[groupId]) addedItems[groupId] = [id];
            else addedItems[groupId].push(id);
        });

    try {
        provider.setProviderState("loading");
        const api = getApi("restaurant");
        await api.post("v1/items/", { businessId: provider.queryParams.businessId?.value, addedItems, deletedItems });
        Info({ title: "TheItemsAppearingInTheListHaveBeenUpdated", type: "success" });
        provider.items.forEach((item) => (item.checked === item.checked ? 1 : undefined));
        provider.setItems(provider.items);
        callback();
        provider.setProviderState("none");
    } catch (e) {
        provider.setProviderState({
            state: "error",
            error: e,
        });
        Info({ title: "Error", content: e?.response ?? e });
    }
};

const RestaurantUtils = {
    onResult: async (data, provider) => {
        const _items = ItemsUtils.onResult(data);
        let itemIds = _items.map((x) => x.id);
        let items = await getApi("restaurant").post(`v1/items/ofbusiness/${provider.queryParams.businessId?.value}/selected`, itemIds);
        _items.forEach((item) => (item.checked = items.some((y) => y.itemId === item.id) ? 1 : undefined));
        setTimeout(() => {
            if (provider.items.length < 1) document.getElementById("rest-save-btn")?.remove();
        }, 100);
        return _items;
    },
    toggleSaveButton: (item, provider) => {
        provider.itemsChanged();
        if (provider.canSave) return;
        provider.canSave = true;

        const saveBtn = Button({
            innerText: Language.Save,
            id: "rest-save-btn",
            onclick: () => {
                updateItems(provider, () => {
                    {
                        provider.canSave = false;
                        saveBtn.remove();
                    }
                });
            },
            className: "fixed bottom-4 m-2 py-3 px-6 shadow-1 text-center font-bold rounded-1 red-text bg-whtie transition-1",
        });
        saveBtn.style = "left:50%;width:130px;margin-left:-65px";
        const restContainer = document.getElementById("restaurant-wrapper");
        restContainer && restContainer.append(saveBtn);
    },

    queryParams: [
        {
            className: "form-section",
            children: [
                {
                    key: "query",
                    type: "text",
                    title: Language.ItemName,
                    value: "",
                    storageKey: "rest-items-query",
                    containerClass: "query-input",
                    showInClearBar: false,
                },
                {
                    key: "businessId",
                    type: "popupSelector",
                    value: "",
                    id: "businesses-selector",
                    storageKey: "selected-rest-business",
                    getData: getRestBusinesses,
                    className: "selector",
                },
            ],
        },
    ],
};

export default RestaurantUtils;
