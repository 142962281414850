// import { Appearance } from "react-native";
// import { Sheard } from "./Sheard";
// import { ChangeTheme, ThemeChanged } from "./Theme";
// import Language, { ChangeLanguage } from "./Utils/Languages";

export const LanguagesTitles = {
    ar: "العربية",
    kurd: "كوردی",
    en_us: "English",
};
export const Languages = [
    { value: "ar", title: "العربية" },
    { value: "kurd", title: "كوردی" },
    // { value: "en_us", title: "English" },
];
export const CurrencesTitles = {
    1: "دينار عراقي",
    2: "دولار امريكي",
};

export const getCurrencesTitle = (currencyId) =>
    ({
        1: "دينار عراقي",
        2: "دولار امريكي",
    }[currencyId]);

export const Currences = [
    { value: "1", title: "دينار عراقي", requestValue: 1 },
    { value: "2", title: "دولار امريكي", requestValue: 2 },
];

export const ShortCurrences = {
    1: "د.ع",
    2: "$",
    3: "€",
    4: "£",
    5: "₪",
    6: "₹",
    7: "₩",
    8: "¥",
    9: "₺",
    10: "₴",
    11: "₫",
};

export const getShortCurrency = (currencyId) => ({ 1: "د.ع", 2: "$", 3: "€", 4: "£", 5: "₪", 6: "₹", 7: "₩", 8: "¥", 9: "₺", 10: "₴", 11: "₫" }[currencyId]);

export const ThemesTitles = {
    light: "الوضع النهاري",
    dark: "الوضع الليلي",
};
export const Themes = [
    { value: "light", title: "الوضع النهاري" },
    { value: "dark", title: "الوضع الليلي" },
];

export const HandleOptions = async () => {
    // try {
    //     let options = await getData("Options");
    //     if (options) {
    //         Options.theme = options.theme;
    //         Options.language = options.language;
    //         Options.currency = options.currency;
    //         await ChangeTheme(Options.theme.value);
    //         await ChangeLanguage(Options.language.value);
    //     } else {
    //         Options.theme = Appearance.getColorScheme() === "dark" ? Themes[0] : Themes[1];
    //         storeData("Options", Options);
    //     }
    // } catch {
    //     Options.theme = Appearance.getColorScheme() === "dark" ? Themes[0] : Themes[1];
    //     storeData("Options", Options);
    // }
    // return await getData("token");
};

export const Options = {
    theme: { value: "light", title: "الوضع الليلي" },
    language: { value: "ar", title: "العربية" },
    currency: { value: 1, title: "دينار عراقي" },
};

export const UpdateOptions = async () => {
    let languageStorage = localStorage.getItem("language") || "ar";
    let themeStorage = localStorage.getItem("theme") || "light";
    let currencyStorage = localStorage.getItem("currency") || "1";

    Options.language = Languages.find((o) => o.value === languageStorage);
    Options.theme = Themes.find((o) => o.value === themeStorage);
    Options.currency = Currences.find((o) => o.value === currencyStorage);
};

export const HandleChangeOptions = (key, item) => {
    let changeTime = 100;
    Options[key] = item;

    if (key === "language") {
        // ChangeLanguage(item.value);
        // Sheard.setNavigatoHolder({ index: 23, backTo: "MenuView", title: Language.X.Settings });
    } else if (key === "theme") {
        // ChangeTheme(item.value);
        localStorage.setItem("theme", item.value);
        changeTime = 400;
    }

    // storeData("Options", Options);
    setTimeout(() => {
        // Sheard.setInitialRouteName("SettingsView");
    }, changeTime);
};
