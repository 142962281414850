import React from "react";
import SvgBorderWithPrice from "../../SVGs/BorderSvgs";
import InvoiceTop from "./InvoiceTop";
import BillNumberLabel from "./NumberLabel";
import SperaterThenEdge from "./SperaterThenEdge";
import { CurrencesTitles } from "../../Settings";
import InvoiceView from "../InvoiceView";

const CustomerInfo = ({ customer }) => (
    <div className="font-bold text-sm text-center relative -top-4 px-2 carbonic-2">
        <p className="carbonic pb-1">{customer.name}</p>
        <p>
            <span>{customer.phone}</span>
            <br />
            <span>{customer.address}</span>
        </p>
    </div>
);

const InvoiceCard = ({ item, provider }) => {
    const invoice = item.invoice;
    return (
        <div
            onClick={() => {
                provider.showInvoice(invoice.id);
            }}
            className="fade-in-card cursor-pointer flex flex-col">
            <div className="shadow-1 carbonic-card overflow-hidden relative flex-1" style={{ borderTopRightRadius: 25, borderTopLeftRadius: 25 }}>
                <InvoiceTop />
                <div className="invoice-top-blur absolute top-10 w-full h-full" />
                {invoice?.customer && <CustomerInfo customer={invoice.customer} />}
            </div>
            <SperaterThenEdge />
            <div style={{ borderBottomRightRadius: 25, borderBottomLeftRadius: 25 }} className="shadow-1 overflow-hidden">
                <div className="flex flex-row justify-around carbonic-card pb-2">
                    <BillNumberLabel number={invoice.number} />
                    <p style={{ width: 3, height: 15, backgroundColor: "#f48114", borderRadius: 25, marginTop: 8 }} />
                    <SvgBorderWithPrice amount={item.total} currency={CurrencesTitles[item.currencyId]} icon="InvoiceTotalAmount" amountClass="orange-text" />
                </div>
                <div className="flex flex-row text-sm justify-between px-4 py-2 invoice-bg-date font-bold carbonic-3">
                    <p>{invoice.date.short}</p>
                    <p>{invoice.date.long}</p>
                </div>
            </div>
        </div>
    );
};

export default InvoiceCard;
