import OwnerCard from "./OwnerCard";
import React, { useState } from "react";
import Language from "../../Utils/Languages";
import BusinessAPI from "../../API/Business";
import TextInput from "../../Components/TextInput";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";
import Button from "../../ComponentsV2/Button";
import Info from "../../NodeBuilder/Info";

const AddOwnerPopup = ({ owners, close, addOwner }) => {
    const [searchTerm, setSearchTerm] = useState(""); //07825428432
    const [foundOwner, setFoundOwner] = useState("");
    const _onSubmit = (e) => {
        e.preventDefault();
        search(e);
        return false;
    };

    const search = () => {
        if (owners.map((o) => o.phoneNumber).indexOf(searchTerm) < 0)
            BusinessAPI.getOwnerByPhone(searchTerm)
                .then(({ data }) => {
                    if (owners.map((o) => o.id).indexOf(data.id) < 0) setFoundOwner(data);
                    else Info({ title: "Error", content: "CanNotAddExisstinNumber", type: "error" });
                })
                .catch(() => Info({ title: "Error", content: "WeCounldNotFoundSomeoneWithThisNumber", type: "error" }));
        else Info({ title: "Error", content: "CannotAddExisitinAccount", type: "error" });
    };

    return (
        <DialogConatiner close={close}>
            <form onSubmit={_onSubmit} className="col-center max-w-5xl rounded-2 mx-auto carbonic-card">
                {foundOwner ? (
                    <div className="flex flex-col">
                        <OwnerCard owner={foundOwner} />
                        <p className="font-bold mt-6 mb-2">
                            <span className="font-bold carbonic">
                                {Language.Worning} {foundOwner.firstName} {foundOwner.lastName} {Language.AccsesToAllThingInCompany}
                            </span>
                        </p>
                        <Button color="red" Extra="m-auto px-10" title={Language.Add} onClick={() => addOwner(foundOwner.id)} />
                    </div>
                ) : (
                    <>
                        <img className="h-20 my-5" src="/Other/keys.png" />
                        <p className="font-bold carbonic mb-5">{Language.AddOwner}</p>
                        <TextInput autoFocus value={searchTerm} setValue={setSearchTerm} placeholder="PhoneNumber" />
                        <input
                            type="submit"
                            value={Language.Search}
                            className="cursor-pointer shadow-1 green-bg text-white rounded-1 py-2 my-2 w-32 font-bold mx-auto"
                        />
                    </>
                )}
            </form>
        </DialogConatiner>
    );
};

export default AddOwnerPopup;
