import React from "react";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";
import DetailsLabel from "../../ComponentsV2/LastUpdateLabel";

const ItemsHeader = ({ provider }) => {
    return (
        <>
            <div className="gap-10 flex flex-row flex-wrap px-4">
                <DetailsLabel details={provider.header.itemsCount} title="TotalNumberOfItems" />
                <DetailsLabel details={provider.header.lastUpdate} />
            </div>
            {provider.header.popupImage && (
                <DialogConatiner
                    childClassName="w-full h-1/2 m-auto"
                    close={() =>
                        provider.setHeader({
                            ...provider.header,
                            popupImage: null,
                        })
                    }>
                    <img
                        alt="small"
                        onClick={() =>
                            provider.setHeader({
                                ...provider.header,
                                popupImage: null,
                            })
                        }
                        className="object-contain h-full w-full fade-in-card"
                        src={`https://items.morabaaapps.com${provider.header.popupImage}`}
                    />
                </DialogConatiner>
            )}
        </>
    );
};

export default ItemsHeader;
