import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import TextInput from "../../Components/TextInput";
import Language from "../../Utils/Languages";
import IconCard from "../../ComponentsV2/IconCard";
import CheckBox from "../../ComponentsV2/CheckBox";
import Representatives from "../../API/Representative";
import SubmitButton from "../../ComponentsV2/SubmitButton";
import { Permissions } from "../Components/RepsPermissions";
import { Delete } from "../../SVGs/Reps";
import CheckButton from "../Components/FrezzButton";
import Confirmer from "../../Containers/Confirmer";

const EditRepresentativeView = (props) => {
    const [firstName, setFirstName] = useState("");
    const [address, setAddress] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [representative, setRepresentative] = useState(null);
    const [repsPermissions, setRepsPermissions] = useState(Permissions);
    const [uiRefresh, setUiRefresh] = useState(false);

    let repsToEdit = JSON.parse(localStorage.getItem("repsToEdit"));
    let history = useNavigate();

    const Done = () => history("/representatives");
    const Error = () => {};
    const updateRepresentative = (e) => {
        e.preventDefault();
        let repsInfo = {
            firstName,
            lastName,
            address,
            phoneNumber,
            isActive: representative.isActive,
        };
        Representatives.update(representative.id, repsInfo, repsPermissions, Done, Error);
        return false;
    };

    const fetchPermissions = () => Representatives.getPermissions(repsToEdit.id, setRepsPermissions);

    const deleteRepresentative = (e) => {
        e.preventDefault();
        Confirmer({
            title: Language.AreYouSure,
            confirm: () => {
                Representatives.delete(representative.id).then(() => history("/representatives"));
            },
        });
    };

    useEffect(() => {
        if (!representative) return;
        fetchPermissions();
        setFirstName(representative.firstName);
        setLastName(representative.lastName);
        setAddress(representative.address);
        setPhoneNumber(representative.phoneNumber);
    }, [representative]);

    useEffect(() => {
        if (repsToEdit) setRepresentative(repsToEdit);
        else Representatives.getRepresentative(repsToEdit.id).then((response) => setRepresentative(response.data));
    }, []);

    const permissionPressed = (repsPermissions) => {
        repsPermissions.isPermitted = !repsPermissions.isPermitted;
        setUiRefresh(!uiRefresh);
    };

    return (
        <>
            <form className="scrller-container scroller" onSubmit={updateRepresentative}>
                <IconCard icon="RepsInfo">
                    <TextInput value={firstName} setValue={setFirstName} required placeholder="Name" />
                    <TextInput value={lastName} setValue={setLastName} required placeholder="NameTitle" />
                    <TextInput value={address} setValue={setAddress} required placeholder="Address" />
                    <TextInput value={phoneNumber} setValue={setPhoneNumber} required placeholder="PhoneNumber" />
                </IconCard>
                <IconCard icon="RepsPermission">
                    {repsPermissions.map((permission) => (
                        <CheckBox key={permission.id} checked={permission.isPermitted} onClick={() => permissionPressed(permission)} title={permission.title} />
                    ))}
                </IconCard>

                <div className="flex flex-row ">
                    <SubmitButton text={Language.Save} />
                    {representative && (
                        <CheckButton item={representative} onClick={() => setRepresentative({ ...representative, isActive: !representative.isActive })} />
                    )}

                    {/* <div
                    onClick={() => setRepresentative({ ...representative, isActive: !representative.isActive })}
                    className={`flex flex-row items-center shadow-1 cursor-pointer carbonic-card-1 carbonic rounded-2 p-3 w-32 my-2 font-bold mx-auto ${
                        representative.isActive ? "" : ""
                    }`}>
                    {Freeze}
                    <p className="mx-auto carbonic">{Language.Freeze}</p>
                </div> */}
                </div>
                <div className="flex flex-row cursor-pointer items-center justify-center mt-5" onClick={deleteRepresentative}>
                    {Delete}
                    <p className="red-text font-bold pr-3">{Language.DeleteRepresentativeAccounts}</p>
                </div>
            </form>
        </>
    );
};

export default EditRepresentativeView;
