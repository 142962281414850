import React from "react";
import { Link } from "react-router-dom";
import { SvgBorderLabel } from "../../SVGs/BorderSvgs";
import { BusinessIcon, TransStatus } from "../../SVGs/AccountsSvgs";
import SalesReport from "../../API/SalesReport";
import Language from "../../Utils/Languages";

const TransactionsLink = ({ accountId, onClick, balance }) => {
    const StateHolder = TransStatus[balance.state];
    return (
        <Link className="flex-1 items-center col py-1" onClick={onClick} to={{ pathname: `/transactions/${accountId}/${balance.currencyId}` }}>
            <StateHolder amount={balance.amount} currency={balance.currency} />
        </Link>
    );
};

const AccountCard = ({ item }) => {
    let { name, businessTitle, address, phoneNumber } = item;
    const NavigateToDetials = () => {
        sessionStorage.setItem(item.id, JSON.stringify({ businessTitle, name }));
    };

    return item ? (
        <div className="fade-in-card rounded-5 flex flex-col carbonic-card shadow-1 relative">
            <div tooltip={item.isFavorite ? Language.RemoveFromFavorite : Language.AddToFavorite} className="tooltip">
                <svg
                    onClick={({ currentTarget }) => {
                        SalesReport.toggleFavorite(item.id);
                        if (item.isFavorite) {
                            item.isFavorite = false;
                            currentTarget.classList.remove("active");
                            currentTarget.parentElement.setAttribute("tooltip", Language.AddToFavorite);
                        } else {
                            item.isFavorite = true;
                            currentTarget.classList.add("active");
                            currentTarget.parentElement.setAttribute("tooltip", Language.RemoveFromFavorite);
                        }
                    }}
                    className={`fav-account ${item.isFavorite ? "active" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    <path d="M17.562 21.56a1 1 0 0 1-.465-.116L12 18.764l-5.097 2.68a1 1 0 0 1-1.45-1.053l.973-5.676-4.124-4.02a1 1 0 0 1 .554-1.705l5.699-.828 2.549-5.164a1.04 1.04 0 0 1 1.793 0l2.548 5.164 5.699.828a1 1 0 0 1 .554 1.705l-4.124 4.02.974 5.676a1 1 0 0 1-.985 1.169Z" />
                </svg>
            </div>

            <div className="flex flex-row py-2">
                <div className="flex flex-col" style={{ width: "40%" }}>
                    {BusinessIcon}
                    <p className="font-bold px-3 carbonic text-center">
                        <span> {name} </span>
                    </p>
                </div>
                <div className="justify-around flex flex-col">
                    <SvgBorderLabel icon="Company" title={businessTitle} />
                    <SvgBorderLabel icon="Phone" title={phoneNumber} />
                    <SvgBorderLabel icon="Address" title={address} />
                </div>
            </div>
            <div className="row items-end border-top pt-3 flex-grow carbonic-card-1 rounded-b-3xl">
                {item.balances
                    ?.sort((a, b) => a.currencyId - b.currencyId)
                    .map((balance, index) => (
                        <TransactionsLink key={index} onClick={NavigateToDetials} accountId={item.id} balance={balance} />
                    ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default AccountCard;
