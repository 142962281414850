import Language from "../Utils/Languages";

const size = 35;

export const CardIcons = [
    //
    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="border"
            d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
        />
        <path d="M508.68,569.85H271.82a12.8,12.8,0,0,0-2.78-1.27c-35.22-6.51-57.21-32.66-57.27-68.22,0-22,.07-44.08-.1-66.12,0-2.65-.47-6.24-2.18-7.79-14.7-13.37-23.35-30-27.68-49.13v-14.2c8.63-21.36,17.54-42.62,25.73-64.14a71.34,71.34,0,0,0,4.1-20.5c1.84-28.66,15.2-49.53,41.83-60.47,8.71-3.59,18.87-5,28.39-5.09,72.37-.41,144.74-.34,217.11-.18,37.37.09,66.54,24.47,69,59.82,1.06,15.2,5.7,28.57,11,42.19,5.42,13.94,11.53,27.66,16.22,41.84A53.26,53.26,0,0,1,597.5,378c-1.85,18.26-11.38,32.89-23.84,46a18.63,18.63,0,0,0-4.72,11.39c-.44,22.35-.08,44.71-.25,67.06a66.87,66.87,0,0,1-51.79,65.21C514.14,568.35,511.41,569.13,508.68,569.85ZM390.52,316.64c-45,0-90,.08-134.95-.12-5.5,0-8.49,1.36-10.33,6.92-4.45,13.44-8.93,27-14.71,39.85-4.52,10.07-2.18,18,4.71,25,4.9,4.94,10.6,9.62,16.81,12.65,20.15,9.84,42.21,3.79,60.21-15.6,11.86-12.77,25-12.89,36.61-.34,25.37,27.49,57.27,27.4,82.91-.22,10.5-11.32,22.61-11.52,34.77-1.74a195.9,195.9,0,0,0,26.61,18.08c21.5,12.12,48.94-2.52,57.34-20.87a18.86,18.86,0,0,0,.59-12.58c-4.81-14.71-10.75-29-15.67-43.71-1.92-5.72-4.76-7.5-10.74-7.46C480,316.75,435.24,316.64,390.52,316.64Z" />
    </svg>,
    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="border"
            d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
        />
        <path d="M386.3,184.71h13.88c10.9,2.63,21.13,6.49,30.12,13.62q62.94,49.88,126.16,99.42c14.21,11.16,24.51,25,29.43,42.44,2,7,2.68,14.29,4,21.45V561.69a14.33,14.33,0,0,0-.76,2.17c-4.54,23.49-18.17,34.74-42.2,34.74q-154.08,0-308.15,0c-25.41,0-41.53-15.63-41.58-40.79q-.18-96.54,0-193.08c0-27.83,11.36-50.11,33.33-67.27,35.82-28,71.42-56.26,107.15-84.36C352.54,201.38,366.4,187.86,386.3,184.71Zm6.84,239.52c10.78,0,21.56.13,32.34,0,13.12-.2,22-9.17,22.09-22.28q.15-32,0-64.09c-.07-13-9.16-22.22-22.18-22.32q-32.06-.27-64.1,0c-13.32.1-22.34,9.35-22.39,22.7q-.12,31.77,0,63.52c0,13.39,9,22.32,22.47,22.49C372,424.34,382.55,424.24,393.14,424.23Zm-21.8,87.34V479.2c-.27-12.85-9-21.83-21.88-21.94q-32.37-.28-64.76,0c-13.15.11-22,9.17-22.09,22.36q-.18,31.78,0,63.59c.06,12.92,8.52,22.34,21.36,22.6q32.94.68,65.91,0c12.94-.25,21.33-9.52,21.45-22.45C371.42,532.77,371.34,522.17,371.34,511.57Zm98.37-54.37c-10.6,0-21.2-.09-31.8,0-14,.15-22.69,8.86-22.75,22.81q-.11,31.23,0,62.44c0,14.52,8.79,23.37,23.44,23.47q30.93.21,61.87,0c14.49-.1,23.38-9.14,23.43-23.57q.09-30.93,0-61.87c0-14.27-8.8-23.13-23-23.28C490.53,457.11,480.12,457.2,469.71,457.2Z" />
    </svg>,
    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <path
            className="border"
            d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
        />
        <path d="M221.82,472.7c.5-1.77,1-3.53,1.48-5.32,4.63-18.5,20.36-31.58,39.51-32.17,13.74-.42,27.5-.17,41.25-.18H516c26.09,0,40.4,11.35,46.54,36.79.08.33.44.59.66.88v27.13a47.73,47.73,0,0,0-1,4.83A85.07,85.07,0,0,1,542,551.05c-17.62,20.79-40.69,33.07-66.07,41.49-38,12.6-77,15.33-116.57,11-30.54-3.38-59.89-11.21-86.4-27.48-23.61-14.48-41.27-33.78-48.19-61.39-1.23-4.87-2-9.86-2.91-14.8Z" />
        <path d="M392.48,179.56A106.46,106.46,0,1,1,286.1,285.71,106.1,106.1,0,0,1,392.48,179.56Z" />
    </svg>,
];
export const TotalStatus = {
    credit: (
        <>
            <svg height={size} fill="#f48114" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M352,679c-6.32-2.71-13.29-4.48-18.81-8.33-8.49-5.95-12.19-15.12-12.2-25.56q0-78.71,0-157.41c0-20.79,13.55-34.78,34.35-35.44.83,0,1.66,0,2.49,0q142.17,0,284.33,0c17.75,0,30.43,8.26,35.12,23.18a38,38,0,0,1,1.66,11.26q.16,78.2.07,156.41c0,19.13-10.45,31.72-29.17,35.17A11,11,0,0,0,648,679Zm26.21-29.2H621.8c5-19.45,11.19-25.61,28.54-28.24V509.38c-17.19-1.92-26.34-11.72-28.42-28.18H378.2c-4.9,19.39-11.21,25.65-28.54,28.24V621.57C366.91,624.12,373.24,630.4,378.21,649.8Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M502,320c3.77,2.59,8,4.72,11.22,7.84,11.52,11.11,22.75,22.5,34,33.88,6.37,6.44,6.65,15,.89,20.95s-14.43,5.74-20.94-.46c-2.77-2.64-5.55-5.3-8.1-8.16-1.19-1.34-1.82-3.17-2.7-4.78l-2.13,1.47V377c0,14.17.07,28.34-.06,42.5-.08,8.3-6.12,14.46-13.93,14.62-8,.16-14.28-6.12-14.43-14.63-.14-8.16-.05-16.33-.06-24.5V370.15a36.9,36.9,0,0,0-3.35,2.54c-3.59,3.48-7,7.17-10.69,10.51a14.1,14.1,0,0,1-19.47-.18,14.31,14.31,0,0,1-1.16-19.47,41.88,41.88,0,0,1,3.43-3.63c11.52-11.57,23-23.23,34.68-34.6,2.4-2.33,5.84-3.58,8.8-5.32Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,565.61c.29-17.62,4.43-34.06,16.19-47.65a53.39,53.39,0,0,1,87.72,9.68c12.74,23.45,13.11,47.92,1.92,72-9.61,20.7-26.47,32-49.48,31.84-23.46-.19-39.75-12.38-49.6-33.38C445.2,587.83,443.49,576.86,443.26,565.61Zm84.73-.07c-.11-10-2.23-19.49-8.26-27.69a24.54,24.54,0,0,0-39.72.33c-10.61,14.66-10.62,39.94,0,54.58a24.52,24.52,0,0,0,40,0C525.82,584.69,527.86,575.38,528,565.54Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M386.75,546.54a19,19,0,1,1-18.85,19.22A19,19,0,0,1,386.75,546.54Z" transform="translate(-320.98 -320)" />
                <path d="M613.07,584.48a19,19,0,1,1,19-19A19,19,0,0,1,613.07,584.48Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold carbonic-1 text-sm">{Language.Credit}</p>
        </>
    ),
    debt: (
        <>
            <svg height={size} fill="#1bd69a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-320.98 -320)" />
                <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold carbonic-1 text-sm">{Language.Debt}</p>
        </>
    ),
};

export const TransStatus = [
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-320.98 -320)" />
                <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="text-base green-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M352,679c-6.32-2.71-13.29-4.48-18.81-8.33-8.49-5.95-12.19-15.12-12.2-25.56q0-78.71,0-157.41c0-20.79,13.55-34.78,34.35-35.44.83,0,1.66,0,2.49,0q142.17,0,284.33,0c17.75,0,30.43,8.26,35.12,23.18a38,38,0,0,1,1.66,11.26q.16,78.2.07,156.41c0,19.13-10.45,31.72-29.17,35.17A11,11,0,0,0,648,679Zm26.21-29.2H621.8c5-19.45,11.19-25.61,28.54-28.24V509.38c-17.19-1.92-26.34-11.72-28.42-28.18H378.2c-4.9,19.39-11.21,25.65-28.54,28.24V621.57C366.91,624.12,373.24,630.4,378.21,649.8Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M502,320c3.77,2.59,8,4.72,11.22,7.84,11.52,11.11,22.75,22.5,34,33.88,6.37,6.44,6.65,15,.89,20.95s-14.43,5.74-20.94-.46c-2.77-2.64-5.55-5.3-8.1-8.16-1.19-1.34-1.82-3.17-2.7-4.78l-2.13,1.47V377c0,14.17.07,28.34-.06,42.5-.08,8.3-6.12,14.46-13.93,14.62-8,.16-14.28-6.12-14.43-14.63-.14-8.16-.05-16.33-.06-24.5V370.15a36.9,36.9,0,0,0-3.35,2.54c-3.59,3.48-7,7.17-10.69,10.51a14.1,14.1,0,0,1-19.47-.18,14.31,14.31,0,0,1-1.16-19.47,41.88,41.88,0,0,1,3.43-3.63c11.52-11.57,23-23.23,34.68-34.6,2.4-2.33,5.84-3.58,8.8-5.32Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,565.61c.29-17.62,4.43-34.06,16.19-47.65a53.39,53.39,0,0,1,87.72,9.68c12.74,23.45,13.11,47.92,1.92,72-9.61,20.7-26.47,32-49.48,31.84-23.46-.19-39.75-12.38-49.6-33.38C445.2,587.83,443.49,576.86,443.26,565.61Zm84.73-.07c-.11-10-2.23-19.49-8.26-27.69a24.54,24.54,0,0,0-39.72.33c-10.61,14.66-10.62,39.94,0,54.58a24.52,24.52,0,0,0,40,0C525.82,584.69,527.86,575.38,528,565.54Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M386.75,546.54a19,19,0,1,1-18.85,19.22A19,19,0,0,1,386.75,546.54Z" transform="translate(-320.98 -320)" />
                <path d="M613.07,584.48a19,19,0,1,1,19-19A19,19,0,0,1,613.07,584.48Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="carbonic-1">{Language.Credit}</span>
                <br />
                <span className="text-md orange-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-320.98 -320)" />
                <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="carbonic-1">{Language.Debt}</span>
                <br />
                <span className="text-base green-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
];
export const Status = [
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-320.98 -320)" />
                <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="text-base green-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M352,679c-6.32-2.71-13.29-4.48-18.81-8.33-8.49-5.95-12.19-15.12-12.2-25.56q0-78.71,0-157.41c0-20.79,13.55-34.78,34.35-35.44.83,0,1.66,0,2.49,0q142.17,0,284.33,0c17.75,0,30.43,8.26,35.12,23.18a38,38,0,0,1,1.66,11.26q.16,78.2.07,156.41c0,19.13-10.45,31.72-29.17,35.17A11,11,0,0,0,648,679Zm26.21-29.2H621.8c5-19.45,11.19-25.61,28.54-28.24V509.38c-17.19-1.92-26.34-11.72-28.42-28.18H378.2c-4.9,19.39-11.21,25.65-28.54,28.24V621.57C366.91,624.12,373.24,630.4,378.21,649.8Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M502,320c3.77,2.59,8,4.72,11.22,7.84,11.52,11.11,22.75,22.5,34,33.88,6.37,6.44,6.65,15,.89,20.95s-14.43,5.74-20.94-.46c-2.77-2.64-5.55-5.3-8.1-8.16-1.19-1.34-1.82-3.17-2.7-4.78l-2.13,1.47V377c0,14.17.07,28.34-.06,42.5-.08,8.3-6.12,14.46-13.93,14.62-8,.16-14.28-6.12-14.43-14.63-.14-8.16-.05-16.33-.06-24.5V370.15a36.9,36.9,0,0,0-3.35,2.54c-3.59,3.48-7,7.17-10.69,10.51a14.1,14.1,0,0,1-19.47-.18,14.31,14.31,0,0,1-1.16-19.47,41.88,41.88,0,0,1,3.43-3.63c11.52-11.57,23-23.23,34.68-34.6,2.4-2.33,5.84-3.58,8.8-5.32Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,565.61c.29-17.62,4.43-34.06,16.19-47.65a53.39,53.39,0,0,1,87.72,9.68c12.74,23.45,13.11,47.92,1.92,72-9.61,20.7-26.47,32-49.48,31.84-23.46-.19-39.75-12.38-49.6-33.38C445.2,587.83,443.49,576.86,443.26,565.61Zm84.73-.07c-.11-10-2.23-19.49-8.26-27.69a24.54,24.54,0,0,0-39.72.33c-10.61,14.66-10.62,39.94,0,54.58a24.52,24.52,0,0,0,40,0C525.82,584.69,527.86,575.38,528,565.54Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M386.75,546.54a19,19,0,1,1-18.85,19.22A19,19,0,0,1,386.75,546.54Z" transform="translate(-320.98 -320)" />
                <path d="M613.07,584.48a19,19,0,1,1,19-19A19,19,0,0,1,613.07,584.48Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="carbonic-1">{Language.Credit}</span>
                <br />
                <span className="text-md orange-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-320.98 -320)" />
                <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="carbonic-1">{Language.Debt}</span>
                <br />
                <span className="text-base green-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
];

export const ConclusionSvg = () => (
    <svg height={22} className="tint-1 px-2 mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 378">
        <path
            d="M628,689H413a26.13,26.13,0,0,1,1.51-5c6.93-12.89,13.8-25.81,21-38.53a61.85,61.85,0,0,0,8-31.49c-.16-32.82-.06-65.64-.06-98.46v-6.34h6c18,0,36,.05,54,0,7.24,0,12.09-3.12,14.29-8.78,3.76-9.67-3.1-18.9-14.41-19-18-.15-36,0-54,0h-5.5c-.47-9.66-.92-18.76-1.39-28.45H448q73.47,0,146.94-.05a30.52,30.52,0,0,0,8.85-1.09c5.79-1.77,9.54-7.93,9.07-14-.44-5.82-5-11.3-10.69-12.37a43.16,43.16,0,0,0-8-.39q-81.47,0-162.93.05a11.29,11.29,0,0,1-8.39-3.29c-8.51-7.84-18.49-13.55-30-14.47-14.4-1.14-28.92-1-43.39-1.19-12.58-.17-15.73-2.3-19.46-14V368c.27-1.43.64-2.85.79-4.3,2.37-23.26,14.46-39.47,36-48.19C373.1,313,380.24,312.45,387,311H614a24.13,24.13,0,0,0,3.32.76c22.26,2,38,13.41,47.48,33.34,2.51,5.26,3.51,11.25,5.2,16.9V651a8.27,8.27,0,0,0-.79,1.75c-3.61,18.62-14.75,30.1-33,34.76C633.52,688.2,630.74,688.51,628,689ZM563.86,340.32c-1.54-.11-2.2-.21-2.86-.21q-87,0-174,0c-18.79,0-31.88,18-27.19,36.84H556.47C556.15,364.23,557,352,563.86,340.32ZM622.3,556.49a37.44,37.44,0,1,0-37.38,37.41A37.14,37.14,0,0,0,622.3,556.49Zm-74.84,54.68c0,12.39-.42,23.87.13,35.3.51,10.85,10.89,16.41,20.57,11.51a131.94,131.94,0,0,0,13-7.38c2.8-1.84,4.92-1.68,7.66.09a128.76,128.76,0,0,0,13.44,7.6c8.16,4,18.73-.42,19.39-9,1-12.33.25-24.78.25-37.73C597.11,626.3,572.59,626.33,547.46,611.17Z"
            transform="translate(-330 -311)"
        />
        <path
            d="M383,689h-1c-7.19-1.3-11.33-5.91-14.6-12.21-7.2-13.87-15.22-27.32-22.24-41.28-2.69-5.35-5-11.59-5.05-17.45-.39-50.66-.35-101.32-.15-152,.05-13.41,5.77-24.59,16.53-32.68,13.87-10.42,29.15-12,44.67-4.45,16.22,7.88,23.88,21.72,23.92,39.57.12,49,0,98,.06,147a40.47,40.47,0,0,1-5.18,20c-7.56,13.69-15.21,27.32-22.41,41.2C394.29,683,390.17,687.66,383,689Z"
            transform="translate(-330 -311)"
        />
    </svg>
);
export const BusinessIcon = (
    <svg height={110} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 587.77 587.77">
        <g className="stroke-drop-shdow">
            <path d="M620.49,763.73a109.51,109.51,0,0,1-216,0h65.18a46.5,46.5,0,0,0,85.62,0Z" transform="translate(-218.74 -386)" />
        </g>
        <g className="stroke-drop-shdow">
            <circle style={{ fill: "none", strokeMiterlimit: 10, strokeWidth: 63 }} cx="291.52" cy="224.15" r="78" />
        </g>
    </svg>
);
export const Company = (
    <svg height={120} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 587.77 587.77">
        <g className="stroke-drop-shdow">
            <path d="M620.49,763.73a109.51,109.51,0,0,1-216,0h65.18a46.5,46.5,0,0,0,85.62,0Z" transform="translate(-218.74 -386)" />
        </g>
        <g className="stroke-drop-shdow">
            <circle style={{ fill: "none", strokeMiterlimit: 10, strokeWidth: 63 }} cx="291.52" cy="224.15" r="78" />
        </g>
    </svg>
);

export const TransactionsSvgs = [
    () => <></>,
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M352,679c-6.32-2.71-13.29-4.48-18.81-8.33-8.49-5.95-12.19-15.12-12.2-25.56q0-78.71,0-157.41c0-20.79,13.55-34.78,34.35-35.44.83,0,1.66,0,2.49,0q142.17,0,284.33,0c17.75,0,30.43,8.26,35.12,23.18a38,38,0,0,1,1.66,11.26q.16,78.2.07,156.41c0,19.13-10.45,31.72-29.17,35.17A11,11,0,0,0,648,679Zm26.21-29.2H621.8c5-19.45,11.19-25.61,28.54-28.24V509.38c-17.19-1.92-26.34-11.72-28.42-28.18H378.2c-4.9,19.39-11.21,25.65-28.54,28.24V621.57C366.91,624.12,373.24,630.4,378.21,649.8Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M502,320c3.77,2.59,8,4.72,11.22,7.84,11.52,11.11,22.75,22.5,34,33.88,6.37,6.44,6.65,15,.89,20.95s-14.43,5.74-20.94-.46c-2.77-2.64-5.55-5.3-8.1-8.16-1.19-1.34-1.82-3.17-2.7-4.78l-2.13,1.47V377c0,14.17.07,28.34-.06,42.5-.08,8.3-6.12,14.46-13.93,14.62-8,.16-14.28-6.12-14.43-14.63-.14-8.16-.05-16.33-.06-24.5V370.15a36.9,36.9,0,0,0-3.35,2.54c-3.59,3.48-7,7.17-10.69,10.51a14.1,14.1,0,0,1-19.47-.18,14.31,14.31,0,0,1-1.16-19.47,41.88,41.88,0,0,1,3.43-3.63c11.52-11.57,23-23.23,34.68-34.6,2.4-2.33,5.84-3.58,8.8-5.32Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,565.61c.29-17.62,4.43-34.06,16.19-47.65a53.39,53.39,0,0,1,87.72,9.68c12.74,23.45,13.11,47.92,1.92,72-9.61,20.7-26.47,32-49.48,31.84-23.46-.19-39.75-12.38-49.6-33.38C445.2,587.83,443.49,576.86,443.26,565.61Zm84.73-.07c-.11-10-2.23-19.49-8.26-27.69a24.54,24.54,0,0,0-39.72.33c-10.61,14.66-10.62,39.94,0,54.58a24.52,24.52,0,0,0,40,0C525.82,584.69,527.86,575.38,528,565.54Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M386.75,546.54a19,19,0,1,1-18.85,19.22A19,19,0,0,1,386.75,546.54Z" transform="translate(-320.98 -320)" />
                <path d="M613.07,584.48a19,19,0,1,1,19-19A19,19,0,0,1,613.07,584.48Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="carbonic-1">{Language.TheAmount}</span>
                <br />
                <span className="text-md orange-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
    ({ amount, currency }) => (
        <>
            <svg className="svg-icon-with-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358.04 359">
                <path
                    d="M648,320c6.32,2.71,13.29,4.48,18.81,8.33,8.49,5.95,12.19,15.12,12.19,25.56q0,78.71,0,157.41c0,20.79-13.55,34.78-34.35,35.44-.83,0-1.66,0-2.49,0q-142.17,0-284.33,0c-17.75,0-30.43-8.26-35.13-23.18a38.27,38.27,0,0,1-1.65-11.26q-.17-78.19-.07-156.41c0-19.13,10.45-31.72,29.17-35.17A11,11,0,0,0,352,320Zm2.23,169.61V377.39c-17.06-2.12-26.21-11.69-28.31-28.26H378.07c-2.12,16.8-11.51,26.36-28.29,28.27V489.62c17.11,2.06,26.15,11.74,28.31,28.25H621.94C624,501.27,633.17,491.7,650.23,489.61Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M498,679c-3.77-2.59-8-4.72-11.22-7.84-11.52-11.11-22.75-22.5-34-33.88-6.37-6.44-6.65-15-.89-20.95s14.43-5.74,20.94.46c2.77,2.64,5.51,5.34,8.12,8.14a38.39,38.39,0,0,1,3.08,4.43l1.73-1.1V622c0-14.17-.07-28.34.06-42.5.08-8.3,6.12-14.46,13.93-14.62,8-.16,14.36,6.12,14.42,14.63.13,16.15,0,32.31,0,49.35a37.05,37.05,0,0,0,3.38-2.56c3.59-3.48,7-7.17,10.69-10.51a14.1,14.1,0,0,1,19.47.18,14.31,14.31,0,0,1,1.16,19.47,43.34,43.34,0,0,1-3.42,3.63c-11.53,11.57-23,23.23-34.69,34.6C508.4,676,505,677.26,502,679Z"
                    transform="translate(-320.98 -320)"
                />
                <path
                    d="M443.26,433.43c.32-17.62,4.5-34,16.31-47.6a53.38,53.38,0,0,1,87.45,9.5c12.89,23.6,13.28,48.22,2,72.46-9.64,20.7-26.59,31.89-49.58,31.68-23.27-.21-39.44-12.36-49.31-33.08C445.19,456,443.43,444.86,443.26,433.43Zm28.75-.07c.09,10,2.19,19.49,8.2,27.7a24.55,24.55,0,0,0,39.72-.15c10.64-14.6,10.71-39.89.16-54.59a24.52,24.52,0,0,0-40-.21C474.22,414.2,472.16,423.51,472,433.36Z"
                    transform="translate(-320.98 -320)"
                />
                <path d="M387.2,452.46a19,19,0,1,1,18.9-19.17A19,19,0,0,1,387.2,452.46Z" transform="translate(-320.98 -320)" />
                <path d="M613,452.46a19,19,0,1,1,19.13-18.95A19,19,0,0,1,613,452.46Z" transform="translate(-320.98 -320)" />
            </svg>
            <p className="font-bold text-center py-2 text-sm">
                <span className="carbonic-1">{Language.Balance}</span>
                <br />
                <span className="text-base green-text">{amount}</span>
                <br />
                <span className="carbonic-3">{currency}</span>
            </p>
        </>
    ),
];
export const TheOpration = (
    <svg height={30} fill="#56e181" className="opration-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
        <rect width="785" height="785" rx="219.79" />
        <path d="M497,272.54c-4.34-3.46-7.33-6.05-10.53-8.37-36.3-26.4-76.77-35.78-121.08-29.28-12.55,1.84-22.45-5-24.21-16.95-1.65-11.25,6.3-20.79,18.63-22.55,58.82-8.36,111.56,5.6,158,42.68.67.53,1.4,1,3.19,2.24,0-6.34-.15-11.83,0-17.3.42-12.36,9.08-20.67,21-20.31,10.08.3,18.81,8.34,18.92,18.44q.39,36.44,0,72.87c-.12,10.14-8.74,18.26-19.57,18.4q-35.21.45-70.42,0a19.81,19.81,0,0,1-20-20.31c.26-11.07,9.12-19.44,20.78-19.53C479.62,272.49,487.49,272.54,497,272.54Z" />
        <path d="M287.68,511.45c21.45,19.66,46.34,31.72,74.54,37a164.43,164.43,0,0,0,55.85.58c11.79-1.83,21.71,5.13,23.57,16.55,1.82,11.15-5.7,21.15-17.54,22.89-56.34,8.31-107.55-4-153.33-38.05-2.64-2-5.23-4-8.9-6.84,0,6.56.22,12.08,0,17.59-.68,14.63-13.71,23.79-26.85,19a19.3,19.3,0,0,1-13.06-18.09q-.18-35.4,0-70.8c.06-11,8.13-19.51,19.41-19.67q35.49-.51,71,0A19.7,19.7,0,0,1,332,491.72c-.15,11.09-9,19.6-20.61,19.72C303.47,511.52,295.58,511.45,287.68,511.45Z" />
        <path d="M271.05,287.69a156.48,156.48,0,0,0-32,57.08c-7.51,24.13-8.93,48.7-5,73.62,1.89,12-5.25,22-17,23.7-11.14,1.64-20.9-6-22.62-18-8.51-58.65,5.26-111.35,42.4-157.81.64-.8,1.2-1.66,2.56-3.56-6.23,0-11.57.15-16.89,0-12.82-.44-21.23-9-20.81-21,.36-10.07,8.48-18.66,18.61-18.75q36.29-.34,72.58,0c10.26.09,18.6,8.41,18.76,19.21q.56,35.39,0,70.8a19.73,19.73,0,0,1-20.6,19.7c-11.15-.44-19.25-9.21-19.34-21-.06-7.57-.07-15.14-.12-22.71A7.06,7.06,0,0,0,271.05,287.69Z" />
        <path d="M512.18,496.08c20.17-23.57,32.56-50.33,37.14-80.67a163.6,163.6,0,0,0-.41-50c-1.73-11.11,5.38-21.41,15.89-23.13,11.5-1.88,21.81,5.18,23.58,16.73,7.92,51.75-2,99.74-31.08,143.53-4,6.11-8.6,11.88-13.59,18.73,6.56,0,12.31-.31,18,.06,15.24,1,24.09,15.6,17.52,28.74-3.43,6.87-9.14,10.9-16.81,11q-36,.32-72,0c-10.55-.09-18.95-8.45-19.09-19.45q-.48-35.4,0-70.8a19.72,19.72,0,0,1,20.26-19.51c11.1.19,19.56,9,19.68,20.65.09,7.74.1,15.49.17,23.23C511.41,495.33,511.6,495.44,512.18,496.08Z" />
    </svg>
);

export const TheNote = (
    <svg height={26} className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
        <path
            d="M669,380.5v238c-.27,1.28-.66,2.55-.8,3.84-2.56,23.23-19.09,41.33-41.93,45.81-2.74.54-5.51.91-8.27,1.35H380a29.84,29.84,0,0,0-3.34-.76c-20.16-2.3-34.65-12.67-42.79-31.15-2.61-5.93-3.3-12.7-4.87-19.09v-238c.28-1.44.65-2.87.84-4.32,2.73-20.55,13.78-34.84,32.87-42.58,5.42-2.2,11.51-2.78,17.29-4.1H618a28.33,28.33,0,0,0,3.35.75c23.39,2.42,41.34,18.51,46.18,41.49C668.14,374.63,668.52,377.58,669,380.5ZM499,424.31H466c-18.66,0-37.31-.11-56,.06-10.09.1-16.49,8.61-13.89,17.92,1.8,6.45,7.51,10.13,16,10.13q87,0,173.91,0a24.94,24.94,0,0,0,5-.36,13.88,13.88,0,0,0,11.44-14.28c-.29-8.08-6.3-13.42-15.48-13.43Q542.94,424.25,499,424.31Zm0,84.45h59.47c10.32,0,20.66.15,31-.16,7.2-.22,12.45-5.75,13-12.78a13.67,13.67,0,0,0-10.89-14.64,26.13,26.13,0,0,0-5.45-.49q-87,0-173.9,0a25,25,0,0,0-5.93.6,13.63,13.63,0,0,0-10.47,14.92c.75,7.69,6.46,12.5,15.3,12.52Q455,508.83,499,508.76ZM470.5,537.33v0h-6c-18.16,0-36.32-.09-54.47.06-8.73.07-14.75,6.31-14.43,14.45s6.42,13.55,15.46,13.58c20.82.08,41.64,0,62.46,0q28.25,0,56.47,0c6.09,0,11.14-1.87,14.2-7.49,5.21-9.57-1.38-20.46-12.73-20.57C511.14,537.19,490.82,537.33,470.5,537.33Z"
            transform="translate(-329 -329.5)"
        />
    </svg>
);
