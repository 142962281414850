import React, { useState } from "react";
import Form from "../../Components/Form";
import { useNavigate } from "react-router";
import Language from "../../Utils/Languages";
import TextInput from "../../Components/TextInput";
import IconCard from "../../ComponentsV2/IconCard";
import CheckBox from "../../ComponentsV2/CheckBox";
import Representatives from "../../API/Representative";
import PasswordInput from "../../Components/PasswordInput";
import { Permissions } from "../Components/RepsPermissions";
import Info from "../../NodeBuilder/Info";

const NewRepresentativeView = () => {
    const [firstName, setFirstName] = useState("");
    const [address, setAddress] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [repsPermissions, setRepsPermissions] = useState(Permissions);
    const [uiRefresh, setUiRefresh] = useState(false);

    let navigate = useNavigate();
    let business = JSON.parse(localStorage.getItem("business"));

    const Done = () => {
        Info({ title: "Done", content: "SuccessfullyRegistraed", type: "success" });
        navigate("/representatives");
    };

    const permissionPressed = (repsPermissions) => {
        repsPermissions.isPermitted = !repsPermissions.isPermitted;
        setUiRefresh(!uiRefresh);
    };

    const addRepresentative = () => {
        if (password !== passwordConfirm) {
            Info({ title: "Error", content: "PasswordsNotMatch", type: "error" });
            return;
        }

        let data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            password: password,
            passwordConfirm: passwordConfirm,
            address: address,
        };
        Representatives.addRepresentative(business.value, data, repsPermissions, Done);
    };

    return (
        <>
            <Form className="scrller-container scroller" submitText={Language.Registration} onSubmit={addRepresentative}>
                <p className=" pr-6 font-bold text-2xl carbonic">
                    {Language.AddNewReps} &#160; {business?.title}
                </p>
                <IconCard icon="RepsInfo">
                    <TextInput value={firstName} setValue={setFirstName} required placeholder="Name" />
                    <TextInput value={lastName} setValue={setLastName} required placeholder="NameTitle" />
                    <TextInput value={address} setValue={setAddress} required placeholder="Address" />
                    <TextInput value={phoneNumber} setValue={setPhoneNumber} required placeholder="PhoneNumber" />
                    <PasswordInput value={password} setValue={setPassword} placeholder="Password" />
                    <PasswordInput value={passwordConfirm} setValue={setPasswordConfirm} placeholder="PasswordConfirm" />
                </IconCard>
                <IconCard icon="RepsPermission">
                    {repsPermissions.map((permission) => (
                        <CheckBox key={permission.id} checked={permission.isPermitted} onClick={() => permissionPressed(permission)} title={permission.title} />
                    ))}
                </IconCard>
            </Form>
        </>
    );
};

export default NewRepresentativeView;
