import { SvgBorderPath } from "./BorderSvgs";

export const Checked = (
    <svg style={{ minHeight: 20, monWidth: 20, margin: 10 }} xmlns="http://www.w3.org/2000/svg" width="78.369px" height="78.369px" viewBox="0 0 78.369 78.369">
        <g>
            <path
                d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704
           c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704
           C78.477,17.894,78.477,18.586,78.049,19.015z"
            />
        </g>
    </svg>
);
export const Freeze = (
    <svg className="carbonic-card-svg h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.34 286.36">
        <path
            d="M499.94,356.82A143.18,143.18,0,1,1,356.83,500.18,143,143,0,0,1,499.94,356.82Zm0,21.25a121.93,121.93,0,1,0,122,122.08C622.05,432.82,567.28,378,499.91,378.07Z"
            transform="translate(-356.83 -356.82)"
        />
        <path
            d="M489.39,500q0-36.23,0-72.46c0-5.37.89-10.28,6.79-12.61a10.05,10.05,0,0,1,13.72,6.75,22.55,22.55,0,0,1,.67,5.43q0,72.95,0,145.9c0,5.24-1.12,9.9-6.78,12.1-5.9,2.3-12.17-.62-13.7-6.74a28.63,28.63,0,0,1-.69-6.92Q489.35,535.7,489.39,500Z"
            transform="translate(-356.83 -356.82)"
        />
    </svg>
);
export const Delete = (
    <svg fill="#dd3643" className="h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 358.15">
        <path
            d="M670,342v53c-1.43,3.3-2.44,6.89-4.38,9.86-5.46,8.34-13.71,10.65-23.32,10.64q-142.3-.18-284.6-.08c-1.83,0-3.85.37-5.46-.25-5-1.93-10.71-3.29-14.49-6.71S332.5,399.58,330,395V342c5.7-16.84,11.7-21.12,29.63-21.12q141.87,0,283.74,0a34.72,34.72,0,0,1,7.43.54C661.89,323.85,667.43,331.57,670,342Z"
            transform="translate(-330 -320.88)"
        />
        <path
            d="M349.44,443.66H650.9v5.63q0,83,0,165.94c0,37.23-26.67,63.79-63.91,63.79H411c-33.63,0-61.45-26.23-61.76-59.77-.54-57.81-.15-115.63-.15-173.44A18.28,18.28,0,0,1,349.44,443.66ZM500,472c-13.48,0-27,0-40.45,0-9.84,0-15.8,5.41-15.72,14,.06,8.28,6.21,13.92,15.5,13.94q40.71.09,81.41,0c9.32,0,15.41-5.63,15.47-14s-5.85-14-15.25-14C527.28,471.91,513.63,472,500,472Z"
            transform="translate(-330 -320.88)"
        />
    </svg>
);

export const IconsCard = {
    HavePermisionForStor: (
        <SvgBorderPath>
            <path
                fill="#f48114"
                d="M386.3,184.71h13.88c10.9,2.63,21.13,6.49,30.12,13.62q62.94,49.88,126.16,99.42c14.21,11.16,24.51,25,29.43,42.44,2,7,2.68,14.29,4,21.45V561.69a14.33,14.33,0,0,0-.76,2.17c-4.54,23.49-18.17,34.74-42.2,34.74q-154.08,0-308.15,0c-25.41,0-41.53-15.63-41.58-40.79q-.18-96.54,0-193.08c0-27.83,11.36-50.11,33.33-67.27,35.82-28,71.42-56.26,107.15-84.36C352.54,201.38,366.4,187.86,386.3,184.71Zm6.84,239.52c10.78,0,21.56.13,32.34,0,13.12-.2,22-9.17,22.09-22.28q.15-32,0-64.09c-.07-13-9.16-22.22-22.18-22.32q-32.06-.27-64.1,0c-13.32.1-22.34,9.35-22.39,22.7q-.12,31.77,0,63.52c0,13.39,9,22.32,22.47,22.49C372,424.34,382.55,424.24,393.14,424.23Zm-21.8,87.34V479.2c-.27-12.85-9-21.83-21.88-21.94q-32.37-.28-64.76,0c-13.15.11-22,9.17-22.09,22.36q-.18,31.78,0,63.59c.06,12.92,8.52,22.34,21.36,22.6q32.94.68,65.91,0c12.94-.25,21.33-9.52,21.45-22.45C371.42,532.77,371.34,522.17,371.34,511.57Zm98.37-54.37c-10.6,0-21.2-.09-31.8,0-14,.15-22.69,8.86-22.75,22.81q-.11,31.23,0,62.44c0,14.52,8.79,23.37,23.44,23.47q30.93.21,61.87,0c14.49-.1,23.38-9.14,23.43-23.57q.09-30.93,0-61.87c0-14.27-8.8-23.13-23-23.28C490.53,457.11,480.12,457.2,469.71,457.2Z"
            />
        </SvgBorderPath>
    ),
    Company: (
        <SvgBorderPath>
            <path
                className="svg-card"
                d="M508.68,569.85H271.82a12.8,12.8,0,0,0-2.78-1.27c-35.22-6.51-57.21-32.66-57.27-68.22,0-22,.07-44.08-.1-66.12,0-2.65-.47-6.24-2.18-7.79-14.7-13.37-23.35-30-27.68-49.13v-14.2c8.63-21.36,17.54-42.62,25.73-64.14a71.34,71.34,0,0,0,4.1-20.5c1.84-28.66,15.2-49.53,41.83-60.47,8.71-3.59,18.87-5,28.39-5.09,72.37-.41,144.74-.34,217.11-.18,37.37.09,66.54,24.47,69,59.82,1.06,15.2,5.7,28.57,11,42.19,5.42,13.94,11.53,27.66,16.22,41.84A53.26,53.26,0,0,1,597.5,378c-1.85,18.26-11.38,32.89-23.84,46a18.63,18.63,0,0,0-4.72,11.39c-.44,22.35-.08,44.71-.25,67.06a66.87,66.87,0,0,1-51.79,65.21C514.14,568.35,511.41,569.13,508.68,569.85ZM390.52,316.64c-45,0-90,.08-134.95-.12-5.5,0-8.49,1.36-10.33,6.92-4.45,13.44-8.93,27-14.71,39.85-4.52,10.07-2.18,18,4.71,25,4.9,4.94,10.6,9.62,16.81,12.65,20.15,9.84,42.21,3.79,60.21-15.6,11.86-12.77,25-12.89,36.61-.34,25.37,27.49,57.27,27.4,82.91-.22,10.5-11.32,22.61-11.52,34.77-1.74a195.9,195.9,0,0,0,26.61,18.08c21.5,12.12,48.94-2.52,57.34-20.87a18.86,18.86,0,0,0,.59-12.58c-4.81-14.71-10.75-29-15.67-43.71-1.92-5.72-4.76-7.5-10.74-7.46C480,316.75,435.24,316.64,390.52,316.64Z"
            />
        </SvgBorderPath>
    ),

    RepsInfo: (
        <SvgBorderPath>
            <path d="M221.82,472.7c.5-1.77,1-3.53,1.48-5.32,4.63-18.5,20.36-31.58,39.51-32.17,13.74-.42,27.5-.17,41.25-.18H516c26.09,0,40.4,11.35,46.54,36.79.08.33.44.59.66.88v27.13a47.73,47.73,0,0,0-1,4.83A85.07,85.07,0,0,1,542,551.05c-17.62,20.79-40.69,33.07-66.07,41.49-38,12.6-77,15.33-116.57,11-30.54-3.38-59.89-11.21-86.4-27.48-23.61-14.48-41.27-33.78-48.19-61.39-1.23-4.87-2-9.86-2.91-14.8Z" />
            <path d="M392.48,179.56A106.46,106.46,0,1,1,286.1,285.71,106.1,106.1,0,0,1,392.48,179.56Z" />
        </SvgBorderPath>
    ),
    RepsPermission: (
        <SvgBorderPath>
            <path d="M324.48,208.53c6.65,1.54,13.5,2.48,19.91,4.71,37.28,13,60.46,38.94,66.24,77.8,5.95,40-9.39,72-42.65,95.15-3.41,2.38-5.07,4.67-4.46,8.63,2,12.84-2.23,23.07-12.71,30.9-3.81,2.85-7.2,6.27-11.25,9.84,6.74,6.59,12.56,12.14,18.21,17.85,7.62,7.69,7.64,15.68,0,23.33-5.66,5.69-11.51,11.18-17.94,17.41,6.21,6,11.94,11.35,17.5,16.88,8.14,8.1,8.16,16,.13,24.06q-17.61,17.65-35.32,35.22c-8.33,8.27-16,8.2-24.47-.17-8.16-8.08-16.1-16.39-24.47-24.25-5-4.71-7-10-7-16.84.16-44.51,0-89,.18-133.52,0-4.64-1.49-7.17-5.34-9.81-25.7-17.59-40.23-41.9-42.93-73-.16-1.84-.55-3.67-.83-5.5v-4.11c.3-1.83.67-3.66.87-5.51,4.24-39.09,24.51-66.55,60.72-81.88,8.21-3.47,17.32-4.83,26-7.16ZM315,305.23a19.19,19.19,0,1,0-19.42-19A19.08,19.08,0,0,0,315,305.23Z" />
            <path d="M567.7,268.14a32.25,32.25,0,0,0-.87,3.45c-3.51,24.08-26.34,39-49.61,32.12-3.15-.93-5-.49-7.13,1.75-7.65,7.87-15.58,15.46-23.23,23.32a18.26,18.26,0,0,1-13.77,5.8c-8.59,0-17.17.13-25.75,0-9.88-.2-16.37-6.23-16.28-14.77s6.73-14.38,16.54-14.5c7.19-.09,15.88,2.27,21.24-.92,8.58-5.1,15.06-13.79,22.13-21.25.95-1,.61-3.73.12-5.45a38.82,38.82,0,0,1,61.84-41,37.49,37.49,0,0,1,13.88,24.93c.2,1.5.59,3,.89,4.46Z" />
            <path d="M567.7,518.9c-1,4.29-1.57,8.74-3.05,12.86a38.53,38.53,0,0,1-48.07,23.41c-20-6.43-31.43-27.33-25.48-47.32,1.06-3.58,1-6.11-2.16-8.32a22.73,22.73,0,0,1-2.94-2.87c-5.47-5.43-10.07-12.88-16.67-15.72s-15.1-1-22.77-1.24c-9-.26-15.41-6.25-15.5-14.5s6.26-14.48,15.26-14.64c9.27-.17,18.59-.58,27.79.21a22.59,22.59,0,0,1,12.22,5.28c8.45,7.49,16.08,15.9,24.27,23.71a7,7,0,0,0,5.6,1.64c24.95-6.72,46.78,7.19,50.69,32.5a25.64,25.64,0,0,0,.81,2.94Z" />
            <path d="M567.7,393.52c-.58,2.81-1,5.65-1.74,8.42-4.11,16-16.64,26.81-33.53,29.05-14.85,2-30.33-5.89-37.84-19.67-1.8-3.32-3.68-4.5-7.47-4.42-13.38.26-26.78.14-40.17.08-9.43-.05-15.89-6-15.88-14.5S437.53,378,447,378c13.74-.05,27.48-.1,41.21.05,2.94,0,4.48-.87,5.92-3.52,8.5-15.67,24.53-23.22,41.92-20,16,2.91,28.37,16.25,30.81,33.08.2,1.32.58,2.6.88,3.9Z" />
        </SvgBorderPath>
    ),
    ThisRepresentative: (
        <SvgBorderPath>
            <path
                className="reps"
                d="M221.82,472.7c.5-1.77,1-3.53,1.48-5.32,4.63-18.5,20.36-31.58,39.51-32.17,13.74-.42,27.5-.17,41.25-.18H516c26.09,0,40.4,11.35,46.54,36.79.08.33.44.59.66.88v27.13a47.73,47.73,0,0,0-1,4.83A85.07,85.07,0,0,1,542,551.05c-17.62,20.79-40.69,33.07-66.07,41.49-38,12.6-77,15.33-116.57,11-30.54-3.38-59.89-11.21-86.4-27.48-23.61-14.48-41.27-33.78-48.19-61.39-1.23-4.87-2-9.86-2.91-14.8Z"
            />
            <path className="reps" d="M392.48,179.56A106.46,106.46,0,1,1,286.1,285.71,106.1,106.1,0,0,1,392.48,179.56Z" />
        </SvgBorderPath>
    ),
    WorkWith: (
        <svg className="svg-card" height={36} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785 785">
            <path
                className="border"
                d="M565.21,45A174.81,174.81,0,0,1,740,219.79V565.21A174.81,174.81,0,0,1,565.21,740H219.79A174.81,174.81,0,0,1,45,565.21V219.79A174.81,174.81,0,0,1,219.79,45H565.21m0-45H219.79C98.4,0,0,98.4,0,219.79V565.21C0,686.6,98.4,785,219.79,785H565.21C686.6,785,785,686.6,785,565.21V219.79C785,98.4,686.6,0,565.21,0Z"
            />
            <path d="M163.46,543.4c2.76-5.64,4.74-11.86,8.44-16.77,5.71-7.56,14-11.37,23.65-11.4,29.68-.1,59.37-.42,89,.09,20.81.36,34.72,18.73,31.26,39.89-4.59,28.12-25.65,49.85-56,55.9-27.28,5.43-53,1.68-74.69-17.6a61.83,61.83,0,0,1-20.75-37.66,8.31,8.31,0,0,0-.94-2Z" />
            <path d="M392.85,264.46c12.92,0,25.91.84,38.75-.19,22.26-1.8,43.88,16.88,35.57,46.16C459,339.19,439,355.35,410.43,360.8c-26.85,5.11-51.93.69-72.95-18.14-13.19-11.82-20.7-26.77-21.15-44.69a32.61,32.61,0,0,1,32.54-33.48C363.53,264.34,378.19,264.46,392.85,264.46Z" />
            <path d="M545.34,515.14c13.11,0,26.27.69,39.33-.15,23.39-1.5,42.71,18.14,35.24,45.46-7.69,28.11-26.78,44.65-54.56,50.51s-54.24,1.81-76.07-18.39c-12.67-11.73-20-26.34-20.31-43.86-.34-18.72,13.51-33.07,32.41-33.49,14.65-.32,29.31-.06,44-.06Z" />
            <path d="M196.4,449.75a43.66,43.66,0,1,1,43.78,43.71A43.8,43.8,0,0,1,196.4,449.75Z" />
            <path d="M588.78,448.93c.67,23.64-18.39,43.65-42.36,44.51s-44.33-18.59-44.91-42.58,18.28-43.9,42.69-44.77C567.94,405.24,588.09,424.61,588.78,448.93Z" />
            <path d="M392.35,242.63a43.53,43.53,0,1,1,44-43.42A43.66,43.66,0,0,1,392.35,242.63Z" />
            <path d="M438.91,573.17c4.85,10.17,9.37,19.66,14.22,29.82q-60.35,20.49-121.16.09c4.8-10.08,9.4-19.71,14.23-29.84A151.69,151.69,0,0,0,438.91,573.17Z" />
            <path d="M214.64,376.05c12-40.53,35.4-74,71.6-99.84-.91,6.74-2.23,12.2-2.25,17.67,0,7.07,1.14,14.14,1.45,21.23.09,2-.4,4.66-1.69,6a155.69,155.69,0,0,0-32.41,49.37c-.6,1.46-2.3,3.37-3.62,3.48C236.78,374.85,225.8,375.39,214.64,376.05Z" />
            <path d="M570.35,375.92c-11.18-.6-22.18-1.1-33.16-1.92-1.19-.09-2.76-1.82-3.29-3.13a153.85,153.85,0,0,0-32.68-49.95c-1.05-1.08-2.09-3.11-1.81-4.41a91.22,91.22,0,0,0,0-39.16C535,302.17,558.35,335.55,570.35,375.92Z" />
        </svg>
    ),
};
