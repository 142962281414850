import React from "react";
import { Select } from "../SVGs/OtherSvgs";

const ListButton = ({ title, onClick, canSelect = true }) => {
    const className = "flex flex-row flex-grow items-center cursor-pointer carbonic-card-2 px-4 py-3 rounded-2 shadow-1 my-3 " + (canSelect ? "" : "no-select");
    return (
        <div onClick={canSelect ? onClick : () => {}} className={className}>
            <p className="font-bold carbonic flex-grow">{title}</p>
            {Select}
        </div>
    );
};

export default ListButton;
