import moment from "moment";
import { Options } from "../Settings";

export const GetFormatedDate = ({ date }) => {
    let long = moment(date).locale("en").format("YYYY-MM-DD");
    let short = moment(date).locale("en").format("hh:mm A");
    return { short, long };
};
export const TimeToString = (date) => {
    const momentLocale = Options.language.value === "ar" ? "ar" : "en";
    return moment(date)
        .locale(momentLocale)
        .fromNow()
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632);
};
