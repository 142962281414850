import React from "react";
import MaterialIcon from "./MaterialIcon";

const GetRating = ({ onRateChange, rating, starSize, className }) => {
    const rate = [1, 2, 3, 4, 5];
    const getRating = () =>
        rate.map((index) => (
            <div
                key={index}
                className="cursor-pointer"
                onClick={() => {
                    onRateChange && onRateChange(index);
                }}>
                <MaterialIcon icon="star" className={index <= rating ? `text-yellow-400 text-${starSize}` : `text-gray-300 text-${starSize}`} />
            </div>
        ));
    return <div className={className + " flex flex-row"}>{getRating()}</div>;
};

export default GetRating;
