import React from "react";
import BusinessDailyReportCard from "./Components/BusinessDailyReportCard";
import SimpaleBuilder from "../PageBuilder/SimpaleBuilder";
import HomeUtils from "./HomeUtils";
import TotalCard from "../SalesReport/Components/TotalCard";

const HomeView = () => {
    return (
        <SimpaleBuilder url="v3/nets" apiId="sales" id="home" itemBuilder={BusinessDailyReportCard} onResult={HomeUtils.onResult} headerBuilder={TotalCard} />
    );
};
export default HomeView;
