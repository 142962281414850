import React from "react";
import { Route, Routes } from "react-router-dom";
const ItemsView = React.lazy(() => import("../Items/ItemsView"));
const NewTicketView = React.lazy(() => import("../Tickets/NewTicketView"));
const TicketView = React.lazy(() => import("../Tickets/TicketView"));
const BusinessesView = React.lazy(() => import("../Business/BusinessesView"));
const BusinessView = React.lazy(() => import("../Business/BusinessView"));
const BusinessTransactionsView = React.lazy(() => import("../SalesReport/BusinessTransactionsView"));
const TransactionsView = React.lazy(() => import("../SalesReport/TransactionsView"));
const NewRepresentativeView = React.lazy(() => import("../Representatives/Views/NewRepresentativeView"));
const RepresentativesView = React.lazy(() => import("../Representatives/Views/RepresentativesView"));
const EditRepresentativeView = React.lazy(() => import("../Representatives/Views/EditRepresentativeView"));
const ChangePasswordView = React.lazy(() => import("../Owner/Views/ChangePasswordView"));
const RestaurantItemsView = React.lazy(() => import("../RestaurantMenu/RestaurantItemsView"));
const NotificationsView = React.lazy(() => import("../Notifications/NotificationsView"));
const SettingsView = React.lazy(() => import("../Settings/SettingsView"));
const AccountSettingsView = React.lazy(() => import("../Settings/AccountSettingsView"));
const InvoicesView = React.lazy(() => import("../Invoices/InvoicesView"));
const InvoiceView = React.lazy(() => import("../Invoices/InvoiceView"));
const OwnerTicketsView = React.lazy(() => import("../Tickets/OwnerTicketsViewOld"));
const BottomBar = React.lazy(() => import("../Navigator/BottomBar"));
const MenuView = React.lazy(() => import("../Menu/MenuView"));
const AccountsView = React.lazy(() => import("../Accounts/AccountsView"));
const HomeView = React.lazy(() => import("../Home/HomeView"));
const BusinessesDaileyReportView = React.lazy(() => import("../Home/BusinessesDaileyReportView"));
const TopBar = React.lazy(() => import("../ComponentsV2/TopBar"));
const RedirectView = React.lazy(() => import("./RedirectView"));
const CustomersView = React.lazy(() => import("../SalesReport/CustomersView"));
const AboutView = React.lazy(() => import("../About/AboutView"));
const AlreadyLoggedInView = React.lazy(() => import("../Auth/Views/AlreadyLoggedInView"));

const LazyAuthedRoutes = () => {
    return (
        <React.Suspense>
            <TopBar />
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/items" element={<ItemsView />} />
                <Route path="/menu/" element={<MenuView />} />
                <Route path="/tickets/new" element={<NewTicketView />} />
                <Route path="/tickets/:id/" element={<TicketView />} />
                <Route path="/tickets/" element={<OwnerTicketsView />} />
                <Route path="/businesses/" element={<BusinessesView />} />
                <Route path="/businesses/:id/" element={<BusinessView />} />
                <Route path="/businesses/:id/tickets/new" element={<NewTicketView />} />
                <Route path="/businesses/:businessId/transactions/:opType" element={<BusinessTransactionsView />} />
                <Route path="/businesses/daily/report" element={<BusinessesDaileyReportView />} />
                <Route path="/accounts/" element={<AccountsView />} />
                <Route path="/customers/" element={<CustomersView />} />
                <Route path="/transactions/:accountId/:currencyId" element={<TransactionsView />} />
                <Route path="/representatives/new" element={<NewRepresentativeView />} />
                <Route path="/representatives/" element={<RepresentativesView />} />
                <Route path="/representatives/:id/" element={<EditRepresentativeView />} />
                <Route path="/password/change" element={<ChangePasswordView />} />
                <Route path="/restaurant/menu/" element={<RestaurantItemsView />} />
                <Route path="/notifications/" element={<NotificationsView />} />
                <Route path="/settings/" element={<SettingsView />} />
                <Route path="/settings/account/" element={<AccountSettingsView />} />
                <Route path="/invoices" element={<InvoicesView />} />
                <Route path="/invoices/:id" element={<InvoiceView />} />
                <Route path="/about/" element={<AboutView />} />
                <Route path="/alreadyloggedin/:token/" element={<AlreadyLoggedInView />} />
                <Route path="*" element={<RedirectView />} />
            </Routes>
            <BottomBar />
        </React.Suspense>
    );
};

export default LazyAuthedRoutes;
