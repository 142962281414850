import React from "react";

const RedButton = ({ onClick, title, Extra }) => {
    return (
        <button onClick={onClick} className={`m-2 red-bg text-white text-center py-2 font-bold rounded-1 ${Extra}`}>
            {title}
        </button>
    );
};

export default RedButton;
