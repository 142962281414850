import Ticket from "../API/Ticket";
import Form from "../Components/Form";
import IconCard from "./Components/IconCard";
import TextInput from "../Components/TextInput";
import ListButton from "../ComponentsV2/ListButton";
import AudioRecorder from "audio-recorder-polyfill";
import DialogCombo from "../ComponentsV2/DialogCombo";
import MaterialIcon from "../Components/MaterialIcon";
import FormTextArea from "../Components/FormTextArea";
import React, { useState, useEffect, useRef } from "react";
import LoaderComponent from "../Components/LoaderComponent";
import mpegEncoder from "audio-recorder-polyfill/mpeg-encoder";
import Scroller from "../ComponentsV2/Scroller";
import useLocalStorage from "@rehooks/local-storage";
import Info from "../NodeBuilder/Info";
import { getBusinesses } from "../Utils/Utils";
let businesses = [];
const NewTicketView = () => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [newFiles, setNewFiles] = useState([]);
    const [imagesToPreview, setImagesToPreview] = useState([]);
    const inputFileRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [canRecord, setCanRecord] = useState(true);
    const [recordSrc, setRecordSrc] = useState(null);

    const [dialogInfoHolder, setDialogInfoHolder] = useState("");

    const [recorder, setRecorder] = useState(null);

    const [selectedBusiness, setSelectedBusiness] = useState(businesses.find((b) => b.id === localStorage.getItem("selected-business")) || businesses[0]);

    const filesChanged = (e) => {
        let files = e.target.files;
        let newFilesToSet = [];
        let newImagesToPreview = [];
        for (let i = 0; i < files.length; i++) {
            let pic = files[i];
            if (pic === undefined) continue;
            newFilesToSet.push(pic);
            if (imagesToPreview.findIndex((x) => x.name === pic.name) > -1) continue;
            newImagesToPreview.push({ name: pic.name, data: URL.createObjectURL(pic) });
        }
        setNewFiles(newImagesToPreview);
        setImagesToPreview(newImagesToPreview);
    };

    const removeImage = (image) => {
        setImagesToPreview(imagesToPreview.filter((x) => x !== image));
        setNewFiles(newFiles.filter((x) => x.name !== image.name));
    };

    const addTicket = async () => {
        if (!selectedBusiness) return;
        setIsLoading(true);
        let blob = !!recordSrc
            ? await fetch(recordSrc)
                  .then((r) => r.blob())
                  .then((bfile) => new File([bfile], "audio.mp3", { type: "audio/mpeg" }))
            : null;
        const data = { title, text, businessId: selectedBusiness?.id, photos: newFiles };
        if (blob) data["audio"] = blob;
        Ticket.postTicket(data)
            .then(() => {
                Info({ title: "Saved", type: "success" });
                window.history.back();
            })
            .finally(() => setIsLoading(false));
    };

    const choosePhotosClicked = (e) => {
        e.preventDefault();
        inputFileRef.current.click();
        return false;
    };

    const toggleRecord = (e) => {
        e?.preventDefault();
        setIsRecording(!isRecording);
        return false;
    };

    const startRecording = () => {
        try {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                setRecorder(new MediaRecorder(stream));
                setCanRecord(true);
            });
        } catch {
            setCanRecord(false);
        }
    };
    const stopRecording = () => {
        if (!recorder) return;
        recorder.stop();
        recorder.stream.getTracks().forEach((i) => i.stop());
    };

    const init = async () => {
        AudioRecorder.encoder = mpegEncoder;
        AudioRecorder.prototype.mimeType = "audio/mpeg";
        window.MediaRecorder = AudioRecorder;
        businesses = await getBusinesses(false);
        setSelectedBusiness(businesses.find((b) => b.id === localStorage.getItem("selected-business")) || businesses[0]);
    };

    useEffect(() => {
        if (!recorder) return;
        recorder.addEventListener("dataavailable", (e) => {
            setRecordSrc(URL.createObjectURL(e.data));
        });
        recorder.start();
    }, [recorder]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (isRecording) startRecording();
        else stopRecording();
    }, [isRecording]);

    const ShowBusinessCompo = () => {
        setDialogInfoHolder({ items: businesses, onClick: (item) => setSelectedBusiness(item) });
    };
    return isLoading ? (
        <LoaderComponent />
    ) : (
        <Scroller>
            <IconCard icon="Company">
                <ListButton canSelect={businesses?.length > 0} onClick={ShowBusinessCompo} title={selectedBusiness?.title} />
            </IconCard>
            <IconCard icon="TicketInfo">
                <Form close={() => window.history.back()} onSubmit={addTicket} flexClassName="pb-4">
                    <TextInput placeholder="" value={title} required setValue={setTitle} />

                    <FormTextArea placeholder="Note" value={text} setValue={setText} className="text-input m-2" />
                    <div className="flex flex-row items-center">
                        <button className="mr-2" onClick={choosePhotosClicked}>
                            <MaterialIcon icon="photo" className="text-gray-600 text-4xl" />
                        </button>
                        {/* {canRecord && (
                            <button className="mr-2" onClick={toggleRecord}>
                                <MaterialIcon
                                    icon="mic"
                                    className={`${isRecording ? "record-animation" : recordSrc ? "green-text" : "text-gray-600"} text-4xl`}
                                />
                            </button>
                        )} */}
                    </div>
                    <input className="hidden" type="file" accept="image/*" multiple={true} ref={inputFileRef} onChange={filesChanged} />
                    <div className="grid gap-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mt-4">
                        {imagesToPreview.map((image, index) => (
                            <div key={index} className="relative mb-5 max-w-max">
                                <p
                                    onClick={() => removeImage(image)}
                                    className="font-bold red-bg rounded-full absolute -top-1 -right-1  z-10  text-center shadow-1 text-white cursor-pointer"
                                    style={{ width: 28, paddingTop: 4 }}>
                                    x
                                </p>
                                <img src={image.data} style={{ maxHeight: 200 }} className="rounded-2 object-contain" alt="document"></img>
                            </div>
                        ))}
                    </div>
                    {recordSrc && (
                        <div className="flex flex-row items-center">
                            <audio src={recordSrc} controls />
                            <button
                                onClick={() => {
                                    setRecordSrc(false);
                                }}
                                className="red-text text-2xl px-2">
                                X
                            </button>
                        </div>
                    )}
                    {dialogInfoHolder && <DialogCombo dialogInfoHolder={dialogInfoHolder} close={() => setDialogInfoHolder(false)} />}
                </Form>
            </IconCard>
        </Scroller>
    );
};

export default NewTicketView;
