import React from "react";
import ListLoader from "./ListLoader";
// const toTop = () => testRef.current.scrollTo({ top: 0, behavior: "smooth" });

const Scroller = ({ fetchData, canLoadMore, children, className = "" }) => {
    const scrollerClass = "scrller-container scroller " + className;
    const itemsScroll = ({ target }) => {
        if (canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 250) {
            canLoadMore = false;
            fetchData(false);
        }
    };

    return (
        <div onScroll={itemsScroll} className={scrollerClass}>
            {/* <div className="carbonic-card carbonic p-2 rounded-md shadow-1 fixed bottom-32 right-56" onClick={toTop}>To Top</div> */}
            {children}
            {canLoadMore && <ListLoader />}
            <span className="col-span-full h-32" />
        </div>
    );
};

export default Scroller;
