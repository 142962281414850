import React, { useEffect, useState } from "react";
import Language from "../../Utils/Languages";

const TimePicker = ({ value, onChange, ...props }) => {
    const hourOptions = new Array(12).fill(0).map((_, i) => i + 1);
    const minuteOptions = new Array(60).fill(0).map((_, i) => i);

    const getInitialHoursFromValue = () => {
        let split = String(value).split(":");
        let hoursFromValue = parseInt(split[0]);
        hoursFromValue -= hoursFromValue > 12 ? 12 : 0;
        return hoursFromValue;
    };

    const getInitialMinutesFromValue = () => {
        let split = String(value).split(":");
        return parseInt(split[1]);
    };

    const getInitialPartOfDayFromValue = () => {
        let split = String(value).split(":");
        let hoursFromValue = parseInt(split[0]);
        return hoursFromValue >= 12 ? "PM" : "AM";
    };

    const [hours, setHours] = useState(getInitialHoursFromValue());
    const [minutes, setMinutes] = useState(getInitialMinutesFromValue());
    const [partOfDay, setPartOfDay] = useState(getInitialPartOfDayFromValue());

    const getHoursIn24Hour = () => {
        let temp = parseInt(hours);
        if (temp < 12) temp += partOfDay === "PM" ? 12 : 0;
        else temp = partOfDay === "PM" ? 12 : 0;
        return temp;
    };

    useEffect(() => {
        let hoursToSet = getHoursIn24Hour();
        onChange(`${String(hoursToSet).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:00`);
    }, [hours, minutes, partOfDay]);

    return (
        <div className={`flex flex-row carbonic-card-1 ${props.className || ""}`}>
            <select className="w-20 rounded border carbonic-card-1  items-center text-center" value={partOfDay} onChange={(e) => setPartOfDay(e.target.value)}>
                <option value="AM">{Language.Am}</option>
                <option value="PM">{Language.Pm}</option>
            </select>
            <select
                className="w-18 rounded border carbonic-card-1  items-center text-center mx-2 px-4"
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}>
                {minuteOptions.map((x) => (
                    <option key={x} value={x}>
                        {String(x).padStart(2, "0")}
                    </option>
                ))}
            </select>
            <p className="self-center font-bold ml-2">:</p>
            <select className="w-18 rounded border carbonic-card-1  items-center text-center px-4" value={hours} onChange={(e) => setHours(e.target.value)}>
                {hourOptions.map((x) => (
                    <option key={x} value={x}>
                        {String(x).padStart(2, "0")}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TimePicker;
