import React from "react";
import AudioPlayer from "./AudioPlayer";
import ImageViewer from "./ImageViewer";

const MessagesHolder = {
    owner: ({ item }) => (
        <>
            <p className="carbonic">{item.text}</p>
            <span className="font-bold carbonic-3 text-sm pt-2">{item.createdAt}</span>
        </>
    ),

    replier: ({ item }) => (
        <>
            <p className="carbonci text-left">{item.replyByFullName}</p>
            <p className="carbonic">{item.text}</p>
            <p className="font-bold carbonic-3 text-sm pt-2">{item.createdAt}</p>
        </>
    ),
    ownerImage: ({ item }) => <ImageViewer url={item.url} />,
    ownerAudio: ({ item }) => <AudioPlayer url={item.url} />,
};

const RepliessCard = ({ item }) => {
    const Body = MessagesHolder[item.replierType];
    return (
        <>
            <div className={`chat chat-container-${item.replierType}`}>
                <Body item={item} />
            </div>
            {item?.displayDay && <p className="carbonic-card rounded-5 carbonic self-center px-6 py-2 shadow-1 font-bold">{item?.displayDay}</p>}
        </>
    );
};

export default RepliessCard;
