import React from "react";
import PageBuilder from "../PageBuilder/PageBuilder";
import NotificationCard from "./Components/NotificationCard";
import NotificationsUtils from "./NotificationsUtils";

const NotificationsView = () => {
    return (
        <PageBuilder
            itemBuilder={NotificationCard}
            id="notifications"
            url="v1/business/"
            formBuilderClass="form-container flex flex-col"
            queryChanged={NotificationsUtils.queryChanged}
            queryParams={NotificationsUtils.queryParams}
            onResult={NotificationsUtils.onResult}
            hasPopup
        />
    );
};

export default NotificationsView;
