import Chat from "../Chat/Chat";
import Ticket from "../API/Ticket";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CloseTicketHolder from "./Components/CloseTicketHolder";

let canLoadMore = false;
const TicketView = () => {
    const [ticket, setTicket] = useState({ state: 0 });
    const [repliess, setRepliess] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    const deleteReply = (replyId) => Ticket.deleteReply(replyId).then(() => setRepliess(repliess.filter((x) => x.id !== replyId)));
    const fetchTicket = () => Ticket.getTicket(params.id, setTicket);
    const closeTicket = (rating, description) => {
        Ticket.close(params.id, rating, description);
    };

    useEffect(() => {
        fetchTicket(true);
    }, []);

    const ticketOpen = ticket.latestState === 0;
    const fetchRepliess = async (clear) => {
        let newRepliess = await Ticket.getReplies(clear, ticket.id, repliess, ticket?.attachments || []);
        canLoadMore = newRepliess.length >= 26;
        newRepliess = clear ? newRepliess : [...newRepliess, ...repliess];
        setRepliess(newRepliess);
        setIsLoading(false);
    };

    useEffect(() => {
        if (ticket) fetchRepliess(true);
    }, [ticket]);

    const sendReply = (reply, setReply) => {
        if (!reply) return;
        setIsLoading(true);
        setReply("");
        let owner = JSON.parse(localStorage.getItem("owner"));
        let data = {
            TicketId: ticket.id,
            ReplyBy: owner.id,
            ReplyByFullName: `${owner.firstName} ${owner.lastName}`,
            Text: reply,
        };

        const onComplate = (newData) => {
            setRepliess(JSON.parse(JSON.stringify(newData)));
            setIsLoading(false);
        };
        const onError = (error) => {
            setIsLoading(false);
        };
        Ticket.reply(data, repliess, onComplate, onError);
    };

    return (
        <Chat
            ticket={ticket}
            repliess={repliess}
            isLoading={isLoading}
            canReply={ticketOpen}
            sendReply={sendReply}
            canLoadMore={canLoadMore}
            fetchRepliess={fetchRepliess}>
            {ticketOpen && <CloseTicketHolder onComplate={closeTicket} />}
        </Chat>
    );
};

export default TicketView;
