import Language from "../../Utils/Languages";

export const Permissions = [
    {
        id: 0,
        isPermitted: false,
        key: "CAN_GIVE_DISCOUNTS",
        title: Language.GiveDiscountPermission,
    },
    {
        id: 1,
        isPermitted: false,
        key: "CAN_HAVE_UNPAID_AMOUNT",
        title: Language.UnpaidAmount,
    },
    {
        id: 2,
        isPermitted: false,
        key: "CAN_SELL_BEYOND_50METERS",
        title: Language.PermissionToSaleInMoreThen50Meters,
    },
    {
        id: 3,
        isPermitted: false,
        key: "CAN_USE_NON_GENERAL_PRICES",
        title: Language.CanUseNonGeneralPrices,
    },
    {
        id: 4,
        isPermitted: false,
        key: "CAN_SEND_WITHOUT_LOCATION",
        title: Language.CanSendWithoutLocation,
    },
    {
        id: 5,
        isPermitted: false,
        key: "CAN_SELL_OUT_OF_STOCK",
        title: Language.CanSellOutOfStock,
    },
];

export const PermissionsTitles = {
    CAN_GIVE_DISCOUNTS: Language.GiveDiscountPermission,
    CAN_SELL_BEYOND_50METERS: Language.PermissionToSaleInMoreThen50Meters,
    CAN_HAVE_UNPAID_AMOUNT: Language.UnpaidAmount,
    CAN_USE_NON_GENERAL_PRICES: Language.CanUseNonGeneralPrices,
    CAN_SEND_WITHOUT_LOCATION: Language.CanSendWithoutLocation,
    CAN_SELL_OUT_OF_STOCK: Language.CanSellOutOfStock,
};
