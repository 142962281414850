import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Eye } from "../SVGs/OtherSvgs";
import Language from "../Utils/Languages";

const PasswordInput = ({ value, setValue, placeholder, defaultValue }) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    return (
        <div className="text-input flex flex-row m-2">
            {isMobile ? (
                <>
                    <input
                        value={value}
                        defaultValue={defaultValue}
                        required={true}
                        name={placeholder}
                        placeholder={Language[placeholder]}
                        autoComplete="current-password"
                        type={!showPassword ? "password" : "text"}
                        onChange={({ target }) => setValue(target.value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632))}
                        className="font-bold flex-grow carbonic focus:outline-none"
                    />
                    <div onClick={toggleShowPassword}>{Eye}</div>
                </>
            ) : (
                <input
                    value={value}
                    required={true}
                    defaultValue={defaultValue}
                    name={placeholder}
                    type="password"
                    autoComplete="current-password"
                    placeholder={Language[placeholder]}
                    onChange={({ target }) => setValue(target.value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632))}
                    className="font-bold flex-grow carbonic focus:outline-none"
                />
            )}
        </div>
    );
};

export default PasswordInput;
