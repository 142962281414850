import React from "react";
import DateFormate from "../../Components/DateFormate";
import { SvgBorderIcons, SvgBorderPath } from "../../SVGs/BorderSvgs";
import Language from "../../Utils/Languages";

const TransactionsCard = ({ item }) => {
    return (
        <div key={item.id} className="carbonic-card overflow-hidden shadow-1 rounded-3 ">
            <div className="p-4">
                <div className="flex flex-row items-center ">
                    <div className="flex flex-row items-center">
                        <SvgBorderPath children={SvgBorderIcons.TheAmount} />
                        <p className="carbonic font-bold p-2 text-sm">
                            <span className="carbonic-1 ">{Language.TheAmount}</span>
                            <br />
                            <span className={`text-base ml-2`}>{item.amount}</span>
                        </p>
                    </div>
                </div>
                <div className="flex flex-row items-center font-bold carbonic-2">
                    <SvgBorderPath children={SvgBorderIcons.Note} />
                    <span className="px-3"> {item.description[0] || "..."}</span>
                    <span>{item.description[1] || "..."}</span>
                </div>
            </div>
            <DateFormate date={item.date} />
        </div>
    );
};
export default TransactionsCard;
// transactions.map((bd) => (
//     <div key={bd.id} className="justify-evenly rounded-2xl shadow-lg ">
//         <RowFlex className="">
//             <p className="text-center px-4 py-2 border-l border-gray-1 font-bold text-gray-4 text-xl">{Language.TheAmount}</p>
//             <p className={`text-center flex-grow font-bold py-2 text-xl ${bd.amount >= 0 ? "text-green-6" : "text-pink-9"}`}>
//                 <span>{formatter.format(bd.amount)}</span>
//                 &nbsp;
//                 <span>{bd.currencyId === 1 ? "د.ع" : "$"}</span>
//             </p>
//         </RowFlex>
//         <div className={`rounded-b-xl ${bg}`}>
//             <p className="text-gray-4  px-2 py-1">
//                 <span className="font-bold">{bd.description[0] || "..."} : </span>
//                 <span className="">{bd.description[1] || "..."}</span>
//             </p>
//             <Date date={bd.date} className={`px-2 py-1 float-left rounded-tr-2xl rounded-bl-2xl text-gray-4 font-semibold ${dateBg}`} />
//         </div>
//     </div>
// ))
