import React from "react";
import { Link } from "react-router-dom";
import Language from "../../Utils/Languages";
import { Company } from "../../SVGs/BusinessesSvgs";
import { SvgBorder } from "../../SVGs/BorderSvgs";
import ChangeTitle from "../../Utils/ChangeTitle";

const BusinessCard = ({ item }) => {
    const business = item.business;
    return (
        <Link onClick={() => ChangeTitle(business.title)} className="fade-in-card rounded-2xl shadow-1 p-2 col carbonic-card" to={`/businesses/${business.id}`}>
            {Company}
            <p className="font-bold carbonic text-base text-center pt-1 pb-3">{business.title}</p>
            <div className="row">
                <div className="flex-1 col">
                    <SvgBorder icon="LocationMarkBg" />
                    <p className="font-medium carbonic-3 text-center">{business.address}</p>
                </div>
                <p style={{ width: 3, height: 15, backgroundColor: "#f48114", borderRadius: 25, marginTop: 6 }} />
                <div className="flex-1 col">
                    <SvgBorder icon="CategoryBg" />
                    <p className="font-medium carbonic-3 text-center">{business.businessCategory.title}</p>
                </div>
            </div>
        </Link>
    );
};

export default BusinessCard;
