import React from "react";
import Grid from "../Containers/Grid";
import GridHeader from "../Containers/GridHeader";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import CreateProvider from "../scripts/CreateApiProviderClass";

const SimpaleBuilder = ({ id, apiId, url, itemBuilder, gridClass = "grid", onResult, headerBuilder, limit }) => {
    const provider = React.useMemo(() => {
        console.debug(id + " RD");
        let _provider = new CreateProvider({
            url,
            scrollerId: `${id}-simple-wrapper`,
            sessionKey: `simple-stored-${id}`,
            providerId: id,
            apiId: apiId || id,
            onResult,
            limit,
        });
        _provider.search(true);
        return _provider;
    }, []);

    return (
        <div id={`${id}-simple-wrapper`} className="wrapper scroller">
            {headerBuilder && <GridHeader provider={provider} builder={headerBuilder} />}
            <Grid ItemBuilder={itemBuilder} provider={provider} className={gridClass} />
            <PageStateProvider provider={provider} />
        </div>
    );
};

export default React.memo(SimpaleBuilder);
