import { getCurrencesTitle } from "../Settings";
import { GetFormatedDate } from "../Utils/DateFromater";
// import { TimeToString } from "../Utils/DateFromater";
import Language from "../Utils/Languages";
import Utils, { getBusinesses } from "../Utils/Utils";

// const getItemsCount = async (api, businessId) => {
//     let query = businessId ? `?businessId=${businessId}` : "";
//     return await api.get(`/v1/items/ofowner/count${query}`);
// };

// const getLastUpdate = async (api, businessId) => {
//     if (!Utils.hasValue(businessId)) return null;
//     let data = await api.get(`/v1/lastupdates/ofbusiness/${businessId}`);
//     if (data) return TimeToString(data.updatedAt);
// };

const InvoicesesUtils = {
    onResult: async (data) => {
        let arr = [];
        data.map((element) => {
            element.invoice.date = GetFormatedDate(element.invoice);
            element.total = Object.values(element.totalByCurrency)[0];
            element.currencyId = Object.keys(element.totalByCurrency)[0];
            arr.push(element);
        });
        return arr;
    },
    // queryChanged: async ({ api, header, setHeader, queryParams }) => {
    //     const businessId = queryParams.businessId?.value || "";
    //     if (header.businessId === businessId) return;
    //     header.businessId = businessId;

    //     const [lastUpdate, itemsCount] = await Promise.all([getLastUpdate(api, businessId), getItemsCount(api, businessId)]);
    //     setHeader({ lastUpdate, itemsCount });
    // },

    queryParams: [
        {
            className: "form-section px-4",
            children: [
                {
                    key: "invoiceId",
                    type: "text",
                    title: "رقم الفاتورة",
                    value: "",
                    containerClass: "query-input",
                    showInClearBar: false,
                },
                {
                    key: "invoiceId-barcode",
                    type: "scanner",
                    value: "",
                    showInClearBar: false,
                    onResult: (text = "") => {
                        if (text.startsWith("https://saturn.morabaa.cloud/")) {
                            text = text.split("https://saturn.morabaa.cloud/")[1];
                        }
                        return text || "";
                    },
                },
            ],
        },
    ],
};

export default InvoicesesUtils;
