import { useEffect } from "react";
import { useParams } from "react-router";
import { HubAPI } from "../../API/remoteAPI";
import ClearStorge from "../../Utils/ClearStorge";

const AlreadyLoggedInView = () => {
    let params = useParams();
    useEffect(() => {
        HubAPI.defaults.headers.common["Authorization"] = `Bearer ${params.token}`;
        HubAPI.get("/owners/me")
            .then((userResponse) => {
                if (!!userResponse.data) {
                    localStorage.setItem("owner", userResponse.data);
                    localStorage.setItem("token", params.token);
                    window.location.pathname = "/";
                }
            })
            .catch(() => {
                ClearStorge();
                window.location.pathname = "/login";
            });
    }, [params]);

    return null;
};

export default AlreadyLoggedInView;
