export const BusinessIcon = (
    <svg height={36} className="accounts-business-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 587.77 587.77">
        <g>
            <rect className="item-icon-bg" width="587.77" height="587.77" rx="163.27" />
        </g>
        <g fill="#ffa44e">
            <path d="M620.49,763.73a109.51,109.51,0,0,1-216,0h65.18a46.5,46.5,0,0,0,85.62,0Z" transform="translate(-218.74 -386)" />
        </g>
        <g>
            <circle style={{ fill: "none", stroke: "#ffa44e", strokeMiterlimit: 10, strokeWidth: 63 }} cx="291.52" cy="224.15" r="78" />
        </g>
    </svg>
);

export const QuantityIcon = (
    <svg height={12} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 358.15">
        <path
            d="M670,342v53c-1.43,3.3-2.44,6.89-4.38,9.86-5.46,8.34-13.71,10.65-23.32,10.64q-142.3-.18-284.6-.08c-1.83,0-3.85.37-5.46-.25-5-1.93-10.71-3.29-14.49-6.71S332.5,399.58,330,395V342c5.7-16.84,11.7-21.12,29.63-21.12q141.87,0,283.74,0a34.72,34.72,0,0,1,7.43.54C661.89,323.85,667.43,331.57,670,342Z"
            transform="translate(-330 -320.88)"
        />
        <path
            d="M349.44,443.66H650.9v5.63q0,83,0,165.94c0,37.23-26.67,63.79-63.91,63.79H411c-33.63,0-61.45-26.23-61.76-59.77-.54-57.81-.15-115.63-.15-173.44A18.28,18.28,0,0,1,349.44,443.66ZM500,472c-13.48,0-27,0-40.45,0-9.84,0-15.8,5.41-15.72,14,.06,8.28,6.21,13.92,15.5,13.94q40.71.09,81.41,0c9.32,0,15.41-5.63,15.47-14s-5.85-14-15.25-14C527.28,471.91,513.63,472,500,472Z"
            transform="translate(-330 -320.88)"
        />
    </svg>
);
