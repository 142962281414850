import React from "react";
import FormTextArea from "../Components/FormTextArea";
import { TicketSvgs } from "../SVGs/Tickets";

const Fotter = ({ placeholder, sendReply, chatRef }) => {
    const [reply, setReply] = React.useState("");
    const toLastMessage = () => chatRef?.current.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <div className="fixed bottom-0 carbonic-card-2 right-0 left-0 max-w-5xl m-auto">
            <div className="flex flex-row items-center pl-5 shadow-1 carbonic-card-1  mx-6 my-2 rounded-4 flex-grow overflow-hidden">
                <div className="flex flex-col flex-grow overflow-hidden">
                    <FormTextArea
                        value={reply}
                        setValue={setReply}
                        onFocus={toLastMessage}
                        placeholder={placeholder}
                        rows={reply?.split("\n")?.length + 1}
                        className="-ml-5 -mb-1 pt-6 px-9 overflow-auto "
                        // onBlur={() => setIsActive(false)}
                    />
                </div>
                <div className="cursor-pointer" onClick={() => (reply ? sendReply(reply, setReply) : {})}>
                    {TicketSvgs.Send}
                </div>
            </div>
        </div>
    );
};

export default Fotter;
