import React from "react";

const Avatar = ({ width = 73, height = 73, className }) => {
    const _ClassName = `${className} select-icon`;
    return (
        <svg className={_ClassName} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 256 256">
            <g transform="translate(128 128) scale(1.21 1.21)">
                <g
                    // style="stroke: none; strokeWidth: 0; stroke-dasharray: none; strokeLinecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                    transform="translate(-104.85 -104.85000000000001) scale(2.33 2.33)">
                    <path
                        d="M 85.625 88.42 c 3.702 0 5.704 -4.286 3.381 -7.169 C 78.652 68.397 62.791 60.168 45 60.168 S 11.348 68.397 0.994 81.251 c -2.323 2.883 -0.321 7.169 3.381 7.169 H 85.625 z"
                        // style="stroke: none; strokeWidth: 1; stroke-dasharray: none; strokeLinecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) "
                        strokeLinecap="round"
                    />
                    <path
                        d="M 45 53.944 L 45 53.944 c -11.289 0 -20.441 -9.152 -20.441 -20.441 V 22.021 C 24.559 10.732 33.711 1.58 45 1.58 h 0 c 11.289 0 20.441 9.152 20.441 20.441 v 11.482 C 65.441 44.792 56.289 53.944 45 53.944 z"
                        // style="stroke: none; strokeWidth: 1; stroke-dasharray: none; strokeLinecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) "
                        strokeLinecap="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Avatar;
