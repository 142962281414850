import React from "react";
import { LeftEdge, RightEdge } from "../../SVGs/InvoicesSvgs";

const SperaterThenEdge = () => (
    <div style={{ zIndex: 1 }} className="relative flex flex-row justify-between">
        {RightEdge}
        <span
            className="absolute right-4 left-4 font-bold text-sm text-center carbonic-1 carbonic-card"
            style={{
                overflow: "hidden",
                fontSize: 20,
                letterSpacing: 5,
                maxHeight: 46,
                paddingTop: 6,
                left: 18,
                right: 18,
                lineHeight: "2",
            }}>
            - - - - - - - - - - - - - - - - - - - - - - - - - - -
        </span>
        {LeftEdge}
    </div>
);
export default SperaterThenEdge;
