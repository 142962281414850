import React from "react";
import DetailsLabel from "../../ComponentsV2/LastUpdateLabel";
import TotalCardAccount from "./TotalCardAccount";

const AccountsHeader = ({ provider }) => {
    return (
        <div>
            <div className="gap-10 flex flex-row flex-wrap px-2">
                <DetailsLabel details={provider.header.accountsCount} title="AccountsCount" />
                <DetailsLabel details={provider.header.lastUpdate} />
            </div>
            {provider.header.showTotals && <TotalCardAccount totals={provider.header.totals} />}
        </div>
    );
};

export default AccountsHeader;
