import React from "react";
import { useNavigate } from "react-router-dom";

const RedirectView = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        navigate(localStorage.getItem("token") ? "/" : "/login");
    }, []);
    return <div />;
};

export default RedirectView;
