import React from "react";

const CheckBox = ({ onClick, checked, title }) => {
    return (
        <div className={`checked-box transition-1 ${checked ? "checked" : ""}`}>
            <p className="font-bold carbonic flex-grow">{title}</p>
            <p onClick={onClick} className="check-bg shadow-1">
                <span className="shadow-1" />
            </p>
        </div>
    );
};

export default CheckBox;
