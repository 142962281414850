import React from "react";
const TimeLine = ({ time, color = "#c4c4c7" }) => {
    return (
        <p className="flex flex-col items-center my-6 border-b" style={{ borderColor: `${color}aa`, color }}>
            <span className="font-bold background px-2 -mb-3">{time}</span>
        </p>
    );
};

export default TimeLine;
