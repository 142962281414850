// import Info from "../Addons/Info.js";
// import { UpdateItem } from "../Utils/MockGenerator.js";

import React from "react";

export const CreateTag = (tag, props, children) => {
    const element = document.createElement(tag);
    Object.entries(props).forEach(([key, value]) => {
        element[key] = value;
    });
    children && element.append(...children);
    return element;
};

export function Div(props, children) {
    return CreateTag("div", props, children);
}
export function P(props, children) {
    return CreateTag("p", props, children);
}
export function Span(props) {
    return CreateTag("span", props);
}
export function Input(props) {
    return CreateTag("input", props);
}
export function Img(props, children) {
    return CreateTag("img", props, children);
}
export function Button(props) {
    return CreateTag("button", props);
}
export function Fragment(children = []) {
    // let fragment = document.createDocumentFragment();
    // fragment.append(...children);
    // return fragment;
    return CreateTag("fragment", {}, children);
}
export function LinkButton(props) {
    document.createElement("a");
    return CreateTag("a", props);
}
export const Br = () => document.createElement("br");

export function Select({ className, value, options, onChange }) {
    const selectNode = document.createElement("select");
    options.forEach((item) => {
        const optionNode = document.createElement("option");
        optionNode.value = item.id;
        optionNode.innerText = item.title;
        selectNode.append(optionNode);
    });

    selectNode.className = className;
    selectNode.onchange = onChange;
    selectNode.value = value;

    return selectNode;
}
export function Video(props, children) {
    props.id = "video";
    return CreateTag("video", props, children);
}

const valdTyps = ["String", "Number", "Boolean", "Object", "Array", "ArrayImages", "Undefined", "Null"];
const getType = (obj) => {
    let type = Object.prototype.toString.call(obj).slice(8, -1);
    if (valdTyps.includes(type)) return type;
};

const String = ({ item, key }) =>
    Span({
        className: "text-gray-700",
        innerText: item,
    });

const Boolean = ({ item, key }) =>
    Span({
        className: "text-gray-700",
        innerText: item,
    });

const StringArray = (item) =>
    Span({
        className: "text-gray-600 px-sm",
        innerText: `${item}, `,
    });

const Array = ({ item }) => {
    item = Object.values(item)
        .filter((value) => nullables.includes(value) === false)
        .sort((o) => (sortByType(o[1]) ? -1 : 1));
    return Div({}, [
        Span({
            className: "text-cyan-500 px-sm",
            innerText: "[",
        }),
        Fragment(
            item.map((_i) => {
                let _type = getType(_i);
                return _type === "Object" || _type === "Array" ? _Object({ key: "", item: _i }) : StringArray(_i);
            })
        ),
        Span({
            className: "text-cyan-500 px-sm",
            innerText: "]",
        }),
    ]);
};

const Image = ({ item }) =>
    Img({
        style: "height: 250px; max-width: 250px;",
        src: item,
    });

const ArrayImages = ({ item }) => {
    return item.map((_i) =>
        Img({
            height: 250,
            src: _i,
        })
    );
};

const nullables = [undefined, null, ""];

function random(number) {
    return Math.floor(Math.random() * (number + 1));
}

function checkIsImageURL(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}
const JsonParser = (item, containerClass, setItem) => {
    const _item = Object.entries(item)
        .filter(([_, value]) => nullables.includes(value) === false)
        .sort((o) => (sortByType(o[1]) ? -1 : 1));
    function onclick() {
        // Info({ title: "Update Item", content: "Update Item" });
        // UpdateItem(item);
        setItem && setItem(item);
    }

    let nodes = document.createElement("div");
    nodes.className = "json-builder";
    nodes.onclick = onclick;
    nodes.id = item.id;

    // const rndCol = `rgba(${random(255)}, ${random(255)}, ${random(255)},${Math.random() / 2})`;
    // nodes.style.backgroundColor = rndCol;

    // nodes.style.background = `url(${item.image})`;
    // nodes.style.backgroundSize = "cover";
    // nodes.style.backgroundOrigin = "content-box";
    // nodes.style.backgroundRepeat = "no-repeat";

    _item.forEach(([key, value], _i) => {
        let type = getType(value);
        if (!type) return; //nodes.append(Span({}, [`${key}: ${value}`]));

        if (type === "Array") {
            if (key === "images") {
                type += "Images";
                value = Object.values(Object.values(value)[0]);
            }
        } else if (type === "String" && value.startsWith("http") && (key === "image" || checkIsImageURL(value))) type = "Image";

        nodes.append(
            Div(
                {
                    className: `obj font-bold ${TypeColors[type]}`,
                },
                [
                    Span({
                        className: `rounded`,
                        style: { fontSize: 12, letterSpacing: 1, paddingTop: 4 },
                        innerText: key,
                    }),
                    Span({
                        className: "text-gray-400 px-sm",
                        innerText: ":",
                    }),
                    UiKit[type]({ key: _i, item: value }),
                ]
            )
        );
    });
    if (containerClass) {
        if (typeof containerClass === "string") nodes = Div({ className: containerClass }, [nodes]);
        else {
            nodes = Div({ style: containerClass }, [nodes]);
            Object.entries(containerClass).forEach(([key, value]) => {
                nodes.style[key] = value;
            });
        }
    }

    return nodes;
};
const _Object = ({ key, item }) => {
    const _item = Object.entries(item)
        .filter(([_, value]) => nullables.includes(value) === false)
        .sort((o) => (sortByType(o[1]) ? -1 : 1));
    function onclick() {
        // Info({ title: "Update Item", content: "Update Item" });
        // UpdateItem(item);
    }

    let nodes = document.createElement("div");
    nodes.className = "obj";
    nodes.onclick = onclick;
    nodes.id = item.id;

    // const rndCol = `rgba(${random(255)}, ${random(255)}, ${random(255)},${Math.random() / 2})`;
    // nodes.style.backgroundColor = rndCol;

    // nodes.style.background = `url(${item.image})`;
    // nodes.style.backgroundSize = "cover";
    // nodes.style.backgroundOrigin = "content-box";
    // nodes.style.backgroundRepeat = "no-repeat";

    _item.forEach(([key, value], _i) => {
        let type = getType(value);
        if (!type) return; //nodes.append(Span({}, [`${key}: ${value}`]));

        if (type === "Array") {
            if (key === "images") {
                type += "Images";
                value = Object.values(Object.values(value)[0]);
            }
        } else if (type === "String" && value.startsWith("http") && (key === "image" || checkIsImageURL(value))) type = "Image";

        nodes.append(
            Div(
                {
                    className: `obj font-bold ${TypeColors[type]}`,
                },
                [
                    Span({
                        className: `rounded`,
                        style: { fontSize: 12, letterSpacing: 1, paddingTop: 4 },
                        innerText: key,
                    }),
                    Span({
                        className: "text-gray-400 px-sm",
                        innerText: ":",
                    }),
                    UiKit[type]({ key: _i, item: value }),
                ]
            )
        );
    });
    return nodes;
};
export default JsonParser;

const sortByType = (obj) => {
    const type = getType(obj);
    const _sortByType = {
        String: true,
        Number: true,
        Boolean: true,
        ArrayImages: true,
        Object: false,
        Array: false,
        Undefined: false,
        Null: false,
    };
    return _sortByType[type];
};

let arrayTypeColor = "text-string-1 shadow-lg";
const TypeColors = {
    String: arrayTypeColor,
    Number: "text-int-1 shadow-lg",
    Boolean: "text-purple-500 shadow-purple",
    Object: "text-gray-400 shadow-red",
    Array: "text-cyan-400 shadow-cyan",
    ArrayImages: "text-cyan-400 shadow-cyan",
    Undefined: "",
    Null: "",
};

const UiKit = {
    String,
    Number: String,
    Boolean,
    Object: _Object,
    Array,
    ArrayImages,
    Undefined: Fragment(),
    Null: Fragment(),
    Image,
};
