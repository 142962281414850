import React from "react";
import { SvgBorderLabel } from "../../SVGs/BorderSvgs";
import { ThePrice, TotalAmout, TotalsSvg } from "../../SVGs/InvoicesSvgs";
import { QuantityIcon } from "../../SVGs/ItemsSvg";
import Language from "../../Utils/Languages";

const InvoicesItems = ({ items }) => (
    <div className="carbonic-card p-3 gap-3 flex flex-col ">
        {items.map((item) => (
            <div key={item.id} className="carbonic-card shadow-1 rounded-3xl overflow-hidden">
                <div className="row px-4 py-3 invoice-bg-header">
                    <p className="carbonic font-bold flex-1">{item.name}</p>
                    <div className="row items-center rounded-3xl px-2 invoice-quantity">
                        {QuantityIcon}
                        <p style={{ height: 18, paddingRight: 6 }} className="text-white font-medium">
                            {item.quantity}
                        </p>
                    </div>
                </div>

                <div className="flex justify-around overflow-hidden carbonic-card-1 rounded-b-lg p-2">
                    <div className="row items-center">
                        {ThePrice}
                        <p className="carbonic-3 pr-3">
                            <span className="font-bold orange-text-3">{Language.ThePrice}</span>
                            <br />
                            <span className="font-medium orange-text"> {item.price} </span>
                        </p>
                    </div>
                    <div className="row items-center">
                        {TotalsSvg}
                        <p className="carbonic-3 pr-3">
                            <span className="font-bold orange-text-3">{Language.TotalAmount}</span>
                            <br />
                            <span className="font-medium orange-text"> {item.totalPrice} </span>
                        </p>
                    </div>
                </div>
            </div>
        ))}
    </div>
);
export default InvoicesItems;
