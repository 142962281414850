import React from "react";
import { Link } from "react-router-dom";
import ChangeTitle from "../../Utils/ChangeTitle";
import Language from "../../Utils/Languages";
const stateIcon = " /tickets/openTicket.png";
const stateIconClose = " /tickets/closeTicket.png";

const TicketCard = ({ item }) => {
    return (
        <Link
            onClick={() => ChangeTitle("")}
            className="flex flex-row carbonic-card-2 shadow-1 rounded-3 font-bold mt-4 "
            to={`/tickets/${item.id}`}
            title={Language.TicketDetails}>
            {item.state === 0 ? (
                <div className="border-l-2 min-w-max border-dashed p-5 flex flex-col justify-center items-center ">
                    <img src={stateIcon} style={{ width: 45, height: 45 }} />
                    <p className="green-text">{Language.Open}</p>
                    {/* <p className="green-text">{item.state}</p> */}
                </div>
            ) : (
                <div className="border-l-2 min-w-max border-dashed p-5 flex flex-col justify-center items-center ">
                    <img src={stateIconClose} style={{ width: 45, height: 45 }} />
                    <p className="red-text">{Language.Closed}</p>
                    {/* <p className="green-text">{item.state}</p> */}
                </div>
            )}

            <div className="p-5 flex-grow">
                <p className="carbonic text-xl">{item.title}</p>
                <p className="carbonic-3 py-1">{item.businessTitle}</p>
                <p className="carbonic-3">{item.dsiscriptions}</p>
                {/* <DateIcon date={item.date} /> */}
            </div>
        </Link>
    );
};

export default TicketCard;
