import Language from "./Languages";
import { HubAPI } from "../API/remoteAPI";
import Info from "../NodeBuilder/Info";
import { getRestBusinesses } from "./Utils";
import { main } from "..";

const RepresentativesTiers = [
    "0eb492d5-51e5-4830-9c22-6849b090c397",
    "18405edf-e58b-4221-b369-c50a102e94bc",
    "2a9852a6-1281-47e1-a9a0-2c9f0277acb8",
    "e90fae80-13f2-426e-98df-31884d2cd24c",
    "6fd39a10-9715-423d-9c9d-2b10768df073",
    "4db955e5-865a-41da-9baa-8dc4ccc1b489",
    "81a23444-ba7b-4ab9-9ca6-49f5e0768d07",
];

const shouldShowRestaurant = async () => ((await getRestBusinesses()).length > 0 ? "rest" : "");

const shouldShowReps = async () => ((await HubAPI.get("/subscriptions/ofowner")).data?.some((o) => RepresentativesTiers.includes(o.tier)) ? "reps" : "");

const addAdditionalTabs = async () => {
    const values = await Promise.all([await shouldShowReps(), await shouldShowRestaurant()]);
    localStorage.setItem("custom-labels", JSON.stringify(values));
};

const UserInfo = {
    allBusinesses: { title: Language.AllCompanies, value: "" },
    subs: [],

    fetchUserInfo: async () => {
        try {
            getProfile();
            setTimeout(async () => {
                addAdditionalTabs();
            }, 20);
        } catch (err) {
            handelError(err);
        }
    },
};

export default UserInfo;

const getProfile = async () => {
    HubAPI.get("/owners/me")
        .then(({ data: owner }) => {
            owner.name = owner.firstName + " " + owner.lastName;
            owner.image = owner.image ? `https://hubcore.morabaaapps.com${owner.image}` : false;
            localStorage.setItem("owner", JSON.stringify(owner));
        })
        .catch(handelError);
};

const handelError = (err) => {
    console.log({ err });
    const status = err?.response?.status;
    if (err.response?.status === 408) {
        Info({ title: "NoInternetConnection", content: "", type: "error" });
    } else if (status === 401 || status === 403) {
        Info({ title: "NotAuthorized", content: "NotAuthorizedMessage", type: "warn" });
        localStorage.setItem("token", "");
        main();
    }
};
