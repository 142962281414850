import React from "react";
import { CloseIcon, TopStar } from "../../SVGs/InvoicesSvgs";

const InvoiceTop = ({ close }) => (
    <>
        {close && <div onClick={close}>{CloseIcon}</div>}
        <div className="invoice-top py-3">
            <p className="carbonic-card py-2 w-1/2 rounded-full m-auto" />
        </div>
        <div className="relative">
            {TopStar}
            <p className="invoice-shape py-2 w-1/5 rounded-full mr-6 my-2" />
            <p className="invoice-shape py-2 w-1/3 rounded-full mr-6" />
        </div>
    </>
);

export default InvoiceTop;
