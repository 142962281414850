import React, { useState } from "react";
import Language from "../../Utils/Languages";
import Button from "../../ComponentsV2/Button";
import TextInput from "../../Components/TextInput";
import DialogConatiner from "../../ComponentsV2/DialogConatiner";
import TextInputReadOnl from "../../Components/TextInputReadOnly";
import { HubAPI } from "../../API/remoteAPI";
import { writeStorage } from "@rehooks/local-storage";
import { Camera } from "../../SVGs/OtherSvgs";
import Info from "../../NodeBuilder/Info";

const UpdateOwnerInfo = ({ close, owner }) => {
    const [firstName, setFirstName] = useState(owner.firstName);
    const [lastName, setLastName] = useState(owner.lastName);
    const [imageFile, setImageFile] = useState({});

    const hiddenFileInput = React.useRef(null);

    const onClose = () => {
        setTimeout(() => close(), 400);
    };
    const saveAll = () => {
        uploadImage();
        onClose();
    };
    const updateName = (showToast) => {
        const dataToSend = {
            firstName,
            lastName,
        };
        HubAPI.patch("/owners/me", dataToSend).then(() => {
            owner.name = firstName + " " + lastName;
            writeStorage("owner", owner);
            Info({ title: "Saved", type: "success" });
        });
    };

    const uploadImage = () => {
        if (imageFile) {
            HubAPI.post("/owners/me/pfp", imageFile.formData).then(({ data }) => {
                owner.image = data.image ? `https://hubcore.morabaaapps.com${data.image}` : false;
                writeStorage("owner", owner);
                Info({ title: "Saved", type: "success" });
            });
            if (firstName !== owner.firstName || lastName !== owner.lastName) updateName(true);
        } else if (firstName !== owner.firstName || lastName !== owner.lastName) updateName(true);
    };

    // const top = height;
    // const onScroll = ({ target }) => {
    //     if (target.scrollTop > 50) {
    //         isBig = false;
    //         setImageClass("w-56 rounded-5 mt-40");
    //     } else if (target.scrollTop < 40) setImageClass("w-full");
    // };

    return (
        <DialogConatiner close={onClose} childClassName="popup-child invoices-popup">
            <div className="max-w-3xl my-8 carbonic-card fade-in-card relative">
                <p
                    onClick={onClose}
                    className="font-bold carbonic-card-2 rounded-full absolute top-[-24px] right-1 z-10000 m-3 text-center shadow-1 carbonic"
                    style={{ width: 28, paddingTop: 4 }}>
                    x
                </p>
                <div className="relative flex flex-col" style={{ width: "100%", minHeight: 300 }}>
                    {(imageFile?.localUrl || owner?.image) && (
                        <img
                            src={imageFile.localUrl || owner.image}
                            className="object-cover  mx-auto rounded-5 m-4"
                            style={{ height: "320px", width: "320px" }}
                        />
                    )}
                    <div
                        onClick={() => hiddenFileInput.current.click()}
                        className="backdrop-blur-sm tab-container-bg rounded-lg top-1/3 left-0 right-0 absolute  m-auto"
                        style={{ width: 125 }}>
                        {Camera}
                    </div>
                </div>
                <div className="carboninc-card flex flex-col">
                    <div className="flex flex-col p-5">
                        <TextInput placeholder="Name" value={firstName} setValue={setFirstName} />
                        <TextInput placeholder="NameTitle" value={lastName} setValue={setLastName} />
                        <TextInputReadOnl placeholder={owner.phone_number} />
                        <Button title={Language.Save} onClick={saveAll} Extra="mx-auto my-8 px-12" color="red" />
                    </div>
                </div>
            </div>

            <input
                type="file"
                accept="image/*"
                className="hidden"
                ref={hiddenFileInput}
                onChange={(e) => {
                    let file = e.target.files[0];
                    let formData = new FormData();
                    formData.append("image", file, file.name);
                    setImageFile({ formData, localUrl: URL.createObjectURL(file) });
                }}
            />
        </DialogConatiner>
    );
};

export default UpdateOwnerInfo;
