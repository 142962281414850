import { Div, Input, LinkButton, P } from "../scripts/NodeBuilder";
import Language from "../Utils/Languages";

const Confirmer = ({ confirm, title, content = "", cancelText = Language.Cancel, contfrimText = Language.Confirm, url, input }) => {
    const Container = Div(
        {
            id: "confirmer",
            className: "popup-builder",
            onclick: ({ target }) => {
                if (target.id === "confirmer") {
                    removeConfiremer();
                }
            },
        },
        [
            Div({ className: "popup-child carbonic-card" }, [
                P({ className: "font-bold text-xl text-center", innerText: title }),
                P({ className: "font-medium text-md", innerText: content }),
                input
                    ? Input({
                          className: "input rounded-xl shadow-1 p-2 text-center",
                          type: input.type || "text",
                          placeholder: input.placeholder || "...",
                          id: "popup-input",
                        autofocus: true,
                          onkeydown: (e) => {
                              if (e.key === "Enter") {
                                  removeConfiremer();
                                  confirm(e.target.value);
                              }
                          },
                      })
                    : "",

                Div({ className: "row gap-10 mt-2 justify-center" }, [
                    url
                        ? LinkButton(
                              {
                                  href: url,
                                  className: "bg-red text-white py-1 px-4 shadow-1 text-center font-bold cursor-pointer",
                                  style: "border-radius:5px;",
                                  innerText: contfrimText,
                                  onclick: () => {
                                      removeConfiremer();
                                  },
                              },
                              []
                          )
                        : P({
                              className: "bg-red text-white py-1 px-4 shadow-1 text-center font-bold cursor-pointer",
                              style: "border-radius:5px;",
                              innerText: contfrimText,
                              onclick: () => {
                                  if (input) confirm(document.getElementById("popup-input").value);
                                  else confirm();
                                  removeConfiremer();
                              },
                          }),
                    P({
                        className: "carbonic carbonic-card-1 py-1 px-4 shadow-1 text-center font-bold cursor-pointer",
                        style: "border-radius:5px",
                        innerText: cancelText,
                        onclick: () => {
                            removeConfiremer();
                        },
                    }),
                ]),
            ]),
        ]
    );
    document.body.append(Container);

    let pastEvent = global.onBack;
    global.onBack = removeConfiremer;

    function removeConfiremer() {
        try {
            Container.remove();
        } catch {}
        global.onBack = pastEvent;
    }
};

export default Confirmer;
