const Languages = {
    ar: {
        Invoices: "فواتيري",
        TheTheme: "المظهر",
        HavePermisionForStor: "المخازن التي لديه صلاحية الوصول إليها",
        Dashboard: "الملخص اليومي",
        AccountsBalances: "أرصدة العملاء",
        Customers: "حساباتي",
        Items: "المواد",
        Companise: "الشركات/الفروع",
        MenuItemTickets: "تذاكر الدعم",
        Reps: "إدارة المندوبين",
        Restaurant: "قائمة المطعم",
        PhoneNumber: "رقم الهاتف",
        Password: "كلمة السر",
        Login: "تسجيل الدخول",
        CreateNewAccount: "إنشاء حساب جديد",
        Daily: "يومي",
        Chart: "مخططات",
        OurTotalCredit: "مجموع دائن علينا:",
        TotalOweUs: "مجموع مدين لنا:",
        Conclusion: "الخلاصة",
        Credit: "دائن / علينا",
        Address: "العنوان",
        Summary: "الملخص",
        LastUpdate: "اخر تحديث",
        Profit: "الأرباح",
        Sale: "المبيعات ",
        Expense: "المصاريف",
        Purchases: "المشتريات",
        Dicounts: "الخصومات",
        Income: "الإيرادات",
        Dinar: "دينار",
        Dollar: "دولار",
        Weekly: "أسبوعي",
        Monthly: "شهري",
        Yearly: "سنوي",
        AccountName: "إسم الحساب",
        AllCompanies: "كل الشركات",
        ThereAreNoMovements: "لا توجد حركات",
        TheAmount: "المبلغ",
        TheOperation: "العملية",
        Balance: "الرصيد",
        Logout: "تسجيل خروج",
        Opened: "تم الفتح",
        Open: "مفتوحة",
        Close: "مغلقة",
        Closed: "تم الإغلاق",
        TicketDetails: "تفاصيل التذكرة",
        CannotAddMoreRepresentatives: "لا يمكن إضافة المزيد من المندوبين",
        TicketStatus: " حالة التذكرة",
        NewTicketFor: "تذكرة جديدة لـ ",
        NewTicket: "تذكرة جديدة",
        Tickets: "تذاكر",
        AddTicket: "إضافة تذكرة",
        Saved: "تم الحفظ",
        Done: "تم",
        Error: "خطأ",
        AnErrorOccurred: "حدث خطأ ما",
        Company: "الشركة",
        Note: "الملاحظة",
        All: "الكل",
        TheValueOfTheEvaluationIsIncorrect: "قيمة التقييم غير صحيحة",
        Rated: "تم التقييم",
        Attachments: "المرفقات",
        ReplyText: "نص الرد",
        Delete: "حذف",
        PleaseWriteTheReasonForTheEvaluationHere: "الرجاء كتابة سبب التقييم هنا",
        RatingDescription: "وصف التقييم",
        RatingAndClosing: "تقييم وإغلاق",
        Evaluation: "تقييم",
        AddReply: "إضافة رد",
        Send: "إرسال",
        Subscribed: "تم الاشتراك",
        NumberOfMonths: "عدد الأشهر",
        Participation: "اشتراك",
        Copied: "تم النسخ",
        TheItemsAppearingInTheListHaveBeenUpdated: "تم تحديث المواد التي تظهر في القائمة",
        Save: "حفظ",
        CopyLink: " نسخ الرابط",
        ItemName: "إسم المادة",
        AreYouSure: "هل انت متأكد؟",
        EditDelegateInformation: "تعديل بيانات المندوب",
        Name: "الإسم",
        NameTitle: "اللقب",
        Freeze: "تجميد",
        DeleteRepresentativeAccount: "حذف حساب المندوب",
        PasswordsNotMatch: "كلمتا المرور غير متطابقتين",
        SuccessfullyRegistraed: "تم التسجيل",
        PasswordConfirm: "تأكيد كلمة السر",
        Registration: "تسجيل",
        GivePermission: "سيتم منح صلاحية لكل الزبائن حتى غير المعروضة أسمائهم حاليًا، هل تريد الإستمرار؟",
        InActive: "جامد",
        ThisRepresentative: "المندوب",
        NoNotifications: "لا توجد إشعارات",
        TheNotifications: "الإشعارات",
        More: "المزيد",
        Quantity: "الكمية",
        SellingPrice: "سعر البيع",
        PurchasingPrice: "سعر الشراء",
        TotalNumberOfItems: "عدد المواد الكلي",
        SearchingFor: "البحث عن",
        HighestSellingPriceFirst: "سعر البيع الأعلى أولًا",
        LowestSellingPriceFirst: "سعر البيع الأقل أولًا",
        DateAddedMostRecentlyFirst: "تاريخ الإضافة الأحدث أولًا",
        DateAddedOldestFirst: "تاريخ الإضافة الأقدم أولًا",
        AlphabeticallyAscending: "حسب الأبجدية تصاعديًا",
        AlphabeticallyDescending: "حسب الأبجدية تنازليًا",
        Cancel: "إلغاء",
        ThereIsNoItems: "لا توجد مواد",
        OrderBy: " ترتيب",
        NotFound: "هذه الصفحة غير موجودة، الرجاء التحقق من الرابط",
        MyCompanies: "شركاتي",
        Am: "صباحًا",
        Pm: "مساءًا",
        InSaved: "لم يتم الحفظ",
        FirstTimeBackup: "وقت النسخة الإحتياطية الأولى",
        SecondTimeBackup: "وقت النسخة الإحتياطية الثانية",
        OldPassword: "  كلمة السر القديمة",
        NewPassword: "  كلمة السر الجديدة",
        NewPasswordConfrim: "  تأكيد كلمة السر الجديدة",
        NewPasswordNotMatch: "كلمة السر الجديدة غير متطابقة",
        DoneChangePassword: "تم تغيير كلمة السر بنجاح",
        PlzWrite: "الرجاء كتابة",
        Change: "تغيير",
        ChangePassword: "تغيير كلمة السر",
        LogoutFromAllDevises: "تسجيل الخروج من كل الاجهزة",
        AddReps: "إضافة مندوب",
        EditeReps: "تعديل مندوب",
        WorkWith: "يعمل مع العملاء",
        SerchByPhoneNameOrAddres: "بحث بالإسم، العنوان أو رقم الهاتف",
        ChoseAll: "إختيار الكل",
        ChoseAsSearchResult: " إختيار الكل حسب نتيجة البحث",
        AccountSitting: "إعدادات الحساب",
        ThirdPartyApps: "تطبيقات الطرف الثالث",
        TheresNoImage: "لاتوجد صورة",
        InstallTheApp: "تثبيت التطبيق",
        DownloadFromStore: "تحميل من المتجر",
        ThereIsNoData: "لا توجد بيانات",
        Search: "بحث",
        CanNotAddExisstinNumber: "لا يمكن اضافة رقم موجود اصلًا",
        WeCounldNotFoundSomeoneWithThisNumber: "لم نستطع العثور على حساب بهذا الرقم",
        CannotAddExisitinAccount: "لا يمكن اضافة رقم موجود اصلًا",
        PlzWritePassword: "الرجاء إدخال كلمة السر",
        AreYouSuer: "هل أنتَ متأكد؟ لا يمكن التراجع بعد التنفيذ",
        RongPassword: "خطأ في كلمة السر",
        AddNewCompanyName: "أدخل إسم الشركة الجديد",
        BackupTime: "أوقات النسخ الإحتياطي",
        Owners: "المالكون",
        AddOwner: "إضافة مالك",
        AccsesToAllThingInCompany: " الوصول لكل ما يخص الشركة بعد الإضافة",
        Worning: "تحذير: سيستطيع",
        Add: "إضافة",
        AddOwnerWorning: " لن تستطيع الوصول لكل ما يخص الشركة بعد الإلغاء وتستخدم هذه الخاصية عند إنهاء الشراكة ورغبتك في إزالة هذه الشركة من حساب مربعي",
        CancelOwnerShip: "إلغاء الملكية",
        Confirm: "تاكيد",
        Code: "الرمز",
        SendCode: "ارسال رمز",
        ThisNumberIsUsed: "رقم الهاتف مستخدم من قبل",
        PlzCallSupport: "رقم الهاتف المستخدم تم تسجيله مسبقًا. الرجاء الإتصال بالدعم لإعادة تعيين كلمة السر إذا كنت قد نسيتها",
        RongCode: "خطأ في مطابقة الرمز",
        ConfirmCode: "رمز التحقق",
        RegistrAccount: "إنشاء الحساب",
        Profile: "الملف الشخصي",
        TotalAmount: "المجموع الكلي",
        TopBarSumaary: "ملخص",
        TheLanguage: "اللغة",
        TheCurrency: "العملة",
        TheTime: "المدة",
        TheType: "النوع",
        Debt: "مدين / لنا",
        MomentLocale: "ar",
        DeleteRepresentativeAccounts: "حذف حساب المندوب",
        AddNewReps: "إضافة مندوب جديد لـ",
        RepsPermission: "صلاحيات المندوب",
        GiveDiscountPermission: "صلاحية اعطاء خصم مادي وعددي",
        UnpaidAmount: "صلاحية ابقاء مبلغ غير مدفوع",
        SavePermissions: "حفظ الصلاحيات",
        GeneralSales: "مبيعات عامة",
        CanUseNonGeneralPrices: "صلاحية إستخدام الأسعار الخاصة وأسعار الجملة",
        PastBalance: "رصيد مدور",
        ThereAreNoTicket: "لاتوجد تذاكر",
        ThereAreAccounts: "لاتوجد حسابات",
        ThereAreNoBusinesses: "لاتوجد شركات",
        PermissionToSaleInMoreThen50Meters: "صلاحية البيع بمسافة اكبر من 50 متر",
        CloseIt: "إغلاق",
        RongPasswordOrPhoneNumber: "خطأ في كلمة السر او رقم الهاتف",
        RongPhoneNumber: "خطأ في الرقم",
        TryLater: "نفذت محاولاتك الرجاء المحاولة لاحقا",
        InvoiceNumber: "رقم الفاتورة",
        TheAccount: "الحساب",
        MainMenu: "القائمة الرئيسية",
        Settings: "الاعدادات",
        AboutMyMorabaa: "عن التطبيق",
        DarkMode: "الوضع الليلي",
        LightMode: "الوضع النهاري",
        InvoiceTotalAmount: "مجموع الفاتورة",
        Yes: "نعم",
        CompaniseTitle: "الشركات",
        ThereIsNoBusinessTitle: "لايوجد عنوان شركة",
        ThereIsNoPhoneNumber: "لايوجد رقم هاتف",
        ThereIsNoLocation: "لايوجد عنوان",
        ThereIsNoBalance: "لايوجد رصيد",
        RepsInfo: "معلومات المندوب",
        FollowYourBusiness: "مـتـابـعـة فـوريـة لأعـمـالـك الـتـجـاريـة",
        TheAppearance: "المظهر",
        FreezeAccount: "تجميد الحساب",
        NotificationDue: "الأقساط المستحقة",
        NotificationExpire: "مواد منتهية الصلاحية",
        NotificationDeletedItems: "المواد المحذوفة",
        NotificationDeletedAccounts: "الحسابات المحذوفة",
        NotificationDeletedSaleBills: "قوائم البيع المحذوفة",
        NotificationDeletedBuyBill: "قوائم الشراء المحذوفة",
        NotificationFaildBucks: "بكبات لم ترفع",
        NotificationType: "نوع الاشعار",
        VoiceMessage: "رسالة صوتية",
        AddImage: "ارفاق صورة",
        Create: "انشاء",
        TicketInfo: "معلومات التذكرة",
        ThePrice: "السعر",
        Wrnning: "تحذير",
        RepsActiveAccount: "الحسابات التي يمكنه التعامل معها",
        Menu: "القائمة",
        NoInternetConnection: "لايوجد إتصال بالإنترنت",
        Retry: "إعادة المحاولة",
        PhoneNumberWasAlreadyUsed: "رقم الهاتف مستخدم من قبل",
        AboutText: "هذا التطبيق وضع لتسهيل عمل الشركات والمؤسسات والمحال التجارية التي تستخدم أنظمة المربع الإدارية والمحاسبية ",
        ForMoreInfo: "للمزيد من التفاصيل وللاشتراك بالمزيد من الخدمات يرجى زيارة الموقع الإلكتروني لشركة المربع للحلول البرمجية",
        DeleteTheAccount: "حذف الحساب",
        DeleteTheAccountText: "سيتم حذف جميع بياناتك ولن تستطيع الوصول إليها مجددا",
        CanSendWithoutLocation: "صلاحية إرسال قائمة بدون موقع",
        Install: "تثبيت",
        NewUpdateAvailble: "يوجد تحديث جديد",
        Update: "تحديث",
        PleaseUpdate: " الرجاء تحديث التطبيق",
        AddToFavorite: "الاضافة الى المفضلة",
        RemoveFromFavorite: "حذف من المفضلة",
        TheApp: "التطبيق",
        AccountsCount: "عدد الحسابات",
        AllTickets: "كل التذاكر",
        ShareMenuUrlTitle: "منيو",
        Share: "مشاركة رابط القائمة",
        GivePermissionToAll: "منح صلاحية الوصول لكل الزبائن",
        Details: "التفاصيل",
        NotAuthorized: "تم تسجيل خروجك",
        NotAuthorizedMessage: "الرجاء تسجيل الدخول مرة اخرى",
        ConfirmCodeMessage: "تم إرسال رمز التفعيل إلى رقم هاتفك",
        PleaseWait: "الرجاء الانتظار",
        TooManyRequests: "لقد قمنا بحظر جميع الطلبات من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى لاحقا",
        InvalidPhoneNumber: "رقم الهاتف غير صحيح",
        HaveAnAccount: "لديك حساب؟",
        CanSellOutOfStock: "صلاحية بيع المواد بالسالب",
        ForgotPassword: "هل نسيت كلمة المرور؟",
        EnterYourPhone: "ادخل رقم هاتفك للاستمرار",
        DoneChangingPassword: "تم تغيير كلمة السر بنجاح",
        EnterYourNewPassword: "ادخل كلمة السر الجديدة",
    },

    kurd: {
        Invoices: "وەەسڵەکانم",
        TheTheme: "ڕووکارەکە",
        HavePermisionForStor: "ئەو فرۆشگایانەی کە دەستی پێدەگات",
        Dashboard: "داشبۆرد",
        AccountsBalances: "باڵانسی هەژمارەکان",
        Customers: "حیساباتی",
        Items: "شتومەکەکان / کەرەستەکان",
        Companise: "كؤمبانياكان",
        MenuItemTickets: "بلیتی پشتگیری",
        Reps: "بەشی نوێنەری فرۆشتن",
        Restaurant: "لیستی چێشتخانەکان",
        PhoneNumber: "ژماره ى موبايل",
        Password: "تێپەڕە وشە",
        Login: "چوونه ژووره وه ",
        CreateNewAccount: "دروستکردنی هەژماری نوێ",
        Daily: "ڕۆژانە",
        Chart: "هێڵکاری",
        OurTotalCredit: "كؤكراوه ي قه رز له سه ر ئيمه ",
        TotalOweUs: "كؤكرواوه ى قه رز",
        Conclusion: "دەرەنجام",
        Credit: "قه رزكه ر/له سه ر ئيمه ",
        Address: "ناونیشان",
        Summary: "پوختە",
        LastUpdate: "دوایین نوێکردنەوە",
        Profit: "قازانج",
        Sale: "فرۆشتن",
        Expense: "خەرجی",
        Purchases: "کڕینەکان",
        Dicounts: "داشکاندنەکان",
        Income: "دەستکەوت",
        Dinar: "دینار",
        Dollar: "دۆلار",
        Weekly: "هەفتانە",
        Monthly: "مانگانە",
        Yearly: "ساڵانە",
        AccountName: "ناوی هەژمار",
        AllCompanies: "هەموو کۆمپانیاکان",
        ThereAreNoMovements: "هیچ جوڵەیەک نییە",
        TheAmount: "بڕ",
        TheOperation: "کردار",
        Balance: "باڵانس",
        Logout: "چوونە دەرەوە",
        Opened: "کراوەیەوە",
        Open: "کردنەوە",
        Close: "داخستن",
        Closed: "داخراوە",
        TicketDetails: "وردەکاریەکانی بلیت",
        NotAuthorized: "NotAuthorized",
        TicketStatus: "دۆخی بلیت",
        NewTicketFor: "بلیتی نوێ بۆ",
        NewTicket: "بلیتی نوێ",
        Tickets: "بلیتەکان",
        AddTicket: "زیادکردنی بلیت",
        Saved: "پاشەکەوتکرا",
        Done: "ئەنجامدرا",
        Error: "هەڵە",
        AnErrorOccurred: "هەڵەیەک ڕوویدا",
        Company: "کۆمپانیا",
        Note: "تێبینی",
        All: "هەموو",
        TheValueOfTheEvaluationIsIncorrect: "بەهای هەڵسەنگاندنەکە نادروستە",
        Rated: "هەڵسەنگێدرا",
        Attachments: "هاوپێچەکان",
        ReplyText: "دەقی وەڵام",
        Delete: "سڕینەوە",
        PleaseWriteTheReasonForTheEvaluationHere: "تکایە هۆکاری هەڵسەنگاندن لێرە بنوسە",
        RatingDescription: "وەسفی هەڵسەنگاندنەکە",
        RatingAndClosing: "هەڵسەنگاندن و داخستن",
        Evaluation: "هەڵسەنگاندن",
        AddReply: "زیادکردنی وەڵام",
        Send: "ناردن",
        Subscribed: "بەشداریکرد",
        NumberOfMonths: "ژمارەی مانگەکان",
        Participation: "بەشداری",
        Copied: "کۆپیکرا",
        TheItemsAppearingInTheListHaveBeenUpdated: "شتومەکەکان کە لە لیستەکەدا دەردەکەوێت نوێکراوەتەوە",
        Save: "پاشەکەوت بکە",
        CopyLink: "کۆپیکردنی لینک",
        ItemName: "ناوی شتومەک",
        AreYouSure: "ئایا دڵنیایت",
        EditDelegateInformation: "بژارکردنی زانیاری نوێنەری فرۆشتن",
        Name: "ناو",
        NameTitle: "نازناو",
        Freeze: "بەستن",
        DeleteRepresentativeAccount: "هەژماری نوێنەر بسڕەوە",
        PasswordsNotMatch: "تێپەڕەوشەکان هاوتا نین",
        SuccessfullyRegistraed: "بە سەرکەوتوویی تۆمارکرا",
        PasswordConfirm: "پشتڕاستکردنەوەی تێپەڕەوشە",
        Registration: "تۆمارکردن",
        GivePermission: "مۆڵەت بدە",
        InActive: "چالاک",
        ThisRepresentative: "ئەم نوێنەرە",
        NoNotifications: "هیچ ئاگانامەیەک نیە",
        TheNotifications: "ئاگانامەکان",
        More: "زیاتر",
        Quantity: "چەندێتی",
        SellingPrice: "نرخی فرۆشتن",
        PurchasingPrice: "نرخی کڕین",
        TotalNumberOfItems: "کۆی ژمارەی شتومەکەکان",
        SearchingFor: "گەڕان بۆ",
        HighestSellingPriceFirst: "بەرزترین نرخی فرۆشتنی یەکەم",
        LowestSellingPriceFirst: "نزمترین نرخی فرۆشتنی یەکەم",
        DateAddedMostRecentlyFirst: "سەرەتای بەروار زیادکراو لەم دواییە",
        DateAddedOldestFirst: "کۆنترین بەروار زیادکراو لەم دواییە",
        AlphabeticallyAscending: "ریزبەندیکردن لە ا-ی",
        AlphabeticallyDescending: "ریزبەندیکردن لە ی-ا",
        Cancel: "هەڵوەشاندنەوه",
        ThereIsNoItems: "هیچ شتومەکێک نییە",
        OrderBy: "ریزبەندیکردن بە پێی",
        NotFound: "نەدۆزرایەوە",
        MyCompanies: "کۆمپانیاکانم",
        Am: "رۆژ",
        Pm: "شەو",
        InSaved: "پاشەکەوت نەکراوە",
        FirstTimeBackup: "پاشەکەوتکردنی یەکەم جار",
        SecondTimeBackup: "پاشەکەوتکردنی جاری دووەم",
        OldPassword: "تێپەڕە وشەی کۆن",
        NewPassword: "تێپەڕە وشەی نوێ",
        NewPasswordConfrim: "پشتڕاستکردنەوەی تێپەڕە وشەی نوێ ",
        NewPasswordNotMatch: "تێپەڕە وشەی نوێ هاوتانییە",
        DoneChangePassword: "تێپەڕە وشەی نوێ بە سەرکەوتووی گۆردرا",
        PlzWrite: "تکایە بنووسە",
        Change: "گوڕین",
        ChangePassword: "گۆڕینی تێپەڕە وشە",
        LogoutFromAllDevises: "چووندەرەوە لە هەموو ئامێرەکان",
        AddReps: "زیادکردنی نوێنەری فرۆشتن",
        EditeReps: "دەستکاریکردن نوێنەری فرۆشتن",
        WorkWith: "کار بکە لەگەڵ",
        SerchByPhoneNameOrAddres: "بگەڕێ بە ناو یان ژمارە تەلەفون یان ناونیشان",
        ChoseAll: "هەڵبژاردنی هەمووی ",
        ChoseAsSearchResult: "هەڵبژێرە بە پشتبەستن بە ئەنجامەکانی گەڕان",
        AccountSitting: "رێکخستنەکانی هەژمار",
        ThirdPartyApps: "کاربەرنامەکانی بەشی سێیەم",
        TheresNoImage: "هیچ وێنەیەک نەدۆزایەوە",
        InstallTheApp: "بەرنامەکە دابەزێنە",
        DownloadFromStore: "DownloadFromStore",
        ThereIsNoData: "هیچ داتایەک نیە",
        Search: "گەڕان",
        CanNotAddExisstinNumber: "پێشتر ژمارەکە تۆمارکراوە",
        WeCounldNotFoundSomeoneWithThisNumber: "هیچ کەسێک بەم ژمارەیەوە نەدۆزرایەوە",
        CannotAddExisitinAccount: "پێشتر هەژمارەکە تۆمارکراوە",
        PlzWritePassword: "تکایە تێپەڕە وشە بنووسە",
        AreYouSuer: "ئایا دڵنیایت?",
        RongPassword: "تێپەڕە وشە هەڵەیە",
        AddNewCompanyName: "ناوی کۆمپانیای نوێ زیاد بکە",
        BackupTime: "کاتی پاشەکەوت",
        Owners: "خاوەنەکان",
        AddOwner: "زیادکردنی خاوەن",
        AccsesToAllThingInCompany: "توانای دەستگەشتن بە هەموو شتەکان کە پەیوەستە بە کۆمپانیا دوای زیادکردن",
        Worning: "ئاگاداربە",
        Add: "زیادکردن",
        AddOwnerWorning:
            "ئاگادارکردنەوە ناتوانیت دەست بگەیت بە هەموو ئەو شتانەی پەیوەندی بە کۆمپانیاکەوە هەیە دوای هەڵوەشانەوەی و بەکارهێنانی ئەم تایبەتمەندیە کاتێک کە کۆتایی بە هاوبەشی و خواستی خۆت دەدەیت بۆ لابردنی ئەم کۆمپانیایە لە هەژماری سندوق.",
        CancelOwnerShip: "هەڵوەشاندنەوەی مافی خاوەندارێتی",
        Confirm: "پشتڕاتکردنەوە",
        Code: "کۆد",
        SendCode: "ناردنی کۆد",
        ThisNumberIsUsed: "ئەم ژمارەیە پێشتر بەکارهاتووە",
        PlzCallSupport: "ژمارەی تەلەفۆنی بەکارهێنراو پێشتر تۆمارکراوە. تکایە پەیوەندی بکە بە پاڵپشتی بۆ دووبارە ڕێکخستنەوەی تێپەڕە وشەکەت ئەگەر لەبیرت چووبێت",
        RongCode: "کۆدەکە هەڵەیە",
        ConfirmCode: "پشتڕاستکردنەوەی کۆد",
        RegistrAccount: "هەژمارێک تۆمار بکە",
        Profile: "پرۆفایل",
        TotalAmount: "کۆی گشتی",
        TopBarSumaary: "پوختە",
        TheLanguage: "زمان",
        TheCurrency: "دراو",
        TheTime: "ماوە",
        TheType: "جۆر",
        Debt: "قەرزار/ئیما",
        MomentLocale: "en-au",
        DeleteRepresentativeAccounts: "هەژمێری نوێنەرەکە بسڕەوە",
        AddNewReps: "زیادکردنی نوێنەرەکی نوێ بۆ",
        RepsPermission: "دەسەڵاتەکانی نوێنەر",
        GiveDiscountPermission: "دەسەڵاتەی پێدانی داشکاندن",
        UnpaidAmount: "رێگە پێدانی هێشتنەوەی بڕی پارەی نەدراو",
        SavePermissions: "ڕێپێدانەکان بپارێزە",
        GeneralSales: "فرۆشتنی گشتی",
        CanUseNonGeneralPrices: "ڕەوایی بۆ بەکارهێنانی نرخی جوملە",
        PastBalance: "ئەژمێری بەکەرهینەر",
        ThereAreNoTicket: "هیچ بلیتێک نییە",
        ThereAreAccounts: "هیچ ئەکاونتێک نییە",
        ThereAreNoBusinesses: "هیچ کۆمپانیایەک نییە",
        PermissionToSaleInMoreThen50Meters: "ڕەوایەتی فرۆشتن بۆ مەودای زیاتر لە ٥٠ مەتر",
        CloseIt: "داخستن",
        RongPasswordOrPhoneNumber: "وشەی نهێنی یان ژمارەی تەلەفۆنی هەڵەیە",
        RongPhoneNumber: "ژمارە هەڵەیە",
        TryLater: "هەوڵەکان شکستیان هێنا، تکایە دواتر هەوڵبدەرەوە",
        InvoiceNumber: "ژمارەی وەەسڵ",
        TheAccount: "ئەکاونتەکە",
        MainMenu: "لیستی سه‌ره‌كی",
        Settings: "دانانەوە",
        AboutMyMorabaa: "دەربارەی ئەپەکە",
        DarkMode: "دۆخی شەو",
        LightMode: "دۆخی ڕۆژ",
        InvoiceTotalAmount: "کۆی گشتی وەەسڵ",
        Yes: "بەڵێ",
        CompaniseTitle: "کۆمپانیاکان",
        ThereIsNoBusinessTitle: "هیچ ناونیشانی کۆمپانیایەک نییە",
        ThereIsNoPhoneNumber: "ژمارەی تەلەفۆن نییە",
        ThereIsNoLocation: "هیچ ناونیشانێک نییە",
        ThereIsNoBalance: "هیچ کریدتێک نییە",
        RepsInfo: "زانیاری نوێنەر",
        FollowYourBusiness: "بەدواداچوونی دەستبەجێ بۆ کارەکەت",
        TheAppearance: "ڕووکارەکە",
        FreezeAccount: "بەستنی ئەکاونت",
        NotificationDue: "قیستەکان دەدرێن",
        NotificationExpire: "کەرەستەی بەسەرچوو",
        NotificationDeletedItems: "شتە سڕاوەکان",
        NotificationDeletedAccounts: "ئەکاونتە سڕاوەکان",
        NotificationDeletedSaleBills: "لیستە فرۆشتنەکان سڕاونەتەوە",
        NotificationDeletedBuyBill: "لیستە کڕینەکان سڕاونەتەوە",
        NotificationFaildBucks: "پاکەپ هەڵنەگیراوە",
        NotificationType: "جۆری ئاگادارکردنەوە",
        VoiceMessage: "پەیامێکی دەنگی",
        AddImage: "وێنەیەک هاوپێچ بکە",
        Create: "دروستکردن",
        TicketInfo: "زانیاری بلیتەکان",
        ThePrice: "نرخ",
        Wrnning: "ئاگادار کردنەوە",
        RepsActiveAccount: "ئەو ئەکاونتانەی کە دەتوانێت مامەڵەی لەگەڵ بکات",
        Menu: "لیستەکە",
        NoInternetConnection: "هێڵی ئینتەرنێتی نییە",
        Retry: "دووبارە هەوڵبدەرەوە",
        PhoneNumberWasAlreadyUsed: "ژمارەی تەلەفۆنەکە پێشتر لە بەکارهێناندایە",
        AboutText: "ئەم ئەپڵیکەیشنە بۆ ئاسانکاری کاری کۆمپانیا و دامەزراوە و دوکانەکان پەرەی پێدراوە کە سیستەمی سندوقی کارگێڕی و ژمێریاری بەکاردەهێنن",
        ForMoreInfo: "بۆ زانیاری زیاتر و بەشداریکردن لە خزمەتگوزاری زیاتر، تکایە سەردانی ماڵپەڕی چارەسەری نەرمەکاڵای ئەل مورەبا بکەن",
        DeleteTheAccount: "ئەکاونتەکە بسڕەوە",
        DeleteTheAccountText: "ئەم کردارە دەتوانرێت بەردەوام نەبێت، ئەگەر ئەکاونتەکە بسڕیت، هەموو زانیارییەکانی ئەو ئەکاونتە بسڕدرێتەوە",

        CanSendWithoutLocation: "Can send a new bill without location",
        Install: "Install",
        NewUpdateAvailble: "new update availble",
        Update: "Update",
        PleaseUpdate: "please update your app",
        AddToFavorite: "add to favorite",
        RemoveFromFavorite: "remove from favorite",
        TheApp: "TheApp",
        AccountsCount: "",
        AllTickets: "all tickets",
        ShareMenuUrlTitle: "menu",
        Share: "share menu url",
        GivePermissionToAll: "give permission to all accounts",
        Details: "details",
        NotAuthorized: "NotAuthorized",
        NotAuthorizedMessage: "NotAuthorizedMessage",
        CannotAddMoreRepresentatives: "CannotAddMoreRepresentatives",
        ConfirmCodeMessage: "ConfirmCodeMessage",
        PleaseWait: "PleaseWait",
        TooManyRequests: "We have blocked all requests from this device due to unusual activity. Try again later",
        InvalidPhoneNumber: "InvalidPhoneNumber",
        HaveAnAccount: "لديك حساب؟",
        CanSellOutOfStock: "صلاحية بيع المواد بالسالب",
        ForgotPassword: "هل نسيت كلمة المرور؟",
        EnterYourPhone: "ادخل رقم هافتك للاستمرار",
        DoneChangingPassword: "تم تغيير كلمة السر بنجاح",
        EnterYourNewPassword: "ادخل كلمة السر الجديدة",
    },
};
const languageKey = localStorage.getItem("language") || "ar";
const Language = Languages[languageKey];
export default Language;
export const ErrorTitles = {
    Error: Language.AnErrorOccurred,
    "Not Found": Language.NotFound,
    "Phone number was already used": Language.PhoneNumberWasAlreadyUsed,
    AboutText: "AboutText",
    ForMoreInfo: "ForMoreInfo",
    "Cannot add more representatives": Language.CannotAddMoreRepresentatives,
};
