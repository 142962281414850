import React, { useState } from "react";
import Form from "../../Components/Form";
import { useNavigate } from "react-router";
import { HubAPI } from "../../API/remoteAPI";
import Language from "../../Utils/Languages";
import CheckBox from "../../ComponentsV2/CheckBox";
import PasswordInput from "../../Components/PasswordInput";
import ChangeTitle from "../../Utils/ChangeTitle";
import LoaderComponent from "../../Components/LoaderComponent";
import ClearStorge from "../../Utils/ClearStorge";
import Info from "../../NodeBuilder/Info";

const ChangePasswordView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [logoutFromAllDevices, setLogoutFromAllDevices] = useState(false);

    let history = useNavigate();

    const setError = (title) => Info({ title, content: "PlzWrite" });

    React.useEffect(() => {
        ChangeTitle(Language.ChangePassword);
    }, []);

    const changePassword = () => {
        if (isLoading) return;
        if (!oldPassword) {
            setError(Language.OldPassword);
            return;
        } else if (!newPassword) {
            setError(Language.NewPassword);
            return;
        } else if (!newPasswordConfirm) {
            setError(Language.NewPasswordConfrim);
            return;
        } else if (newPassword !== newPasswordConfirm) {
            setError(Language.NewPasswordNotMatch);
            return;
        }
        let data = {
            oldPassword,
            newPassword,
            newPasswordConfirm,
            logoutFromAllDevices,
        };
        setIsLoading(true);
        HubAPI.post("/owners/password/change", data)
            .then(({ data }) => {
                if (data) {
                    Info({ title: "DoneChangePassword", type: "success" });
                    if (logoutFromAllDevices) ClearStorge(logoutFromAllDevices);
                    setOldPassword("");
                    setNewPassword("");
                    setNewPasswordConfirm("");
                }
            })
            .finally(() => setIsLoading(false));
    };

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <div className="h-screen flex flex-col">
            <Form className="my-auto" onSubmit={changePassword} submitText={Language.Change}>
                <PasswordInput placeholder="OldPassword" value={oldPassword} setValue={setOldPassword} />
                <PasswordInput placeholder="NewPassword" value={newPassword} setValue={setNewPassword} />
                <PasswordInput placeholder="NewPasswordConfrim" value={newPasswordConfirm} setValue={setNewPasswordConfirm} />
                <CheckBox onClick={() => setLogoutFromAllDevices(!logoutFromAllDevices)} title={Language.LogoutFromAllDevises} checked={logoutFromAllDevices} />
            </Form>
        </div>
    );
};

export default ChangePasswordView;
