import React from "react";
import { BillNumber } from "../../SVGs/InvoicesSvgs";
import Language from "../../Utils/Languages";
const BillNumberLabel = ({ number }) => {
    return (
        <div className="flex flex-col items-center">
            {BillNumber}
            <p className="font-medium text-center py-2 text-sm">
                <span className="carbonic-1">{Language.InvoiceNumber}</span>
                <br />
                <span className="carbonic-1 text-base">{number}</span>
            </p>
        </div>
    );
};

export default BillNumberLabel;
