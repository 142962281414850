import React from "react";
import { IconsCard } from "../SVGs/Reps";
import Language from "../Utils/Languages";

const IconCard = ({ icon, children }) => {
    return (
        <div className="rounded-3 carbonic-card shadow-1 p-4 m-4">
            <div className="flex flex-row items-center icon-card">
                {IconsCard[icon]}
                <p className="pr-4 carbonic font-bold">{Language[icon]}</p>
            </div>
            <div className="p-2">{children}</div>
        </div>
    );
};

export default IconCard;
