import React from "react";
import IconLabel from "./Components/IconLabel";
import ProfileHolder from "./Components/ProfileHolder";
import Language from "../Utils/Languages";
import { MenuLabels } from "../Utils/MenuLabels";
import ClearStorge from "../Utils/ClearStorge";
import Confirmer from "../Containers/Confirmer";

const HeaderTilte = ({ title }) => <p className="font-bold carbonic-1 py-3">{title}</p>;

const scuredRoutes = {
    rest: { title: Language.Restaurant, icon: "/menu/resturent.png", route: "/restaurant/menu/" },
    reps: { title: Language.Reps, icon: "/menu/reps.png", route: "/representatives/" },
};

const MenuView = () => {
    const owner = React.useMemo(() => JSON.parse(localStorage.getItem("owner")), []);

    const extraLabels = React.useMemo(() => (JSON.parse(localStorage.getItem("custom-labels")) || []).filter((o) => o), []);

    return (
        <div className="px-6 scroller scrller-container small wrapper">
            {owner && (
                <ProfileHolder
                    onClick={() => {}}
                    user={owner}
                    onLogout={() => {
                        Confirmer({
                            title: `${Language.Logout}!`,
                            confirm: ClearStorge,
                        });
                    }}
                />
            )}
            <HeaderTilte title={Language.MainMenu} />
            <IconLabel label={MenuLabels.Items[2]} />
            {extraLabels.map((labelKey) => {
                const label = scuredRoutes[labelKey];
                return label ? <IconLabel key={label.route} label={label} /> : <></>;
            })}
            {MenuLabels.Items.slice(3).map((label) => (
                <IconLabel key={label.route} label={label} />
            ))}

            <HeaderTilte title={Language.TheApp} />
            <IconLabel label={MenuLabels.Items[0]} />
            <IconLabel label={MenuLabels.Items[1]} />
        </div>
    );
};

export default MenuView;
