import React from "react";
import { Link } from "react-router-dom";
import Language from "../../Utils/Languages";
import { IconsCard } from "../../SVGs/Reps";
import ListButton from "../../ComponentsV2/ListButton";
import { Edit } from "../../SVGs/OtherSvgs";
import ChangeTitle from "../../Utils/ChangeTitle";

const ThisReps = ({ onListClick, business, children, reps, representatives, icon = "ThisRepresentative" }) => {
    return (
        <div className="rounded-3 carbonic-card shadow-1 p-4 m-4">
            <div className={`flex flex-row items-center icon-card ${reps.isActive ? "" : "un-"}active-reps`}>
                {IconsCard[icon]}
                <p className="pr-4 carbonic font-bold flex-grow ">{Language[icon]}</p>
                <Link
                    onClick={() => {
                        ChangeTitle(Language.AddReps);
                        localStorage.setItem("business", JSON.stringify(business));
                    }}
                    to={{ pathname: "/representatives/new", title: Language.AddReps }}>
                    <p className="pr-4 green-text font-bold cursor-pointer">
                        <span className="text-2xl px-1">+</span>
                        <span>{Language.AddReps}</span>
                    </p>
                </Link>
            </div>
            <div className="p-2">
                {children}
                <div className="flex flex-row items-center">
                    <ListButton canSelect={representatives.length > 1} onClick={onListClick} title={reps.title} />
                    <Link
                        onClick={() => {
                            ChangeTitle(Language.EditDelegateInformation + " " + reps?.firstName + " " + reps?.lastName);
                            localStorage.setItem("repsToEdit", JSON.stringify(reps));
                        }}
                        to={{ pathname: "/representatives/edit", title: Language.AddReps }}>
                        {Edit}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ThisReps;
