import { Link } from "react-router-dom";
import Language from "../Utils/Languages";

const PopupNotificatinoCard = ({ confirm, title, content = "", cancelText = Language.Cancel, contfrimText = Language.Confirm, url, close }) => {
    return (
        <>
            <p className="font-bold text-xl text-center">{title}</p>
            <p>{content}</p>
            <div className="row gap-10 mt-2 justify-center">
                {url ? (
                    <Link to={url} className="bg-red text-white py-1 px-4 shadow-1 text-center font-bold cursor-pointer" style={{ borderRadius: 5 }}>
                        <p>{contfrimText}</p>
                    </Link>
                ) : (
                    <p
                        className="bg-red text-white py-1 px-4 shadow-1 text-center font-bold cursor-pointer"
                        style="border-radius:5px;"
                        onClick={() => {
                            close && close();
                            confirm();
                        }}>
                        {contfrimText}
                    </p>
                )}
                <p
                    className="carbonic carbonic-card-1 py-1 px-4 shadow-1 text-center font-bold cursor-pointer"
                    style={{ borderRadius: 5 }}
                    onClick={() => {
                        close && close();
                    }}>
                    {cancelText}
                </p>
            </div>
        </>
    );
};

export default PopupNotificatinoCard;
