// const formatter = new Intl.NumberFormat("en-US");
// const Formate = (amount, currencyId) => {
//     amount = parseFloat(amount, currencyId);
//     if (!amount) return 0;
//     if (amount >= 0) return formatter.format(amount);
//     return `${formatter.format(Math.abs(amount))}-`;
// };

const Formate = (amount) => {
    let newVal = `${amount}`?.replace("-", "").split("."),
        beforPoint = newVal[0],
        length = beforPoint?.length,
        owl = newVal[1] && !newVal[1]?.startsWith("00") ? `.${newVal[1].length > 2 ? newVal[1].substring(0, 2) : newVal[1]}` : "";
    for (let o = length; o > 0; o -= 3) o - 3 > 0 ? (owl = `,${beforPoint.substring(o, o - 3)}${owl}`) : (owl = beforPoint.substring(0, o) + owl);
    return amount >= 0 ? owl : `- ${owl}`;
};

export default Formate;
