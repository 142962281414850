import Tab from "./Tab";
import Language from "../Utils/Languages";
import { RouteTitles } from "../Utils/MenuLabels";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ChangeTitle from "../Utils/ChangeTitle";
import Rotater from "./Rotater";

const routs = {
    "/menu/": { title: Language.Menu, i: 0 },
    "/accounts/": { title: Language.AccountsBalances, i: 1 },
    "/": { title: "", i: 2 },
    "/items/": { title: Language.Items, i: 3 },
    "/notifications/": { title: Language.TheNotifications, i: 4 },
};

const toggleBackButton = (display = "block") => {
    document.getElementById("back-button").style.display = display;
};

const BottomBar = () => {
    const location = useLocation();
    const [selected, setSelected] = useState(routs[location.pathname]);

    useEffect(() => {
        let _route = routs[location.pathname];
        setSelected(_route);
        if (!_route) toggleBackButton("block");
        else handleRotater(_route);
        let title = RouteTitles[location.pathname];
        if (title) ChangeTitle(title);
    }, [location]);

    return selected ? (
        <div id="bottom-bar" className="fixed max-w-5xl right-0 left-0 bottom-0 mx-auto " style={{ zIndex: 3001 }}>
            <div className="bottom-bar text-center pt-4 shadow-1 tab-container-bg safe-area-bottom">
                {Object.entries(routs).map(([route, { title, i }]) => (
                    <Tab title={title} key={i} index={i} route={route} active={selected.i === i ? "active" : ""} />
                ))}
                <Rotater />
            </div>
        </div>
    ) : (
        <></>
    );
};

export default React.memo(BottomBar);
function handleRotater(_route) {
    setTimeout(() => {
        toggleBackButton("none");
        const rotater = document.getElementById("rotater");
        if (!rotater) return;
        rotater.style.transform = `rotate(${[180, 90, 0, -90, -180][_route.i]}deg)`;
        if (_route.i === 2) rotater.setAttribute("aria-selected", "true");
        else rotater.removeAttribute("aria-selected");
    }, 100);
}
