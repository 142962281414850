export const BottomBarSvgs = [
    <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 283.12">
        <path
            d="M689,375c-4.18,10.15-7.2,12.12-18.52,12.12H329c-10.77,0-13.91-2.11-18-12.12v-5c3.27-8.58,9.47-11.57,18.61-11.55q170.58.29,341.16.13c11.05,0,14.14,2.14,18.23,12.42Z"
            transform="translate(-311 -358.45)"
        />
        <path
            d="M311,625c4.15-10,7.24-12.12,18-12.12H670.48c11.35,0,14.36,2,18.52,12.12v4c-4.26,10.47-7.17,12.42-18.74,12.42q-170.32,0-340.65.13c-9.12,0-15.34-3-18.61-11.55Z"
            transform="translate(-311 -358.45)"
        />
        <path
            d="M689,502c-4.17,10.24-7.18,12.25-18.41,12.25H329.4c-11.24,0-14.21-2-18.4-12.25v-4c4.23-10.28,7.15-12.24,18.38-12.24H670.59c11.26,0,14.25,2,18.41,12.24Z"
            transform="translate(-311 -358.45)"
        />
    </svg>,
    <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.59 394.96">
        <path
            d="M300.11,515.13c2.18-8.55,5.09-16.7,11.35-23.3,8.44-8.89,18.72-13.65,30.94-13.69q56-.17,112.05,0c9.11,0,15.22,5.72,15.25,14.8.13,40.35.07,80.7,0,121.05,0,7.61-4.46,12.09-11.07,14.87H435.74c-7.21-1-14.44-1.77-21.62-2.88-26.52-4.12-51.57-12.3-73.86-27.68-16.76-11.57-30.19-26-36.66-45.79-1.54-4.69-2.35-9.61-3.49-14.42Z"
            transform="translate(-300.11 -233.87)"
        />
        <path
            d="M450.82,441c-57,.15-103.69-46.5-103.67-103.56s46.29-103.35,103.16-103.52A103.66,103.66,0,0,1,554.24,337.14C554.45,394.16,508,440.82,450.82,441Z"
            transform="translate(-300.11 -233.87)"
        />
        <path
            d="M577.71,478.1c21.74,0,43.49,0,65.23,0,6.71,0,12.27,4,14.09,9.83,1.77,5.66,0,12.46-4.89,15.56a19.33,19.33,0,0,1-9.7,3q-65,.3-130,.08c-9.5,0-15.28-6-15.1-14.75.16-8.21,6.42-13.77,15.6-13.78Q545.35,478.07,577.71,478.1Z"
            transform="translate(-300.11 -233.87)"
        />
        <path
            d="M577.35,590.81c21.91,0,43.82,0,65.73,0,7.33,0,13,4.73,14.23,11.43,1.28,7.12-2.14,13.69-8.69,16a22.19,22.19,0,0,1-7.31,1.09q-64,.09-128,0c-9,0-14.9-4.65-15.87-11.94-1-7.69,3.34-14.4,10.72-16.21a22.77,22.77,0,0,1,5.42-.41Q545.49,590.78,577.35,590.81Z"
            transform="translate(-300.11 -233.87)"
        />
        <path
            d="M558.79,534.79c15.77,0,31.55,0,47.32,0,5.91,0,10,3,12.54,8.22s2,10.57-1.94,14.29c-3,2.82-7.64,5.39-11.58,5.45-31,.43-62.09.31-93.13.19-8.52,0-14.51-6.3-14.44-14.43s5.66-13.63,14.42-13.72c15.6-.17,31.21,0,46.81,0Z"
            transform="translate(-300.11 -233.87)"
        />
    </svg>,
    <svg height={30} width={30} className="tint-main-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.1 417">
        <path
            d="M292,631V366a18.84,18.84,0,0,0,.91-2.75c5.43-32,23.27-54.24,53-66.85,5.75-2.44,12-3.63,18.08-5.4H636a22.32,22.32,0,0,0,2.79.94c40,7.65,69,41.87,69.17,82.66.26,66.31.09,132.63.06,198.95a77.2,77.2,0,0,1-8.77,36.56c-16.53,31.25-42.7,47.21-78,47.38-54.49.27-109,.07-163.47.07H451V708H375c-1.6-.61-3.17-1.63-4.81-1.77-34.28-2.94-63.21-25.51-73.86-58C294.49,642.63,293.42,636.75,292,631Zm111-20.44h7q104.22,0,208.45,0c25.82,0,42.57-16.73,42.58-42.51q.06-94,0-187.95c0-25.83-16.71-42.62-42.46-42.64q-118.47-.06-237,0c-25.82,0-42.62,16.79-42.64,42.48q0,43,0,86,0,76.23,0,152.46c0,17.7,9.67,32.38,25.3,38.36,12.53,4.8,25.55,2.24,38.7,2.82Z"
            transform="translate(-292 -291)"
        />
    </svg>,
    <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 105">
        <path d="M114,2.36H54v30.69c0,1.2-.65,2.31-1.7,2.88-.5,.27-1.04,.4-1.58,.4-.61,0-1.23-.17-1.76-.51l-12.46-7.95-12.46,7.95c-1.01,.64-2.29,.69-3.34,.11-1.05-.57-1.7-1.68-1.7-2.88V2.36h-6c-4.42,0-8,3.58-8,8V94.64c0,4.42,3.58,8,8,8H114c4.42,0,8-3.58,8-8V10.36c0-4.42-3.58-8-8-8ZM17.66,78.07l7.84-13.58c.44-.77,1.56-.77,2,0l7.84,13.58c.45,.77-.11,1.73-1,1.73h-4.56v7.43c0,.82-.66,1.49-1.49,1.49h-3.58c-.83,0-1.49-.67-1.49-1.49v-7.43h-4.56c-.89,0-1.45-.96-1-1.73Zm17.25,15.84c-.36,.37-.86,.59-1.41,.59h-14c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41,.36-.37,.86-.59,1.41-.59h14c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41Zm50.68-18.82c.36-.37,.86-.59,1.41-.59h12c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41-.36,.37-.86,.59-1.41,.59h-12c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41Zm22.82,18.82c-.36,.37-.86,.59-1.41,.59h-20c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41,.36-.37,.86-.59,1.41-.59h20c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41Zm8-8c-.36,.37-.86,.59-1.41,.59h-28c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41,.36-.37,.86-.59,1.41-.59h28c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41ZM36.5,23.88c.75,0,1.5,.2,2.15,.62l11.35,7.24V2.5H23V31.74l11.35-7.24c.65-.42,1.4-.62,2.15-.62Z" />
    </svg>,
    <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 368.19">
        <path
            d="M670,581v6a13.26,13.26,0,0,0-.82,2.28,32.65,32.65,0,0,1-32.72,27.84q-136.43,0-272.85,0c-15.16,0-27.58-9.33-31.92-23.91-.61-2.06-1.13-4.14-1.69-6.21v-6c3.26-7.94,6.45-15.91,9.83-23.8a73.69,73.69,0,0,1,5.53-11.15c11.17-17.24,14.53-35.92,13.58-56.38a301.09,301.09,0,0,1,2.2-55.27c7.81-56,52.92-103.58,108.3-115.46,9.69-2.08,19.7-2.69,29.56-4h2c2,.25,3.94.46,5.9.77,10.63,1.68,21.54,2.38,31.85,5.25A139.93,139.93,0,0,1,641.17,452.38c.52,20.46.07,40.95.34,61.42A27.18,27.18,0,0,0,644,524.21C652.5,543.21,661.3,562.1,670,581Z"
            transform="translate(-330 -315)"
        />
        <path d="M555.2,636.09c-.7,23.51-26.39,46.12-53.3,47.06-28.08,1-54.7-20.72-57.31-47.06Z" transform="translate(-330 -315)" />
    </svg>,
];

// const PngIcon = (iconPath) => {
//     return <img src={iconPath} style={{ height: 50, width: 50 }} />;
// };

// export const BottomBarSvgs = {
//     menu: (
//         <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378 283.12">
//             <path
//                 d="M689,375c-4.18,10.15-7.2,12.12-18.52,12.12H329c-10.77,0-13.91-2.11-18-12.12v-5c3.27-8.58,9.47-11.57,18.61-11.55q170.58.29,341.16.13c11.05,0,14.14,2.14,18.23,12.42Z"
//                 transform="translate(-311 -358.45)"
//             />
//             <path
//                 d="M311,625c4.15-10,7.24-12.12,18-12.12H670.48c11.35,0,14.36,2,18.52,12.12v4c-4.26,10.47-7.17,12.42-18.74,12.42q-170.32,0-340.65.13c-9.12,0-15.34-3-18.61-11.55Z"
//                 transform="translate(-311 -358.45)"
//             />
//             <path
//                 d="M689,502c-4.17,10.24-7.18,12.25-18.41,12.25H329.4c-11.24,0-14.21-2-18.4-12.25v-4c4.23-10.28,7.15-12.24,18.38-12.24H670.59c11.26,0,14.25,2,18.41,12.24Z"
//                 transform="translate(-311 -358.45)"
//             />
//         </svg>
//     ),
//     accounts: (
//         <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.59 394.96">
//             <path
//                 d="M300.11,515.13c2.18-8.55,5.09-16.7,11.35-23.3,8.44-8.89,18.72-13.65,30.94-13.69q56-.17,112.05,0c9.11,0,15.22,5.72,15.25,14.8.13,40.35.07,80.7,0,121.05,0,7.61-4.46,12.09-11.07,14.87H435.74c-7.21-1-14.44-1.77-21.62-2.88-26.52-4.12-51.57-12.3-73.86-27.68-16.76-11.57-30.19-26-36.66-45.79-1.54-4.69-2.35-9.61-3.49-14.42Z"
//                 transform="translate(-300.11 -233.87)"
//             />
//             <path
//                 d="M450.82,441c-57,.15-103.69-46.5-103.67-103.56s46.29-103.35,103.16-103.52A103.66,103.66,0,0,1,554.24,337.14C554.45,394.16,508,440.82,450.82,441Z"
//                 transform="translate(-300.11 -233.87)"
//             />
//             <path
//                 d="M577.71,478.1c21.74,0,43.49,0,65.23,0,6.71,0,12.27,4,14.09,9.83,1.77,5.66,0,12.46-4.89,15.56a19.33,19.33,0,0,1-9.7,3q-65,.3-130,.08c-9.5,0-15.28-6-15.1-14.75.16-8.21,6.42-13.77,15.6-13.78Q545.35,478.07,577.71,478.1Z"
//                 transform="translate(-300.11 -233.87)"
//             />
//             <path
//                 d="M577.35,590.81c21.91,0,43.82,0,65.73,0,7.33,0,13,4.73,14.23,11.43,1.28,7.12-2.14,13.69-8.69,16a22.19,22.19,0,0,1-7.31,1.09q-64,.09-128,0c-9,0-14.9-4.65-15.87-11.94-1-7.69,3.34-14.4,10.72-16.21a22.77,22.77,0,0,1,5.42-.41Q545.49,590.78,577.35,590.81Z"
//                 transform="translate(-300.11 -233.87)"
//             />
//             <path
//                 d="M558.79,534.79c15.77,0,31.55,0,47.32,0,5.91,0,10,3,12.54,8.22s2,10.57-1.94,14.29c-3,2.82-7.64,5.39-11.58,5.45-31,.43-62.09.31-93.13.19-8.52,0-14.51-6.3-14.44-14.43s5.66-13.63,14.42-13.72c15.6-.17,31.21,0,46.81,0Z"
//                 transform="translate(-300.11 -233.87)"
//             />
//         </svg>
//     ),
//     home: (
//         <svg style={{ backgroundColor: "red" }} height={30} width={30} className="tint-main-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.1 417">
//             <path
//                 d="M292,631V366a18.84,18.84,0,0,0,.91-2.75c5.43-32,23.27-54.24,53-66.85,5.75-2.44,12-3.63,18.08-5.4H636a22.32,22.32,0,0,0,2.79.94c40,7.65,69,41.87,69.17,82.66.26,66.31.09,132.63.06,198.95a77.2,77.2,0,0,1-8.77,36.56c-16.53,31.25-42.7,47.21-78,47.38-54.49.27-109,.07-163.47.07H451V708H375c-1.6-.61-3.17-1.63-4.81-1.77-34.28-2.94-63.21-25.51-73.86-58C294.49,642.63,293.42,636.75,292,631Zm111-20.44h7q104.22,0,208.45,0c25.82,0,42.57-16.73,42.58-42.51q.06-94,0-187.95c0-25.83-16.71-42.62-42.46-42.64q-118.47-.06-237,0c-25.82,0-42.62,16.79-42.64,42.48q0,43,0,86,0,76.23,0,152.46c0,17.7,9.67,32.38,25.3,38.36,12.53,4.8,25.55,2.24,38.7,2.82Z"
//                 transform="translate(-292 -291)"
//             />
//         </svg>
//     ),
//     items: (
//         <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 105">
//             <path d="M114,2.36H54v30.69c0,1.2-.65,2.31-1.7,2.88-.5,.27-1.04,.4-1.58,.4-.61,0-1.23-.17-1.76-.51l-12.46-7.95-12.46,7.95c-1.01,.64-2.29,.69-3.34,.11-1.05-.57-1.7-1.68-1.7-2.88V2.36h-6c-4.42,0-8,3.58-8,8V94.64c0,4.42,3.58,8,8,8H114c4.42,0,8-3.58,8-8V10.36c0-4.42-3.58-8-8-8ZM17.66,78.07l7.84-13.58c.44-.77,1.56-.77,2,0l7.84,13.58c.45,.77-.11,1.73-1,1.73h-4.56v7.43c0,.82-.66,1.49-1.49,1.49h-3.58c-.83,0-1.49-.67-1.49-1.49v-7.43h-4.56c-.89,0-1.45-.96-1-1.73Zm17.25,15.84c-.36,.37-.86,.59-1.41,.59h-14c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41,.36-.37,.86-.59,1.41-.59h14c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41Zm50.68-18.82c.36-.37,.86-.59,1.41-.59h12c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41-.36,.37-.86,.59-1.41,.59h-12c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41Zm22.82,18.82c-.36,.37-.86,.59-1.41,.59h-20c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41,.36-.37,.86-.59,1.41-.59h20c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41Zm8-8c-.36,.37-.86,.59-1.41,.59h-28c-1.1,0-2-.9-2-2,0-.55,.22-1.05,.59-1.41,.36-.37,.86-.59,1.41-.59h28c1.1,0,2,.9,2,2,0,.55-.22,1.05-.59,1.41ZM36.5,23.88c.75,0,1.5,.2,2.15,.62l11.35,7.24V2.5H23V31.74l11.35-7.24c.65-.42,1.4-.62,2.15-.62Z" />
//         </svg>
//     ),
//     notification: (
//         <svg height={30} width={30} className="tint-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 368.19">
//             <path
//                 d="M670,581v6a13.26,13.26,0,0,0-.82,2.28,32.65,32.65,0,0,1-32.72,27.84q-136.43,0-272.85,0c-15.16,0-27.58-9.33-31.92-23.91-.61-2.06-1.13-4.14-1.69-6.21v-6c3.26-7.94,6.45-15.91,9.83-23.8a73.69,73.69,0,0,1,5.53-11.15c11.17-17.24,14.53-35.92,13.58-56.38a301.09,301.09,0,0,1,2.2-55.27c7.81-56,52.92-103.58,108.3-115.46,9.69-2.08,19.7-2.69,29.56-4h2c2,.25,3.94.46,5.9.77,10.63,1.68,21.54,2.38,31.85,5.25A139.93,139.93,0,0,1,641.17,452.38c.52,20.46.07,40.95.34,61.42A27.18,27.18,0,0,0,644,524.21C652.5,543.21,661.3,562.1,670,581Z"
//                 transform="translate(-330 -315)"
//             />
//             <path d="M555.2,636.09c-.7,23.51-26.39,46.12-53.3,47.06-28.08,1-54.7-20.72-57.31-47.06Z" transform="translate(-330 -315)" />
//         </svg>
//     ),
//     businesses: PngIcon("/menu/businesses.png"),
//     customers: PngIcon("/menu/cutsomers.png"),
//     tickets: PngIcon("/menu/tickets.png"),
//     invoices: PngIcon("/menu/invoices.png"),
//     restaurant: PngIcon("/menu/resturent.png"),
//     reps: PngIcon("/menu/reps.png"),
//     settings: PngIcon("/menu/settings.png"),
//     about: PngIcon("/menu/about.png"),
// };

// const getMenuIcon = (icon) => {
//     return BottomBarSvgs[icon] ?? BottomBarSvgs["items"];
// };
// export default getMenuIcon;
