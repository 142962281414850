import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
const LoginView = React.lazy(() => import("../Auth/Views/LoginView"));
const RegisterView = React.lazy(() => import("../Auth/Views/RegisterView"));
const LoginFor3rdPartyView = React.lazy(() => import("../Auth/Views/LoginFor3rdPartyView"));
const RedirectView = React.lazy(() => import("./RedirectView"));
const AlreadyLoggedInView = React.lazy(() => import("../Auth/Views/AlreadyLoggedInView"));
const Forgetpassword = React.lazy(() => import("../Auth/Views/Forgetpassword"));

const UnAuthedRoutes = () => (
    <React.Suspense>
        <Routes>
            <Route path="/login" element={<LoginView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/login/app" element={<LoginFor3rdPartyView />} />
            <Route path="/alreadyloggedin/:token/" element={<AlreadyLoggedInView />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
            <Route path="*" element={<RedirectView />} />
        </Routes>
    </React.Suspense>
);

export default UnAuthedRoutes;
