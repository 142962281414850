import React from "react";
import { Link } from "react-router-dom";
import { BottomBarSvgs } from "../SVGs/BottomBarSvgs";

const Tab = ({ title, index, onClick, active, route }) => (
    <Link to={route} className={active} onClick={onClick} style={{ postion: "relative", zIndex: 1 }}>
        {BottomBarSvgs[index]}
        <span className="font-bold pt-2" style={{ color: active ? "#DD3643" : "#0000", height: 24, fontSize: 12 }}>
            {title}
        </span>
    </Link>
);

export default Tab;
