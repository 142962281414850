import React from "react";
import Language from "../../Utils/Languages";
import GlowLabel from "../../ComponentsV2/GlowLabel";
import HeaderLabel from "../../ComponentsV2/HeaderLabel";

const TotalCard = ({ provider }) => {
    const total = provider.header;
    return (
        <div className="carbonic-card shadow-1 rounded-2xl overflow-hidden mt-4">
            <p className="carbonic font-bold text-xl text-center pt-2">{Language.AllCompanies} </p>
            <div className="flex flex-row justify-around pb-8">
                <GlowLabel total={total} prop="profits" color="green-text" />
                <GlowLabel total={total} prop="sales" color="cyan-text" />
            </div>
            <div className="carbonic-card-1 border-top flex-row flex" style={{ borderTopWidth: 1 }}>
                <HeaderLabel total={total} prop="expenses" extra="border-left" />
                <HeaderLabel total={total} prop="purchases" />
            </div>
        </div>
    );
};

export default TotalCard;
